import * as React from 'react';
import { Chips } from '@cian/ui-kit';
import { IconProductTopSeller16 } from '@cian/ui-kit-design-tokens/icons';

import { getOffersCount } from '../../actions/offersData';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { isSalesLeaderDescriptor } from '../../constants/descriptors';
import { updateTags } from '../../actions/filterTags';
import { isDescriptorActive } from '../../utils/filters';
import { TTypedThunkDispatch } from '../../types/thunk';

export interface IIsSalesLeaderProps extends IDescriptorProps<string> {
  getOffersCount(): void;
  updateTags(): void;
}

export const IsSalesLeaderComponent = ({
  actionCreator,
  descriptors,
  jsonQuery,
  getOffersCount,
  updateTags,
}: IIsSalesLeaderProps) => {
  const activeDescriptor = React.useMemo(
    () => descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery)) || descriptors[1],
    [descriptors, jsonQuery],
  );

  const isActive = activeDescriptor.state === 'On';
  const value = React.useMemo(() => (isActive ? [true] : []), [isActive]);
  const options = React.useMemo(
    () => [{ label: activeDescriptor.label, value: true, icon: <IconProductTopSeller16 /> }],
    [activeDescriptor],
  );

  const handleStateChange = React.useCallback(() => {
    actionCreator(isActive ? descriptors[1] : descriptors[0])();
    updateTags();
    getOffersCount();
  }, [isActive, actionCreator, getOffersCount, updateTags]);

  return <Chips options={options} size="S" value={value} onChange={handleStateChange} iconPosition="left" />;
};

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
    updateTags: () => {
      dispatch(updateTags());
    },
  };
}

export const IsSalesLeader = connectToDescriptors<{}, {}>(
  isSalesLeaderDescriptor,
  mapDispatchToProps,
)(IsSalesLeaderComponent);
