import { getHttpApi } from '@cian/http-api/browser';

import {
  IApiGeoGeocodedForSearchRequest,
  IApiGeoGeocodedForSearchResponse200,
  IApiGeoGeocodedForSearchResponse400,
  fetchApiGeoGeocodedForSearch,
} from '../../repositories/monolith-cian-realty/unversioned/api-geo-geocoded-for-search';

export async function getGeocodedSearchData(parameters: IApiGeoGeocodedForSearchRequest, subdomain?: string) {
  // eslint-disable-next-line no-useless-catch
  try {
    const httpApi = getHttpApi();
    const { response, statusCode } = await fetchApiGeoGeocodedForSearch<
      IApiGeoGeocodedForSearchResponse200,
      IApiGeoGeocodedForSearchResponse400
    >({
      httpApi,
      parameters,
      config: {
        bodyAsEncodeString: true,
        requestConfig: {
          headers: [['Content-Type', 'application/x-www-form-urlencoded']],
        },
        subdomain: subdomain || 'www',
      },
    });

    if ('errors' in response || statusCode !== 200) {
      throw response;
    }

    return response;
  } catch (error) {
    throw error;
  }
}
