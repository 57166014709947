import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';

export enum ERegionCitiesActions {
  set = 'set/regionCities',
  clear = 'clear/regionCities',
}

export interface IRegionCitiesAction {
  type: ERegionCitiesActions;
  data: ISearchCatalogItemSchema[];
}

export interface IRegionCitiesClearAction {
  type: ERegionCitiesActions;
}

export function setRegionCities(regionCitiesData: ISearchCatalogItemSchema[]) {
  return {
    data: regionCitiesData,
    type: ERegionCitiesActions.set,
  };
}

export function clearRegionCities() {
  return {
    type: ERegionCitiesActions.clear,
  };
}
