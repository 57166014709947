import { useSelector } from 'react-redux';
import { getSalesDescription } from '../../selectors/getSalesDescription';
import * as React from 'react';
import { DiscountMortgageDescription } from '../../components/DiscountMortgageDescription';
import { DiscountMortgageDescriptionPopup } from '../../components/DiscountMortgageDescriptionPopup';
import { getDiscountMortgagePopup } from '../../selectors/discountMortgage';

export const DiscountMortgageDescriptionContainer = () => {
  const [open, setOpen] = React.useState(false);
  const description = useSelector(getSalesDescription);
  const descriptionText = React.useMemo(() => (description ? description.text.split('\n') : []), [description]);
  const popup = useSelector(getDiscountMortgagePopup);

  const setClose = React.useCallback(() => setOpen(false), []);
  const onClick = React.useCallback(() => setOpen(true), []);

  if (!description || !popup) {
    return null;
  }

  return (
    <>
      <DiscountMortgageDescription onClick={onClick} text={descriptionText} type={description.salesType} />
      <DiscountMortgageDescriptionPopup
        description={popup.description}
        props={popup.props}
        internalBannerUrl="https://www.cian.ru/ipoteka/?banner=header-gov-help"
        onClose={setClose}
        open={open}
        title={popup.name}
      />
    </>
  );
};
