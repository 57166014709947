// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ISuggestRequest,
  TSuggestModel,
  IMappers,
  TSuggestResponse,
  ISuggestResponse,
  ISuggestResponseError,
} from './types';

export const defaultConfig: TSuggestModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'geo-suggest',
  pathApi: '/v2/suggest/',
} as TSuggestModel;

export function createSuggestModel(parameters: ISuggestRequest): TSuggestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISuggestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ISuggestRequest;
}

export async function fetchSuggest<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ISuggestOptions<TResponse200, TResponse400>): Promise<TSuggestResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createSuggestModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISuggestResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ISuggestResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TSuggestResponse;
}
