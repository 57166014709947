import { IQuickLink } from '../../../types/quickLinks';

export const MORE_BUTTON_LENGTH = 48;
export const LINK_SYMBOL_LENGTH = 8;
export const LINK_GAP = 4;
export const LINKS_GAP = 16;

function getLinkLength(link: IQuickLink): number {
  const titleLength = link.title.length;
  const counterLength = link.counter.toString().length;

  return titleLength * LINK_SYMBOL_LENGTH + LINK_GAP + counterLength * LINK_SYMBOL_LENGTH;
}

export function getLinksLength(blockWidth: number, links: IQuickLink[]): [length: number, width: number] {
  const width = blockWidth - MORE_BUTTON_LENGTH - LINKS_GAP;
  let linksWidth = 0;
  let linksCount = 0;

  for (const link of links) {
    const linkLength = getLinkLength(link) + LINKS_GAP;

    if (linksWidth + linkLength > width) {
      break;
    }

    linksWidth += linkLength;
    linksCount++;
  }

  return [linksWidth + MORE_BUTTON_LENGTH + LINKS_GAP, linksCount];
}
