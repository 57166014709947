import * as React from 'react';
import { Chips } from '@cian/ui-kit';

import { IDescriptorProps, isDescriptorActive } from '../../utils/filters';
import { TTypedThunkDispatch } from '../../types/thunk';
import { updateTags } from '../../actions/filterTags';
import { getOffersCount } from '../../actions/offersData';
import { connectToDescriptors } from '../сonnectToDescriptors';
import { hasDiscountMortgageDescriptor } from '../../constants/descriptors';

export interface IHasDiscountMortgageProps extends IDescriptorProps<string> {
  getOffersCount(): void;
  updateTags(): void;
}

export const HasDiscountMortgageComponent = ({
  actionCreator,
  descriptors,
  jsonQuery,
  getOffersCount,
  updateTags,
}: IHasDiscountMortgageProps) => {
  const activeDescriptor = React.useMemo(
    () => descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery)) || descriptors[1],
    [descriptors, jsonQuery],
  );

  const isActive = activeDescriptor.state === 'On';
  const value = React.useMemo(() => (isActive ? [true] : []), [isActive]);
  const options = React.useMemo(() => [{ label: activeDescriptor.label, value: true }], [activeDescriptor]);

  const handleStateChange = React.useCallback(() => {
    actionCreator(isActive ? descriptors[1] : descriptors[0])();
    updateTags();
    getOffersCount();
  }, [isActive, actionCreator, getOffersCount, updateTags]);

  return <Chips options={options} size="S" value={value} onChange={handleStateChange} />;
};

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
    updateTags: () => {
      dispatch(updateTags());
    },
  };
}

export const HasDiscountMortgage = connectToDescriptors<{}, {}>(
  hasDiscountMortgageDescriptor,
  mapDispatchToProps,
)(HasDiscountMortgageComponent);
