import { IJsonQuery } from '../../utils/filters';
import { IOffersJsonQueryRequestSchema } from '../../types/interfaces';
import { NEWBUILDINGS_GK_SUGGEST_COUNT } from '../../constants/config';
import { IHttpApi } from '@cian/http-api';
import {
  fetchGetNewbuildingsForSerp,
  TGetNewbuildingsForSerpResponse,
} from '../../repositories/newbuilding-search/v1/get-newbuildings-for-serp';
import { NotFoundError } from '@cian/peperrors/shared';
import { IOffersDataState } from '../../types/offersData';

export function getOffersData(
  httpApi: IHttpApi,
  jsonQuery: IJsonQuery,
  uri: string,
  subdomain: string,
  canUseHiddenBase: boolean,
  offset?: number,
  count?: number,
): Promise<TGetNewbuildingsForSerpResponse> {
  const parameters: IOffersJsonQueryRequestSchema = {
    jsonQuery,
    uri,
    subdomain,
    offset,
    count,
    userCanUseHiddenBase: canUseHiddenBase,
  };

  return fetchGetNewbuildingsForSerp({ httpApi, parameters });
}

export async function resolveOffersData(
  httpApi: IHttpApi,
  jsonQuery: IJsonQuery,
  uri: string,
  subdomain: string,
  page: number = 1,
  canUseHiddenBase: boolean,
): Promise<IOffersDataState> {
  const offset = (page - 1) * NEWBUILDINGS_GK_SUGGEST_COUNT;
  const getSearchOffersDesktopResponse = await getOffersData(
    httpApi,
    jsonQuery,
    uri,
    subdomain,
    canUseHiddenBase,
    offset,
    NEWBUILDINGS_GK_SUGGEST_COUNT,
  );
  if (getSearchOffersDesktopResponse.statusCode === 200) {
    return getSearchOffersDesktopResponse.response as IOffersDataState;
  } else {
    throw new NotFoundError({
      domain: 'src/shared/api/offersData/getOffersData',
      message: 'newbuildings data for serp response error',
    });
  }
}
