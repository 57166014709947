import { INewbuildingPromoSnippetBuilders, INewbuildingPromoSnippetSellers } from '../../types/newbuilding';

export function getNewbuildingPromoSnippetDct(
  index: number,
  contactList: INewbuildingPromoSnippetBuilders[] | INewbuildingPromoSnippetSellers[],
) {
  const extensionFields = contactList[index];

  return {
    phone: {
      countryCode: extensionFields?.phone.code,
      code: extensionFields?.phone.countryCode,
      number: extensionFields?.phone.number,
    },
    realtyUserId: extensionFields?.realtyUserId,
    dynamicCalltracking: extensionFields?.newbuildingDynamicCalltracking,
    showPhone: !!extensionFields?.showPhone,
  };
}
