import * as React from 'react';

/***** STYLES *****/
import * as styles from './Text.css';

/***** UTILS *****/
import { IStyleArgument, mergeStyles } from '@cian/utils';
import { isNil } from 'ramda';

/***** CONSTANTS *****/
const TYPES_TO_TAGS: { [type: string]: keyof JSX.IntrinsicElements } = { h1: 'h1', h2: 'h2', h3: 'h3' };

/***** TYPES *****/
import { TColors } from '../../types/colors';
import { TDisplayType, TFontSize, TLineHeight } from '../../types/cssVariables';

export interface ITextProps extends React.HTMLProps<HTMLSpanElement> {
  /* Native Props */
  children: React.ReactNode | string;
  display?: TDisplayType; // default display for 992…1231
  fontSize?: TFontSize;
  fontSizeMobile?: TFontSize;
  fontSizeDesktop?: TFontSize;
  lineHeight?: TLineHeight;
  lineHeightMobile?: TLineHeight;
  lineHeightDesktop?: TLineHeight;
  letterSpacing?: string;
  fontWeight?: 'normal' | 'bold' | 'inherit';
  overflow?: 'inherit' | 'auto' | 'visible' | 'hidden' | 'scroll';
  textAlign?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  textDecoration?: 'inherit' | 'none' | 'underline' | 'overline' | 'line-through';
  textTransform?: 'uppercase';
  verticalAlign?: 'middle' | 'bottom' | 'top';
  visibility?: 'inherit' | 'visible' | 'hidden';
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'inherit';
  wordWrap?: 'inherit' | 'normal' | 'break-word';

  /* Custom Props */
  borderBox?: boolean; // enable/disable box-sizing: border-box
  color?: TColors;
  colorHovered?: TColors;
  displayDesktopMax?: TDisplayType; // display for 1232…1376
  displayMobile?: TDisplayType; // display for 320…991
  displayPhone?: TDisplayType; // display for 320…639
  displayTablet?: TDisplayType; // display for 640…991
  rowEllipsis?: boolean; // enable/disable text-ellipsis when text does not fit
  ellipsis?: boolean; // enable/disable text-ellipsis when text does not fit
  textStyle?: IStyleArgument;
  tagName?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'time' | 'address' | 'figure'; // names of text tag
  type?: 'normal' | 'small' | 'big' | 'h1' | 'h2' | 'h3'; // abstract type of font-size/line-height from UI-Kit
  width?: 'auto' | 'inherit' | 'wide'; // abstract type of width, wide = width: 100%

  dataMark?: string;
}

/***** COMPONENT *****/
export function Text(props: ITextProps) {
  const {
    /* With default variables */
    display = 'inline',
    fontWeight = 'normal',
    lineHeight = 20,
    tagName = 'span',
    whiteSpace = 'normal',
    wordWrap = 'normal',

    /* Without default variables */
    borderBox,
    children,
    color,
    colorHovered,
    displayDesktopMax,
    displayMobile,
    displayPhone,
    displayTablet,
    ellipsis,
    rowEllipsis,
    fontSize,
    fontSizeMobile,
    fontSizeDesktop,
    lineHeightMobile,
    lineHeightDesktop,
    letterSpacing,
    overflow,
    textAlign,
    textDecoration,
    textTransform,
    textStyle,
    type,
    verticalAlign,
    visibility,
    width,
    id,
    dataMark = 'Text',
  } = props;
  const Tag = type ? TYPES_TO_TAGS[type] || tagName : tagName;

  const inlineStyles = {
    letterSpacing,
  };

  return (
    <Tag
      id={id}
      data-mark={dataMark}
      {...mergeStyles(
        styles.element,
        styles[`element-border_box--${borderBox}`],
        color && styles[`element-color--${color}`],
        styles[`element-display--${display}`],
        styles[`element-font_weight--${fontWeight}`],
        styles[`element-word_wrap--${wordWrap}`],
        styles[`element-white_space--${whiteSpace}`],
        !type && styles[`element-font_size--${fontSize}`],
        !isNil(fontSizeMobile) && styles[`element-font_size_mobile--${fontSizeMobile}`],
        !isNil(fontSizeDesktop) && styles[`element-font_size_desktop--${fontSizeDesktop}`],
        !type && styles[`element-line_height--${lineHeight}`],
        !isNil(lineHeightMobile) && styles[`element-line_height_mobile--${lineHeightMobile}`],
        !isNil(lineHeightDesktop) && styles[`element-line_height_desktop--${lineHeightDesktop}`],
        colorHovered && styles[`element-color_hovered--${colorHovered}`],
        displayDesktopMax && styles[`element-display_desktop_max--${displayDesktopMax}`],
        displayMobile && styles[`element-display_mobile--${displayMobile}`],
        displayPhone && styles[`element-display_phone--${displayPhone}`],
        displayTablet && styles[`element-display_tablet--${displayTablet}`],
        ellipsis && styles['element-ellipsis'],
        rowEllipsis && styles['element-row_ellipsis'],
        overflow && styles[`element-overflow--${overflow}`],
        textAlign && styles[`element-text_align--${textAlign}`],
        textDecoration && styles[`element-text_decoration--${textDecoration}`],
        textTransform && styles[`element-text_transform--${textTransform}`],
        type && styles[`element-type--${type}`],
        verticalAlign && styles[`element-vertical_align--${verticalAlign}`],
        visibility && styles[`element-visibility--${visibility}`],
        width && styles[`element-width--${width}`],
        textStyle,
        inlineStyles,
      )}
    >
      {children}
    </Tag>
  );
}
