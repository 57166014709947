import { IHttpApi } from '@cian/http-api';
import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import {
  fetchGetRegionCities,
  TGetRegionCitiesResponse,
} from '../../repositories/monolith-python/v1/get-region-cities';

export function getRegionCities(
  httpApi: IHttpApi,
  regionId: number,
  subdomain: string,
): Promise<TGetRegionCitiesResponse> {
  return fetchGetRegionCities({ httpApi, parameters: { regionId }, config: { subdomain } });
}

export async function resolveRegionCities(
  httpApi: IHttpApi,
  regionId: number,
  subdomain: string,
): Promise<ISearchCatalogItemSchema[]> {
  const getResponse = await getRegionCities(httpApi, regionId, subdomain);
  if (getResponse.statusCode === 200) {
    return getResponse.response.data.items as ISearchCatalogItemSchema[];
  }

  return [] as ISearchCatalogItemSchema[];
}
