import * as React from 'react';
import * as style from './style.css';

export const GkCardLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <div data-name="GKCardComponent" className={style['layout']} data-mark="GKCard">
      {children}
    </div>
  );
};
