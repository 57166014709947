import { TPromoSearchTypes } from '../../types/promoSearchTypes';

export function getValueToTagLabelMap(promoSearchTypes: NonNullable<TPromoSearchTypes>) {
  const valueToTagLabelMap = new Map<string, string>();

  const allPromoSearchTypes = promoSearchTypes.mortgage.concat(promoSearchTypes.discount);

  allPromoSearchTypes.forEach(item => {
    valueToTagLabelMap.set(item.value, item.fullName);
  });

  return valueToTagLabelMap;
}
