/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 58" width="41" height="58">
    <mask id="cianLogo_a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M26.208 10.319a4.447 4.447 0 108.893 0 4.447 4.447 0 00-8.893 0zm-5.252.022A9.699 9.699 0 0130.655.642c5.356 0 9.698 4.342 9.698 9.699a9.65 9.65 0 01-1.968 5.855l-7.73 9.439-7.086-8.545-.644-.893a9.645 9.645 0 01-1.97-5.856zm13.402 19.086v16.276l5.628 6.761L33.335 58 19.993 41.971 6.651 58 0 52.465l19.993-24.02 7.18 8.627v-7.645h7.185z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#0468FF"
      fillRule="evenodd"
      d="M26.208 10.319a4.447 4.447 0 108.893 0 4.447 4.447 0 00-8.893 0zm-5.252.022A9.699 9.699 0 0130.655.642c5.356 0 9.698 4.342 9.698 9.699a9.65 9.65 0 01-1.968 5.855l-7.73 9.439-7.086-8.545-.644-.893a9.645 9.645 0 01-1.97-5.856zm13.402 19.086v16.276l5.628 6.761L33.335 58 19.993 41.971 6.651 58 0 52.465l19.993-24.02 7.18 8.627v-7.645h7.185z"
      clipRule="evenodd"
    />
    <path
      fill="#E8E9EC"
      d="M38.385 16.196l.182.15.005-.008-.187-.142zm-7.73 9.439l-.181.15.182.22.18-.221-.181-.15zm-7.086-8.545l-.19.138.004.006.005.007.181-.15zm-.644-.893l.191-.137-.003-.005-.188.142zm-.584-.862l.202-.12v-.001l-.202.121zm12.017 30.368h-.236v.085l.055.065.18-.15zm0-16.276h.235v-.235h-.236v.235zm5.628 23.038l.15.18.18-.15-.15-.18-.18.15zM33.335 58l-.18.15.15.181.18-.15-.15-.181zM19.993 41.971l.18-.15-.18-.218-.18.218.18.15zM6.651 58l-.15.18.18.151.15-.18-.18-.151zM0 52.465l-.18-.151-.151.18.18.151.151-.18zm19.993-24.02l.18-.15-.18-.217-.18.217.18.15zm7.18 8.627l-.18.15.415.5v-.65h-.235zm0-7.645v-.235h-.235v.235h.235zm3.482-14.897a4.212 4.212 0 01-4.212-4.211h-.47A4.682 4.682 0 0030.655 15v-.47zm4.212-4.211a4.212 4.212 0 01-4.212 4.211V15a4.682 4.682 0 004.682-4.681h-.47zm-4.212-4.212a4.212 4.212 0 014.212 4.212h.47a4.682 4.682 0 00-4.682-4.683v.47zm-4.212 4.212a4.211 4.211 0 014.212-4.212v-.47a4.682 4.682 0 00-4.682 4.682h.47zM30.655.407c-5.487 0-9.934 4.447-9.934 9.934h.47A9.463 9.463 0 0130.655.877v-.47zm9.933 9.934c0-5.487-4.447-9.934-9.933-9.934v.47a9.463 9.463 0 019.463 9.464h.47zm-2.016 5.997a9.89 9.89 0 002.016-5.997h-.47a9.42 9.42 0 01-1.92 5.713l.374.284zm-7.735 9.446l7.73-9.439-.364-.298-7.73 9.439.364.298zm-7.449-8.543l7.086 8.544.362-.3-7.086-8.545-.362.3zm-.653-.906l.644.893.381-.275-.644-.893-.381.275zm-.595-.879c.183.306.383.6.598.884l.375-.285a9.379 9.379 0 01-.57-.84l-.403.241zm-1.419-5.115a9.88 9.88 0 001.419 5.116l.403-.243a9.411 9.411 0 01-1.352-4.873h-.47zm13.872 35.362V29.427h-.47v16.276h.47zm5.573 6.611l-5.628-6.761-.361.3 5.628 6.762.361-.3zm-6.68 5.867l6.65-5.536-.3-.361-6.651 5.535.3.362zM19.811 42.12l13.342 16.03.362-.302L20.173 41.82l-.362.3zM6.831 58.15l13.342-16.03-.362-.3L6.47 57.85l.361.3zM-.15 52.644L6.5 58.18l.301-.362-6.65-5.535-.301.361zm19.962-24.35L-.18 52.314l.362.3 19.993-24.018-.362-.3zm7.542 8.626l-7.18-8.626-.362.301 7.18 8.626.362-.3zm-.416-7.494v7.645h.47v-7.645h-.47zm7.42-.235h-7.185v.47h7.185v-.47z"
      mask="url(#cianLogo_a)"
    />
  </svg>
);
