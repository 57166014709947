import * as React from 'react';

import * as styles from './FieldRow.css';
import { FieldTitle } from '../FieldTitle';

interface IFieldRowProps {
  title: string;
  field: React.ReactNode;
  titleAlignment?: 'center' | 'top';
  dataTestId?: string;
}

export const FieldRow: React.FC<IFieldRowProps> = ({ title, field, titleAlignment, dataTestId }) => {
  return (
    <div className={styles['field-row']} data-testid={dataTestId}>
      <FieldTitle alignment={titleAlignment}>{title}</FieldTitle>
      <div className={styles['field']}>{field}</div>
    </div>
  );
};
