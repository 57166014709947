import * as React from 'react';
import { useSelector } from 'react-redux';
import { getIsPartnerRewardsRegistrationStatusAvailable, getIsBrokerAvailable } from '../../../selectors/broker';
import { useBrokerLinks } from '../../../hooks/broker';
import { BrokerBlock } from '../../../components/BrokerBlock';

export const BrokerBlockContainer = () => {
  const { rewardTariffsLink } = useBrokerLinks();
  const isRegistrationStatusAvailable = useSelector(getIsPartnerRewardsRegistrationStatusAvailable);
  const isBrokerAvailable = useSelector(getIsBrokerAvailable);
  const isBrokerBlockVisible = isRegistrationStatusAvailable || isBrokerAvailable;

  if (!isBrokerBlockVisible) {
    return null;
  }

  return <BrokerBlock rewardTariffsLink={rewardTariffsLink} />;
};
