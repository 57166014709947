/* eslint-disable react/no-danger */
import * as React from 'react';
import { IconActionChevronUp16 } from '@cian/ui-kit-design-tokens/icons';
import { clsx } from 'clsx';

import { SPACE_CODE } from '../../../../constants/keyCodes';

import * as styles from './SeoMarkedListItem.css';

export interface ISeoMarkedListItemProps {
  id: string;
  title: string;
  content: string;
  expandedByDefault: boolean;
}

export const SeoMarkedListItem: React.VFC<ISeoMarkedListItemProps> = ({ id, title, content, expandedByDefault }) => {
  const [isExpanded, setExpanded] = React.useState<boolean>(expandedByDefault);
  const headingId = `${id}_heading`;
  const bodyId = `${id}_body`;

  const toggleExpanded = React.useCallback(() => setExpanded(prev => !prev), []);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === SPACE_CODE) {
        event.preventDefault();

        toggleExpanded();
      }
    },
    [toggleExpanded],
  );

  return (
    <div className={clsx(styles['container'], !isExpanded && styles['closed'])} data-testid="SeoMarkedListItem">
      <div
        id={headingId}
        role="button"
        aria-expanded={isExpanded}
        aria-controls={bodyId}
        tabIndex={0}
        className={styles['heading']}
        onClick={toggleExpanded}
        onKeyDown={handleKeyDown}
      >
        <div className={styles['title']} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles['arrow-wrapper']}>
          <IconActionChevronUp16 />
        </div>
      </div>
      <div
        id={bodyId}
        aria-describedby={headingId}
        className={styles['content']}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
