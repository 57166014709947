import * as React from 'react';
import { Radio, Spinner, UIHeading3, UIText2 } from '@cian/ui-kit';

import { ClickableText } from '../../../../components/ClickableText';
import { Container } from '../../../../components/Container';
import { SIZE_12, SIZE_20, SIZE_24, SIZE_300, SIZE_WIDE } from '../../../../constants/sizes';
import { ISearchCatalogItemSchema } from '../../../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import { IRegion, IRegionsByID } from '../../../../types/regions';
import {
  isMoscowAndMoscowArea,
  isMoscowOrMoscowArea,
  isSBPOrLo,
  isSPBAndLo,
  LO_ID,
  MOSCOW_AND_MOSCOW_AREA_ID,
  MOSCOW_AREA_ID,
  MOSCOW_ID,
  SPB_AND_LO_ID,
  SPB_ID,
} from '../../../../utils/geo';
import { TCityModalStep } from './CityModal';

export interface ICityModalContentProps {
  onClick(region: IRegion): void;
  regionColumns: IRegion[][][];
  step: TCityModalStep;
  mainRegions: (IRegion | ISearchCatalogItemSchema)[];
  currentRegion: IRegion | ISearchCatalogItemSchema;
  regionsByID: IRegionsByID;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICityModalContentState {}

export class CityModalContent extends React.Component<ICityModalContentProps, ICityModalContentState> {
  public render() {
    const { regionColumns, step, currentRegion } = this.props;
    const mainRegions = this.getMainRegions();

    return (
      <>
        <Container>
          {mainRegions.map((region: IRegion) => (
            <Container key={region.id} margin={`0 0 ${SIZE_20}`}>
              {step === 2 ? (
                <Radio
                  label={region.fullName}
                  onChange={this.handleClick(region)}
                  checked={region.id === currentRegion.id}
                  value={String(region.id)}
                  name="city"
                />
              ) : (
                <ClickableText onClick={this.handleClick(region)}>
                  <UIText2 color="current_color">{region.fullName}</UIText2>
                </ClickableText>
              )}
            </Container>
          ))}
        </Container>
        <Container display="flex">
          {!regionColumns[0].length && !mainRegions.length ? (
            <Container
              data-testid="CityModalLoader"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={SIZE_WIDE}
              height={SIZE_300}
            >
              <Spinner size={40} />
            </Container>
          ) : (
            regionColumns.map((column: IRegion[][], index: number) => (
              <Container key={`column-${index}`} width={SIZE_WIDE}>
                {column.map((regionArray: IRegion[], j: number) => (
                  <Container key={j}>
                    <UIHeading3>{regionArray[0].displayName[0]}</UIHeading3>
                    <Container margin={`${SIZE_12} 0 ${SIZE_24}`}>
                      {regionArray.map((region: IRegion) => (
                        <Container key={region.id} margin={`0 0 ${SIZE_20}`}>
                          {step === 2 ? (
                            <Radio
                              label={region.displayName}
                              onChange={this.handleClick(region)}
                              checked={region.id === currentRegion.id}
                              value={String(region.id)}
                              name="city"
                            />
                          ) : (
                            <ClickableText onClick={this.handleClick(region)}>
                              <UIText2 color="current_color">{region.displayName}</UIText2>
                            </ClickableText>
                          )}
                        </Container>
                      ))}
                    </Container>
                  </Container>
                ))}
              </Container>
            ))
          )}
        </Container>
      </>
    );
  }

  private getMainRegions = () => {
    const { currentRegion, regionsByID, step, mainRegions } = this.props;
    const region = currentRegion.parentId ? regionsByID[currentRegion.parentId] : currentRegion;

    if (step === 2 && (isMoscowAndMoscowArea(region) || isMoscowOrMoscowArea(region))) {
      return [regionsByID[MOSCOW_AND_MOSCOW_AREA_ID], regionsByID[MOSCOW_AREA_ID], regionsByID[MOSCOW_ID]];
    } else if (step === 2 && (isSPBAndLo(region) || isSBPOrLo(region))) {
      return [regionsByID[SPB_AND_LO_ID], regionsByID[LO_ID], regionsByID[SPB_ID]];
    }

    return mainRegions;
  };

  private handleClick = (region: IRegion) => () => {
    this.props.onClick(region);
  };
}
