import * as Redux from 'redux';
import { IFiltersAction } from './createAction';
import { getValue, mergeTerms } from '../../utils/filters';
import {
  IFilterPureDescriptor,
  IJsonQuery,
  IOptionalDescriptorProps,
  IJQDescription,
  IPolygonItem,
} from '../../types/interfaces';
import { EFiltersActions, IInitJsonQuerAction, IClearJsonQueryAction } from '../../actions/filters';
import { equals } from 'ramda';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionWithAnyValue = IFiltersAction<any>;

function makeJsonQuery({ form }: IOptionalDescriptorProps) {
  return (JQ: IJsonQuery, action?: ActionWithAnyValue) =>
    Object.assign(
      JQ,
      ...form.map(({ key, value, type }) => ({ [key]: mergeTerms(JQ[key], getValue(value, JQ, action), type) })),
    );
}

function clearState(JQ: IJsonQuery, descriptor: IFilterPureDescriptor) {
  return Object.keys(JQ)
    .filter(key => !descriptor.clear.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: JQ[key as keyof IJsonQuery] }), {} as IJsonQuery);
}

function modifyState(JQ: IJsonQuery, descriptor: IFilterPureDescriptor) {
  if (!descriptor.modify) {
    return JQ;
  }

  return descriptor.modify.reduce(
    (acc, modifier) => {
      if (!JQ[modifier.key]) {
        return acc;
      }

      return {
        ...acc,
        [modifier.key]: modifier.value(acc[modifier.key]),
      };
    },
    { ...JQ },
  );
}

function createReducer(descriptor: IFilterPureDescriptor) {
  const transformJQ = makeJsonQuery(descriptor);

  return (state: IJsonQuery, action: ActionWithAnyValue) => {
    if (action.type === `${descriptor.name}/${descriptor.state}`) {
      return transformJQ(modifyState(clearState(state, descriptor), descriptor), action);
    }

    return state;
  };
}

const defaultJsonQuery: IJsonQuery = {
  _type: 'newobjectsale',
  engine_version: { type: 'term', value: 2 },
};

export function composeFilterDescriptors(...descriptors: IFilterPureDescriptor[]) {
  return (state: IJsonQuery = defaultJsonQuery, action: Redux.Action) => {
    switch (action.type) {
      case EFiltersActions.init: {
        const { jsonQuery } = action as IInitJsonQuerAction;

        return jsonQuery;
      }
      case EFiltersActions.clear: {
        const newState = { ...state };
        const { value, key } = action as IClearJsonQueryAction;
        const newStatePart = newState[key] as IJQDescription;
        const jqType = newStatePart && newStatePart.type;

        if (['term', 'range'].includes(jqType)) {
          delete newState[key];
        } else if (jqType === 'geo') {
          newStatePart.value = newStatePart.value.filter((item: { id: number }) => item.id !== value.id);
        } else if (jqType === 'terms' && key === 'builders') {
          newStatePart.value = newStatePart.value.filter((item: string | number | boolean | IPolygonItem) => {
            return Number(item) !== Number(value);
          });
        } else if (jqType === 'terms' && key === 'status') {
          newStatePart.value = newStatePart.value.filter((item: string) => {
            return item.toString() !== value.toString();
          });
        } else if (jqType === 'terms') {
          newStatePart.value = newStatePart.value.filter((item: string | number | boolean | IPolygonItem) => {
            return !equals(item, value);
          });
        }
        if (['geo', 'terms'].includes(jqType) && newStatePart.value.length === 0) {
          delete newState[key];
        }

        return newState;
      }
      default:
        return descriptors.reduce((newState, descriptor) => createReducer(descriptor)(newState, action), state);
    }
  };
}
