import { ArticleHeading5 } from '@cian/ui-kit';
import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './SeoCrossLinksV2.css';
import { capitalize } from './utis/capitalize';
import { ICrossLinks } from '../../types/crossLinks';

interface ISeoLinksProps {
  seoBlocks: ICrossLinks[];
}

const LocalMarkup = {
  ListItem: ({ children }: React.PropsWithChildren<{}>) => <li>{children}</li>,
  Link: ({ href, text }: { href: string; text: string }) => (
    <a className={styles['link']} href={href} title={text}>
      {text}
    </a>
  ),
  Button: ({ text, onClick }: { text: string; onClick: () => void }) => (
    <button onClick={onClick} className={styles['button']}>
      {text}
    </button>
  ),
  List: ({ className, children }: React.PropsWithChildren<{ className?: string }>) => (
    <ul className={clsx(styles['list'], className)}>{children}</ul>
  ),
};

export function SeoCrossLinksV2({ seoBlocks }: ISeoLinksProps) {
  const [activeBlock, setActiveBlock] = React.useState<number | null>(null);

  return (
    <div data-testid="SeoCrossLinksV2" className={styles['container']}>
      {activeBlock === null && (
        <div className={clsx(styles['grid'], styles['grid_fit'])} data-testid="SeoCrossLinksV2Preview">
          {seoBlocks.map((seoBlock, blockIndex) => (
            <div className={styles['row']} key={blockIndex}>
              <div className={styles['heading']}>
                <ArticleHeading5 as="h5">{capitalize(seoBlock.title)}</ArticleHeading5>
              </div>
              <LocalMarkup.List>
                {seoBlock.links.slice(0, 4).map((link, linkIndex) => (
                  <LocalMarkup.ListItem key={`${seoBlock.title}-${linkIndex}`}>
                    <LocalMarkup.Link href={link.uri} text={link.name} />
                  </LocalMarkup.ListItem>
                ))}
                {seoBlock.links.length > 4 && (
                  <LocalMarkup.ListItem>
                    <LocalMarkup.Button text="Все" onClick={() => setActiveBlock(blockIndex)} />
                  </LocalMarkup.ListItem>
                )}
              </LocalMarkup.List>
            </div>
          ))}
        </div>
      )}

      {seoBlocks.map((seoBlock, blockIndex) => (
        <div
          className={clsx(styles['block'], blockIndex === activeBlock && styles['block_visible'])}
          key={`${seoBlock.title}-full-${blockIndex}`}
          data-testid="SeoCrossLinksV2Full"
        >
          <div className={styles['heading']}>
            <ArticleHeading5 as="h5">{capitalize(seoBlock.title)}</ArticleHeading5>
          </div>
          <LocalMarkup.List className={styles['grid']}>
            {seoBlock.links.map((link, linkIndex) => (
              <LocalMarkup.ListItem key={`${seoBlock.title}-${linkIndex}`}>
                <LocalMarkup.Link href={link.uri} text={link.name} />
              </LocalMarkup.ListItem>
            ))}
          </LocalMarkup.List>
        </div>
      ))}

      {activeBlock !== null && (
        <div className={clsx(styles['actions-row'], styles['grid'])}>
          {seoBlocks.map((seoBlock, blockIndex) => {
            if (blockIndex === activeBlock) {
              return null;
            }

            return (
              <LocalMarkup.Button
                onClick={() => setActiveBlock(blockIndex)}
                key={`${seoBlock.title}-btn-${blockIndex}`}
                text={capitalize(seoBlock.title)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
