import { IApplicationState } from '../../types/redux';

export const getAnalyticsUser = (state: IApplicationState) => {
  const user = state.user;

  if (!user.isAuthenticated) {
    return {};
  }

  return {
    userId: user.userId,
  };
};
