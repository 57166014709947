import { AnyAction } from 'redux';
import { IJsonQuery } from '../../types/interfaces';

type getValueFn<T> = (state: IJsonQuery, action?: AnyAction) => T;

export function getValue<T>(value: getValueFn<T> | T, state: IJsonQuery, action?: AnyAction): T {
  if (typeof value === 'function') {
    return (value as Function)(state, action);
  } else {
    return value;
  }
}
