import { TPuidMap } from '../../types/adfox';
import { IJsonQueryUnderground } from '../../types/jsonQuery';
import { IApplicationState } from '../../types/redux';
import { isMoscowOrMoscowArea, isSBPOrLo } from '../../utils/geo';
import { getCurrentJsonQuery } from '../location';

// TODO: получать из offersData.puids после фикса на бэке https://jira.cian.tech/browse/CD-165836
export function selectAdditionalAdfoxPuids(state: IApplicationState): TPuidMap {
  const region = state.region.currentRegion;
  const jsonQuery = getCurrentJsonQuery(state);

  const undergroundPuid = (
    (jsonQuery.geo &&
      jsonQuery.geo.value
        .filter(geo => geo.type === 'underground')
        .map((underground: IJsonQueryUnderground) => underground.id)) ||
    []
  ).join(':');

  const puid9 = isMoscowOrMoscowArea(region.id) ? undergroundPuid : '';
  const puid21 = isSBPOrLo(region.id) ? undergroundPuid : '';

  return {
    puid9,
    puid21,
  };
}
