import { completeRegion } from './getRegionById';
import { IRegion } from '../../types/regions';
import { IHttpApi } from '@cian/http-api';
import {
  fetchGetRegions,
  IGetRegionsResponse200,
  TGetRegionsResponse,
} from '../../repositories/monolith-python/v1/get-regions';
import { NotFoundError } from '@cian/peperrors/shared';
import { ILogger } from '@cian/logger';

const moscowAndMoscowRegion: Partial<IRegion> = {
  displayName: 'Москва и область',
  fullName: 'Москва и МО',
  fullNamePrepositional: 'Москве и МО',
  id: -1,
  name: 'Москва и МО',
  isArea: true,
  hasMetro: true,
};

export function fillMoscowToMoscowAndMo(region: IRegion) {
  return Object.assign({}, region, moscowAndMoscowRegion);
}

const SPBAndRegion: Partial<IRegion> = {
  displayName: 'Санкт-Петербург и область',
  fullName: 'Санкт-Петербург и ЛО',
  fullNamePrepositional: 'Санкт-Петербурге и ЛО',
  id: -2,
  name: 'Санкт-Петербург и ЛО',
  prepositionalPretext: 'в',
  isArea: true,
  hasMetro: true,
};

export function SPBToSPBAndRegion(region: IRegion) {
  return Object.assign({}, region, SPBAndRegion);
}

export function getRegions({
  httpApi,
  subdomain,
}: {
  httpApi: IHttpApi;
  subdomain: string;
}): Promise<TGetRegionsResponse> {
  return fetchGetRegions({ httpApi, parameters: {}, config: { subdomain } });
}

export async function resolveGetRegions({
  httpApi,
  subdomain,
  logger,
}: {
  httpApi: IHttpApi;
  subdomain: string;
  logger: ILogger;
}): Promise<IRegion[]> {
  try {
    const getRegionsResponse: TGetRegionsResponse = await getRegions({ httpApi, subdomain });

    if (getRegionsResponse.statusCode === 200) {
      const items = (getRegionsResponse as IGetRegionsResponse200).response.data.items;
      const filteredItems = items ? items.filter(item => !!item) : [];
      const regions = filteredItems ? filteredItems.map(completeRegion) : [];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const moscowAndMo = fillMoscowToMoscowAndMo(regions.find(({ id }) => id === 1)!);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const SPBAndLo = SPBToSPBAndRegion(regions.find(({ id }) => id === 2)!);

      return [moscowAndMo, SPBAndLo].concat(regions);
    }

    if (getRegionsResponse.statusCode === 400) {
      throw new NotFoundError({
        message: '/monolith-python/v1/get-regions answers with status code 400',
        domain: 'src/shared/api/region/getRegions#resolveGetRegions',
      });
    }

    return [] as IRegion[];
  } catch (e) {
    logger.error(e);

    return [] as IRegion[];
  }
}
