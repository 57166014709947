import * as React from 'react';
import { TPhoneInfo } from './types';
import { SIZE_16, SIZE_4, SIZE_8 } from '../../constants/sizes';
import { Container } from '../../components/Container';
import { PhoneButton } from './PhoneButton';
import { Text } from '../../components/Text';
import { IBuilder } from '../../types/newbuilding';
import { formatWorkTime } from '../../utils/text';
import { PhoneInfoBuilderLogo } from '../../components/PhoneInfoBuilderLogo';

export const isBuilder = (phoneInfo: TPhoneInfo): phoneInfo is IBuilder => 'logoUrl' in phoneInfo;

interface IPhoneInfoOwnProps {
  phoneInfo: TPhoneInfo;
  newbuildingId: number;
  onClick?(): void;
}

export const PhoneInfo = (props: IPhoneInfoOwnProps) => {
  const { newbuildingId, phoneInfo, onClick } = props;
  const { name, phone, workTimeInfo, newbuildingDynamicCalltracking } = phoneInfo;
  const siteBlockId = newbuildingDynamicCalltracking ? newbuildingDynamicCalltracking.siteBlockId : null;
  const logo = isBuilder(phoneInfo) ? phoneInfo.logoUrl : phoneInfo.avatarUrl;

  return (
    <Container dataMark="PhoneInfo" padding={`0 0 ${SIZE_16}`} display="flex">
      <Container
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        minWidth="174px"
        margin={`0 ${SIZE_8} 0 0`}
      >
        <Text fontWeight={isBuilder(phoneInfo) ? 'bold' : 'normal'}>{name}</Text>
        {phone && (
          <Container padding={`${SIZE_8} 0 ${SIZE_8}`}>
            <PhoneButton
              phone={phone}
              type="secondary"
              preview
              onClick={onClick}
              siteBlockId={siteBlockId}
              newbuildingId={newbuildingId}
            />
          </Container>
        )}
        {workTimeInfo && isBuilder(phoneInfo) && (
          <Container padding={`${SIZE_4} 0 0`}>
            <Text fontSize={12} color="gray_4" whiteSpace="nowrap">
              Офис работает с {formatWorkTime(workTimeInfo.callFrom)} до {formatWorkTime(workTimeInfo.callTo)}
            </Text>
          </Container>
        )}
      </Container>
      {logo && <PhoneInfoBuilderLogo logo={logo} />}
    </Container>
  );
};
