import * as React from 'react';
import {
  IconProductCianLogo16,
  IconMapMetroMsk16,
  IconMapPin16,
  IconActionPinLine16,
  IconPlaceholderBuilder16,
} from '@cian/ui-kit-design-tokens/icons';
import { ISuggestData, ISuggestGroup } from './types';

export const SUGGEST_CATEGORIES: ISuggestGroup[] = [
  {
    key: 'newbuildings',
    label: 'Жилые комплексы',
    icon: <IconProductCianLogo16 color="gray20_100" />,
  },
  {
    key: 'undergrounds',
    label: 'Метро',
    icon: <IconMapMetroMsk16 color="gray20_100" />,
  },
  {
    key: 'districts',
    label: 'Районы',
    icon: <IconActionPinLine16 color="gray20_100" />,
  },
  {
    key: 'builders',
    label: 'Застройщики',
    icon: <IconPlaceholderBuilder16 color="gray20_100" />,
  },
  {
    key: 'address',
    label: 'Адреса',
    icon: <IconMapPin16 color="gray20_100" />,
  },
];

export const EMPTY_SUGGESTIONS: ISuggestData = {
  address: [],
  builders: [],
  districts: [],
  newbuildings: [],
  undergrounds: [],
};
