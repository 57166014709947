import * as styles from './DiscountMortgageDescription.css';
import { ArticleParagraph1, Button } from '@cian/ui-kit';
import * as React from 'react';
import { ESalesType } from '../../types/offersData';

interface IDiscountMortgageDescriptionProps {
  text: string[];
  type: ESalesType;
  onClick(): void;
}
const icon = require('../../assets/discount-mortgage.svg');
export const DiscountMortgageDescription = (props: IDiscountMortgageDescriptionProps) => {
  const { text, onClick } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['media']}>
        <img className={styles['icon']} src={icon} alt="" />
      </div>
      <div className={styles['content']}>
        <ArticleParagraph1>
          {text.map((content, idx) => {
            if (idx < text.length - 1) {
              return [content, <br key={idx} />];
            }

            return content;
          })}
        </ArticleParagraph1>
      </div>
      <div className={styles['button']}>
        <Button theme="fill_secondary" size="XS" onClick={onClick}>
          Подробнее
        </Button>
      </div>
    </div>
  );
};
