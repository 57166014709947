import { IGeoCodedSearchDetails } from '../../../types/geocode';
import { IRegionInfo } from '../selectGeocodeSuggestion';

export const getRegionInfo = (locationsList: IGeoCodedSearchDetails[]) => {
  return locationsList.reduce((prevValue: IRegionInfo, currentValue: IRegionInfo) => {
    if (currentValue && currentValue.locationInfo) {
      return currentValue;
    }

    return prevValue;
  }, undefined);
};
