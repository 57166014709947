import { INewbuilding } from '../../types/newbuilding';

export function getFirstSalesStartNewbuildingId(
  newbuildingList: INewbuilding[],
  newbuildingFromPromoSnippet: INewbuilding | undefined,
): number | null {
  if (newbuildingFromPromoSnippet && newbuildingFromPromoSnippet.salesStartLabel) {
    return newbuildingFromPromoSnippet.id;
  }

  const newbuilding = newbuildingList.find(newbuildingItem => newbuildingItem.salesStartLabel);

  return (newbuilding && newbuilding.id) || null;
}
