import * as React from 'react';

import { ISalesLink } from '../../types/offersData';
import { SalesLink } from './components/SalesLink';

import * as styles from './SalesLinks.css';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

interface ISalesLinksProps {
  links: ISalesLink[];
  onLinkClick(link: ISalesLink): void;
  onShow(): void;
}

export function SalesLinks({ links, onLinkClick, onShow }: ISalesLinksProps) {
  return (
    <div className={styles['container']}>
      <ActionAfterViewObserver callback={onShow} triggerOnce>
        <div className={styles['links']}>
          {links.map((link, index) => (
            <SalesLink key={index} link={link} onClick={onLinkClick} />
          ))}
        </div>
      </ActionAfterViewObserver>
    </div>
  );
}
