export interface ICoords {
  left: number;
  top: number;
}

/**
 * Вычисляет положение абсолютно спозиционированного портала portalNode относительно якоря node
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class PopupCoordsCalculator {
  // Все методы этого класса должны удовлетворять этой сигнатуре (это для удобства обращения)
  [n: string]: (node: HTMLElement, portalNode: HTMLDivElement, force?: boolean, padding?: number) => ICoords;

  public static top(options: {
    node: HTMLElement;
    portalNode: HTMLDivElement;
    force?: boolean;
    padding?: number;
  }): ICoords {
    const { left, top } = options.node.getBoundingClientRect();
    const { height: portalHeight } = options.portalNode.getBoundingClientRect();
    const mod = options.padding || 0;

    if (!options.force && top - portalHeight <= 0) {
      return PopupCoordsCalculator.bottom({
        node: options.node,
        portalNode: options.portalNode,
        force: true,
        padding: options.padding,
      });
    }

    return { left, top: top + window.scrollY - portalHeight - mod };
  }

  public static bottom(options: {
    node: HTMLElement;
    portalNode: HTMLDivElement;
    force?: boolean;
    padding?: number;
  }): ICoords {
    const { left, bottom } = options.node.getBoundingClientRect();
    const { height: portalHeight } = options.portalNode.getBoundingClientRect();
    const mod = options.padding || 0;
    const scrollY = window.scrollY || document.documentElement.scrollTop;

    if (!options.force && bottom + portalHeight + scrollY >= window.innerHeight + scrollY) {
      return PopupCoordsCalculator.top({
        node: options.node,
        portalNode: options.portalNode,
        force: true,
        padding: options.padding,
      });
    }

    return { left, top: bottom + scrollY + mod };
  }

  public static right(options: {
    node: HTMLElement;
    portalNode: HTMLDivElement;
    force?: boolean;
    padding?: number;
  }): ICoords {
    const { right, bottom, height } = options.node.getBoundingClientRect();
    const { width: portalWidth } = options.portalNode.getBoundingClientRect();
    const mod = options.padding || 0;

    if (!options.force && right + portalWidth > window.innerWidth) {
      return PopupCoordsCalculator.left({
        node: options.node,
        portalNode: options.portalNode,
        force: true,
        padding: options.padding,
      });
    }

    return { left: right + mod, top: bottom - height + window.scrollY };
  }

  public static left(options: {
    node: HTMLElement;
    portalNode: HTMLDivElement;
    force?: boolean;
    padding?: number;
  }): ICoords {
    const { left, bottom, height } = options.node.getBoundingClientRect();
    const { width: portalWidth } = options.portalNode.getBoundingClientRect();
    const mod = options.padding || 0;

    if (!options.force && left - portalWidth < 0) {
      return PopupCoordsCalculator.right({
        node: options.node,
        portalNode: options.portalNode,
        force: true,
        padding: options.padding,
      });
    }

    return { left: left - portalWidth - mod, top: bottom - height + window.scrollY };
  }

  public static middle(options: {
    node: HTMLElement;
    portalNode: HTMLDivElement;
    force?: boolean;
    padding?: number;
  }): ICoords {
    const { width: portalWidth, height: portalHeight } = options.portalNode.getBoundingClientRect();

    return {
      left: (window.innerWidth - portalWidth) / 2,
      top: (window.innerHeight - portalHeight) / 2 + window.scrollY,
    };
  }
}
