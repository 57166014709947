import { getConfig } from '@cian/config/browser';

let ymapsPromise: Promise<YMaps.IYMaps>;

export function loadYmapsApi(options: YMaps.IReadyOptions): Promise<YMaps.IYMaps> {
  const config = getConfig();
  const apiKey = config.getStrict<string>('builders.YandexApiKey');
  const suggestApiKey = config.getStrict<string>('builders.YandexSuggestApiKey');

  if (!ymapsPromise) {
    ymapsPromise = new Promise<YMaps.IYMaps>((resolve, reject) => {
      if (window.ymaps) {
        resolve(window.ymaps);

        return;
      }

      const e = window.document.createElement('script');
      e.src = `https://enterprise.api-maps.yandex.ru/2.1/?apikey=${apiKey}&suggest_apikey=${suggestApiKey}&lang=ru-RU&load=package.system&coordorder=longlat`;
      e.async = true;
      e.defer = true;
      e.onload = () => {
        resolve(window.ymaps);
      };
      e.onerror = () => {
        reject(new Error('Unable to load Yandex Maps API'));
      };
      window.document.body.appendChild(e);
    });
  }

  return ymapsPromise.then(ymaps => {
    return ymaps.ready(options).then(() => ymaps);
  });
}
