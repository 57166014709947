import { FAKE_RUSSIA_REGION } from '../../constants/fakeRegions';
import { ILogger } from '@cian/logger';
import { IHttpApi } from '@cian/http-api';
import {
  fetchRegion_meta,
  IRegion_metaResponse200,
  TRegion_metaResponse,
} from '../../repositories/monolith-python/v1/region_meta';
import { IRegionMetaResponseSchema } from '../../repositories/monolith-python/entities/schemas/RegionMetaResponseSchema';

// Тайпинги из api modals не сходятся
const DEFAULT_RESPONCE = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: FAKE_RUSSIA_REGION as any,
  highways: [],
  underground: [],
  districts: [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

export async function getRegionMeta(
  regionId: number,
  logger: ILogger,
  subdomain: string,
  httpApi: IHttpApi,
): Promise<IRegionMetaResponseSchema> {
  try {
    const response: TRegion_metaResponse = await fetchRegion_meta({
      httpApi,
      parameters: { locationId: regionId },
      config: { subdomain },
    });
    if (response.statusCode === 200) {
      if (!response.response.data.location) {
        return DEFAULT_RESPONCE;
      }

      return (response as IRegion_metaResponse200).response.data as IRegionMetaResponseSchema;
    }

    return DEFAULT_RESPONCE;
  } catch (error) {
    logger.error(error, {
      message: error.message,
      stack: error.stack,
      comment: 'Error in getRegionMeta',
    });

    return DEFAULT_RESPONCE;
  }
}
