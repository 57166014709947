import { AnyAction } from 'redux';
import { descriptorsFactory } from '../../utils/filters';
import { IApplicationState } from '../../types/redux';

export const builderDescriptor = descriptorsFactory({ name: 'Builder', clear: [] })(
  {
    form: [
      {
        key: 'builders',
        type: 'terms',
        value: (state: IApplicationState, action: AnyAction) => [action.value],
      },
    ],
    label: 'Застройщик',
    state: 'builders_set',
  },
  {
    form: [],
    label: 'Застройщик',
    state: 'builders_clear',
  },
);
