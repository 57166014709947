/* eslint-disable max-lines */
import * as React from 'react';
import * as styles from './Container.css';

import { mergeStyles } from '@cian/utils';

/***** UTILS *****/
import { isUndefined } from '@cian/newbuilding-utils';

/***** TYPES *****/
import { TColors } from '../../types/colors';
import {
  TAlignContent,
  TAlignItems,
  TAlignSelf,
  TDisplayType,
  TFlexDirection,
  TFlexWrap,
  TJustifyContent,
  TFloat,
  TWhiteSpace,
} from '../../types/cssVariables';

export interface IContainerProps extends React.HTMLProps<HTMLDivElement> {
  /* Native Props */
  dataMark?: string;
  alignContent?: TAlignContent;
  alignItems?: TAlignItems;
  alignSelf?: TAlignSelf;
  bottom?: string;
  boxShadow?: string;
  children?: React.ReactNode;
  clearfix?: boolean;
  columnGap?: string;
  color?: string;
  dataId?: string;
  display?: TDisplayType; // default display for 992…1231
  flexBasis?: string;
  flexWrap?: TFlexWrap;
  flexDirection?: TFlexDirection;
  float?: TFloat;
  height?: string;
  justifyContent?: TJustifyContent;
  left?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  minWidth?: string;
  overflow?: 'inherit' | 'auto' | 'visible' | 'hidden' | 'scroll';
  opacity?: number;
  padding?: string;
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'inherit';
  right?: string;
  rowGap?: string;
  top?: string;
  transform?: string;
  verticalAlign?: 'middle' | 'bottom' | 'top';
  visibility?: 'inherit' | 'visible' | 'hidden';
  width?: string;
  zIndex?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  borderRadius?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 48 | 'round';
  borderTopWidth?: 0 | 1 | 2 | 3 | 4;
  borderBottomWidth?: 0 | 1 | 2 | 3 | 4;
  borderRightWidth?: 0 | 1 | 2 | 3 | 4;
  borderLeftWidth?: 0 | 1 | 2 | 3 | 4;
  borderWidth?: 0 | 1 | 2 | 3 | 4;
  borderStyle?: 'dashed' | 'solid';
  /* Custom Props */
  borderBox?: boolean; // enable/disable box-sizing: border-box
  backgroundColor?: TColors; // colors from UI Kit
  backgroundColorHover?: TColors; // colors from UI Kit on hover
  borderColor?: TColors; // border colors from UI Kit on hover
  borderColorHovered?: TColors;
  acceleration?: boolean; // enable/disable text-ellipsis when text does not fit
  alignContentDesktopMax?: TAlignContent;
  alignContentMobile?: TAlignContent;
  alignContentPhone?: TAlignContent;
  alignContentTablet?: TAlignContent;
  alignItemsPhone?: TAlignItems;
  alignItemsDesktopMax?: TAlignItems;
  alignItemsMobile?: TAlignItems;
  alignItemsTablet?: TAlignItems;
  alignSelfDesktopMax?: TAlignSelf;
  alignSelfMobile?: TAlignSelf;
  alignSelfPhone?: TAlignSelf;
  alignSelfTablet?: TAlignSelf;
  columnCount?: number;
  displayDesktopMax?: TDisplayType; // display for 1232…1376
  displayMobile?: TDisplayType; // display for 320…991
  displayPhone?: TDisplayType; // display for 320…639
  displayTablet?: TDisplayType; // display for 640…991
  flex?: string;
  flexWrapDesktopMax?: TFlexWrap;
  flexWrapMobile?: TFlexWrap;
  flexWrapPhone?: TFlexWrap;
  flexWrapTablet?: TFlexWrap;
  flexDirectionDesktopMax?: TFlexDirection;
  flexDirectionMobile?: TFlexDirection;
  flexDirectionPhone?: TFlexDirection;
  flexDirectionTablet?: TFlexDirection;
  justifyContentDesktopMax?: TJustifyContent;
  justifyContentMobile?: TJustifyContent;
  justifyContentPhone?: TJustifyContent;
  justifyContentTablet?: TJustifyContent;
  whiteSpaceDesktopMax?: TWhiteSpace;
  whiteSpaceMobile?: TWhiteSpace;
  whiteSpacePhone?: TWhiteSpace;
  whiteSpaceTablet?: TWhiteSpace;
  whiteSpace?: TWhiteSpace;
  noPrint?: boolean;
  tagName?: 'div' | 'noindex' | 'aside' | 'section' | 'article' | 'header' | 'main' | 'footer' | 'nav' | 'ul' | 'li'; // names of text tag;
  shadow?: boolean;
  printAsBlock?: boolean;
  printBreakInsideAvoid?: boolean;
}

/**
 * @deprecated Устарел, нужно писать компоненты со своими стилями.
 */
export const Container = React.forwardRef((props: IContainerProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    /* With default variables */
    backgroundColor = 'transparent',
    display = 'block',
    /* Without default variables */
    dataMark,
    acceleration,
    alignContent,
    alignContentDesktopMax,
    alignContentMobile,
    alignContentPhone,
    alignContentTablet,

    alignItems,
    alignItemsDesktopMax,
    alignItemsMobile,
    alignItemsPhone,
    alignItemsTablet,

    alignSelf,
    alignSelfDesktopMax,
    alignSelfMobile,
    alignSelfPhone,
    alignSelfTablet,

    backgroundColorHover,

    borderBottomWidth,
    borderBox,
    borderColor,
    borderColorHovered,
    borderLeftWidth,
    borderRadius,
    borderRightWidth,
    borderTopWidth,
    borderStyle,
    borderWidth,

    boxShadow,

    children,
    clearfix,
    color,
    columnCount,
    columnGap,
    dataId,
    displayDesktopMax,
    displayMobile,
    displayPhone,
    displayTablet,

    flex,
    flexBasis,
    flexDirection,
    flexDirectionDesktopMax,
    flexDirectionMobile,
    flexDirectionPhone,
    flexDirectionTablet,

    flexWrap,
    flexWrapDesktopMax,
    flexWrapMobile,
    flexWrapPhone,
    flexWrapTablet,

    whiteSpaceDesktopMax,
    whiteSpaceMobile,
    whiteSpacePhone,
    whiteSpaceTablet,
    whiteSpace,

    float,
    height,
    id,
    justifyContent,
    justifyContentDesktopMax,
    justifyContentMobile,
    justifyContentPhone,
    justifyContentTablet,

    bottom,
    left,
    margin,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    noPrint,
    opacity,
    overflow,
    padding,
    position,
    printAsBlock,
    printBreakInsideAvoid,
    right,
    rowGap,
    shadow,
    top,
    transform,
    verticalAlign,
    visibility,
    width,
    zIndex,
    className,
    style,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tagName,
    ...rest
  } = props;

  const inlineStyles = {
    bottom,
    borderStyle,
    boxShadow,
    color,
    columnCount,
    columnGap,
    flex,
    flexBasis,
    height,
    left,
    margin,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    opacity,
    padding,
    right,
    rowGap,
    top,
    transform,
    width,
    ...style,
  };

  return (
    <div
      id={id}
      {...(dataMark ? { 'data-mark': dataMark } : {})}
      data-id={dataId}
      {...mergeStyles(
        inlineStyles,
        className,
        styles['container'],
        styles[`container-border_box--${borderBox}`],
        styles[`container-background_color--${backgroundColor}`],
        styles[`container-display--${display}`],
        shadow && styles[`container--shadow`],
        noPrint && styles[`container-no_print`],
        !isUndefined(zIndex) && styles[`container-z_index--${zIndex}`],
        !isUndefined(borderRadius) && styles[`container-border_radius--${borderRadius}`],
        !isUndefined(borderTopWidth) && styles[`container-border_top_width--${borderTopWidth}`],
        !isUndefined(borderBottomWidth) && styles[`container-border_bottom_width--${borderBottomWidth}`],
        !isUndefined(borderLeftWidth) && styles[`container-border_left_width--${borderLeftWidth}`],
        !isUndefined(borderRightWidth) && styles[`container-border_right_width--${borderRightWidth}`],
        !isUndefined(borderWidth) && styles[`container-border_width--${borderWidth}`],
        acceleration && styles['container-acceleration'],
        alignContent && styles[`container-align_contents--${alignContent}`],
        alignContentDesktopMax && styles[`container-align_contents_desktop_max--${alignContentDesktopMax}`],
        alignContentMobile && styles[`container-align_contents_mobile--${alignContentMobile}`],
        alignContentPhone && styles[`container-align_contents_phone--${alignContentPhone}`],
        alignContentTablet && styles[`container-align_contents_tablet--${alignContentTablet}`],
        alignItems && styles[`container-align_items--${alignItems}`],
        alignItemsDesktopMax && styles[`container-align_items_desktop_max--${alignItemsDesktopMax}`],
        alignItemsMobile && styles[`container-align_items_mobile--${alignItemsMobile}`],
        alignItemsPhone && styles[`container-align_items_phone--${alignItemsPhone}`],
        alignItemsTablet && styles[`container-align_items_tablet--${alignItemsTablet}`],
        alignSelf && styles[`container-align_self--${alignSelf}`],
        alignSelfDesktopMax && styles[`container-align_self_desktop_max--${alignSelfDesktopMax}`],
        alignSelfMobile && styles[`container-align_self_mobile--${alignSelfMobile}`],
        alignSelfPhone && styles[`container-align_selfs_phone--${alignSelfPhone}`],
        alignSelfTablet && styles[`container-align_self_tablet--${alignSelfTablet}`],
        backgroundColorHover && styles[`container-background_color_hover--${backgroundColorHover}`],
        borderColor && styles[`container-border_color--${borderColor}`],
        borderColorHovered && styles[`container-border_color_hover--${borderColorHovered}`],
        clearfix && styles['container-clearfix'],
        displayDesktopMax && styles[`container-display_desktop_max--${displayDesktopMax}`],
        displayMobile && styles[`container-display_mobile--${displayMobile}`],
        displayPhone && styles[`container-display_phone--${displayPhone}`],
        displayTablet && styles[`container-display_tablet--${displayTablet}`],
        flexDirection && styles[`container-flex_direction--${flexDirection}`],
        flexDirectionDesktopMax && styles[`container-flex_direction_desktop_max--${flexDirectionDesktopMax}`],
        flexDirectionMobile && styles[`container-flex_direction_mobile--${flexDirectionMobile}`],
        flexDirectionPhone && styles[`container-flex_direction_phone--${flexDirectionPhone}`],
        flexDirectionTablet && styles[`container-flex_direction_tablet--${flexDirectionTablet}`],
        whiteSpace && styles[`container-white_space--${whiteSpace}`],
        whiteSpaceDesktopMax && styles[`container-white_space_desktop_max--${whiteSpaceDesktopMax}`],
        whiteSpaceMobile && styles[`container-white_space_mobile--${whiteSpaceMobile}`],
        whiteSpacePhone && styles[`container-white_space_phone--${whiteSpacePhone}`],
        whiteSpaceTablet && styles[`container-white_space_tablet--${whiteSpaceTablet}`],
        flexWrap && styles[`container-flex_wrap--${flexWrap}`],
        flexWrapDesktopMax && styles[`container-flex_wrap_desktop_max--${flexWrapDesktopMax}`],
        flexWrapMobile && styles[`container-flex_wrap_mobile--${flexWrapMobile}`],
        flexWrapPhone && styles[`container-flex_wrap_phone--${flexWrapPhone}`],
        flexWrapTablet && styles[`container-flex_wrap_tablet--${flexWrapTablet}`],
        float && styles[`container-float--${float}`],
        justifyContent && styles[`container-justify_content--${justifyContent}`],
        justifyContentDesktopMax && styles[`container-justify_content_desktop_max--${justifyContentDesktopMax}`],
        justifyContentMobile && styles[`container-justify_content_mobile--${justifyContentMobile}`],
        justifyContentPhone && styles[`container-justify_content_phone--${justifyContentPhone}`],
        justifyContentTablet && styles[`container-justify_content_tablet--${justifyContentTablet}`],
        overflow && styles[`container-overflow--${overflow}`],
        position && styles[`container-position--${position}`],
        printAsBlock && styles[`container-print_as_block`],
        verticalAlign && styles[`container-vertical_align--${verticalAlign}`],
        visibility && styles[`container-visibility--${visibility}`],
        printBreakInsideAvoid && styles[`container-break_inside_avoid`],
      )}
      {...rest}
      ref={ref}
    >
      {children || null}
    </div>
  );
});

Container.displayName = 'Container';
