import * as React from 'react';
import { useSelector } from 'react-redux';

import { BrokerButton } from '../../../components/BrokerButton';

import { INewbuilding } from '../../../types/newbuilding';
import { useNewbuildingBrokerContext } from '../../NewbuildingBrokerWidgetProvider';
import { useBrokerLinks } from '../../../hooks/broker';
import {
  getIsBrokerAvailable,
  getIsPartnerRewardsRegistrationStatusAvailable,
  getNewbuildingBrokerWidgetParams,
} from '../../../selectors/broker';
import { getCurrentJsonQuery } from '../../../selectors/location';
import { trackSellApartmentClick } from './tracking';
import { getAnalyticsUser } from '../../../selectors/analytics';

interface IBrokerButtonContainerProps {
  newbuilding: INewbuilding;
}

export const BrokerButtonContainer: React.FC<IBrokerButtonContainerProps> = ({ newbuilding }) => {
  const { landingPageLink } = useBrokerLinks();
  const user = useSelector(getAnalyticsUser);
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const brokerWidgetParams = useSelector(getNewbuildingBrokerWidgetParams);
  const isRegistrationStatusAvailable = useSelector(getIsPartnerRewardsRegistrationStatusAvailable);
  const isBrokerAvailable = useSelector(getIsBrokerAvailable);
  const isBrokerButtonVisible = isRegistrationStatusAvailable || isBrokerAvailable;
  const buttonText = isBrokerAvailable ? 'Провести сделку' : 'Заработать комиссию';

  const openWidget = useNewbuildingBrokerContext();

  const [loading, setLoading] = React.useState(false);

  const openLandingOrWidget = React.useCallback(async () => {
    setLoading(true);

    if (isRegistrationStatusAvailable) {
      window.open(landingPageLink, '_blank', 'noopener');
    } else {
      if (brokerWidgetParams) {
        const { accountLegalType, hasConfirmedPhone } = brokerWidgetParams;

        await openWidget({
          accountLegalType,
          hasConfirmedPhone,
          newbuildingId: newbuilding.id,
        });
      }
    }

    setLoading(false);
  }, [brokerWidgetParams, landingPageLink, isRegistrationStatusAvailable, newbuilding, openWidget]);

  const handleButtonClick = React.useCallback(async () => {
    trackSellApartmentClick(jsonQuery, user);
    await openLandingOrWidget();
  }, [openLandingOrWidget, jsonQuery, user]);

  if (!isBrokerButtonVisible) {
    return null;
  }

  return <BrokerButton loading={loading} onClick={handleButtonClick} buttonText={buttonText} />;
};
