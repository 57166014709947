import * as React from 'react';
import { Image } from '@cian/ui-kit';
import { IconPlaceholderBuilder16 } from '@cian/ui-kit-design-tokens/icons';
import { Container } from '../Container';

import * as styles from './styles.css';

interface IDeveloperLogoProps {
  logoUrl: string;
  url: string;
  name: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

export function DeveloperLogo({ logoUrl, url, name, onClick }: IDeveloperLogoProps) {
  return (
    <a
      href={url}
      rel="nofollow noreferrer"
      target="_blank"
      title={name}
      onClick={onClick}
      className={styles['container']}
      data-testid="developer-logo"
    >
      {logoUrl ? (
        <Image alt={`Логотип застройщика ${name}`} src={`https://www.cian.ru${logoUrl}`} objectFit="contain" />
      ) : (
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          backgroundColor="gray_0"
          borderRadius={2}
        >
          <IconPlaceholderBuilder16 />
        </Container>
      )}
    </a>
  );
}
