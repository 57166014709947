import { AnyAction } from 'redux';
import { defaultRange, descriptorsFactory, IJsonQuery } from '../../utils/filters';

const modifyValue = ({ living_area = defaultRange }: IJsonQuery, action: AnyAction) => {
  return action ? Object.assign({}, living_area.value, { [action.subType]: action.value }) : living_area.value;
};

export const livingAreaInputDescriptors = descriptorsFactory({ name: 'LivingArea', clear: [] })({
  form: [{ key: 'living_area', type: 'range', value: modifyValue }],
  label: '',
  state: 'living_area',
});
