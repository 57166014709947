import { descriptorsFactory } from '../../utils/filters';

export const manifestationTypeDescriptors = descriptorsFactory({
  name: 'manifestationType',
  clear: ['from_developer'],
})(
  {
    form: [{ key: 'from_developer', value: true, type: 'term' }],
    label: 'От застройщиков',
    state: 'On',
  },
  {
    form: [{ key: 'from_developer', value: false, type: 'term' }],
    label: 'От агентов и частных лиц',
    state: 'Off',
  },
  {
    form: [],
    label: 'Без фильтра',
    state: '',
  },
);
