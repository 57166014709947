export const MOSCOW_ID = 1;
export const MOSCOW_AREA_ID = 4593;
export const MOSCOW_AND_MOSCOW_AREA_ID = -1;

export const SPB_ID = 2;
export const LO_ID = 4588;
export const SPB_AND_LO_ID = -2;

export const MOSCOW_AND_MO_IDS = [MOSCOW_ID, MOSCOW_AREA_ID];
export const SPB_AND_LO_IDS = [SPB_ID, LO_ID];

export const BASE_SUBDOMAIN = 'www';

export const MOSCOW_AND_REGION = [MOSCOW_ID, MOSCOW_AREA_ID, MOSCOW_AND_MOSCOW_AREA_ID];
