import { INewbuildingConsultant } from '../../types/newbuildingConsultant';
import { ENBConsutantActions, TNewbuildingConsultantActions } from '../../actions/newbuildingConsultant';

export const defaultState: INewbuildingConsultant = {
  accountName: '',
  chatOpen: false,
  chatNeedForceOpen: false,
};

export function newbuildingConsultant(
  state: INewbuildingConsultant = defaultState,
  action: TNewbuildingConsultantActions,
) {
  switch (action.type) {
    case ENBConsutantActions.OpenChat:
      return {
        ...state,
        chatOpen: action.payload,
      };
    default:
      return state;
  }
}
