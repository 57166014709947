import { descriptorsFactory } from '../../utils/filters';

export const isSalesStartDescriptor = descriptorsFactory({
  name: 'isSalesStart',
  clear: ['is_sales_start'],
})(
  {
    form: [
      {
        key: 'is_sales_start',
        type: 'term',
        value: true,
      },
    ],
    label: 'Старт продаж',
    state: 'On',
  },
  {
    form: [],
    label: 'Старт продаж',
    state: 'Off',
  },
);
