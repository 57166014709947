import * as React from 'react';
import * as style from './style.css';
import { QuickLink } from './QuickLink';
import { MoreButton } from './MoreButton';
import { useRef } from 'react';
import { throttle } from '@cian/newbuilding-utils';
import { IQuickLink } from '../../types/quickLinks';
import { getLinksLength, LINKS_GAP, MORE_BUTTON_LENGTH } from './utils/linkLength';
import { clsx } from 'clsx';

interface IShortListProps {
  links: IQuickLink[];
  isExpanded: boolean;
  onMoreButtonClick(): void;
}

export const LinksList = ({ links, isExpanded, onMoreButtonClick }: IShortListProps) => {
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  const [linksWidth, setLinksWidth] = React.useState(0);
  const [linksCount, setLinksCount] = React.useState(links.length);

  /* istanbul ignore next */
  const listStyle = React.useMemo(() => {
    return isExpanded
      ? {}
      : { width: linksWidth ? `${linksWidth}px` : `calc(100% - ${MORE_BUTTON_LENGTH}px - ${LINKS_GAP}px)` };
  }, [isExpanded, linksWidth]);

  React.useLayoutEffect(() => {
    function onResize() {
      const width = ref.current?.getBoundingClientRect().width;

      /* istanbul ignore next */
      if (width) {
        const [linksLength, count] = getLinksLength(width, links);
        setLinksWidth(linksLength);
        setLinksCount(count);
      }
    }

    const throttledCallback = throttle(200, onResize);

    if (isExpanded) {
      window.removeEventListener('resize', throttledCallback);
      setLinksCount(links.length);

      return;
    }

    onResize();
    window.addEventListener('resize', throttledCallback);

    return () => {
      window.removeEventListener('resize', throttledCallback);
    };
  }, [links, isExpanded]);

  return (
    <div className={style['links-block']} ref={ref}>
      <div className={clsx(style['links'], isExpanded && style['links--expanded'])} style={listStyle}>
        {links.slice(0, linksCount).map((link, index) => (
          <QuickLink link={link} key={index} />
        ))}
        <MoreButton isExpanded={isExpanded} onClick={onMoreButtonClick} />
        {links.slice(linksCount).map((link, index) => (
          <QuickLink link={link} key={`rest_${index}`} />
        ))}
      </div>
    </div>
  );
};
