/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IGetRegionsResponseSchema } from '../../entities/schemas/GetRegionsResponseSchema';

export type TGetRegionsModel = IModel<IGetRegionsRequest, TGetRegionsResponse>;

export interface IGetRegionsRequest {}

export type TGetRegionsResponse = IGetRegionsResponse200 | IGetRegionsResponse400;

export interface IGetRegionsResponse200 extends IModelResponse<IGetRegionsResponse> {
  statusCode: 200;
}

export interface IGetRegionsResponse400 extends IModelResponse<IGetRegionsResponseError> {
  statusCode: 400;
}

export interface IGetRegionsResponse {
  data: IGetRegionsResponseSchema;
  status: EStatus;
}

export interface IGetRegionsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetRegionsResponse | IGetRegionsResponseError;

export enum EStatus {
  /** undefined **/
  Ok = 'ok',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetRegionsResponse): TResponse200;
  400(response: IGetRegionsResponseError): TResponse400;
}
