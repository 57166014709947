import { IApplicationState } from '../../types/redux';
import { getIsBrokerAvailable } from './getIsBrokerAvailable';

export const getNewbuildingBrokerWidgetParams = (state: IApplicationState) => {
  if (getIsBrokerAvailable(state)) {
    const brokerParams = state.offersData.newbuildingBrokerCommon;

    if (brokerParams?.accountLegalType) {
      return {
        accountLegalType: brokerParams.accountLegalType,
        hasConfirmedPhone: brokerParams.hasConfirmedPhone,
      };
    }
  }

  return null;
};
