import * as React from 'react';

import { present } from '@cian/newbuilding-utils';
import { PopupCoordsCalculator } from './PopupCoordsCalculator';
import { PopupPortal } from './PopupPortal';
import * as styles from './styles/popupPortal.css';

interface IMountPopupProps extends React.PropsWithChildren {
  component: React.ReactNode;
  placement?: 'top' | 'bottom' | 'right' | 'left' | 'middle';
  calculateOnScroll?: boolean;
  padding?: number;
  onScroll?(): void;
}

interface IMountPopupState {
  top: number;
  left: number;
  didMount: boolean;
}

/**
 * Компонент который монтирует поап абсолютно относительно своего children размещая его при этом в портале.
 * Не контролирует и (не должен) состояние открытия закрытия popup. Если места на экране не хватает умеет перемещять
 * попап. если слева нет места то на право etc
 * Для изменения логики отображения можно заменить калькулятор PopupCoordsCalculator
 * @example
 * <MountPopup placement="bottom" component={isOpen && <PopupContent/>}>
 *   <DropdownButton active={isOpen} onClick={this.handleToggle} text={text} />
 * </MountPopup>
 */
export class MountPopup extends React.PureComponent<IMountPopupProps, IMountPopupState> {
  private coordinatesCalculator = PopupCoordsCalculator;

  public static defaultProps = {
    placement: 'top',
    calculateOnScroll: false,
  };

  public state: Readonly<IMountPopupState> = {
    didMount: false,
    left: 0,
    top: 0,
  };

  private ref = React.createRef<HTMLDivElement>();
  private portalRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, false);
    this.setState({ didMount: true });
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll, false);
  }

  public componentDidUpdate(prevProps: IMountPopupProps, prevState: IMountPopupState) {
    if (
      this.props.component &&
      (prevProps.component !== this.props.component || prevState.didMount !== this.state.didMount)
    ) {
      this.calculate();
    }
  }

  // убрали правило из конвенции и из tslint-config(https://cianru.atlassian.net/browse/CD-33534),
  // но забыли про test-utils, по этому пока  пишем с исключениями
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  private calculate() {
    const { padding = 8 } = this.props;

    const refs = [this.ref.current, this.portalRef.current];
    if (refs.every(present)) {
      if (refs.every(r => Boolean(present(r!.offsetParent)))) {
        const calculator = this.coordinatesCalculator[this.props.placement!];
        this.setState(calculator({ node: this.ref.current!, portalNode: this.portalRef.current!, padding }));
      }
    } else {
      throw new Error("MountPopup couldn't get reference of anchor object or of a portal element");
    }
  }
  /* eslint-enable @typescript-eslint/no-non-null-assertion */

  public render() {
    const { children, component, calculateOnScroll } = this.props;
    const { top, left, didMount } = this.state;

    return (
      <>
        {didMount ? (
          <PopupPortal calculateOnScroll={calculateOnScroll} left={left} top={top} setRef={this.portalRef}>
            {component}
          </PopupPortal>
        ) : null}
        <span className={styles['container']} ref={this.ref}>
          {children}
        </span>
      </>
    );
  }

  private handleScroll = () => {
    const { calculateOnScroll, onScroll } = this.props;
    if (calculateOnScroll) {
      this.calculate();
    }

    if (onScroll) {
      onScroll();
    }
  };
}
