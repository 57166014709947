import { IOffersDataState } from '../../types/offersData';
import { IThunkActionCreator } from '../../types/thunk';
import { resolveOffersData } from '../../api/offersData';

import { setOffersData } from './setOffersData';
import { updateBrowserUrl } from './updateBrowserUrl';
import { ISetLocationPageParams, setPage } from '../location';
import { trackingActionUpdateListing, trackingActionShowSimilarJK, trackPromoSnippetShow } from '../analytics';
import { changePreloaderVisibility } from '../preloader';
import { getSeparateUrl, memoize } from '@cian/newbuilding-utils';
import { cleanOffersCount } from './setOffersCount';
import { setProducts } from '../analytics/actions';
import { prepareProducts } from '../../mappers';
import { getPromoSnippetVisibility } from '../../utils/promoSnippet';
import { setPromoSnippetVisibility } from '../promoSnippet';
import { setQuickLinks } from '../quickLinks';

interface IGetOffersDataProps extends ISetLocationPageParams {
  shouldUpdateHistory?: boolean;
  trackingAction?(queryString: string): IThunkActionCreator;
  path?: string;
  page?: number;
}

const defaultParams = {
  shouldUpdateHistory: true,
};
const getSeparateUrlMemo = memoize(getSeparateUrl);
export const isNewDomain = memoize((fullUrl: string) => {
  if (typeof window === 'undefined' || !fullUrl) {
    return false;
  }

  const { domain: newDomain } = getSeparateUrlMemo(fullUrl);
  const { domain } = getSeparateUrlMemo(window.location.href);
  const isLocalDev = domain.indexOf('localhost') !== -1;

  return !isLocalDev && domain !== newDomain;
});
export const getOffersData = (params: IGetOffersDataProps = {}): IThunkActionCreator => {
  return (dispatch, getState, context) => {
    const { location, jsonQuery, user, config } = getState();
    const {
      trackingAction,
      path = location.path,
      page = 1,
      shouldUpdateHistory,
    } = {
      ...defaultParams,
      ...params,
    };
    const canUseHiddenBase = user.isAuthenticated && user.permissions.canUseHiddenBase;

    dispatch(changePreloaderVisibility(true));

    return resolveOffersData(context.httpApi, jsonQuery, path, location.subdomain, page, canUseHiddenBase)
      .then((offersData: IOffersDataState) => {
        dispatch(setPage(page, offersData, jsonQuery, params.canUseHiddenBase));

        if (shouldUpdateHistory) {
          dispatch(updateBrowserUrl(offersData));
        }

        if (isNewDomain(offersData.fullUrl)) {
          return;
        }

        dispatch(setOffersData(offersData));
        dispatch(setQuickLinks([]));
        dispatch(setProducts(prepareProducts(offersData)));
        dispatch(trackingActionUpdateListing());

        if (offersData.newbuildingPromoSnippet) {
          dispatch(trackPromoSnippetShow(offersData.newbuildingPromoSnippet.newbuilding.id, jsonQuery));
        }

        if (trackingAction) {
          dispatch(trackingAction(offersData.queryString));
        }
        if (offersData.similarNewbuildings && offersData.similarNewbuildings.length) {
          dispatch(trackingActionShowSimilarJK(jsonQuery));
        }

        const isPromoSnippetVisible =
          config.isPromoSnippetEnabled &&
          offersData.newbuildingPromoSnippet !== null &&
          getPromoSnippetVisibility(page);

        dispatch(setPromoSnippetVisibility(isPromoSnippetVisible));

        dispatch(changePreloaderVisibility(false));
        dispatch(cleanOffersCount());
      })
      .catch(() => {
        dispatch(changePreloaderVisibility(false));
      });
  };
};
