import { definitionFactory } from '../definitionFactory';
import { equalTagKey } from '../../../utils/tags';
import { onDeleteAction } from '../definitionsDeleteAction';

export const builderDefinition = definitionFactory({
  key: 'builders',
  mapper: (jsonQueryValue, tags, state, extraParams) => {
    const hasDeveloperTags = extraParams && extraParams.developer_tags;
    const newTags =
      hasDeveloperTags &&
      jsonQueryValue.map((tagId: number) => {
        const tag =
          extraParams && extraParams.developer_tags && extraParams.developer_tags.find(tag => Number(tag.id) === tagId);

        return {
          label: tag && tag.text,
          value: tagId,
        };
      });

    return newTags ? newTags : tags.filter(tag => equalTagKey('builders')(tag) && jsonQueryValue.includes(tag.value));
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'builders', options);
  },
});
