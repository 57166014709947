import * as React from 'react';

import { constructionStatusDescriptors } from '../../constants/descriptors';
import { isDescriptorActive, IDescriptorMappedForUI } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../../containers/сonnectToDescriptors';
import { CheckboxButtonsMultiple } from '../../components/CheckboxButtonsMultiple';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

export interface IStatusComponentProps extends IDescriptorProps<string> {
  getOffersCount(): void;
}

class ConstructionStatusComponent extends React.Component<IStatusComponentProps> {
  private getActiveDescriptor = (props: IStatusComponentProps) => {
    const { jsonQuery, descriptors } = props;

    return descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
  };

  public shouldComponentUpdate(nextProps: IStatusComponentProps) {
    const nextActiveDescriptor = this.getActiveDescriptor(nextProps);
    const activeDescriptor = this.getActiveDescriptor(this.props);

    /* istanbul ignore next */
    const nextValue = nextActiveDescriptor ? nextActiveDescriptor.state : undefined;
    /* istanbul ignore next */
    const value = activeDescriptor ? activeDescriptor.state : undefined;

    return nextValue !== value;
  }

  public render() {
    const { descriptors } = this.props;
    const activeDescriptor = this.getActiveDescriptor(this.props);
    const value = activeDescriptor ? activeDescriptor.state : undefined;

    return (
      <CheckboxButtonsMultiple
        stack={descriptors.reduce(
          (fits: IDescriptorMappedForUI[], { label, state }) =>
            state === '' ? fits : fits.concat({ value: state, text: label }),
          [],
        )}
        onChange={this.handleChange}
        value={value}
      />
    );
  }

  private handleChange = (newValue: string) => {
    const activeDescriptor = this.getActiveDescriptor(this.props);
    const currentValue = activeDescriptor && activeDescriptor.state;

    this.props.actionCreator(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.props.descriptors.find(d => {
        let wanted = newValue;

        if (wanted === currentValue) {
          wanted = '';
        }

        return d.state === wanted;
        // убрали правило из конвенции и из tslint-config(https://cianru.atlassian.net/browse/CD-33534),
        // но забыли про test-utils, по этому пока  пишем с исключениями
      })!,
    )();
    this.props.getOffersCount();
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const ConstructionStatus = connectToDescriptors(
  constructionStatusDescriptors,
  mapDispatchToProps,
)(ConstructionStatusComponent);
