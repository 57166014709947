import { IApplicationState } from '../../types/redux';
import { IRegion } from '../../types/regions';

export interface ICitiesByAlphabet {
  [symbol: string]: (IRegion | string)[];
}

export function getCitiesByAlphabet(state: IApplicationState): ICitiesByAlphabet {
  const {
    temp: { regionCities },
    region: { regionsByID },
  } = state;
  const result: ICitiesByAlphabet = {
    _: [],
    order: [],
  };

  if (!regionCities || !regionCities.length) {
    return result;
  }

  regionCities.forEach((region: IRegion) => {
    const { displayName } = region;
    const parentRegion = regionsByID[region.parentId || ''];

    if (parentRegion && parentRegion.mainTownId === region.id) {
      result._.push(parentRegion);
      result._.push(region);
    } else {
      if (!result[displayName[0]]) {
        result[displayName[0]] = [];
        result.order.push(displayName[0]);
      }

      result[displayName[0]].push(region);
    }
  });
  result.order.sort();

  return result;
}
