import { getSearchAutocomplete } from '../../../api/geo/ab/get-search-autocomplete';
import { extractSuggestion } from '../../../containers/Filters/GeoInput/helpers';
import { EDealType, EOfferType } from '../../../repositories/geo-suggest/v2/suggest';
import { ISuggestionGroup } from '../../../types/suggestions';

export interface IGeoSuggestions {
  builders: ISuggestionGroup[];
  districts: ISuggestionGroup[];
  newbuildings: ISuggestionGroup[];
  undergrounds: ISuggestionGroup[];
}

export const requestGeoSuggestions = async (term: string, locationId: number): Promise<IGeoSuggestions> => {
  const response = await getSearchAutocomplete({
    query: term,
    offerType: EOfferType.Newobject,
    dealType: EDealType.Sale,
    regionId: locationId,
  });

  const { suggestions } = response.data;

  return {
    builders: extractSuggestion(suggestions, 'builders'),
    districts: extractSuggestion(suggestions, 'districts'),
    newbuildings: extractSuggestion(suggestions, 'newbuildings'),
    undergrounds: extractSuggestion(suggestions, 'undergrounds'),
  };
};
