import { descriptorsFactory } from '../../utils/filters';
import { setGeo } from '../../utils/descriptorActions';

export const geoDescriptor = descriptorsFactory({ name: 'geo', clear: [] })(
  {
    form: [{ key: 'geo', value: setGeo, type: 'geo' }],
    label: 'geo',
    state: 'set',
  },
  {
    form: [],
    label: 'geo',
    state: 'clear',
  },
);
