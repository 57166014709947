import * as React from 'react';
import { Button } from '@cian/ui-kit';

import { IconFunctionalSuccess12, IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';

import { Container } from '../../components/Container';
import { Text } from '../../components/Text';
import { TextAndIcon } from '../../components/TextAndIcon';
import { SIZE_12, SIZE_16, SIZE_332 } from '../../constants/sizes';
import { Clickable } from '../../components/Clickable';

export interface IMotivationPopupProps {
  onClose(): void;
}

export class MotivationPopup extends React.PureComponent<IMotivationPopupProps> {
  public render() {
    return (
      <Container backgroundColor="white" boxShadow="0 2px 8px 0 rgba(0, 0, 0, 0.25)" width={SIZE_332}>
        <Container
          display="flex"
          justifyContent="space-between"
          padding={`${SIZE_12} ${SIZE_16}`}
          backgroundColor="green_light"
        >
          <TextAndIcon icon={<IconFunctionalSuccess12 color={'fill_success_primary__active_100'} />} fontWeight="bold">
            Добавлено в избранное
          </TextAndIcon>
          <Container display="flex" flexDirection="column" justifyContent="center">
            <Clickable onClick={this.handleClosePopup}>
              <IconActionClose16 color={'gray40_100'} />
            </Clickable>
          </Container>
        </Container>
        <Container padding={`${SIZE_12} ${SIZE_16}`}>
          <Container>
            <Text>Войдите, для просмотра избранного с&nbsp;любого устройства</Text>
          </Container>
          <Container padding={`${SIZE_12} 0 0`}>
            <Button onClick={this.handleClickLogin} size="XS">
              Войти
            </Button>
            <Button onClick={this.handleClickRegistration} size="XS" theme="fill_white_primary">
              Зарегистрироваться
            </Button>
          </Container>
        </Container>
      </Container>
    );
  }

  private handleClickLogin = () => {
    this.handleClosePopup();

    if (window.waitForLoginPopup) {
      window.waitForLoginPopup();
    }
  };

  private handleClickRegistration = () => {
    this.handleClosePopup();

    if (window.waitForRegistrationPopup) {
      window.waitForRegistrationPopup();
    }
  };

  private handleClosePopup = () => {
    this.props.onClose();
  };
}
