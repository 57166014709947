import * as React from 'react';
import { Base64 } from 'js-base64';
import { AdFoxTemplates, TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { useApplicationContext } from '../../utils/applicationContext';
import { TgbNativedescGeneralContainer } from '../../components/NativeTGB/TgbNativedescGeneral';

type BannerContext = TTgbNativedescGeneralContext;

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES: Array<BannerContext['type']> = ['tgb_nativedesc_general'];

export interface IAdFoxTemplateParams {
  type: string;
}

export type TAdFoxTemplatesParams = IAdFoxTemplateParams & BannerContext;

export const AdfoxOwnTemplatesContainer: React.FC<TAdFoxBannerTemplate> = props => {
  const ctx = useApplicationContext();
  const { bundleName, bundleParams } = props;

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'tgb_nativedesc_general':
                return <TgbNativedescGeneralContainer context={parsedDecodedParams} />;
            }
          }
        }
      } catch (e) {
        ctx.logger.error(e, {
          message: '[AdFox] Failed to decode/parse bundleParams',
          bannerId: props.bundleParams?.bannerId,
        });
      }
    }
  }

  return <AdFoxTemplates {...props} />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
