import { descriptorsFactory } from '../../utils/filters';

export const roomsDescriptor = descriptorsFactory({
  clear: [],
  name: 'Rooms',
})(
  {
    form: [{ key: 'room', type: 'terms', value: ['oneRoom'] }],
    label: '1-комнатная',
    state: 'oneRoom',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['twoRoom'] }],
    label: '2-комнатная',
    state: 'twoRoom',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['threeRoom'] }],
    label: '3-комнатная',
    state: 'threeRoom',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['fourRoom'] }],
    label: '4-комнатная',
    state: 'fourRoom',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['fiveRoom'] }],
    label: '5-комнатная',
    state: 'fiveRoom',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['large'] }],
    label: '6+ комнатная',
    state: 'large',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['openPlan'] }],
    label: 'Свободная планировка',
    state: 'openPlan',
  },
  {
    form: [{ key: 'room', type: 'terms', value: ['studio'] }],
    label: 'Студия',
    state: 'studio',
  },
);
