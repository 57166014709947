import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

export const ADFOX_TIMEOUT = 2000;

export const MORE_FILTERS_BANNER_SIZE = {
  width: '100%',
  minHeight: '100px',
};

export const PARALLAX_BANNER_SIZE: TAdFoxBannerSizeProps = {
  width: '100%',
  minHeight: '500px',
};

export const NATIVE_TGB_BANNER_SIZE = {
  width: '100%',
  minHeight: '248px',
};

export const NATIVE_TGB_POSITIONS = [5, 15, 20];

export enum EAdFoxBannerType {
  PARALLAX = 'parallax',
  PICTURE = 'picture',
  NATIVE = 'native',
}

export interface IBanners {
  [key: string]: {
    [key: string]: string;
  };
}

const banners: IBanners = {
  [EAdFoxBannerType.PARALLAX]: {
    p1: 'dazao',
    p2: 'y',
    pct: 'a',
    pfb: 'a',
    pfc: 'a',
    plp: 'a',
    pli: 'a',
    pop: 'a',
    pt: 'b',
    random: '1768139593',
  },
  [EAdFoxBannerType.PICTURE]: {
    p1: 'crzye',
    p2: 'fmsa',
  },
  [EAdFoxBannerType.NATIVE]: {
    p1: 'cusvh',
    p2: 'fkhj',
    pct: 'b',
    pfb: 'a',
    pfc: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
    pt: 'b',
    random: '1453650711',
  },
};

export function getBannerByType(type: EAdFoxBannerType) {
  return banners[type];
}
