import { TTypedThunkDispatch } from '../../types/thunk';
import { resolveRegionSearchCities } from '../../api/regionSearchCities';
import { setRegionSearchCities } from './setRegionSearchCities';
import { IApplicationState } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';
import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';

export function getRegionSearchCities(text: string) {
  return (dispatch: TTypedThunkDispatch, getState: () => IApplicationState, { httpApi }: IApplicationContext) => {
    const subdomain = getState().location.subdomain || 'www';

    return resolveRegionSearchCities({ httpApi, text, subdomain }).then(
      (regionCitiesData: ISearchCatalogItemSchema[]) => {
        dispatch(setRegionSearchCities(regionCitiesData));
      },
    );
  };
}
