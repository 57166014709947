import * as React from 'react';

import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { InputsMultiple } from '../../components/InputsMultiple';
import { priceInputDescriptors } from '../../constants/descriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';
import { DEFAULT_INPUT_TIMEOUT } from '../../constants/timeouts';
import { debounce } from '@cian/newbuilding-utils';

export interface IPriceInputProps extends IDescriptorProps<number> {
  getOffersCount(): void;
}

export class PriceInputsComponent extends React.Component<IPriceInputProps> {
  public getOffersCount = debounce(DEFAULT_INPUT_TIMEOUT, this.props.getOffersCount);

  public shouldComponentUpdate(nextProps: IPriceInputProps) {
    const { gte: nextGte, lte: nextLte } = this.getPrice(nextProps);
    const { gte, lte } = this.getPrice(this.props);

    return nextGte !== gte || nextLte !== lte;
  }

  public getPrice = ({ jsonQuery: { price } }: IPriceInputProps) => {
    const gte = price && price.value ? price.value.gte : '';
    const lte = price && price.value ? price.value.lte : '';

    return { gte, lte };
  };

  public render() {
    const { actionCreator, descriptors } = this.props;
    const { gte, lte } = this.getPrice(this.props);

    const action = (type: 'gte' | 'lte') => (value: string) => {
      actionCreator(descriptors[0])(parseFloat(value), type);
      this.getOffersCount();
    };

    return (
      <InputsMultiple
        label="Цена: "
        stack={[
          {
            placeholder: 'от',
            ['aria-label']: 'Минимальная цена квартиры',
            value: gte,
            onChangeValue: action('gte'),
          },
          {
            placeholder: 'до',
            ['aria-label']: 'Максимальная цена квартиры',
            value: lte,
            onChangeValue: action('lte'),
          },
        ]}
      />
    );
  }
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const PriceInput = connectToDescriptors(priceInputDescriptors, mapDispatchToProps)(PriceInputsComponent);
