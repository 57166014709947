import * as React from 'react';
import { clsx } from 'clsx';
import { UIText2 } from '@cian/ui-kit/typography';
import { IconProductSalesStart12 } from '@cian/ui-kit-design-tokens/icons';
import * as styles from './SalesStartLabel.css';

export interface ISalesStartLabel {
  description: string;
  hovered?: boolean;
  onMouseEnter?(e: React.MouseEvent<HTMLDivElement>): void;
  onMouseLeave?(e: React.MouseEvent<HTMLDivElement>): void;
}

export function SalesStartLabel(props: ISalesStartLabel) {
  const { description, hovered, onMouseEnter, onMouseLeave } = props;

  return (
    <span
      className={clsx({
        [styles['sales_start_label--hoverable']]: onMouseEnter || onMouseLeave,
      })}
    >
      <UIText2
        color={hovered ? 'error_100' : 'black_100'}
        display="inline-block"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {description}
        <span className={styles['sales_start_label_icon']}>
          <IconProductSalesStart12 color={'error_100'} />
        </span>
      </UIText2>
    </span>
  );
}
