import * as React from 'react';
import { memoize } from '@cian/newbuilding-utils';
import { Chips } from '@cian/ui-kit';

import { createMortgagePromoSearchTypeDescriptors } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

export interface IMortgagePromoSearchTypeProps extends IDescriptorProps<string> {
  getOffersCount(): void;
}

export const MortgagePromoSearchTypeComponent: React.FC<IMortgagePromoSearchTypeProps> = React.memo(
  ({ actionCreator, getOffersCount, jsonQuery, descriptors }) => {
    const options = React.useMemo(
      () =>
        descriptors.map(({ state, label }) => ({
          label,
          value: state,
        })),
      [descriptors],
    );

    const activeDescriptors = descriptors.filter(descriptor => isDescriptorActive(descriptor)(jsonQuery));
    const currentValues = React.useMemo(() => activeDescriptors.map(d => d.state), [activeDescriptors]);

    const handleChipClick = React.useCallback(
      (value: string) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        actionCreator(descriptors.find(d => d.state === value)!)();
        getOffersCount();
      },
      [actionCreator, descriptors, getOffersCount],
    );

    return (
      <Chips options={options} size="S" value={currentValues} selectType="multiple" onChipClick={handleChipClick} />
    );
  },
);

MortgagePromoSearchTypeComponent.displayName = 'MortgagePromoSearchTypeComponent';

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const MortgagePromoSearchType = connectToDescriptors(
  memoize(createMortgagePromoSearchTypeDescriptors),
  mapDispatchToProps,
)(MortgagePromoSearchTypeComponent);
