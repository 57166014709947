import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TDealType = 'rent' | 'sale';

export type TSourceType = 'realty' | 'map';

interface ISubscriptionsRequestSchema {
  email: string;
  action: 'subscribe';
  deal_type?: TDealType;
  filter_params: string;
  source: TSourceType;
}

interface ISubscriptionsResponseBody {
  status: 'ok';
}

interface ISubscriptionsResponseSchema200 extends IModelResponse<ISubscriptionsResponseBody> {
  statusCode: 200;
}

interface ISubscriptionsResponseSchema400 extends IModelResponse<ISubscriptionsResponseBody> {
  statusCode: 400;
}

type TSubscriptionsResponseSchema = ISubscriptionsResponseSchema200 | ISubscriptionsResponseSchema400;

export type TSubscriptionsModel = IModel<ISubscriptionsRequestSchema, TSubscriptionsResponseSchema>;

const defaultConfig: TSubscriptionsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/ajax/subscriptions',
};

const defaultParametrs: ISubscriptionsRequestSchema = {
  email: '',
  action: 'subscribe',
  filter_params: '',
  source: 'realty',
};

interface ISubscriptionsResponseError {
  status: 'Error';
}

export type TSubscriptionsResponse = ISubscriptionsResponseBody | ISubscriptionsResponseError | string;

export const getDeal = (qs: string = ''): TDealType | void => {
  const matchedQs = qs.match(/deal_type=\w+/gi);

  return matchedQs ? (matchedQs[0].split('=')[1] as TDealType) : undefined;
};

export function createSubscriptionsModel(
  config: Partial<TSubscriptionsModel>,
  parameters?: Partial<ISubscriptionsRequestSchema>,
): TSubscriptionsModel {
  const params = Object.assign(
    {},
    defaultParametrs,
    { deal_type: getDeal(config.parameters && config.parameters.filter_params) },
    config.parameters,
    parameters,
  );

  return {
    ...defaultConfig,
    ...config,
    parameters: params,
  };
}
