import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const undergroundWalkTimeDefinition = definitionFactory({
  key: 'underground_walk_time',
  mapper: ({ lte }) =>
    lte
      ? [
          {
            label: `До метро ${lte} мин. пешком`,
            value: { lte },
          },
        ]
      : [],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'underground_walk_time', options);
  },
});
