import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const hasFlatTourBookingDefinition = definitionFactory({
  key: 'has_flat_tour_booking',
  mapper: jsonQueryValue => {
    return [
      {
        label: 'С записью на просмотр',
        value: jsonQueryValue,
      },
    ];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'has_flat_tour_booking', options);
  },
});
