import { ITextSizeCalculator } from '../../components/Tooltip';

let sharedNode: HTMLDivElement;

function getSharedNode() {
  if (typeof window !== 'undefined' && !sharedNode) {
    sharedNode = window.document.createElement('div');
    sharedNode.style.position = 'fixed';
    sharedNode.style.top = '-1000px';
    sharedNode.style.visibility = 'hidden';
    sharedNode.style.width = 'auto';
    sharedNode.style.height = 'auto';
    sharedNode.style.whiteSpace = 'nowrap';
    sharedNode.style.zIndex = '-1';

    window.document.body.appendChild(sharedNode);
  }

  return sharedNode;
}

export function createTextSizeCalculator(className: string): ITextSizeCalculator {
  const node = getSharedNode();

  return {
    calculate: (text: string) => {
      node.className = className;

      if (node.textContent !== text) {
        node.textContent = text;
      }

      return node.clientWidth + 1;
    },
    detach: () => undefined,
  };
}
