import { getHttpApi } from '@cian/http-api/browser';
import {
  fetchApiGeoGeocodeCached,
  IApiGeoGeocodeCachedRequest,
  IApiGeoGeocodeCachedResponse200,
  IApiGeoGeocodeCachedResponse400,
} from '../../repositories/monolith-cian-realty/unversioned/api-geo-geocode-cached';

export async function getCachedGeocode(parameters: IApiGeoGeocodeCachedRequest, subdomain?: string) {
  // eslint-disable-next-line no-useless-catch
  try {
    const httpApi = getHttpApi();

    const { response, statusCode } = await fetchApiGeoGeocodeCached<
      IApiGeoGeocodeCachedResponse200,
      IApiGeoGeocodeCachedResponse400
    >({
      httpApi,
      parameters,
      config: {
        subdomain: subdomain || 'www',
      },
    });

    if ('errors' in response || statusCode !== 200) {
      throw response;
    }

    return response;
  } catch (error) {
    throw error;
  }
}
