import * as React from 'react';

import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { Select, IVariants } from '../../components/Select';
import { finishDateDescriptor } from '../../constants/descriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';
import { updateTags } from '../../actions/filterTags';

const NUMBER_OF_YEARS_FOR_THE_LIST = 7;
const FIRST_QUARTER_OF_THE_YEAR = 1;
const LAST_QUARTER_OF_THE_YEAR = 4;
const IS_FINISHED = 'isFinished';
const IS_FINISHED_LABEL = 'Сдан';

function createOptions(): IVariants[] {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor((currentMonth + 3) / 3);

  const options: IVariants[] = [
    {
      id: IS_FINISHED,
      label: IS_FINISHED_LABEL,
    },
  ];

  for (let year = currentYear; year < currentYear + NUMBER_OF_YEARS_FOR_THE_LIST; year++) {
    const shouldSetYear = currentYear !== year || currentQuarter !== LAST_QUARTER_OF_THE_YEAR;
    if (shouldSetYear) {
      const yearStr = year.toString();
      options.push({
        id: yearStr,
        label: yearStr,
        isSubtitle: true,
      });
    }
    const nextQuarter = currentQuarter + 1;
    const startQuarter = currentYear === year ? nextQuarter : FIRST_QUARTER_OF_THE_YEAR;
    for (let quarter = startQuarter; quarter <= LAST_QUARTER_OF_THE_YEAR; quarter++) {
      options.push({
        id: `${quarter}.${year}`,
        label: `до ${quarter} квартала`,
      });
    }
  }

  return options;
}

interface IFinishDateComponentProps {
  getOffersCount(): void;
  updateTags(): void;
}

type TFinishDateComponent = IFinishDateComponentProps & IDescriptorProps<string | boolean>;

class FinishDateComponent extends React.PureComponent<TFinishDateComponent> {
  public render() {
    const stateValue = this.getStateValue();

    return (
      <Select
        placeholder="Срок сдачи"
        options={this.options}
        value={stateValue}
        text={this.getText(stateValue)}
        onChange={this.handlerOnChange}
      />
    );
  }

  private options = createOptions();

  private getText = (value: string) => {
    if (value === IS_FINISHED) {
      return IS_FINISHED_LABEL;
    } else if (value) {
      const [quarter, year] = value.split('.');

      return `Сдача до ${quarter} кв. ${year}`;
    }

    return undefined;
  };

  private getStateValue = () => {
    const { jsonQuery } = this.props;
    const { finish_date, is_finished } = jsonQuery;
    if (is_finished && is_finished.value) {
      return IS_FINISHED;
    }
    const stateValue = (finish_date && finish_date.value) || '';

    return stateValue;
  };
  private handlerOnChange = (value: string) => {
    const { actionCreator, descriptors } = this.props;
    const stateValue = this.getStateValue();

    if (value === IS_FINISHED) {
      actionCreator(descriptors[1])(value !== stateValue);
      this.props.updateTags();
    } else if (value === stateValue) {
      actionCreator(descriptors[0])('');
    } else {
      actionCreator(descriptors[0])(value);
    }
    this.props.getOffersCount();
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
    updateTags: () => {
      dispatch(updateTags());
    },
  };
}

export const FinishDate = connectToDescriptors(finishDateDescriptor, mapDispatchToProps)(FinishDateComponent);
