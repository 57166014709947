import { IQuickLinksTab } from '../../types/quickLinks';

export enum EQuickLinksActions {
  SetQuickLinks = 'set/quickLinks',
}

export interface ISetQuickLinksAction {
  type: EQuickLinksActions.SetQuickLinks;
  payload: IQuickLinksTab[] | null;
}

export function setQuickLinks(quickLinks: IQuickLinksTab[] | null): ISetQuickLinksAction {
  return {
    type: EQuickLinksActions.SetQuickLinks,
    payload: quickLinks,
  };
}

export type TQuickLinksActions = ISetQuickLinksAction;
