import * as React from 'react';
import { Button } from '@cian/ui-kit';

import { Container } from '../../../../components/Container';
import { SearchButton } from '../../../../components/SearchButton';
import { SIZE_12, SIZE_16, SIZE_20 } from '../../../../constants/sizes';
import { TCityModalStep } from './CityModal';

interface ICityModalInputProps {
  count: number | null;
  isCountFetching?: boolean;
  step: TCityModalStep;
  onBackClick(): void;
  onSubmit(): void;
}

export class CityModalFooter extends React.PureComponent<ICityModalInputProps> {
  public render() {
    const { count, isCountFetching, step, onBackClick, onSubmit } = this.props;

    return (
      <Container padding={`${SIZE_16} ${SIZE_20}`} display="flex" justifyContent="center" position="relative">
        {step === 2 && (
          <Container margin={`0 ${SIZE_12} 0 0`}>
            <Button onClick={onBackClick} size="XS" theme="fill_secondary" type="button">
              Назад к регионам
            </Button>
          </Container>
        )}
        <SearchButton onClick={onSubmit} count={count} loading={isCountFetching} />
      </Container>
    );
  }
}
