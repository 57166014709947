import * as React from 'react';
import { connect } from 'react-redux';

import { trackingActionOpenContacts, trackingActionShowPhone } from '../../actions/analytics';
import { Carousel } from '../../components/Carousel';
import { Container } from '../../components/Container';
import { SIZE_12, SIZE_16, SIZE_160, SIZE_216, SIZE_4, SIZE_40, SIZE_8 } from '../../constants/sizes';
import { MIDDLE_DOT, NO_BREAK_SPACE, SPACE_SYMBOL } from '../../constants/symbolsUnicode';
import { Text } from '../../components/Text';
import { Image } from '../../components/Image';
import { CardLabelList } from '../../components/CardLabelList';
import { ISlideProps } from '../../components/Carousel/Carousel';
import { CarouselBlock } from '../../components/CarouselBlock';
import { Link } from '../../components/Link';
import { NewbuildingFeatures } from '../../components/NewbuildingFeatures';
import { SalesLeader } from '../../components/SalesLeader';
import { getNewbuildingImages } from '../../selectors/getNewbuildingImages';
import { getNewbuildingIsUpcomingSale } from '../../selectors/getNewbuildingIsUpcomingSale';
import { getNewbuildingUrl } from '../../selectors/getNewbuildingUrl';
import { IImageInfo, INewbuilding, IRoom } from '../../types/newbuilding';
import { IApplicationState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { isNewbuildingInLeadFactory } from '../../utils/helpers';
import { ReviewLinkContainer } from '../ReviewLinkContainer';
import { SalesStartLabelContainer } from '../SalesStartLabelContainer';
import { UpcomingSaleButtonContainer } from '../UpcomingSaleButtonContaier';
import { CianInfo } from './CianInfo';
import { getMetroInfo } from './getMetroInfo';
import { Advertises } from './Advertise';
import { FavoriteButton } from './FavoriteButton';
import { RoomCounts } from './RoomCounts';
import { BuilderInfo } from './BuilderInfo';
import { ShowPhoneButtonContainer } from './ShowPhoneButtonContainer';
import { getGeoDistance } from './getGeoDistance';
import { AddressLine } from './addressLine';
import { BrokerButtonContainer } from './BrokerButtonContainer';
import { LabelsContainer } from '../LabelsContainer';
import { GkCardLayout } from '../../components/GKCardLayout';
import { getNewbuildingBrokerEnabled } from '../../selectors/broker';
import { BrokerBlockContainer } from './BrokerBlockContainer';

interface IGKCardOwnProps {
  newbuilding: INewbuilding;
  onCardClick(offerId: number): void;
}

interface IGKCardStoreProps {
  rooms?: IRoom[];
  isMobile: boolean;
  urlFinisher?: string;
}
interface IGKDispatchProps {
  trackingOnPhoneClick(): void;
  trackingOpenContacts(gaLabelFull: string): void;
}

type TGKCardProps = IGKCardOwnProps & IGKCardStoreProps & IGKDispatchProps;

export class GKCardComponent extends React.PureComponent<TGKCardProps> {
  public preloadLink: HTMLLinkElement;

  public componentDidMount() {
    this.preloadLink = document.createElement('link');
    this.preloadLink.setAttribute('rel', 'preconnect');
    this.preloadLink.setAttribute('href', this.props.newbuilding.url);
    document.head.appendChild(this.preloadLink);
  }

  public componentWillUnmount() {
    document.head.removeChild(this.preloadLink);
  }

  private handleShowPhonesButtonClick = () => {
    const { newbuilding, trackingOpenContacts } = this.props;

    trackingOpenContacts(newbuilding.gaLabels.full);
  };

  private handleCardClick = () => {
    const { newbuilding, onCardClick } = this.props;

    onCardClick(newbuilding.id);
  };

  public render() {
    const { newbuilding, trackingOnPhoneClick, urlFinisher } = this.props;

    const newbuildingImages = getNewbuildingImages(newbuilding);
    const newbuildingUrl = getNewbuildingUrl(newbuilding);
    const isUpcomingSale = getNewbuildingIsUpcomingSale(newbuilding);
    const brokerEnabled = getNewbuildingBrokerEnabled(newbuilding);

    const hasProblem = Boolean(newbuilding.isProblem && newbuildingUrl);

    return (
      <GkCardLayout>
        <CarouselBlock
          features={<NewbuildingFeatures newbuilding={newbuilding} />}
          labels={<LabelsContainer offerId={newbuilding.id} />}
        >
          {newbuildingImages.length ? (
            <Carousel
              slides={newbuildingImages.map(
                (img: IImageInfo, index: number) =>
                  // eslint-disable-next-line react/display-name
                  /* istanbul ignore next */ ({ visible }: ISlideProps) =>
                    visible && (
                      <Image
                        alt={`${index + 1}-я Фотография ${newbuilding.displayName || newbuilding.name}`}
                        src={img.thumbnailUrl}
                        lazy
                        objectFit="cover"
                      />
                    ),
              )}
              defaultSlideIndex={newbuildingImages.findIndex((img: IImageInfo) => img.isDefault)}
            />
          ) : (
            <Image />
          )}
        </CarouselBlock>
        <Container flex="1 1 100%" padding={`0 ${SIZE_16}`}>
          <Container>
            <Container padding={`${SIZE_4} ${SIZE_12} 0 0`} display="inline-block" dataMark="GKCardTitle">
              <Link url={newbuildingUrl} onClick={this.handleCardClick} target="blank">
                <Text color="blue" fontSize={18} lineHeight={22} fontWeight="bold" colorHovered="red">
                  {newbuilding.displayName || newbuilding.name}
                </Text>
              </Link>
            </Container>
            {newbuilding.isSalesLeader && (
              /* istanbul ignore next */ <Container padding={`0 ${SIZE_12} 0 0`} display="inline">
                <SalesLeader />
              </Container>
            )}
          </Container>
          <Container margin={`0 0 ${SIZE_8}`}>
            <Text fontSize={14} lineHeight={18}>
              {this.replaceSpacesToNbsp(newbuilding.specialStatusDisplay)}
              {newbuilding.offerTypes && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}${newbuilding.offerTypes}`}
              {newbuilding.firstMaterial && ` ${MIDDLE_DOT}${NO_BREAK_SPACE}${newbuilding.firstMaterial.name}`}
              {newbuilding.isFz_214 &&
                /* istanbul ignore next */ ` ${MIDDLE_DOT}${NO_BREAK_SPACE}Оформление по${NO_BREAK_SPACE}214-ФЗ`}
            </Text>
            {newbuilding.salesStartLabel && (
              <SalesStartLabelContainer
                newbuildingId={newbuilding.id}
                description={` ${MIDDLE_DOT}${NO_BREAK_SPACE}${newbuilding.salesStartLabel}`}
              />
            )}
          </Container>
          <CardLabelList labels={newbuilding.labels} hasProblem={hasProblem} url={newbuildingUrl} id={newbuilding.id} />
          <ReviewLinkContainer
            newbuildingId={newbuilding.id}
            newbuildingUrl={newbuildingUrl}
            newbuilding={newbuilding}
          />
          <div>
            {getMetroInfo({ newbuilding })}
            {newbuilding.seoGeoDistance && newbuilding.undergroundInfo && (
              /* istanbul ignore next */ <Text color="gray_4">
                {NO_BREAK_SPACE}
                {MIDDLE_DOT}
                {SPACE_SYMBOL}
              </Text>
            )}
            {getGeoDistance(newbuilding, urlFinisher)}
            {AddressLine({ newbuilding })}
          </div>
          <Container height={SIZE_40} overflow="hidden">
            <Text display="inline-block" rowEllipsis>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: this.getDescription(newbuilding.description) }} />
            </Text>
          </Container>
          {!isUpcomingSale && (
            <Advertises
              fromAgentsPropsCount={newbuilding.fromAgentsPropsCount}
              fromDeveloperPropsCount={newbuilding.fromDeveloperPropsCount}
              fromDevelopersRooms={newbuilding.fromDevelopersRooms}
            />
          )}
          {brokerEnabled && (
            <Container margin={`${SIZE_16} 0 0`}>
              <BrokerBlockContainer />
            </Container>
          )}
          <Container margin={`${SIZE_16} 0 0`} display="flex" flexWrap="wrap-reverse" rowGap={SIZE_16}>
            <Container noPrint margin={`0 ${SIZE_8} 0 0`} display="flex" flexWrap="wrap" rowGap={SIZE_8}>
              {brokerEnabled && (
                <Container margin={`0 ${SIZE_8} 0 0`}>
                  <BrokerButtonContainer newbuilding={newbuilding} />
                </Container>
              )}
              <Container margin={`0 ${SIZE_8} 0 0`}>
                {isUpcomingSale ? (
                  <UpcomingSaleButtonContainer newbuildingId={newbuilding.id} gaLabel={newbuilding.gaLabels.full} />
                ) : (
                  <ShowPhoneButtonContainer
                    onShowPhonesClick={this.handleShowPhonesButtonClick}
                    onPhoneClick={trackingOnPhoneClick}
                    builders={newbuilding.builders}
                    sellers={newbuilding.sellers}
                    newbuildingId={newbuilding.id}
                  />
                )}
              </Container>
              <FavoriteButton
                isFavorite={Boolean(newbuilding.isFavorite)}
                newbuildingId={newbuilding.id}
                gaLabelFull={newbuilding.gaLabels.full}
              />
            </Container>
            {!isUpcomingSale && (
              <RoomCounts
                fromDeveloperPropsLink={newbuilding.fromDeveloperPropsLink}
                fromAgentsPropsLink={newbuilding.fromAgentsPropsLink}
                fromAgentsPropsCount={newbuilding.fromAgentsPropsCount}
                fromAgentsCountsDisplay={newbuilding.fromAgentsCountsDisplay}
                fromDeveloperPropsCount={newbuilding.fromDeveloperPropsCount}
              />
            )}
          </Container>
        </Container>
        <Container width={this.props.isMobile ? SIZE_160 : SIZE_216} flex="0 0 auto" position={'relative'}>
          {isNewbuildingInLeadFactory(newbuilding) ? <CianInfo /> : <BuilderInfo builders={newbuilding.builders} />}
        </Container>
      </GkCardLayout>
    );
  }
  private getDescription = (text: string) => (text || '').replace(/<h3.*?<\/h3>/gm, '');
  private replaceSpacesToNbsp = (text: string) => (text || '').replace(new RegExp(' ', 'g'), NO_BREAK_SPACE);
}

export const mapStateToProps = (state: IApplicationState): IGKCardStoreProps => {
  return {
    isMobile: state.media.mobile,
    urlFinisher: state.location.path,
  };
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch: TTypedThunkDispatch, ownProps: IGKCardOwnProps) {
  return {
    trackingOnPhoneClick() {
      dispatch(trackingActionShowPhone(ownProps.newbuilding));
    },
    trackingOpenContacts(gaLabelFull: string) {
      dispatch(trackingActionOpenContacts(gaLabelFull));
    },
  };
}
export const GKCard = connect(mapStateToProps, mapDispatchToProps)(GKCardComponent);
