import { IJsonQueryGeo, IJsonQueryLocation, IJsonQueryStreet } from '../../types/jsonQuery';
import { IJsonQuery } from '../../types/interfaces';

export interface IJQGeo {
  id: number;
  type: string;
}

export type TJQGeo = IJsonQueryLocation | IJsonQueryStreet;

const defaultGeo: IJsonQueryGeo = { type: 'geo', value: [] as IJsonQueryLocation[] };

export function setGeoArray(geo: IJsonQueryGeo, value: TJQGeo[]) {
  if (!value.length) {
    return [];
  }

  return value.reduce<TJQGeo[]>(
    (acc, val) =>
      acc.some(({ id, type }) => id === val.id && type === val.type)
        ? acc.filter(({ id, type }) => id !== val.id && type === val.type)
        : acc.concat([val]),
    geo.value as TJQGeo[],
  );
}

export function setGeoSingle(geo: IJsonQueryGeo = defaultGeo, value: TJQGeo) {
  let geoList = geo.value as TJQGeo[];

  geoList = geoList.filter(({ id }) => Number(id) !== Number(value.id));

  return geoList.concat(value as TJQGeo);
}

export function setGeo({ geo = defaultGeo }: IJsonQuery, { value }: { value: TJQGeo | TJQGeo[] }) {
  return Array.isArray(value) ? setGeoArray(geo, value) : setGeoSingle(geo, value);
}
