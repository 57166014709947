import { descriptorsFactory, ITerms } from '../../utils/filters';
import { APARTMENTS_VALUE, DISCOUNTS_BY_PROPERTY_VALUE } from '../promoSearchTypes';

const removeApartmentPromoSearchType = (item: ITerms<string>) => ({
  ...item,
  value: item.value.filter(item => item !== APARTMENTS_VALUE),
});

const removeFlatPromoSearchType = (item: ITerms<string>) => ({
  ...item,
  value: item.value.filter(item => item !== DISCOUNTS_BY_PROPERTY_VALUE),
});

export const ON_VALUE = 'On';
export const OFF_VALUE = 'Off';

export const apartmentTypeDescriptors = descriptorsFactory({ name: 'IsApartment', clear: ['is_apartment'] })(
  {
    form: [{ key: 'is_apartment', value: false, type: 'term' }],
    label: 'Без апартаментов',
    state: OFF_VALUE,
    modify: [{ key: 'promo_search_types', value: removeApartmentPromoSearchType }],
  },
  {
    form: [{ key: 'is_apartment', value: true, type: 'term' }],
    label: 'Только апартаменты',
    state: ON_VALUE,
    modify: [{ key: 'promo_search_types', value: removeFlatPromoSearchType }],
  },
  {
    form: [],
    label: 'Без фильтра',
    state: '',
  },
);
