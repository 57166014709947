import {
  fromDeveloperDesdcriptor,
  finishDateDescriptor,
  priceInputDescriptors,
  priceTypeDescriptors,
  totalAreaInputDescriptors,
  manifestationTypeDescriptors,
  kitchenAreaInputDescriptors,
  livingAreaInputDescriptors,
  apartmentTypeDescriptors,
  penthouseTypeDescriptor,
  houseTypeDescriptor,
  newbuildingClassDescriptor,
  roomsDescriptor,
  regionDescriptor,
  geoDescriptor,
  newbuildingDescriptor,
  builderDescriptor,
  undergroundWalkTimeDescriptors,
  polygonDescriptor,
  hasDecorationDescriptors,
  constructionStatusDescriptors,
  hasMortgageDesdcriptor,
  saleTypeDescriptor,
  isSalesLeaderDescriptor,
  isSalesStartDescriptor,
  isBlackFridayDescriptor,
  hasDiscountMortgageDescriptor,
  createMortgagePromoSearchTypeDescriptors,
} from '../../constants/descriptors';
import { createDiscountPromoSearchTypeDescriptors } from '../../constants/descriptors/discountPromoSearchTypeDescriptors';
import { composeFilterDescriptors } from '../../containers/сonnectToDescriptors/descriptorToReducer';
import { IApplicationState } from '../../types/redux';

export const createJsonQueryReducer = (state: IApplicationState) =>
  composeFilterDescriptors(
    ...priceInputDescriptors,
    ...priceTypeDescriptors,
    ...fromDeveloperDesdcriptor,
    ...finishDateDescriptor,
    ...manifestationTypeDescriptors,
    ...totalAreaInputDescriptors,
    ...kitchenAreaInputDescriptors,
    ...livingAreaInputDescriptors,
    ...apartmentTypeDescriptors,
    ...penthouseTypeDescriptor,
    ...houseTypeDescriptor,
    ...newbuildingClassDescriptor,
    ...roomsDescriptor,
    ...regionDescriptor,
    ...newbuildingDescriptor,
    ...builderDescriptor,
    ...geoDescriptor,
    ...undergroundWalkTimeDescriptors,
    ...polygonDescriptor,
    ...hasDecorationDescriptors,
    ...constructionStatusDescriptors,
    ...hasMortgageDesdcriptor,
    ...saleTypeDescriptor,
    ...isSalesLeaderDescriptor,
    ...isSalesStartDescriptor,
    ...isBlackFridayDescriptor,
    ...hasDiscountMortgageDescriptor,
    ...createDiscountPromoSearchTypeDescriptors(state),
    ...createMortgagePromoSearchTypeDescriptors(state),
  );
