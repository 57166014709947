import * as React from 'react';
import * as style from './style.css';

interface IDiscountMortgageBannerProps {
  url: string;
  onClick: () => void;
}

export const DiscountMortgageBanner = ({ url, onClick }: IDiscountMortgageBannerProps) => {
  return (
    <a
      className={style['banner']}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      data-testid="DiscountMortgageBanner"
    >
      <div className={style['content']}>
        <div className={style['tablet-coin']} />
        <div className={style['text']}>
          <h2 className={style['header']}>Льготная ипотека закончится 1 июля</h2>
          <p className={style['description']}>
            Успейте воспользоваться выгодной ставкой&nbsp;— ищите пурпурные значки в&nbsp;новостройках
          </p>
        </div>
        <div className={style['label']} />
        <div className={style['house-1']} />
        <div className={style['house-2']} />
        <div className={style['coin']} />
      </div>
    </a>
  );
};
