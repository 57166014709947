import { combineReducers } from 'redux';
import { tags } from './tags';
import { offersCount } from './offersCount';
import { IStateTag } from '../../actions/filterTags/definitionFactory';
import { IStateOffersCount } from '../../actions/offersData';
import { TPromoSearchTypes } from '../../types/promoSearchTypes';

export interface IFilterState {
  tags: IStateTag[];
  offersCount: IStateOffersCount;
  promoSearchTypes: TPromoSearchTypes;
}

export const filters = combineReducers<IFilterState>({
  tags,
  offersCount,
  promoSearchTypes: (state = null) => state,
});
