import * as React from 'react';

import * as styles from './ReviewLink.css';
import { Container } from '../Container';
import { SIZE_4, SIZE_8 } from '../../constants/sizes';
import { EStarSize, EStarTheme, Star, getPrettyFloat } from '@cian/frontend-reviews-components';
import { Text } from '../Text';
import { Link } from '../Link';
import { IReviewStat } from '../../types/reviewStat';

export interface IReviewLinkProps extends IReviewStat {
  newbuildingUrl: string;
  reviewLinkClick: () => void;
}

export function ReviewLink({
  reviewCountText,
  totalRate,
  reviewCount,
  newbuildingUrl,
  reviewLinkClick,
}: IReviewLinkProps) {
  if (reviewCount === 0) {
    return (
      <Container display="flex" alignItems="center" margin={`0 0 ${SIZE_8}`}>
        <Container padding={`0 ${SIZE_8} 0 0`} display="flex" alignItems="center">
          <Star size={EStarSize.M} theme={EStarTheme.NotFilled} />
        </Container>
        <Text fontSize={14} lineHeight={20} color="gray_4" itemProp="reviewCount">
          {reviewCountText}
        </Text>
      </Container>
    );
  }

  return (
    <Link
      data-testid="ReviewLink"
      className={styles['link']}
      url={`${newbuildingUrl}otzyvy/`}
      onClick={reviewLinkClick}
      target="blank"
    >
      <Container display="flex" alignItems="center" margin={`0 0 ${SIZE_8}`}>
        <Text fontSize={16} lineHeight={24} fontWeight="bold" color="orange" itemProp="ratingValue">
          {getPrettyFloat(totalRate)}
        </Text>
        <Container padding={`0 ${SIZE_8} 0 ${SIZE_4} `} display="flex" alignItems="center">
          <Star size={EStarSize.M} theme={EStarTheme.Filled} />
        </Container>
        <Text fontSize={14} lineHeight={20} color="gray_4" itemProp="reviewCount">
          {reviewCountText}
        </Text>
      </Container>
    </Link>
  );
}
