import { IBuilder } from '@cian/frontend-newbuilding-call-component/es/types/builder';
import { IApplicationState } from '../../types/redux';
import { getNewbuildingPromoSnippetDct, getWorkTime } from '../../utils/newbuildingPromoSnippet';

export const getNewbuildingPromoSnippetBuilders = (state: IApplicationState) =>
  state.offersData?.newbuildingPromoSnippet?.newbuilding
    ? state.offersData.newbuildingPromoSnippet.newbuilding.builders.reduce(
        (preparedBuilders: IBuilder[], builder, index) => {
          if (builder.phone && state.offersData.newbuildingPromoSnippet?.extensionFields?.builders) {
            preparedBuilders.push({
              website: builder.url,
              isFichering: true,
              id: builder.id,
              name: builder.name,
              ...getNewbuildingPromoSnippetDct(
                index,
                state.offersData.newbuildingPromoSnippet.extensionFields.builders,
              ),
              logoUrl: builder.logoUrl,
              workTime: getWorkTime(builder.workTimeInfo),
            });
          }

          return preparedBuilders;
        },
        [],
      )
    : [];
