import * as React from 'react';
import { Suggest } from '../../../components/Suggest';
import { TTypedThunkDispatch } from '../../../types/thunk';
import { IApplicationState } from '../../../types/redux';
import { connect } from 'react-redux';
import { MOSCOW_AND_MOSCOW_AREA_ID } from '../../../constants/geo';
import { onSuggestionSelect } from '../../../actions/suggest';
import { TSuggestionSelectedItem } from '../../../types/suggestions';
import { getBBoxFromRegion } from '../../../utils/geo';

export interface IGeoInputComponentState {
  isDataLoading: boolean;
}

export interface IGeoInputComponentStoreProps {
  regionTermValue: number[];
  subdomain?: string;
  regionId: number;
  boundedBy?: [[number, number], [number, number]];
}

export interface IGeoInputComponentDispatchProps {
  onSuggestionSelectAction(item: TSuggestionSelectedItem): void;
}

const NAME_AND_PLACEHOLDER = 'Город, адрес, метро, район, шоссе или ЖК';

export type TGeoInputComponentProps = IGeoInputComponentStoreProps & IGeoInputComponentDispatchProps;

class GeoInputComponent extends React.PureComponent<TGeoInputComponentProps, IGeoInputComponentState> {
  private onSuggestionSelect = (item: TSuggestionSelectedItem) => {
    this.props.onSuggestionSelectAction(item);
  };

  public render() {
    const { subdomain, regionId, boundedBy } = this.props;

    return (
      <Suggest
        placeholder={NAME_AND_PLACEHOLDER}
        ariaLabel={NAME_AND_PLACEHOLDER}
        subdomain={subdomain}
        locationId={regionId || MOSCOW_AND_MOSCOW_AREA_ID}
        onSuggestionSelect={this.onSuggestionSelect}
        boundedBy={boundedBy}
      />
    );
  }
}

function mapStateToProps(state: IApplicationState): IGeoInputComponentStoreProps {
  return {
    subdomain: state.location.subdomain,
    regionTermValue: state.jsonQuery.region ? state.jsonQuery.region.value : [],
    regionId: state.region.currentRegion.id,
    boundedBy: getBBoxFromRegion(state.region.currentRegion),
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    onSuggestionSelectAction: (item: TSuggestionSelectedItem) => {
      dispatch(onSuggestionSelect(item));
    },
  };
}

export const GeoInput = connect(mapStateToProps, mapDispatchToProps)(GeoInputComponent);
