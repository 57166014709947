import { descriptorsFactory } from '../../utils/filters';

export const newbuildingClassDescriptor = descriptorsFactory({
  clear: [],
  name: 'NewbuildingClassDescriptor',
})(
  {
    form: [{ key: 'newbuilding_class', type: 'terms', value: ['economy'] }],
    label: 'Эконом',
    state: 'economy',
  },
  {
    form: [{ key: 'newbuilding_class', type: 'terms', value: ['comfort'] }],
    label: 'Комфорт',
    state: 'comfort',
  },
  {
    form: [{ key: 'newbuilding_class', type: 'terms', value: ['business'] }],
    label: 'Бизнес',
    state: 'business',
  },
  {
    form: [{ key: 'newbuilding_class', type: 'terms', value: ['premium'] }],
    label: 'Премиум',
    state: 'premium',
  },
);
