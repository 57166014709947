import * as React from 'react';

import { roomsDescriptor } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { SelectMultiline } from '../../components/Select';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

export interface IRoomsProps extends IDescriptorProps<string> {
  getOffersCount(): void;
}

const EM_DASH = '\u2014';

interface INames<T> {
  [Identifier: string]: T;
}

const RoomNames: INames<number> = {
  oneRoom: 1,
  twoRoom: 2,
  threeRoom: 3,
  fourRoom: 4,
  fiveRoom: 5,
  large: 6,
};

const ExtraNames: INames<string> = {
  studio: 'студия',
  openPlan: 'своб. план.',
};

function filterRoomValues(values: string[]) {
  const sortedValues = values
    .map(value => RoomNames[value])
    .filter(Boolean)
    .sort();

  if (sortedValues.length < 3) {
    return filterValues(values, RoomNames);
  }

  let seq: [number, number] = [sortedValues[0], sortedValues[0]];
  const sequences: [number, number][] = [];

  sortedValues.forEach((value, index) => {
    if (index === 0) {
      return;
    } else if (value - 1 === seq[1]) {
      seq[1] = value;
    } else {
      sequences.push(seq);
      seq = [value, value];
    }
  });

  sequences.push(seq);

  return sequences
    .map(([start, end]) => {
      return start === end ? String(start) : `${start}${end - start > 1 ? EM_DASH : ', '}${end}`;
    })
    .filter(Boolean) as string[];
}

function filterValues(values: string[], names: INames<string | number>) {
  return values
    .map((val: string) => names[val])
    .sort()
    .filter(Boolean)
    .map(String);
}

function renderLabel(values: string[]) {
  let postfix = '';
  const roomInfo = filterRoomValues(values);
  const extraInfo = filterValues(values, ExtraNames);

  if (roomInfo.length) {
    postfix = !extraInfo.length && roomInfo.length === 1 && !roomInfo[0].includes(EM_DASH) ? '-комнатная' : ' комн.';
  }

  const roomInfoFormatted = roomInfo.join(', ');
  const extraInfoFormatted = extraInfo.join(', ');

  const defaultValue = 'Комнатность';
  const result = [roomInfoFormatted + postfix, extraInfoFormatted].filter(Boolean).join(', ') || defaultValue;

  return result;
}

export class RoomsComponent extends React.PureComponent<IRoomsProps> {
  public render() {
    const { actionCreator, jsonQuery, descriptors, getOffersCount } = this.props;
    const activeDescriptors = descriptors.filter(descriptor => isDescriptorActive(descriptor)(jsonQuery));

    const onChange = (id: string) => {
      // убрали правило из конвенции и из tslint-config(https://cianru.atlassian.net/browse/CD-33534),
      // но забыли про test-utils, по этому пока  пишем с исключениями
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      actionCreator(descriptors.find(d => d.state === id)!)();
      getOffersCount();
    };

    return (
      <SelectMultiline
        placeholder="Комнатность"
        text={jsonQuery.room && renderLabel(jsonQuery.room.value.map(String))}
        onChange={onChange}
        value={activeDescriptors.map(({ state }) => state)}
        options={descriptors.map(({ label, state }) => ({ label, id: state }))}
      />
    );
  }
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const Rooms = connectToDescriptors(roomsDescriptor, mapDispatchToProps)(RoomsComponent);
