import * as React from 'react';
import { debounce } from '@cian/newbuilding-utils';
import { Input, Outside, Popover } from '@cian/ui-kit';

import { Container } from '../../../../components/Container';
import { SelectionList } from '../../../../components/SelectionList';
import { SIZE_360, SIZE_WIDE } from '../../../../constants/sizes';
import { DEFAULT_INPUT_TIMEOUT } from '../../../../constants/timeouts';
import { TRegionSearchCitiesState } from '../../../../types/redux';

interface IOption {
  id: string;
  label: string;
}

interface ICityModalInputState {
  isOpenSuggest: boolean;
  inputValue: string;
}

interface ICityModalInputProps {
  searchList: TRegionSearchCitiesState;
  onClick(region: IOption): void;
  onChange(value: string): void;
}

export class CityModalInput extends React.PureComponent<ICityModalInputProps, ICityModalInputState> {
  public state: Readonly<ICityModalInputState> = {
    isOpenSuggest: false,
    inputValue: '',
  };

  public render() {
    const { isOpenSuggest, inputValue } = this.state;
    const { searchList } = this.props;
    const isPopoverVisible = Boolean(isOpenSuggest && searchList.length && inputValue.length);

    return (
      <Container width={SIZE_360} maxWidth={SIZE_WIDE}>
        <Popover
          placement="bottom"
          open={isPopoverVisible}
          content={
            <Outside onOutside={this.handleCloseSuggest}>
              <Container
                shadow
                borderColor="gray_0"
                borderStyle="solid"
                backgroundColor="white"
                maxWidth={SIZE_WIDE}
                width={SIZE_360}
                onScroll={this.handleCloseSuggest}
              >
                <SelectionList
                  onClick={this.handleListClick}
                  options={searchList.map(({ displayName }, index) => ({ id: `${index}`, label: displayName }))}
                  selectedItems={[]}
                  simple
                />
              </Container>
            </Outside>
          }
        >
          <Input
            onChange={this.handleInput}
            value={inputValue}
            aria-label="Регион или город"
            placeholder="Введите регион или город"
            size="XS"
          />
        </Popover>
      </Container>
    );
  }

  private handleInput = (event: React.ChangeEvent, inputValue: string) => {
    this.setState({
      inputValue,
      isOpenSuggest: true,
    });

    this.debouncedInputChange(inputValue);
  };

  private handleInputChange = (inputValue: string) => {
    this.props.onChange(inputValue);
  };

  private debouncedInputChange = debounce(DEFAULT_INPUT_TIMEOUT, this.handleInputChange);

  private handleCloseSuggest = () => {
    this.setState({ isOpenSuggest: false });
  };

  private handleListClick = (option: IOption) => {
    this.props.onClick(option);

    this.setState({
      inputValue: '',
      isOpenSuggest: false,
    });
  };
}
