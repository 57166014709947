import { descriptorsFactory } from '../../utils/filters';

export const hasDecorationDescriptors = descriptorsFactory({ name: 'hasDecoration', clear: ['has_decoration'] })(
  {
    form: [{ key: 'has_decoration', value: false, type: 'term' }],
    label: 'Без отделки',
    state: 'Off',
  },
  {
    form: [{ key: 'has_decoration', value: true, type: 'term' }],
    label: 'С отделкой',
    state: 'On',
  },
  {
    form: [],
    label: 'Без фильтра',
    state: '',
  },
);
