import { EPreloaderActions, IPreloaderAction } from '../../actions/preloader/changePreloaderVisibility';

export interface IPreloaderState {
  isVisible: boolean;
}

export const DEFAULT_PRELOADER_STATE = {
  isVisible: false,
};

export function preloader(state: IPreloaderState = DEFAULT_PRELOADER_STATE, action: IPreloaderAction) {
  switch (action.type) {
    case EPreloaderActions.changeVisibility:
      return {
        isVisible: action.isVisible,
      };
    default:
      return state;
  }
}
