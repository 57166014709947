import { apartmentTypeDefinition } from './apartmentTypeDefinition';
import { builderDefinition } from './builderDefinition';
import { geoDefinition } from './geoDefinition';
import { houseTypeDefinition } from './houseType';
import { kitchenAreaInputDefinition } from './kitchenAreaInputDefinition';
import { livingAreaInputDefinition } from './livingAreaInputDefinition';
import { manifestationTypeDefinition } from './manifestationTypeDefinition';
import { newbuildingClassDefinition } from './newbuildingClassDefinition';
import { newbuildingDefinition } from './newbuildingDefinition';
import { penthouseTypeDefinition } from './penthouseTypeDefinition';
import { totalAreaInputDefinition } from './totalAreaInputDefinition';
import { undergroundWalkTimeDefinition } from './undergroundWalkTimeDefinition';
import { polygonDefinition } from './polygonDefinition';
import { hasDecorationDefinition } from './hasDecorationDefinition';
import { constructionStatusDefinition } from './constructionStatusDefinition';
import { saleTypeDefinition } from './saleTypeDefinition';
import { yearGteDefinition } from './yearGteDefinition';
import { mcadDefinition } from './mcadDefinition';
import { hasFlatTourBookingDefinition } from './hasFlatTourBookingDefinition';
import { hasMortgageDefinition } from './hasMortgageDefinition';
import { isSalesStartDefinition } from './isSalesStartDefinition';
import { isSalesLeaderDefinition } from './isSalesLeaderDefinition';
import { isBlackFridayDefinition } from './isBlackFridayDefinition';
import { isUpcomingSaleDefinition } from './isUpcomingSaleDefinition';
import { hasPanoramasDefinition } from './hasPanoramasDefinition';
import { hasDiscountMortgageDefinition } from './hasDiscountMortgageDefinition';
import { promoSearchTypeDefinition } from './promoSearchTypeDefinition';

export const definitions = new Map([
  apartmentTypeDefinition,
  builderDefinition,
  geoDefinition,
  houseTypeDefinition,
  kitchenAreaInputDefinition,
  livingAreaInputDefinition,
  manifestationTypeDefinition,
  newbuildingClassDefinition,
  newbuildingDefinition,
  penthouseTypeDefinition,
  totalAreaInputDefinition,
  undergroundWalkTimeDefinition,
  polygonDefinition,
  hasDecorationDefinition,
  hasPanoramasDefinition,
  constructionStatusDefinition,
  saleTypeDefinition,
  yearGteDefinition,
  hasFlatTourBookingDefinition,
  hasMortgageDefinition,
  mcadDefinition,
  isSalesStartDefinition,
  isSalesLeaderDefinition,
  isBlackFridayDefinition,
  isUpcomingSaleDefinition,
  hasDiscountMortgageDefinition,
  promoSearchTypeDefinition,
]);
