import * as React from 'react';
import { connect } from 'react-redux';

import { IFromDevelopersRoom } from '../../types/newbuilding';
import { Container } from '../../components/Container';
import { SIZE_THIRD, SIZE_12 } from '../../constants/sizes';
import { Text } from '../../components/Text';
import { Link } from '../../components/Link';
import { Subscribe } from './Subscribe';
import { trackingActionClickDevelopersFlats } from '../../actions/analytics';
import { TTypedThunkDispatch } from '../../types/thunk';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

interface IAdvertiseOwnProps {
  room: IFromDevelopersRoom;
}

interface IAdvertiseDispatchProps {
  trackingClickDevelopersFlats(rooms: string): void;
}

type TAdvertiseProps = IAdvertiseOwnProps & IAdvertiseDispatchProps;

export const AdvertiseComponentWithOfferUrl = (props: TAdvertiseProps) => {
  const { room, trackingClickDevelopersFlats } = props;

  const [isLinkHovered, setIsLinkHovered] = React.useState(false);
  const onMouseHandler = React.useCallback(() => {
    setIsLinkHovered(!isLinkHovered);
  }, [isLinkHovered]);

  const onLinkClickHandler = () => trackingClickDevelopersFlats(room.countDisplay);

  return (
    <>
      <Container>
        <Text
          fontSize={14}
          lineHeight={18}
          color={isLinkHovered ? 'red' : 'blue'}
          colorHovered="red"
          whiteSpace="nowrap"
        >
          <Link url={room.offersUrl} onClick={onLinkClickHandler} target="blank">
            {`${room.countDisplay}${NO_BREAK_SPACE}от${NO_BREAK_SPACE}${room.minTotalArea}${NO_BREAK_SPACE}м²`}
          </Link>
        </Text>
      </Container>
      <Container onMouseEnter={onMouseHandler} onMouseLeave={onMouseHandler}>
        <Text fontSize={16} lineHeight={20} color="black" whiteSpace="nowrap">
          <Link url={room.offersUrl} onClick={onLinkClickHandler} target="blank">
            {room.price2Range}
          </Link>
        </Text>
      </Container>
    </>
  );
};

export const AdvertiseComponentWithoutOfferUrl = ({ room }: IAdvertiseOwnProps) => {
  return (
    <>
      <Container>
        <Text fontSize={14} lineHeight={18} whiteSpace="nowrap">
          {`${room.countDisplay}`}
        </Text>
      </Container>
      <Container>
        <Text fontSize={16} lineHeight={20} color="black" whiteSpace="nowrap">
          {room.price2Range}
        </Text>
      </Container>
    </>
  );
};

export const AdvertiseComponent = (props: TAdvertiseProps) => {
  const { room } = props;

  const innerAdvertiseComponent = room.offersUrl ? (
    <AdvertiseComponentWithOfferUrl {...props} />
  ) : (
    <AdvertiseComponentWithoutOfferUrl room={room} />
  );

  return (
    <Container width={SIZE_THIRD} float="left" margin={`${SIZE_12} 0 0`} dataMark="Advertise" data-testid="Advertise">
      {innerAdvertiseComponent}
    </Container>
  );
};

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    trackingClickDevelopersFlats(rooms: string) {
      dispatch(trackingActionClickDevelopersFlats(rooms));
    },
  };
}
const Advertise = connect<null, IAdvertiseDispatchProps, IAdvertiseOwnProps>(
  null,
  mapDispatchToProps,
)(AdvertiseComponent);

interface IAdvertisesProps {
  fromDevelopersRooms: IFromDevelopersRoom[];
  fromDeveloperPropsCount: number;
  fromAgentsPropsCount: number;
}
export function Advertises({ fromDevelopersRooms, fromDeveloperPropsCount, fromAgentsPropsCount }: IAdvertisesProps) {
  if (fromDevelopersRooms && fromDevelopersRooms.length) {
    return (
      <Container clearfix>
        {fromDevelopersRooms.map((room, index) => (
          <Advertise room={room} key={index} />
        ))}
      </Container>
    );
  }

  if (!(fromDeveloperPropsCount || fromAgentsPropsCount)) {
    return <Subscribe />;
  }

  return <Container margin={`${SIZE_12} 0`} data-testid="EmptyAdvertises" />;
}
