import * as React from 'react';
import { connect } from 'react-redux';

import { plural } from '@cian/utils';

import { SIZE_4 } from '../../constants/sizes';
import { Link } from '../../components/Link';
import { Container } from '../../components/Container';
import { Text } from '../../components/Text';
import { TTypedThunkDispatch } from '../../types/thunk';
import { trackingActionClickDevelopersFlats, trackingActionClickAgentsFlats } from '../../actions/analytics';
import { MIDDLE_DOT } from '../../constants/symbolsUnicode';

interface IRoomCountsComponentDispatchProps {
  trackingClickDevelopersFlats(): void;
  trackingClickAgentsFlats(): void;
}

interface IRoomCountsComponentOwnProps {
  isPromo?: boolean;
  fromDeveloperPropsLink?: string;
  fromAgentsPropsLink?: string;
  fromAgentsPropsCount: number;
  fromAgentsCountsDisplay: string;
  fromDeveloperPropsCount: number;
}

type TRoomCountsComponentProps = IRoomCountsComponentDispatchProps & IRoomCountsComponentOwnProps;

export const ROOMS_PLURAL_VARIANTS: [string, string, string] = ['квартира', 'квартиры', 'квартир'];

interface ICountsInfo {
  label: string;
  url: string;
}

interface ILinksItem {
  link?: string;
  count: number;
  fromAgentsCountsDisplay?: string;
  countsInfo: ICountsInfo;
  trackingClickFlat(): void;
}

const countsMap: { [item: string]: ICountsInfo } = {
  agents: {
    label: 'от агентов',
    url: '&from_developer=0',
  },
  developer: {
    label: 'от застройщика',
    url: '&from_developer=1',
  },
};

function RoomCountsComponent(props: TRoomCountsComponentProps) {
  const {
    isPromo = false,
    fromDeveloperPropsLink,
    fromAgentsPropsLink,
    fromAgentsPropsCount,
    fromAgentsCountsDisplay,
    fromDeveloperPropsCount,
    trackingClickDevelopersFlats,
    trackingClickAgentsFlats,
  } = props;

  const linksItems: ILinksItem[] = [
    {
      link: fromDeveloperPropsLink,
      count: fromDeveloperPropsCount,
      countsInfo: countsMap.developer,
      trackingClickFlat: trackingClickDevelopersFlats,
    },
    {
      link: fromAgentsPropsLink,
      count: fromAgentsPropsCount,
      fromAgentsCountsDisplay,
      countsInfo: countsMap.agents,
      trackingClickFlat: trackingClickAgentsFlats,
    },
  ].filter(({ count }) => count > 0);

  if (!linksItems.length) {
    return null;
  }

  return (
    <Container padding={`${SIZE_4} 0`} display="inline-block">
      {getPrimaryLink(linksItems[0], isPromo)}
      {linksItems.length === 2 && (
        <>
          <Container margin={`0 ${SIZE_4}`} display="inline-block">
            <Text color="gray_3">{MIDDLE_DOT}</Text>
          </Container>
          {getSecondaryLink(linksItems[1], isPromo)}
        </>
      )}
    </Container>
  );
}

function getPrimaryLink(
  { link, count, countsInfo, fromAgentsCountsDisplay, trackingClickFlat }: ILinksItem,
  isPromo: boolean,
) {
  const countsDisplay = fromAgentsCountsDisplay || `${count} ${plural(count, ROOMS_PLURAL_VARIANTS)}`;
  const { label } = countsInfo;

  if (!link) {
    return null;
  }

  return (
    <Link dataMark="RoomCounts" url={link} onClick={trackingClickFlat} target="blank" data-testid="primary-link">
      <Text color={isPromo ? 'blue_2' : 'blue'} {...(isPromo ? '' : { colorHovered: 'red' })} whiteSpace="nowrap">
        {`${countsDisplay} ${label}`}
      </Text>
    </Link>
  );
}

function getSecondaryLink({ link, count, countsInfo, trackingClickFlat }: ILinksItem, isPromo: boolean) {
  const { label } = countsInfo;

  if (!link) {
    return null;
  }

  return (
    <Link
      url={link}
      onClick={trackingClickFlat}
      target="blank"
      dataMark="fromAgentsRoomCounts"
      data-testid="secondary-link"
    >
      <Text color={isPromo ? 'gray_6' : 'gray_3'} colorHovered={isPromo ? 'blue_2' : 'red'} whiteSpace="nowrap">
        {`${count} ${label}`}
      </Text>
    </Link>
  );
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    trackingClickDevelopersFlats() {
      dispatch(trackingActionClickDevelopersFlats('all-variants'));
    },
    trackingClickAgentsFlats() {
      dispatch(trackingActionClickAgentsFlats());
    },
  };
}

export const RoomCounts = connect<null, IRoomCountsComponentDispatchProps, IRoomCountsComponentOwnProps>(
  null,
  mapDispatchToProps,
)(RoomCountsComponent);
