import { IRegion } from '../../types/regions';
import { IThunkActionCreator } from '../../types/thunk';
import { trackingActionRegionChange } from '../analytics';
import { setCurrentRegion } from '../region/currentRegion';
import { clearTagsAfterChangeRegion } from '../filterTags';

export const setRegion = (region: IRegion, geoType: string, previousRegionId: number): IThunkActionCreator => {
  return dispatch => {
    dispatch(trackingActionRegionChange(geoType));
    dispatch(setCurrentRegion(region));
    dispatch(clearTagsAfterChangeRegion(previousRegionId));
  };
};
