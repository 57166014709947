import { ERegionSearchCitiesActions } from '../../actions/regionSearchCities';
import { ERegionCitiesActions } from '../../actions/regionCities';
import { TReduxActions, ITempState } from '../../types/redux';

export function temp(state: ITempState = {}, action: TReduxActions) {
  switch (action.type) {
    case ERegionSearchCitiesActions.set:
      return {
        ...state,
        regionSearchCities: action.data,
      };
    case ERegionCitiesActions.set:
      return {
        ...state,
        regionCities: action.data,
      };
    case ERegionCitiesActions.clear:
      return {
        ...state,
        regionCities: [],
      };
    default:
      return { ...state };
  }
}
