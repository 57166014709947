import { IMedia } from '../../types/redux';
import { EMediaActions, IMediaAction } from '../../actions/media';
import * as mediasConsts from '../../constants/media';
import { isUndefined } from '@cian/newbuilding-utils';

export const initialMediaState: IMedia = {
  phone: false,
  mobile: false,
  tablet: false,
  desktop: false,
  desktopMax: false,
};

const mediaConstantsMap: { [item: string]: string } = {
  MEDIA_PHONE: 'phone',
  MEDIA_MOBILE: 'mobile',
  MEDIA_TABLET: 'tablet',
  MEDIA_DESKTOP: 'desktop',
  MEDIA_DESKTOP_MAX: 'desktopMax',
};

export function media(state: IMedia = initialMediaState, action: IMediaAction) {
  switch (action.type) {
    case EMediaActions.set: {
      const map: IMedia = initialMediaState;
      const medias: { [media: string]: string } = { ...mediasConsts };

      Object.keys(medias).forEach((name: string) => {
        const currentName: string = mediaConstantsMap[name];

        if (!isUndefined(map[currentName])) {
          const windowMedia = window.matchMedia(medias[name]);

          map[currentName] = windowMedia ? windowMedia.matches : false;
        }
      });

      return map;
    }
    default:
      return { ...state };
  }
}
