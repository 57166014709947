import * as React from 'react';

import * as styles from './BlackFridayBanner.css';
import { ArticleHeading1, ArticleParagraph1 } from '@cian/ui-kit';

interface IBlackFridayBannerProps {
  url: string;
  onBannerClick: () => void;
}

export const BlackFridayBanner: React.FC<IBlackFridayBannerProps> = ({ url, onBannerClick }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles['container']}
      data-testid="BlackFridayBanner"
      onClick={onBannerClick}
    >
      <ArticleHeading1 color="white_100">Скидки на новостройки переехали к нам</ArticleHeading1>
      <div className={styles['description-wrapper']}>
        <ArticleParagraph1 color="white_100">
          Эксклюзивные предложения от застройщиков только до 29 сентября
        </ArticleParagraph1>
      </div>
      <div>
        <button className={styles['button']}>
          <ArticleParagraph1 fontWeight="bold">Подробнее</ArticleParagraph1>
        </button>
      </div>
    </a>
  );
};
