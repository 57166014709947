import { IRange, ITerm, ITerms } from '../../types/interfaces';
import { IJsonQueryGeo } from '../../types/jsonQuery';
import { hasTerm } from './hasTerm';
import { hasTerms } from './hasTerms';

type JQValue<T> = IJsonQueryGeo | ITerm<T> | ITerms<T> | IRange | T;

export function hasByType<T>(type: string, obj: JQValue<T>, val: T | T[]): boolean {
  switch (type) {
    case 'term':
      return hasTerm(obj as ITerm<T>, val);
    case 'terms':
      return hasTerms(obj as ITerms<T>, val as T[]);
    case 'plain':
      return obj === val;
    case 'range':
      return Boolean((obj as IRange).value.gte && (obj as IRange).value.lte);
    default:
      return false;
  }
}
