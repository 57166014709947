import { IRegion } from '../../types/regions';
import { ECurrentRegionActions } from '../../actions/region/currentRegion';
import { TReduxActions } from '../../types/redux';

export const defaultRegion: IRegion = {
  displayName: 'Москва',
  name: 'Москва',
  hasHighway: false,
  hasDistricts: true,
  boundedBy: { upperCorner: { lat: 56.021224, lng: 37.967428 }, lowerCorner: { lat: 55.142175, lng: 36.803101 } },
  hasMetro: true,
  fullNamePrepositional: 'Москве',
  baseHost: 'http://www.master.dev3.cian.ru',
  fullName: 'Москва',
  lat: 55.7557,
  lng: 37.6213,
  id: 1,
  prepositionalPretext: 'в',
  isArea: true,
  isCity: false,
};

export function currentRegion(state: IRegion = defaultRegion, action: TReduxActions) {
  switch (action.type) {
    case ECurrentRegionActions.set:
      return action.data;
    default:
      return { ...state };
  }
}
