import * as React from 'react';
import { INewbuilding } from '../../types/newbuilding';
import { getMetroInfo } from '../../containers/OffersContainer/getMetroInfo';
import * as styles from './PromoSnippetUnderground.css';

interface IPromoSnippetUndergroundProps {
  newbuilding: INewbuilding;
}

export function PromoSnippetUnderground({ newbuilding }: IPromoSnippetUndergroundProps) {
  return <div className={styles['container']}>{getMetroInfo({ newbuilding, isPromo: true })}</div>;
}
