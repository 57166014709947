import * as React from 'react';

/***** STYLES *****/
import * as styles from './Clickable.css';

/***** TYPES *****/
export interface IClickableProps extends React.HTMLProps<HTMLSpanElement> {
  dataMark?: string;
  children: React.ReactNode;
  onClick?(event: React.SyntheticEvent): void;
}

/***** COMPONENT *****/
export function Clickable(props: IClickableProps) {
  const { children, id, onClick, dataMark = 'Clickable' } = props;

  return (
    <span
      id={id}
      data-mark={dataMark}
      className={styles.element}
      onClick={(event: React.SyntheticEvent) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {children}
    </span>
  );
}
