import * as geo from '../../utils/geo';
import { IRegion } from '../../types/regions';
import { IGetRegionResponseSchema } from '../../repositories/monolith-python/entities/schemas/GetRegionResponseSchema';
import { IRegionMetaResponseSchema } from '../../repositories/monolith-python/entities/schemas/RegionMetaResponseSchema';
import { IRegionPresenterSchema } from '../../repositories/monolith-python/entities/schemas/RegionPresenterSchema';

export function completeRegion(
  region: IGetRegionResponseSchema | IRegionMetaResponseSchema | IRegionPresenterSchema,
): IRegion {
  const completedRegion = { ...region };

  if (geo.isMoscow(region as IRegion)) {
    Object.assign(completedRegion, { parentId: geo.MOSCOW_AREA_ID });
  }

  if (geo.isSPB(region as IRegion)) {
    Object.assign(completedRegion, { parentId: geo.LO_ID });
  }

  Object.assign(completedRegion, { isCity: geo.isCity(region as IRegion) });
  Object.assign(completedRegion, { isArea: geo.isArea(region as IRegion) });

  return completedRegion as IRegion;
}
