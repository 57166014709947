import { IFilterPureDescriptor, IJsonQuery } from '../../types/interfaces';
import { isDescriptorActive } from './isDescriptorActive';

export function isValid(state: IJsonQuery) {
  return (descriptor: IFilterPureDescriptor) => {
    const satisfiesWhenNot = descriptor.whenNot
      ? !descriptor.whenNot.some(d => isDescriptorActive({ form: [d] })(state))
      : true;
    const satisfiesWhen = descriptor.when ? descriptor.when.some(d => isDescriptorActive({ form: [d] })(state)) : true;

    return satisfiesWhenNot && satisfiesWhen;
  };
}
