import { IHttpApi } from '@cian/http-api';
import {
  fetchSearchRegionsCities,
  TSearchRegionsCitiesResponse,
} from '../../repositories/monolith-python/v1/search-regions-cities';
import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';

export async function getRegionSearchCities({
  text,
  subdomain,
  httpApi,
}: {
  text: string;
  subdomain: string;
  httpApi: IHttpApi;
}): Promise<TSearchRegionsCitiesResponse> {
  return fetchSearchRegionsCities({ httpApi, parameters: { text }, config: { subdomain } });
}

export async function resolveRegionSearchCities({
  text,
  subdomain,
  httpApi,
}: {
  text: string;
  subdomain: string;
  httpApi: IHttpApi;
}): Promise<ISearchCatalogItemSchema[]> {
  const getResponse = await getRegionSearchCities({ text, subdomain, httpApi });
  if (getResponse.statusCode === 200 && getResponse.response.data.items) {
    return getResponse.response.data.items;
  }

  return [] as ISearchCatalogItemSchema[];
}
