import { pathOr } from 'ramda';
import { IApplicationState } from '../../types/redux';
import {
  getHasTour,
  getNewbuildingPromoSnippetBuilders,
  getNewbuildingPromoSnippetSellers,
  getMinPriceForMeterFromDeveloperValue,
} from './';

interface IExtras {
  cplDynamicCalltracking: 1 | 0;
  cplCalltracking: 1 | 0;
  '3dtour_enabled'?: 1;
  additional_price?: number;
}

export function getNewbuildingExtra(state: IApplicationState) {
  const minPriceForMeterFromDeveloperValue = getMinPriceForMeterFromDeveloperValue(state);
  const builders = getNewbuildingPromoSnippetBuilders(state);
  const sellers = getNewbuildingPromoSnippetSellers(state);

  const contacts = [...builders, ...sellers].filter(el => !!el.phone?.number);

  const siteBlockId = contacts.some(el => !!pathOr(null, ['dynamicCalltracking', 'siteBlockId'], el));
  const isFromLeadFactory = contacts.some(el => pathOr(false, ['isLeadFactory'], el));
  const hasTour = getHasTour(state);

  const extras: IExtras = {
    cplDynamicCalltracking: siteBlockId ? 1 : 0,
    cplCalltracking: !contacts.length || isFromLeadFactory ? 0 : 1,
    additional_price: minPriceForMeterFromDeveloperValue || undefined,
  };

  if (hasTour) {
    extras['3dtour_enabled'] = 1;
  }

  return extras;
}
