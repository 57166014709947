import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const apartmentTypeDefinition = definitionFactory({
  key: 'is_apartment',
  mapper: jsonQueryValue => [
    {
      label: jsonQueryValue === true ? 'Только апартаменты' : 'Без апартаментов',
      value: jsonQueryValue,
    },
  ],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'is_apartment', options);
  },
});
