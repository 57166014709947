import { EAnalyticsActions, TAnalyticsActions } from '../../actions/analytics/actions';
import { IUserGaDataLayerResponseSchema } from '../../repositories/monolith-python/entities/views/UserGaDataLayerResponseSchema';
import { IProduct, IProductById } from '../../types/analytics';

export interface IAnalyticsState {
  userGaData?: IUserGaDataLayerResponseSchema;
  products: IProduct[];
  productsById: IProductById;
}

const INITIAL_STATE = {
  products: [],
  productsById: {},
};

export function analyticsReducer(state: IAnalyticsState = INITIAL_STATE, action: TAnalyticsActions): IAnalyticsState {
  switch (action.type) {
    case EAnalyticsActions.SetProducts: {
      const { products, productsById } = action.payload;

      return { ...state, products, productsById };
    }

    default:
      return state;
  }
}
