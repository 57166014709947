import * as React from 'react';
import { IFromDevelopersRoom } from '../../types/newbuilding';
import * as styles from './PromoSnippetAdvertise.css';
import { plural, shortenNumber } from '@cian/utils';

interface IPromoSnippetAdvertiseProps {
  room: IFromDevelopersRoom;
  onClick(room: string): void;
}

const OFFERS_COUNT_PLURAL_VARIANTS: [string, string, string] = ['предложение', 'предложения', 'предложений'];

export function PromoSnippetAdvertise({ room, onClick }: IPromoSnippetAdvertiseProps) {
  const handleClick = () => {
    onClick(room.countDisplay);
  };

  return (
    <a
      className={styles.container}
      target="_blank"
      rel="nofollow noreferrer"
      href={room.offersUrl}
      onClick={handleClick}
      data-testid="advertise"
    >
      <div className={styles.room}>{room.countDisplay}</div>
      <div className={styles.area}>от {room.minTotalArea} м²</div>
      <div className={styles.price}>
        от {shortenNumber(Number(room.minPrice2), { withoutPostfix: true, separator: ',' })} млн ₽
      </div>
      <div className={styles.count}>
        {room.offersCount} {plural(room.offersCount, OFFERS_COUNT_PLURAL_VARIANTS)}
      </div>
    </a>
  );
}
