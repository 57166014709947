import { IAllFilterTagsActions, ETagsActions } from '../../actions/filterTags';
import { IStateTag } from '../../actions/filterTags/definitionFactory';

export interface IFilterTagsState {
  tags: IStateTag[];
}

export function initialState(): IStateTag[] {
  return [];
}

export const tags = (state: IStateTag[] = initialState(), action: IAllFilterTagsActions) => {
  switch (action.type) {
    case ETagsActions.set:
      return action.tags;
    case ETagsActions.clean:
      return initialState();
    default:
      return state;
  }
};
