import * as React from 'react';

/***** UTILS *****/
import { IStyleArgument, mergeStyles } from '@cian/utils';

/***** STYLES *****/
import * as styles from './Icon.css';

/***** COMPONENTS *****/
import * as icons from './icons/';

/***** TYPES *****/
export type TIconType = 'check' | 'close' | 'mosmetro' | 'location' | 'newbuilding' | 'builder' | 'district';

export type TIconSize = 7 | 10 | 12 | 14 | 16 | 22 | 40 | 48;
import { TColors } from '../../types/colors';
import { TDisplayType } from '../../types/cssVariables';
export interface IIconProps {
  color?: TColors;
  colorHovered?: TColors;
  display?: TDisplayType;
  opacity?: number;
  size?: TIconSize;
  sizeMobile?: TIconSize;
  sizeDesktop?: TIconSize;
  style?: IStyleArgument;
  type: TIconType;
  dataMark?: string;
}

/***** CONSTANTS *****/
const DEFAULT_COLORS: { [type: string]: string } = {
  error: 'red',
};

/***** HELPERS *****/
const getColor = (type: string, color?: string): string => {
  return color || DEFAULT_COLORS[type] || 'blue';
};

/***** COMPONENT *****/
export function Icon(props: IIconProps) {
  const {
    style,
    color,
    colorHovered,
    display,
    opacity,
    size = 12,
    sizeMobile,
    sizeDesktop,
    type,
    dataMark = 'Icon',
  } = props;
  const IconComponent = icons[type as keyof typeof icons];
  const currentColor = getColor(type, color);

  return (
    <div
      {...mergeStyles(
        { opacity },
        [styles['container']],
        [styles[`container-size--${size}`]],
        display && [styles[`container-display--${display}`]],
        sizeMobile && styles[`container-size_mobile--${sizeMobile}`],
        sizeDesktop && styles[`container-size_desktop--${sizeDesktop}`],
        style,
      )}
      data-mark={dataMark}
    >
      <div
        {...mergeStyles(
          [styles['icon']],
          [styles[`icon-color--${currentColor}`]],
          colorHovered && [styles[`icon-color_hovered--${colorHovered}`]],
          type && [styles[`icon-type--${type}`]],
        )}
      >
        <IconComponent />
      </div>
    </div>
  );
}
