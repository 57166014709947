import * as React from 'react';
import { useSelector } from 'react-redux';
import { getMortgageBannerUrl } from '../../selectors/discountMortgage/getMortgageBannerUrl';
import { DiscountMortgageBanner } from '../../components/DiscountMortgageBanner';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { getCurrentJsonQuery } from '../../selectors/location';
import { trackBannerClick, trackBannerShow } from './tracking';

export const DiscountMortgageBannerContainer = () => {
  const url = useSelector(getMortgageBannerUrl);
  const jsonQuery = useSelector(getCurrentJsonQuery);

  const onBannerShown = React.useCallback(() => trackBannerShow(jsonQuery), [jsonQuery]);
  const onBannerClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={onBannerShown} triggerOnce>
      <DiscountMortgageBanner url={url} onClick={onBannerClick} />
    </ActionAfterViewObserver>
  );
};
