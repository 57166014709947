import { IGeoCodedSearchDetails } from '../../../types/geocode';

export type IRegionInfo = IGeoCodedSearchDetails | undefined | null;

export const locationFromCurrentRegion = (
  regionInfo: IRegionInfo,
  regionTermValue: Array<number | string>,
  lastLocationsListItem: IRegionInfo,
) => {
  return (
    regionInfo &&
    regionTermValue.includes(regionInfo.id) &&
    !(lastLocationsListItem && lastLocationsListItem.locationInfo)
  );
};
