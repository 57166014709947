import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as styles from './styles/popupPortal.css';

export interface IPopupPortalProps extends React.HTMLProps<HTMLElement> {
  children?: React.ReactNode;
  top: number;
  left: number;
  calculateOnScroll?: boolean;
  setRef: React.RefObject<HTMLDivElement>;
}

export function PopupPortal({ setRef, children, calculateOnScroll, top = 0, left = 0 }: IPopupPortalProps) {
  if (!children) {
    return null;
  }

  const style = calculateOnScroll ? { transform: `translate3d(${left}px, ${top}px, 0)` } : { top, left };

  const portalWithContainer = (
    <div ref={setRef} data-mark="PopupPortal" className={styles['portal']} style={style}>
      {children}
    </div>
  );

  // Компонент не может вернуть портал как сущность, однако портал может быть обернут во фрагмент
  return React.createElement(React.Fragment, {}, portalize(portalWithContainer));
}

function portalize(reactNode: React.ReactNode) {
  return ReactDOM.createPortal(React.Children.only(reactNode), getPortalNode());
}

function getPortalNode(): HTMLElement {
  const node = document.getElementById('popup_zone');
  if (!node) {
    throw new Error("couldn't get portal container element: #popup_zone");
  }

  return node;
}
