import { descriptorsFactory } from '../../utils/filters';

export const constructionStatusDescriptors = descriptorsFactory({ name: 'Status', clear: [] })(
  {
    form: [{ key: 'status', type: 'terms', value: ['underConstruction'] }],
    label: 'Строится',
    state: 'Off',
    clear: ['is_finished'],
  },
  {
    form: [{ key: 'is_finished', type: 'term', value: true }],
    label: 'Сдан',
    state: 'On',
    clear: ['status'],
  },
  {
    form: [],
    label: 'Без фильтра',
    state: '',
    clear: ['status', 'is_finished'],
  },
);
