import * as React from 'react';
import { useSelector } from 'react-redux';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';

import { getNewbuildingIdsForBuildersPromoSlider } from '../../selectors/newbuildingPromoCarousel';
import { NewbuildingPromoCarouselLoadableContainer } from './NewbuildingPromoCarouselLoadable';
import { NewbuildingPromoCarouselLayout } from '../../components/NewbuildingPromoCarouselLayout';

export function LazyNewbuildingPromoCarouselContainer() {
  const [isVisible, setIsVisible] = React.useState(false);
  const newbuildingIds = useSelector(getNewbuildingIdsForBuildersPromoSlider);

  if (!newbuildingIds.length) {
    return null;
  }

  return (
    <ActionAfterViewed callback={() => setIsVisible(true)} percentVisible={0.8}>
      <div>
        {isVisible && (
          <NewbuildingPromoCarouselLayout isShown={isVisible}>
            <NewbuildingPromoCarouselLoadableContainer newbuildingIds={newbuildingIds} />
          </NewbuildingPromoCarouselLayout>
        )}
      </div>
    </ActionAfterViewed>
  );
}
