import { definitionFactory } from '../definitionFactory';
import { equalTagKey } from '../../../utils/tags';
import { onDeleteAction } from '../definitionsDeleteAction';

export const newbuildingDefinition = definitionFactory({
  key: 'newbuilding_id',
  mapper: (jsonQueryValue, tags, state, extraParams) => {
    const hasNewbuildingTags = extraParams && extraParams.newobject_tags;
    const newTags =
      hasNewbuildingTags &&
      jsonQueryValue.map((tagId: number) => {
        const tag =
          extraParams && extraParams.newobject_tags && extraParams.newobject_tags.find(tag => Number(tag.id) === tagId);

        return {
          label: tag && tag.text,
          value: tagId,
        };
      });

    return newTags
      ? newTags
      : tags.filter(tag => equalTagKey('newbuilding_id')(tag) && jsonQueryValue.includes(tag.value));
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'newbuilding_id', options);
  },
});
