import * as React from 'react';
import { Image as UIImage } from '@cian/ui-kit';
import { NoSsr } from '@cian/react-utils';

import * as styles from './Image.css';

import { NoPhotoImage } from './NoPhotoImage';

export interface IImageProps {
  lazy?: boolean;
  noEmptyImage?: boolean;
  src?: string;
  objectFit?: 'contain' | 'cover' | 'fill';
  alt?: string;
  onClick?: () => void;
}

export class Image extends React.Component<IImageProps> {
  public render() {
    const { lazy, noEmptyImage, src, alt, objectFit } = this.props;

    if (!src && !noEmptyImage) {
      return (
        <div className={styles['image--empty']} data-testid="image-empty">
          <NoPhotoImage />
        </div>
      );
    }

    if (src && !noEmptyImage && lazy) {
      return (
        <NoSsr>
          <UIImage loading="lazy" src={src} alt={alt} objectFit={objectFit} preloader={false} />
        </NoSsr>
      );
    }

    return <img className={styles['image--simple']} src={src} alt={alt} style={{ ...(objectFit && { objectFit }) }} />;
  }
}
