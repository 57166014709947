/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TApiGeoGeocodedForSearchModel,
  IApiGeoGeocodedForSearchRequest,
  IMappers,
  IApiGeoGeocodedForSearchResponse,
  IApiGeoGeocodedForSearchResponseError,
  TApiGeoGeocodedForSearchResponse,
} from './types';

const defaultConfig: TApiGeoGeocodedForSearchModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'monolith-cian-realty',
  pathApi: '/api/geo/geocoded-for-search/',
  hostType: 'api',
} as TApiGeoGeocodedForSearchModel;

function createApiGeoGeocodedForSearchModel(
  parameters: IApiGeoGeocodedForSearchRequest,
): TApiGeoGeocodedForSearchModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IApiGeoGeocodedForSearchOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IApiGeoGeocodedForSearchRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchApiGeoGeocodedForSearch<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IApiGeoGeocodedForSearchOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TApiGeoGeocodedForSearchResponse
> {
  const { response, statusCode } = await httpApi.fetch(createApiGeoGeocodedForSearchModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IApiGeoGeocodedForSearchResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IApiGeoGeocodedForSearchResponseError);
    }
  }

  return { response, statusCode } as TApiGeoGeocodedForSearchResponse;
}

export { defaultConfig, createApiGeoGeocodedForSearchModel, fetchApiGeoGeocodedForSearch };
