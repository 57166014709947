import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { Container } from '../Container';
import { Text } from '../Text';

import * as styles from './Tag.css';

import { TColors } from '../../types/colors';
import { Undergrounds } from '../Undergrounds';
import { SIZE_8 } from '../../constants/sizes';
import { IUndergroundDetails } from '../../types/suggestions';

type TTagButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export interface ITagProps extends TTagButtonProps {
  onClick?(event: React.SyntheticEvent): void;
  onClose?(event: React.SyntheticEvent): void;
  arrowType?: 'up' | 'down';
  color?: TColors;
  pointColor?: string;
  undergroundDetails?: Array<IUndergroundDetails>;
}

export class Tag extends React.PureComponent<ITagProps> {
  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { arrowType, children, pointColor, undergroundDetails, disabled, onClick, onClose, ...rest } = this.props;

    return (
      <button
        onClick={this.onClickHandler}
        {...mergeStyles(styles['tag'], disabled && styles['tag--disabled'])}
        disabled={disabled}
        {...rest}
        type="button"
      >
        <div className={styles['tag-content']}>
          {(undergroundDetails || pointColor) && (
            <Container display="inline-block" margin={`0 ${SIZE_8} 0 0`} verticalAlign="middle">
              <Undergrounds pointColor={pointColor} undergrounds={undergroundDetails} />
            </Container>
          )}
          <Text color={disabled ? 'gray_2' : 'gray_4'} fontSize={14} lineHeight={20}>
            {children}
          </Text>
        </div>
        <div
          {...mergeStyles(
            !onClick && styles['tag-icon_close'],
            this.canShowArrowUp() && styles['tag-icon_up'],
            this.canShowArrowDown() && styles['tag-icon_down'],
          )}
        />
      </button>
    );
  }

  private canShowArrowUp = () => {
    const { onClick, arrowType } = this.props;

    return onClick && arrowType !== 'down';
  };

  private canShowArrowDown = () => {
    const { onClick, arrowType } = this.props;

    return onClick && arrowType === 'down';
  };

  private onClickHandler = (event: React.SyntheticEvent) => {
    const { onClick, onClose } = this.props;

    if (onClick) {
      onClick(event);
    } else if (!onClick && onClose) {
      onClose(event);
    }
  };
}
