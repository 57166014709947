import { ca } from '@cian/analytics';
import { IJsonQuery } from '../../../types/jsonQuery';

export const trackBannerShown = (sc: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BlackFriday',
    action: 'show_sopr',
    label: 'listing_banner',
    sc,
  });
};
