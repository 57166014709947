import * as React from 'react';
import { Container } from '../Container';
import { Text } from '../Text';
import { SIZE_16 } from '../../constants/sizes';

export interface IBuilderLabelProps {
  text: string;
  dataMark?: string;
}

export function BuilderLabel({ text, dataMark }: IBuilderLabelProps) {
  return (
    <Container
      backgroundColor="green_light"
      padding="0 6px"
      display="inline-block"
      height={SIZE_16}
      data-mark={dataMark}
    >
      <Text
        color="green"
        fontSize={10}
        fontWeight="bold"
        lineHeight={16}
        textTransform="uppercase"
        verticalAlign="top"
        letterSpacing="1px"
      >
        {text}
      </Text>
    </Container>
  );
}
