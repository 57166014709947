import * as React from 'react';

import { Link } from '../Link';
import { IBreadcrumb } from '../../types/offersData';

import * as styles from './Breadcrumbs.css';

export interface IBreadcrumbsProps {
  stack: IBreadcrumb[];
  dataMark?: string;
}

export function Breadcrumbs(props: IBreadcrumbsProps) {
  const { dataMark = 'Breadcrumbs', stack } = props;

  return (
    <div data-mark={dataMark}>
      {stack.map((item: IBreadcrumb) => (
        <Link key={item.url} linkStyle={styles['crumb']} url={item.url}>
          {item.title}
        </Link>
      ))}
    </div>
  );
}
