import * as React from 'react';
import { Button } from '@cian/ui-kit';
import { getSearchButtonText } from './helpers';
import { TButtonSize } from '@cian/ui-kit/button/types';

export interface ISearchButtonProps {
  count?: number | null;
  loading?: boolean;
  size?: TButtonSize;
  onClick?(event: React.MouseEvent): void;
}

export const SearchButton: React.FC<ISearchButtonProps> = ({ count, loading, size = 'XS', onClick }) => {
  return (
    <Button loading={loading} onClick={onClick} size={size} type="submit" data-testid="SearchButton">
      {getSearchButtonText(count)}
    </Button>
  );
};
