export enum EPromoSnippetActions {
  setPromoSnippetVisibility = 'set/promoSnippetVisibility',
}

export interface IPromoSnippetVisibilityAction {
  type: EPromoSnippetActions.setPromoSnippetVisibility;
  payload: boolean;
}

export function setPromoSnippetVisibility(isVisible: boolean): IPromoSnippetVisibilityAction {
  return {
    type: EPromoSnippetActions.setPromoSnippetVisibility,
    payload: isVisible,
  };
}
