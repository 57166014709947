import * as React from 'react';
import { clsx } from 'clsx';

import { SuggestTermInfo } from '../SuggestTermInfo';

import * as styles from './SuggestItem.css';

export interface ISuggestItemProps {
  description?: string;
  onClick?(event: React.MouseEvent): void;
  onHover?(event: React.MouseEvent): void;
  selected?: boolean;
  term: string;
  title: string;
  undergroundColors?: string[];
  url?: string;
}

export const SuggestItem: React.FC<ISuggestItemProps> = ({
  description,
  onClick,
  onHover,
  selected,
  term,
  title,
  undergroundColors,
  url,
}) => {
  return (
    <div className={styles['container']}>
      <div
        className={clsx(styles['suggest-item'], selected && styles['suggest-item__selected'])}
        onClick={onClick}
        onMouseEnter={onHover}
      >
        <SuggestTermInfo description={description} term={term} title={title} />
      </div>

      {undergroundColors && undergroundColors.length > 0 && (
        <div className={styles['undergrounds']}>
          {undergroundColors.map((color, idx) => {
            return <div key={idx} className={styles['underground-item']} style={{ backgroundColor: `#${color}` }} />;
          })}
        </div>
      )}

      {url && <a className={styles['link']} href={url} data-mark="CategoryLink" rel="noreferrer" target="_blank" />}
    </div>
  );
};
