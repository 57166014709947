/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRegionCitiesModel,
  IGetRegionCitiesRequest,
  IMappers,
  IGetRegionCitiesResponse,
  IGetRegionCitiesResponseError,
  TGetRegionCitiesResponse,
} from './types';

const defaultConfig: TGetRegionCitiesModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-region-cities/',
  hostType: 'api',
} as TGetRegionCitiesModel;

function createGetRegionCitiesModel(parameters: IGetRegionCitiesRequest): TGetRegionCitiesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRegionCitiesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRegionCitiesRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRegionCities<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRegionCitiesOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetRegionCitiesResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetRegionCitiesModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRegionCitiesResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRegionCitiesResponseError);
    }
  }

  return { response, statusCode } as TGetRegionCitiesResponse;
}

export { defaultConfig, createGetRegionCitiesModel, fetchGetRegionCities };
