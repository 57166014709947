import { IOffersDataState } from '../../types/offersData';

export enum EOffersActions {
  set = 'set/regions',
}

export interface INewbuildingsAction {
  type: EOffersActions;
  data: IOffersDataState;
}

export function setOffersData(offersData: IOffersDataState) {
  return {
    data: offersData,
    type: EOffersActions.set,
  };
}
