import { IApplicationState } from '../../types/redux';
import { getUser } from './getUser';

export const getIsAgent = (state: IApplicationState) => {
  const user = getUser(state);

  if (user && user.isAuthenticated) {
    return user.isAgent;
  }

  return false;
};
