import * as React from 'react';
import { Checkbox, Label } from '@cian/ui-kit';

import { IconProductHotLeads16 } from '@cian/ui-kit-design-tokens/icons';

import { getOffersCount } from '../../actions/offersData';
import { Container } from '../../components/Container';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { isBlackFridayDescriptor } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { TTypedThunkDispatch } from '../../types/thunk';
import { updateTags } from '../../actions/filterTags';

export interface IIsBlackFridayProps extends IDescriptorProps<string> {
  getOffersCount(): void;
  updateTags(): void;
}

export const IsBlackFridayComponent = ({
  actionCreator,
  descriptors,
  jsonQuery,
  getOffersCount,
  updateTags,
}: IIsBlackFridayProps) => {
  const activeDescriptor = React.useMemo(
    () => descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery)) || descriptors[1],
    [descriptors, jsonQuery],
  );

  const isActive = activeDescriptor.state === 'On';

  const handleStateChange = React.useCallback(() => {
    actionCreator(isActive ? descriptors[1] : descriptors[0])();
    updateTags();
    getOffersCount();
  }, [isActive, actionCreator, getOffersCount, updateTags]);

  return (
    <Container display="flex" columnGap="4px">
      <Checkbox
        checked={isActive}
        onChange={handleStateChange}
        label={activeDescriptor.label}
        data-testid="BlackFridayCheckbox"
      />
      <Label
        background="var(--surface-basic)"
        color="var(--accent-primary-basic)"
        icon={<IconProductHotLeads16 color="primary_100" />}
      >
        до 29 сентября
      </Label>
    </Container>
  );
};

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
    updateTags: () => {
      dispatch(updateTags());
    },
  };
}

export const IsBlackFriday = connectToDescriptors<{}, {}>(
  isBlackFridayDescriptor,
  mapDispatchToProps,
)(IsBlackFridayComponent);
