import { pathOr } from 'ramda';
import { INewbuilding } from '../../types/newbuilding';

export function getNewbuildingExtra(newbuilding: INewbuilding) {
  const { builders } = newbuilding;
  const sellers = newbuilding.sellers || [];
  const contacts = [...builders, ...sellers].filter(el => !!el.phone);

  const siteBlockId = contacts.some(el => !!pathOr(null, ['newbuildingDynamicCalltracking', 'siteBlockId'], el));
  const isFromLeadFactory = contacts.some(el => pathOr(false, ['isLeadFactory'], el));

  return {
    cplDynamicCalltracking: siteBlockId ? 1 : 0,
    cplCalltracking: !contacts.length || isFromLeadFactory ? 0 : 1,
  };
}
