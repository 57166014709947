import { IProduct, IProductById } from '../types/analytics';
import { IOffersDataState } from '../types/offersData';

export const prepareProducts = (offersData: IOffersDataState) => {
  const products: IProduct[] = [];
  const productsById: IProductById = {};

  offersData.newbuildings.forEach((newbuilding, index) => {
    const product: IProduct = {
      id: newbuilding.id,
      offerType: 'JK',
      position: index + 1,
    };

    products.push(product);
    productsById[newbuilding.id] = product;
  });

  return { products, productsById };
};
