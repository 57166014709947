import { IThunkActionCreator } from '../../types/thunk';
import { createSimpleAction } from '../../containers/сonnectToDescriptors';
import { newbuildingDescriptor } from '../../constants/descriptors';
import { addTag } from './addTag';

export const selectNewbuildingSuggestion = (value?: number, displayName?: string): IThunkActionCreator => {
  return dispatch => {
    createSimpleAction(dispatch, newbuildingDescriptor[value === undefined ? 1 : 0])(value);
    dispatch(addTag('newbuilding_id', value, displayName));
  };
};
