import * as React from 'react';
import { IFromDevelopersRoom } from '../../types/newbuilding';
import { PromoSnippetAdvertise } from '../PromoSnippetAdvertise';

interface IPromoSnippetAdvertisesProps {
  fromDevelopersRooms: IFromDevelopersRoom[];
  onClick(room: string): void;
}

export function PromoSnippetAdvertises({ fromDevelopersRooms, onClick }: IPromoSnippetAdvertisesProps) {
  return (
    <>
      {fromDevelopersRooms.map((room, index) => (
        <PromoSnippetAdvertise onClick={onClick} room={room} key={index} />
      ))}
    </>
  );
}
