import { Microfrontend } from '@cian/mf-react';
import { useBooleanState } from '@cian/react-utils';
import { useSelector } from 'react-redux';
import * as React from 'react';

import { useNewbuildingBrokerWidgetAPI } from './useNewbuildingBrokerWidgetAPI';
import { NewbuildingBrokerWidgetContext } from './NewbuildingBrokerWidgetContext';
import { TOpenWidgetResultHandler } from './types';
import { BrokerFallbackModal } from '../../components/BrokerFallbackModal';
import { IOpenBrokerModalParams } from '../../types/microfrontends/newbuildingBrokerWidget';
import { getCurrentJsonQuery } from '../../selectors/location';
import { trackFallbackModalOpen, trackFallbackModalRetryClick } from '../../utils/newbuildingBroker/sharedTracking';
import { getAnalyticsUser } from '../../selectors/analytics';

/**
 * Компонент, обеспечивающий загрузку микрофронта брокерских модалок на клиенте.
 *
 * 1. Пользователь кликает на кнопку, и вызывается `handleWidgetOpeningStart`.
 * 2. Регистрируем манифест, загружаем ассеты
 * 3. Сеттим в React-стейт манифест. Ждем сеттинга с помощью useEffect.
 * 4. По стейту отрисовывается <Microfrontend />
 * 5. Инициализуруется реф на <Microfrontend />, содержащий локальное API.
 * 6. Вызываем на рефе локальное API, и открывается модалка брокерки.
 */
export const NewbuildingBrokerWidgetProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const paramsRef = React.useRef<IOpenBrokerModalParams>();
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const user = useSelector(getAnalyticsUser);
  const [loading, setLoading] = React.useState(false);
  const { state: isFallbackModalVisible, setTrue: showFallbackModal, setFalse: closeFallbackModal } = useBooleanState();

  const handleWidgetOpeningFinish = React.useCallback<TOpenWidgetResultHandler>(
    ({ isError }) => {
      if (isError) {
        if (!isFallbackModalVisible) {
          trackFallbackModalOpen(jsonQuery, user);
          showFallbackModal();
        }
      } else if (isFallbackModalVisible && !isError) {
        closeFallbackModal();
      }
    },
    [closeFallbackModal, isFallbackModalVisible, showFallbackModal, jsonQuery, user],
  );

  const { openWidget, widgetRef, widgetManifest } = useNewbuildingBrokerWidgetAPI(handleWidgetOpeningFinish);

  const handleWidgetOpeningStart = React.useCallback(
    async (params: IOpenBrokerModalParams) => {
      // Сохраняем в реф для работы с теми же параметрами на случай, если произойдет ошибка.
      paramsRef.current = params;

      await openWidget(params);
    },
    [openWidget],
  );

  const handleRetryClick = React.useCallback(async () => {
    trackFallbackModalRetryClick(jsonQuery, user);

    setLoading(true);
    await openWidget(paramsRef.current as IOpenBrokerModalParams);
    setLoading(false);
  }, [openWidget, jsonQuery, user]);

  return (
    <NewbuildingBrokerWidgetContext.Provider value={handleWidgetOpeningStart}>
      {children}
      {widgetManifest && (
        <Microfrontend
          ref={widgetRef}
          name={widgetManifest.microfrontendName}
          image={widgetManifest.imageVersion}
          runtime={widgetManifest.runtimeName}
          instance={widgetManifest.instanceId}
        />
      )}
      {isFallbackModalVisible && (
        <BrokerFallbackModal
          isLoading={loading}
          isOpen={isFallbackModalVisible}
          onClose={closeFallbackModal}
          onRetry={handleRetryClick}
        />
      )}
    </NewbuildingBrokerWidgetContext.Provider>
  );
};
