import { resolveOffersDataCount } from '../../api/offersData/getOffersDataCount';
import { IThunkActionCreator } from '../../types/thunk';
import { QsIdToJSONQueryIds } from '../../utils/geo';
import { IRegion } from '../../types/regions';
import { loadOffersCount, setOffersCount, cleanOffersCount } from './setOffersCount';
import { IJsonQuery } from '../../types/interfaces';

type JsonQueryKey = keyof IJsonQuery;

export const getOffersCount = (region?: IRegion): IThunkActionCreator => {
  return (dispatch, getState) => {
    const state = getState();
    const { jsonQuery: originalJsonQuery } = state;
    const {
      location,
      filters: {
        offersCount: { isFetching },
      },
    } = state;

    if (isFetching) {
      return;
    }
    dispatch(loadOffersCount());

    const jsonQuery = { ...originalJsonQuery };

    if (region) {
      const queriesKeyForRemove: JsonQueryKey[] = ['builders', 'newbuilding_id', 'geo'];
      queriesKeyForRemove.forEach(query => delete jsonQuery[query]);
    }

    const regionId = region && QsIdToJSONQueryIds(region.id);
    const regionJsonQuery = Array.isArray(regionId) ? regionId : [regionId];
    const query = region
      ? Object.assign({}, jsonQuery, { region: { type: 'terms', value: [...regionJsonQuery] } })
      : jsonQuery;

    return resolveOffersDataCount(query, location.subdomain)
      .then(offersDataCount => {
        if (typeof offersDataCount.count !== 'undefined') {
          dispatch(setOffersCount(offersDataCount.count));
        }
      })
      .catch(() => {
        dispatch(cleanOffersCount());
      });
  };
};
