import * as React from 'react';
import { Tag } from '../../components/Tag';
import { IStateTag, IDefinitionValue, TStateTagValue } from '../../actions/filterTags';
import { connect } from 'react-redux';
import { TTypedThunkDispatch } from '../../types/thunk';

interface IDispProps {
  deleteTag(value: TStateTagValue): void;
}

export type IHouseTypeProps = IStateTag & IDispProps;

export function defaultRenderer(props: IHouseTypeProps): JSX.Element {
  const { label, deleteTag, value, pointColor, undergroundDetails } = props;

  return (
    <Tag onClose={() => deleteTag(value)} pointColor={pointColor} undergroundDetails={undergroundDetails}>
      {label}
    </Tag>
  );
}

export function rendererFactory(definition: IDefinitionValue) {
  return connect(null, (dispatch: TTypedThunkDispatch) => ({
    deleteTag(value: TStateTagValue) {
      const onDeleteAction = definition.onDeleteAction(value);
      dispatch(onDeleteAction);
    },
  }))(definition.renderer);
}
