import { ERegionActions, IRegionAction } from '../../actions/region/regions';
import { TRegions } from '../../types/regions';

export const regions = (state: TRegions = [], action: IRegionAction) => {
  switch (action.type) {
    case ERegionActions.init:
      return action.data;
    default:
      return state;
  }
};
