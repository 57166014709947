import { definitionFactory, TStateTagForFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';
import { equals } from 'ramda';

export const polygonDefinition = definitionFactory({
  key: 'polygon',
  mapper: (jsonQueryValue, tags) => {
    const polygonTag = jsonQueryValue && jsonQueryValue[0];

    const newTag = polygonTag && {
      label: polygonTag.name,
      value: {
        name: polygonTag.name,
        coordinates: polygonTag.coordinates,
      },
    };

    const prepareTags = [
      ...tags
        .filter(tag => {
          return tag.keyName === 'polygon' && !equals(tag.value, newTag.value);
        })
        .filter(tag => tag.keyName !== 'polygon'),
      newTag,
    ];

    return prepareTags as TStateTagForFactory[];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'polygon', options);
  },
});
