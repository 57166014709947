import * as React from 'react';

import { Container } from '../../components/Container';
import { Text } from '../../components/Text';
import { Link } from '../../components/Link';
import { INewbuilding } from '../../types/newbuilding';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

const getTransportTypeName = (transportType: string) => {
  switch (transportType) {
    case 'transport':
      return 'на транспорте';
    case 'walk':
      return 'пешком';
    default:
      return '';
  }
};

const getGeoName = (type: string) => {
  switch (type) {
    case 'district':
      return 'района';
    case 'street':
      return 'ул.';
    case 'country':
      return 'страны';
    case 'location':
      return 'местоположения';
    case 'road':
      return 'шоссе';
    case 'underground':
      return 'метро';
    case 'house':
      return 'дома';
    default:
      return '';
  }
};

export function getGeoDistance(newbuilding: INewbuilding, urlFinisher?: string) {
  if (newbuilding.seoGeoDistance) {
    const { travelTime, type, transportType, name } = newbuilding.seoGeoDistance;

    const spot = `${getGeoName(type)} ${name}`;

    return (
      <Container display="inline-flex" dataMark="ExpectedTime">
        <Text color="gray_4">
          {`${travelTime}${NO_BREAK_SPACE}мин. ${getTransportTypeName(transportType)} от `}
          {urlFinisher ? (
            <Link target="_blank" url={urlFinisher}>
              <Text color="gray_4" colorHovered="red" whiteSpace="nowrap">
                {spot}
              </Text>
            </Link>
          ) : (
            spot
          )}
        </Text>
      </Container>
    );
  }

  return null;
}
