import { IOffersDataState } from '../../types/offersData';
import { EOffersActions, INewbuildingsAction } from '../../actions/offersData';
import { DEFAULT_OFFERS_DATA } from '../../constants/redux';

export function offersData(state: IOffersDataState = DEFAULT_OFFERS_DATA, action: INewbuildingsAction) {
  switch (action.type) {
    case EOffersActions.set:
      return action.data;
    default:
      return { ...state };
  }
}
