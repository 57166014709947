import * as React from 'react';
import { Button } from '@cian/ui-kit';

export const PrintButtonContainer: React.FC = () => {
  const printDocument = React.useCallback(() => {
    window.print();
  }, []);

  return (
    <Button onClick={printDocument} size="XS" theme="fill_secondary" type="button">
      Распечатать предложения
    </Button>
  );
};
