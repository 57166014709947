import * as React from 'react';
import { ArticleParagraph1 } from '@cian/ui-kit';

import { SALES_TYPE_ICONS } from '../../constants/salesTypeIcons';
import { ESalesType } from '../../types/offersData';

import * as styles from './SalesDescription.css';

interface ISalesLinkProps {
  text: string[];
  type: ESalesType;
}

export function SalesDescription({ type, text }: ISalesLinkProps) {
  const iconSource = SALES_TYPE_ICONS.get(type);

  return (
    <div className={styles['container']}>
      {iconSource && <img className={styles['icon']} src={iconSource} alt="" />}
      <div className={styles['content']}>
        <ArticleParagraph1>
          {text.map((content, idx) => {
            if (idx < text.length - 1) {
              return [content, <br key={idx} />];
            }

            return content;
          })}
        </ArticleParagraph1>
      </div>
    </div>
  );
}
