import { AnyAction } from 'redux';
import { descriptorsFactory } from '../../utils/filters';
import { IApplicationState } from '../../types/redux';
import * as geo from '../../utils/geo';

export function moscowAndMoAndSpbAndLo(id: number) {
  switch (id) {
    case geo.MOSCOW_AND_MOSCOW_AREA_ID:
      return geo.MOSCOW_AND_MO_IDS;
    case geo.SPB_AND_LO_ID:
      return geo.SPB_AND_LO_IDS;
    default:
      return [id];
  }
}

export const regionDescriptor = descriptorsFactory({ name: 'Region', clear: ['region', 'underground_walk_time'] })({
  form: [
    {
      key: 'region',
      type: 'terms',
      value: (state: IApplicationState, action: AnyAction) => moscowAndMoAndSpbAndLo(action.value),
    },
  ],
  label: 'Регион',
  state: 'region',
});
