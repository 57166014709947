import * as React from 'react';
import * as style from './style.css';
import { UIHeading4 } from '@cian/ui-kit';

export interface IBuilderNameProps {
  name: string;
}

export function BuilderName({ name }: IBuilderNameProps) {
  return (
    <div className={style['title']}>
      <UIHeading4 as="span">{name}</UIHeading4>
    </div>
  );
}
