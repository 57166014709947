import * as React from 'react';
import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { ISeoLink } from '../../types/offersData';
import { Text } from '../../components/Text';
import { Link } from '../../components/Link';

interface ISeoLinksStoreProps {
  seoLinks: ISeoLink[];
}

const SPACE_SYMBOL = '\u0020';

class SeoLinks extends React.PureComponent<ISeoLinksStoreProps> {
  public render() {
    const { seoLinks = [] } = this.props;

    if (!seoLinks) {
      return null;
    }

    return (
      <Text color="gray_4">
        {seoLinks.map(({ title, url }, index) => (
          <React.Fragment key={index}>
            {!!index && `${SPACE_SYMBOL}|${SPACE_SYMBOL}`}
            <Link url={url}>{title}</Link>
          </React.Fragment>
        ))}
      </Text>
    );
  }
}

function mapStateToProps(state: IApplicationState): ISeoLinksStoreProps {
  let seoLinks: ISeoLink[] = [];

  if (state.offersData.seo && state.offersData.seo.seoLinks) {
    seoLinks = state.offersData.seo.seoLinks;
  }

  return { seoLinks };
}

export const SeoLinksContainer = connect(mapStateToProps)(SeoLinks);
