import { IHttpApi } from '@cian/http-api';
import { fetchAddFavorite } from '../../repositories/favorites/v1/add-favorite';
import { fetchDeleteOfferFromFavorites } from '../../repositories/favorites/v1/delete-offer-from-favorites';
import {
  EDealType,
  EEntityType,
} from '../../repositories/favorites/entities/add_favorite/AddFavoriteEntityPublicRequestSchema';

export async function addNewbuildingToFavorites({
  httpApi,
  newbuildingId,
}: {
  httpApi: IHttpApi;
  newbuildingId: number;
}) {
  return fetchAddFavorite({
    httpApi,
    parameters: {
      dealType: EDealType.Sale,
      entityType: EEntityType.Newbuilding,
      entityId: newbuildingId,
    },
  });
}

export async function removeNewbuildingFromFavorites({
  httpApi,
  newbuildingId,
}: {
  httpApi: IHttpApi;
  newbuildingId: number;
}) {
  return fetchDeleteOfferFromFavorites({
    httpApi,
    parameters: {
      dealType: EDealType.Sale,
      entityType: EEntityType.Newbuilding,
      entityId: newbuildingId,
    },
  });
}
