import { getValue } from './getValue';
import { hasByType } from './hasByType';
import { IJQDescription, IJsonQuery, IPolygonItem } from '../../types/interfaces';

export function isDescriptorActive(descriptor: { form: IJQDescription[] }) {
  return (jsonQuery: IJsonQuery) =>
    descriptor.form.every(({ key, value, type }) =>
      jsonQuery[key]
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          hasByType<number | string | boolean | IPolygonItem>(type, jsonQuery[key]!, getValue(value, jsonQuery))
        : false,
    );
}
