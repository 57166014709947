import { AnyAction } from 'redux';

import { descriptorsFactory, IJsonQuery } from '../../utils/filters';

export const newbuildingDescriptor = descriptorsFactory({ name: 'Newbuilding', clear: [] })(
  {
    form: [
      {
        key: 'newbuilding_id',
        type: 'terms',
        value: (state: IJsonQuery, action: AnyAction) => [action.value],
      },
    ],
    label: 'ЖК',
    state: 'newbuilding_id_set',
  },
  {
    form: [],
    label: 'ЖК',
    state: 'newbuilding_id_clear',
  },
);
