import { IDeleteOptions, TDefinitionKey, TJsonQueryValue } from './definitionFactory';
import { IThunkActionCreator } from '../../types/thunk';
import { clearJsonQueryAction } from '../filters';
import { getOffersCount } from '../offersData';
import { putDefaultRegion, updateTags } from './tags';

export function onDeleteAction(
  value: TJsonQueryValue,
  key: TDefinitionKey,
  options: IDeleteOptions,
): IThunkActionCreator {
  return dispatch => {
    dispatch(clearJsonQueryAction(key, value));
    dispatch(updateTags());
    dispatch(putDefaultRegion());
    if (!options.skipUpdateOffersCount) {
      dispatch(getOffersCount());
    }
  };
}
