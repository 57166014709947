import { EOffersCountActions, IStateOffersCount } from '../../actions/offersData';
import { AnyAction, combineReducers } from 'redux';

export function initialState(): IStateOffersCount {
  return {
    count: null,
    isFetching: false,
  };
}

export const count = (state: number | null = initialState().count, action: AnyAction) => {
  switch (action.type) {
    case EOffersCountActions.set:
      return action.count;
    case EOffersCountActions.clean:
      return initialState().count;
    default:
      return state;
  }
};

export const isFetching = (state: boolean = initialState().isFetching, action: AnyAction) => {
  switch (action.type) {
    case EOffersCountActions.get:
      return true;
    case EOffersCountActions.set:
      return false;
    case EOffersCountActions.clean:
      return false;
    default:
      return state;
  }
};

export const offersCount = combineReducers({
  isFetching,
  count,
});
