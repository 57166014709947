import { IApplicationState } from '../../types/redux';
import { IRegion } from '../../types/regions';
import { isMoscowAndMoscowArea, isSPBAndLo } from '../../utils/geo';

export interface IRegionsByAlphabet {
  [symbol: string]: (IRegion | string)[];
}

export function getRegionsByAlphabet(state: IApplicationState): IRegionsByAlphabet {
  const { regions } = state.region;
  const result: IRegionsByAlphabet = {
    _: [],
    order: [],
  };

  if (!regions || !regions.length) {
    return result;
  }
  regions.forEach((region: IRegion) => {
    const { displayName } = region;

    if (isMoscowAndMoscowArea(region) || isSPBAndLo(region)) {
      result._.push(region);
    } else {
      if (!result[displayName[0]]) {
        result[displayName[0]] = [];
        result.order.push(displayName[0]);
      }

      result[displayName[0]].push(region);
    }
  });

  result.order.sort();

  return result;
}
