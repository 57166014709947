import { EUndergroundActions, IUndergroundAction } from '../../actions/region';
import { IUnderground } from '../../types/regions';

export type TUndergroundsState = Array<IUnderground>;

export const undergrounds = (state: TUndergroundsState = [], action: IUndergroundAction) => {
  switch (action.type) {
    case EUndergroundActions.init:
      return action.data;
    default:
      return state;
  }
};
