import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from '@cian/ui-kit';

import { ClickOutside } from '../../components/outside';
import { Container } from '../../components/Container';
import { Divider } from '../../components/Divider';
import { MountPopup } from '../../components/PopupViewer';

import { SIZE_16, SIZE_12, SIZE_4 } from '../../constants/sizes';
import { getNewbuildingById } from '../../selectors/getNewbuildingById';
import { getCallButtonText, isNewbuildingInLeadFactory } from '../../utils/helpers';

import { PhoneButton } from './PhoneButton';
import { PhoneInfo } from './PhoneInfo';
import { formatWorkTime } from '../../utils/text';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';
import { IApplicationState } from '../../types/redux';
import { IShowPhoneButtonOwnProps, IMapStateToPropsPhoneButtonProps, IShowPhoneButtonState } from './types';

type TShowPhoneButtonComponentProps = IShowPhoneButtonOwnProps & IMapStateToPropsPhoneButtonProps;

export class ShowPhoneButtonComponent extends React.Component<TShowPhoneButtonComponentProps, IShowPhoneButtonState> {
  public state: Readonly<IShowPhoneButtonState> = {
    showPopup: false,
  };

  public render() {
    const { agents, builders, onPhoneClick, newbuildingId, isLeadFactory } = this.props;
    const { showPopup } = this.state;

    if (this.numberOfPhonesIsEqual(0)) {
      return null;
    }

    if (this.numberOfPhonesIsEqual(1)) {
      const phone = this.getPhone();
      const siteBlockId = this.getSiteBlockId();
      const time = this.getTime();

      return phone ? (
        <PhoneButton
          phone={phone}
          time={time}
          type="primary"
          onClick={onPhoneClick}
          siteBlockId={siteBlockId}
          newbuildingId={newbuildingId}
        />
      ) : null;
    }

    return (
      <MountPopup
        placement="bottom"
        component={
          showPopup && (
            <ClickOutside onOutside={this.popupToggle}>
              <Container backgroundColor="white" boxShadow="0 2px 8px 0 rgba(0, 0, 0, 0.25)">
                {builders && !!builders.length && (
                  <Container padding={`${SIZE_16} ${SIZE_12} 0`}>
                    {builders.map((builder, index) => (
                      <PhoneInfo
                        key={builder.name + index}
                        phoneInfo={builder}
                        onClick={onPhoneClick}
                        newbuildingId={newbuildingId}
                      />
                    ))}
                  </Container>
                )}
                {builders && !!builders.length && agents && !!agents.length && <Divider />}
                {agents && !!agents.length && (
                  <Container padding={`${SIZE_16} ${SIZE_12} ${SIZE_4}`}>
                    {agents.map((agent, index) => (
                      <PhoneInfo
                        key={agent.name + index}
                        phoneInfo={agent}
                        onClick={onPhoneClick}
                        newbuildingId={newbuildingId}
                      />
                    ))}
                  </Container>
                )}
              </Container>
            </ClickOutside>
          )
        }
      >
        <Button
          onClick={this.popupToggle}
          data-mark="ShowPhonePopupButton"
          size="XS"
          theme="fill_primary"
          type="button"
        >
          {getCallButtonText(isLeadFactory)}
        </Button>
      </MountPopup>
    );
  }

  private popupToggle = () => {
    const { onShowPhonesClick } = this.props;
    const isShow = !this.state.showPopup;
    this.setState({ showPopup: isShow });

    if (onShowPhonesClick && isShow) {
      onShowPhonesClick();
    }
  };

  private numberOfPhonesIsEqual = (count: number) => {
    const { agents, builders = [] } = this.props;
    const agentsLength = agents ? agents.length : 0;

    return agentsLength + builders.length === count;
  };

  private getPhone = () => {
    const { agents, builders = [] } = this.props;
    const item = agents && agents.length ? agents[0] : builders[0];

    return item.phone || '';
  };

  private getSiteBlockId = () => {
    const { agents, builders = [] } = this.props;
    const item = agents && agents.length ? agents[0] : builders[0];

    return item?.newbuildingDynamicCalltracking?.siteBlockId || null;
  };

  private getTime = () => {
    const { agents, builders = [] } = this.props;
    const nbsp = NO_BREAK_SPACE;

    if (agents && agents[0]) {
      const workTimeInfo = agents[0].workTimeInfo;

      if (workTimeInfo && workTimeInfo.callFrom) {
        return `с ${formatWorkTime(workTimeInfo.callFrom)} до ${formatWorkTime(workTimeInfo.callTo)}`;
      }
    } else if (builders && builders[0]) {
      const workTimeInfo = builders[0].workTimeInfo;

      if (workTimeInfo && workTimeInfo.callFrom) {
        return `с${nbsp}${formatWorkTime(workTimeInfo.callFrom)} до${nbsp}${formatWorkTime(workTimeInfo.callTo)}`;
      }
    }

    return '';
  };
}

export const mapStateToProps = (
  state: IApplicationState,
  { newbuildingId }: IShowPhoneButtonOwnProps,
): IMapStateToPropsPhoneButtonProps => {
  const newbuilding = getNewbuildingById(state, newbuildingId);

  return {
    isLeadFactory: !!newbuilding && isNewbuildingInLeadFactory(newbuilding),
    dynamicCalltrackingPlaceType: 'ShowPhoneButton',
  };
};

export const ShowPhoneButton = connect(mapStateToProps)(ShowPhoneButtonComponent);
