import * as React from 'react';
import { connect } from 'react-redux';
import { DynamicCalltrackingButton, EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { ellipsis, formatPhoneNumber } from '@cian/newbuilding-utils';
import { UIText3 } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import { getNewbuildingById } from '../../selectors/getNewbuildingById';
import { IApplicationState } from '../../types/redux';
import { getCallButtonText, isNewbuildingInLeadFactory } from '../../utils/helpers';
import {
  IMapStateToPropsPhoneButtonProps,
  IPhoneButtonOwnProps,
  IPhoneButtonState,
  IShowPhoneButtonOwnProps,
} from './types';

import * as styles from './PhoneButton.css';

type TPhoneButtonComponentProps = IPhoneButtonOwnProps & IMapStateToPropsPhoneButtonProps;

export class PhoneButtonComponent extends React.Component<TPhoneButtonComponentProps, IPhoneButtonState> {
  public state: Readonly<IPhoneButtonState> = {
    showPhone: false,
  };

  public render() {
    const { preview, isLeadFactory, dynamicCalltrackingPlaceType, siteBlockId, time, type } = this.props;
    const { showPhone } = this.state;
    const phone = formatPhoneNumber(this.props.phone);

    const callButtonDefaultName = getCallButtonText(isLeadFactory);

    return (
      <div data-testid="PhoneButton">
        <DynamicCalltrackingButton
          {...mergeStyles(
            styles['show-phone'],
            type === 'primary' ? styles['phone-primary'] : styles['phone-secondary'],
          )}
          openPhoneClassName={styles['opened-phone']}
          phone={phone}
          placeType={dynamicCalltrackingPlaceType}
          platformType={EPlatformTypes.WebDesktop}
          pageType={EPageTypes.NewbuildingList}
          isOpen={showPhone}
          blockId={siteBlockId}
          onClick={this.showPhonesHandler}
          dataMarkButton={'ShowPhoneButton'}
          dataMarkPhone={'PhoneButton'}
        >
          {!showPhone && preview ? ellipsis(phone, phone.length - 1) : callButtonDefaultName}
        </DynamicCalltrackingButton>
        {showPhone && time && (
          <UIText3 color="gray60_100" whiteSpace="nowrap">
            {time}
          </UIText3>
        )}
      </div>
    );
  }

  private showPhonesHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    }

    const { onClick } = this.props;
    this.setState({ showPhone: true });

    if (onClick) {
      onClick();
    }
  };
}

export const mapStateToProps = (
  state: IApplicationState,
  { newbuildingId }: IShowPhoneButtonOwnProps,
): IMapStateToPropsPhoneButtonProps => {
  const newbuilding = getNewbuildingById(state, newbuildingId);

  return {
    isLeadFactory: !!newbuilding && isNewbuildingInLeadFactory(newbuilding),
    dynamicCalltrackingPlaceType: 'ShowPhoneButton',
  };
};

export const PhoneButton = connect(mapStateToProps)(PhoneButtonComponent);
