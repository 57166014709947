import * as React from 'react';
import * as style from './style.css';
import { IQuickLinksTab } from '../../types/quickLinks';
import { Tabs } from './Tabs';
import { LinksList } from './LinksList';
import { getDefaultTab } from './utils/getDefaultTab';

interface IQuickLinksTabsComponentProps {
  tabs: IQuickLinksTab[];
}

export const QuickLinksTabsComponent = ({ tabs }: IQuickLinksTabsComponentProps) => {
  const [selectedTab, setSelectedTab] = React.useState(getDefaultTab(tabs));
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onTabChange = React.useCallback((_: React.ChangeEvent<HTMLInputElement>, value: number) => {
    setSelectedTab(value);
  }, []);
  const onMoreButtonClick = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const links = React.useMemo(() => tabs[selectedTab].links, [tabs, selectedTab]);

  return (
    <div className={style['container']}>
      {isExpanded && <Tabs tabs={tabs} value={selectedTab} onChange={onTabChange} />}
      <LinksList links={links} isExpanded={isExpanded} onMoreButtonClick={onMoreButtonClick} />
    </div>
  );
};
