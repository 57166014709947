import * as React from 'react';

import { mergeStyles } from '@cian/utils';

/***** STYLES *****/
import * as styles from './Divider.css';

/***** TYPES *****/
import { TColors } from '../../types/colors';
export interface IDividerProps extends React.HTMLProps<HTMLDivElement> {
  dataMark?: string;
  backgroundColor?: TColors; // colors from UI Kit
}

/***** COMPONENT *****/
export function Divider(props: IDividerProps) {
  const { backgroundColor = 'gray_1', id, dataMark = 'Divider' } = props;

  return (
    <div
      data-mark={dataMark}
      id={id}
      {...mergeStyles(styles.element, styles[`element-background_color--${backgroundColor}`])}
    />
  );
}
