import { definitionFactory } from '../definitionFactory';
import { rangeAreaStr } from '../../../utils/text';
import { onDeleteAction } from '../definitionsDeleteAction';

export const kitchenAreaInputDefinition = definitionFactory({
  key: 'kitchen_area',
  mapper: ({ gte, lte }) =>
    gte || lte
      ? [
          {
            label: rangeAreaStr('Площадь кухни', gte, lte),
            value: { gte, lte },
          },
        ]
      : [],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'kitchen_area', options);
  },
});
