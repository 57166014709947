import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { MarkedList } from '../../components/MarkedList';

export interface IMarkedListStoreProps {
  items?: string[];
  dataMark?: string;
}

function mapStateToProps(state: IApplicationState): IMarkedListStoreProps {
  const { seo } = state.offersData;

  return {
    items: seo ? seo.markedList : undefined,
  };
}

export const MarkedListContainer = connect(mapStateToProps)(MarkedList);
