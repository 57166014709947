import * as React from 'react';
import { isEmpty } from 'ramda';
import { debounce, getEventValue } from '@cian/newbuilding-utils';
import { Input, Outside } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';

import { DEFAULT_INPUT_TIMEOUT } from '../../constants/timeouts';
import { ISuggestionGroup, TSuggestionSelectedItem } from '../../types/suggestions';
import { inRangeMemo } from '../../utils/functional';

import { SuggestCategory } from './components/SuggestCategory';
import { EMPTY_SUGGESTIONS, SUGGEST_CATEGORIES } from './constants';
import { requestGeocodedSearchData, requestSuggestions } from './helpers';
import { ISuggestData, TBoundedBy } from './types';
import * as styles from './Suggest.css';

export interface ISuggestProps {
  ariaLabel?: string;
  boundedBy?: TBoundedBy;
  locationId: number;
  onSuggestionSelect(suggestion: TSuggestionSelectedItem): void;
  placeholder?: string;
  subdomain?: string;
}

export interface ISuggestState {
  loading: boolean;
  pure: boolean;
  selectedIndex: number;
  suggestions: ISuggestData;
  term: string;
}

export class Suggest extends React.PureComponent<ISuggestProps, ISuggestState> {
  public state: Readonly<ISuggestState> = {
    loading: false,
    pure: true,
    selectedIndex: 0,
    suggestions: EMPTY_SUGGESTIONS,
    term: '',
  };

  private get allSuggests(): ISuggestionGroup[] {
    const { suggestions } = this.state;

    return SUGGEST_CATEGORIES.reduce<ISuggestionGroup[]>((acc, category) => acc.concat(suggestions[category.key]), []);
  }

  public render() {
    const { term, selectedIndex, pure, suggestions } = this.state;
    const selectedSuggest = this.allSuggests[selectedIndex];

    return (
      <Outside onOutside={this.toggleSuggestBlur}>
        <div>
          <div data-mark="SuggestInput" className={styles['suggest_input']}>
            <Input {...this.getInputProps()} />
          </div>
          <div data-mark="Suggest" className={styles['suggest_list_handler']}>
            {!pure && (
              <div className={styles['suggest_list']}>
                <div className={styles['suggest_list_input_handler']}>
                  <Input {...this.getInputProps()} autoFocus />

                  <span onClick={this.handleClose} className={styles['suggest_list_close_icon']} aria-label="Закрыть">
                    <IconActionClose16 color="current_color" />
                  </span>
                </div>

                {SUGGEST_CATEGORIES.map(category => {
                  const group = suggestions[category.key];
                  const firstInGroup = group[0];

                  const selectedValue =
                    selectedSuggest && firstInGroup && selectedSuggest.type === firstInGroup.type
                      ? selectedSuggest.value
                      : undefined;

                  return (
                    !isEmpty(group) && (
                      <SuggestCategory
                        key={category.key}
                        label={category.label}
                        suggests={group}
                        icon={category.icon}
                        selected={selectedValue}
                        onHover={this.handleHover}
                        onSelect={this.handleSelect}
                        highlight={term}
                      />
                    )
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Outside>
    );
  }

  private getInputProps() {
    const { term, loading } = this.state;
    const { placeholder, ariaLabel } = this.props;

    return {
      ['aria-label']: ariaLabel,
      loading,
      onChange: this.handleChange,
      onFocus: this.toggleSuggestFocus,
      onKeyDown: this.handleKeyDown,
      placeholder,
      size: 'XS' as const,
      value: term,
    };
  }

  private handleChange = (event: React.ChangeEvent) => {
    const value = getEventValue(event);
    this.setTerm(value);
    this.setPure(false);
  };

  private handleSelect = (suggestion: ISuggestionGroup) => {
    this.setTerm('');
    this.setPure(true);

    if (suggestion.type === 'geo') {
      this.selectGeoSuggestion(suggestion);
    } else {
      this.selectSimpleSuggestion(suggestion);
    }
  };

  private handleHover = (suggest: ISuggestionGroup) => {
    const selectedIndex = this.allSuggests.findIndex(d => d.value === suggest.value && d.type === suggest.type);
    this.setState({ selectedIndex });
  };

  private toggleSuggestBlur = () => {
    this.setPure(true);
  };

  private toggleSuggestFocus = () => {
    this.setPure(false);
  };

  private clearState = () => {
    this.setState({
      suggestions: EMPTY_SUGGESTIONS,
      selectedIndex: 0,
    });
  };

  private fetchSuggestions = async (term: string) => {
    const { boundedBy, locationId } = this.props;

    this.setLoading(true);

    try {
      const suggestions = await requestSuggestions({ boundedBy, locationId, term });
      this.setState({ suggestions });
    } catch {
      // ignore error
    } finally {
      this.setLoading(false);
    }
  };

  private debounceFetchSuggestions = debounce(DEFAULT_INPUT_TIMEOUT, this.fetchSuggestions);

  private getSuggestions = (term: string) => {
    const preparedTerm = term.trim();

    if (preparedTerm) {
      this.debounceFetchSuggestions(preparedTerm);
    }
  };

  private setLoading = (loading: boolean) => this.setState({ loading });

  private setPure = (pure: boolean) => this.setState({ pure });

  private setTerm = (term: string) => {
    if (term) {
      this.getSuggestions(term);
    } else {
      this.clearState();
    }

    this.setState({ term });
  };

  private handleClose = () => {
    this.setTerm('');
    this.setPure(true);
  };

  private addSelectedIndex = (i: number) => {
    const { selectedIndex } = this.state;
    const range = inRangeMemo(0, this.allSuggests.length - 1);
    this.setState({ selectedIndex: range(selectedIndex + i) });
  };

  private handleKeyDown = (event: React.KeyboardEvent) => {
    const { selectedIndex } = this.state;
    const selectedSuggest = this.allSuggests[selectedIndex];

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.addSelectedIndex(1);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.addSelectedIndex(-1);
        break;
      case 'Tab':
      case 'Enter':
        this.handleSelect(selectedSuggest);
        break;
      case 'Escape':
        this.toggleSuggestBlur();
        break;
      default:
        break;
    }
  };

  private selectGeoSuggestion = async (suggestion: ISuggestionGroup) => {
    const { onSuggestionSelect, subdomain } = this.props;
    this.setLoading(true);

    try {
      const searchData = await requestGeocodedSearchData(suggestion, subdomain);

      if (searchData) {
        onSuggestionSelect(searchData);
      }
    } catch {
      // ignore error
    } finally {
      this.setLoading(false);
    }
  };

  private selectSimpleSuggestion = (suggestion: ISuggestionGroup) => {
    const { onSuggestionSelect } = this.props;

    onSuggestionSelect({
      data: suggestion,
      dataType: 'simple',
      type: suggestion.type,
    });
  };
}
