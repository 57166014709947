import * as React from 'react';
import { Button, UIHeading1, UIText1 } from '@cian/ui-kit';

import * as styles from './ConsultBanner.css';

interface IConsultBannerProps {
  onClick?(): void;
  url: string;
}

export function ConsultBanner({ onClick, url }: IConsultBannerProps) {
  return (
    <a
      className={styles['container']}
      data-testid="ConsultBanner"
      href={url}
      onClick={onClick}
      rel="noreferrer"
      target="_blank"
    >
      <UIHeading1>Бесплатно подберём квартиру</UIHeading1>
      <div className={styles['description']}>
        <UIText1>Консультант найдёт квартиру по вашим параметрам&nbsp;и&nbsp;ответит на все вопросы</UIText1>
      </div>
      <div className={styles['button']}>
        <Button theme="fill_primary">Подобрать квартиру</Button>
      </div>
    </a>
  );
}
