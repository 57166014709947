// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IAddFavoriteEntityPublicRequestSchema {
  /** Добавлять ли в папку, если у пользователя есть недавно редактированная папка **/
  addToFolder?: boolean;
  /** Тип сделки **/
  dealType: EDealType;
  /** Идентификатор объявления **/
  entityId: number;
  /** Тип объекта **/
  entityType: EEntityType;
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EEntityType {
  /** ЖК **/
  'Newbuilding' = 'newbuilding',
  /** Коммерческая **/
  'OfferCommercial' = 'offerCommercial',
  /** Жилая **/
  'OfferResidental' = 'offerResidental',
  /** Загородка **/
  'OfferSuburban' = 'offerSuburban',
}
