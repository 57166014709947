import * as React from 'react';
import { Button, ModalWindow, Spinner } from '@cian/ui-kit';

import { Container } from '../../../components/Container';
import { FieldRow, FieldRowGroupTitle } from '../../../components/Filters';
import { SearchButton } from '../../../components/SearchButton';
import {
  SIZE_0,
  SIZE_8,
  SIZE_24,
  SIZE_28,
  SIZE_192,
  SIZE_WIDE,
  SIZE_440,
  SIZE_100,
  SIZE_16,
} from '../../../constants/sizes';
import { NO_BREAK_SPACE } from '../../../constants/symbolsUnicode';
import { ADFOX_TIMEOUT, MORE_FILTERS_BANNER_SIZE, EAdFoxBannerType } from '../../../constants/adfox';
import { AdfoxContainer } from '../../AdfoxContainer';
import {
  ManifestationType,
  TotalAreaInput,
  KitchenAreaInput,
  LivingAreaInput,
  ApartmentType,
  PenthouseType,
  HouseType,
  NewbuildingClass,
  UndergroundWalkTimeInput,
  HasDecoration,
  ConstructionStatus,
  HasMortgage,
  SaleType,
  IsSalesStart,
  IsSalesLeader,
  IsBlackFriday,
  HasDiscountMortgage,
  DiscountPromoSearchType,
  MortgagePromoSearchType,
} from '../../Filters';

export interface IModalStoreProps {
  count: number | null;
  isCountFetching?: boolean;
  hasUndreground: boolean;
  canUseHiddenBase: boolean;
  isBlackFridayActive: boolean;
  discountMortgageEnabled: boolean;
  promoSearchTypesEnabled: boolean;
}

export interface IModalDispatchProps {
  onSubmit(canUseHiddenBase: boolean): void;
  trackingPopupMoreParamOpen(): void;
  updateTags(): void;
}

interface IModalState {
  isOpen: boolean | undefined;
  bannerLoaded: boolean;
  bannerError: boolean;
}

type TModalProps = IModalStoreProps & IModalDispatchProps;

export class MoreFiltersModalComponent extends React.PureComponent<TModalProps, IModalState> {
  public state: Readonly<IModalState> = {
    isOpen: false,
    bannerLoaded: false,
    bannerError: false,
  };
  private adTimer: number;

  public componentWillUnmount() {
    window.clearTimeout(this.adTimer);
  }

  public render() {
    const {
      count,
      isCountFetching,
      hasUndreground,
      isBlackFridayActive,
      discountMortgageEnabled,
      promoSearchTypesEnabled,
    } = this.props;
    const { isOpen, bannerLoaded, bannerError } = this.state;

    const hasProfitableFilters = isBlackFridayActive || promoSearchTypesEnabled;

    return (
      <>
        <Button size="XS" tabIndex={-1} theme="stroke_secondary" type="button" onClick={() => this.handleToggle(true)}>
          Ещё фильтры
        </Button>
        <ModalWindow
          open={Boolean(isOpen)}
          onClose={() => this.handleToggle(false)}
          fixed={true}
          title="Ещё фильтры"
          footer={
            <Container padding={`${SIZE_0} ${SIZE_16}`} display="flex" justifyContent="flex-end" alignItems="center">
              <SearchButton count={count} loading={isCountFetching} size="L" onClick={this.handleSubmit} />
            </Container>
          }
          content={
            <>
              {!bannerLoaded && (
                <Container
                  display="flex"
                  justifyContent="center"
                  width="calc(100% - 80px)"
                  position="absolute"
                  top="calc(50% - 20px)"
                >
                  <Spinner color="primary_100" size={40} />
                </Container>
              )}
              <Container
                display="flex"
                visibility={bannerLoaded ? 'visible' : 'hidden'}
                flexDirection="column"
                justifyContent="space-between"
                height={SIZE_WIDE}
              >
                <Container padding={`${SIZE_8} ${SIZE_0} ${SIZE_8}`} overflow="auto">
                  {!bannerError && (
                    <Container margin={`0 ${SIZE_28}`} width={SIZE_440} height={SIZE_100}>
                      <AdfoxContainer
                        onError={this.handleAdfoxError}
                        onLoad={this.handleAdfoxMount}
                        type={EAdFoxBannerType.PICTURE}
                        size={MORE_FILTERS_BANNER_SIZE}
                      />
                    </Container>
                  )}
                  <FieldRowGroupTitle title="О квартире" />
                  <FieldRow
                    title="Площадь, м²"
                    field={
                      <Container display="flex">
                        <Container width={SIZE_192} data-testid="TotalAreaContainer">
                          <TotalAreaInput />
                        </Container>
                        <Container width={SIZE_192} margin={`0 0 0 ${SIZE_24}`}>
                          <KitchenAreaInput />
                        </Container>
                        <Container width={SIZE_192} margin={`0 0 0 ${SIZE_24}`}>
                          <LivingAreaInput />
                        </Container>
                      </Container>
                    }
                  />
                  <FieldRow title="Отделка" field={<HasDecoration />} dataTestId="DecorationRow" />
                  {hasProfitableFilters && (
                    <>
                      <FieldRowGroupTitle title="Выгодно" />
                      {isBlackFridayActive && (
                        <FieldRow title="Спецпредложения" field={<IsBlackFriday />} dataTestId="IsBlackFridayRow" />
                      )}
                      {promoSearchTypesEnabled && (
                        <>
                          <FieldRow
                            title="Ипотечные предложения"
                            field={<MortgagePromoSearchType />}
                            titleAlignment="top"
                            dataTestId="MortgagePromoSearchTypeRow"
                          />
                          <FieldRow
                            title={`Скидки и акции от${NO_BREAK_SPACE}застройщиков`}
                            field={<DiscountPromoSearchType />}
                            titleAlignment="top"
                            dataTestId="DiscountPromoSearchTypeRow"
                          />
                        </>
                      )}
                    </>
                  )}
                  <FieldRowGroupTitle title="О жилом комплексе и корпусе" />
                  <FieldRow title="Тип дома" field={<HouseType />} dataTestId="HouseTypeRow" />
                  <FieldRow
                    title="Класс жилого комплекса"
                    field={<NewbuildingClass />}
                    dataTestId="NewbuildingClassRow"
                  />
                  <FieldRow
                    title="Апартаменты"
                    field={
                      <Container display="flex">
                        <ApartmentType />
                        <PenthouseType />
                      </Container>
                    }
                    dataTestId="ApartmentTypeRow"
                  />
                  {hasUndreground && (
                    <FieldRow
                      title="Пешком до метро"
                      field={<UndergroundWalkTimeInput />}
                      dataTestId="UndergroundWalkTimeRow"
                    />
                  )}
                  <FieldRow
                    title="Статус строительства"
                    field={<ConstructionStatus />}
                    dataTestId="ConstructionStatusRow"
                  />
                  <FieldRow title="Тип объявления" field={<ManifestationType />} dataTestId="ManifestationTypeRow" />
                  <FieldRow
                    title="Условия продажи"
                    field={
                      <Container display="flex">
                        <SaleType />
                        <Container margin={`0 0 0 ${SIZE_16}`} alignSelf="center">
                          <HasMortgage />
                        </Container>
                      </Container>
                    }
                    dataTestId="SaleTypeRow"
                  />
                  <FieldRow
                    title="Подборки жилых комплексов"
                    field={
                      <Container display="flex">
                        <IsSalesStart />
                        <IsSalesLeader />
                        {discountMortgageEnabled && (
                          <Container>
                            <HasDiscountMortgage />
                          </Container>
                        )}
                      </Container>
                    }
                    dataTestId="NewbuildingCompliationRow"
                  />
                </Container>
              </Container>
            </>
          }
        />
      </>
    );
  }

  private handleToggle = (isOpen: boolean) => {
    if (isOpen) {
      this.props.trackingPopupMoreParamOpen();
      this.adTimer = window.setTimeout(this.handleAdfoxError, ADFOX_TIMEOUT);
    } else {
      this.props.updateTags(); //При закрытии модального окна обновляем тэги
    }
    this.setState({
      isOpen,
      bannerLoaded: false,
      bannerError: false,
    });
  };

  private handleSubmit = () => {
    this.props.onSubmit(this.props.canUseHiddenBase);
    this.setState({
      isOpen: false,
      bannerLoaded: false,
      bannerError: false,
    });
  };

  private handleAdfoxError = () => {
    this.setState({
      bannerLoaded: true,
      bannerError: true,
    });
  };

  private handleAdfoxMount = () => {
    window.clearTimeout(this.adTimer);
    this.setState({
      bannerLoaded: true,
      bannerError: false,
    });
  };
}
