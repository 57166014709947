import { createSubscriptionsModel, TSubscriptionsResponse } from './subscriptions';
import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';

export function subscriptions({
  email,
  queryString,
  subdomain,
  httpApi,
}: {
  email: string;
  queryString: string;
  subdomain: string;
  httpApi: IHttpApi;
}) {
  return httpApi.fetch(
    createSubscriptionsModel(
      {},
      {
        email,
        filter_params: queryString,
      },
    ),
    {
      requestConfig: { headers: [['Content-Type', 'application/json']] },
      subdomain,
    },
  );
}

export async function subscribeForUpdates({
  email,
  queryString,
  subdomain,
  httpApi,
  logger,
}: {
  email: string;
  queryString: string;
  subdomain: string;
  httpApi: IHttpApi;
  logger: ILogger;
}): Promise<TSubscriptionsResponse> {
  const subscriptionsResponse = await subscriptions({ email, queryString, subdomain, httpApi });
  if (subscriptionsResponse.statusCode === 200 && subscriptionsResponse.response.status === 'ok') {
    return subscriptionsResponse.response;
  }

  logger.error('Subscribe for updates error!');

  throw { status: 'Error' };
}
