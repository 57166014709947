import { Action } from 'redux';
import { IRegion } from '../../types/regions';
import { triggerHeaderRegionChanged } from '../../utils/events/triggerHeaderRegionChanged';
import { setRegionCookies } from '../../utils/geo/setRegionCookies';

export enum ECurrentRegionActions {
  set = 'set/currentRegion',
}
export type TCurrentRegionType = 'set/currentRegion';

export interface ICurrentRegionAction extends Action<TCurrentRegionType> {
  type: TCurrentRegionType;
  data: IRegion;
}

export function setCurrentRegion(region: IRegion): ICurrentRegionAction {
  triggerHeaderRegionChanged(region);
  setRegionCookies(region);

  return {
    data: region,
    type: ECurrentRegionActions.set,
  };
}
