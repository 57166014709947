import * as React from 'react';
import { clsx } from 'clsx';
import { isNil } from 'ramda';
import { isUndefined } from '@cian/newbuilding-utils';
import { Button, Popover } from '@cian/ui-kit';
import { IconActionChevronRight12 } from '@cian/ui-kit-design-tokens/icons';

import { ClickOutside } from '../outside';
import { Container } from '../Container';
import { SelectionList } from '../SelectionList';

import * as styles from './Select.css';

export interface IMultilineVariants {
  id: string;
  label: string;
}

export interface ISelectMultilineProps {
  text?: string;
  placeholder: string;
  options: Array<IMultilineVariants>;
  onChange?(value: string): void;
  value?: string[];
}

export interface ISelectMultilineState {
  selected: string[];
  isOpen?: boolean;
}

export class SelectMultiline extends React.PureComponent<ISelectMultilineProps, ISelectMultilineState> {
  public state: Readonly<ISelectMultilineState> = {
    selected: this.props.value || [],
    isOpen: false,
  };
  public fakeSelect: HTMLSelectElement;

  public componentDidUpdate(prevProps: ISelectMultilineProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selected: this.props.value || [] });
    }
  }

  public render() {
    const { text, options, placeholder } = this.props;
    const { selected, isOpen } = this.state;

    return (
      <label data-mark="SelectMultiline" className={styles['hidden-select-handler']} data-style="select_component">
        <select
          onChange={({ currentTarget }) => this.handleChange({ id: currentTarget.value })}
          value={selected}
          multiple
          tabIndex={-1}
          ref={select => select && (this.fakeSelect = select)}
          className={styles['hidden-select']}
        >
          {options.map(({ label, id }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </select>

        <Popover
          open={Boolean(isOpen)}
          placement="bottom"
          content={
            <ClickOutside onOutside={this.handleBlur}>
              <Container overflow="hidden" shadow>
                <div className={styles['list']}>
                  <SelectionList options={options} selectedItems={selected} multiline onClick={this.handleChange} />
                </div>
              </Container>
            </ClickOutside>
          }
        >
          <div>
            <Button onClick={this.handleToggle} size="XS" tabIndex={-1} theme="stroke_secondary" type="button">
              {isUndefined(text) ? placeholder : text}
            </Button>
            <span className={clsx(styles['arrow_icon'], isOpen && styles['arrow_icon__open'])} aria-hidden>
              <IconActionChevronRight12 color="primary_100" />
            </span>
          </div>
        </Popover>
      </label>
    );
  }

  private handleChange = ({ id }: { id: string; label?: string }) => {
    if (isNil(this.props.value)) {
      const selected = [];
      let isFinded = false;

      for (const item of this.state.selected) {
        if (item === id) {
          isFinded = true;
        } else {
          selected.push(item);
        }
      }

      if (!isFinded) {
        selected.push(id);
      }

      this.setState({ selected });
    }

    if (this.props.onChange) {
      this.props.onChange(id);
    }
  };

  private handleToggle = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  private handleBlur = () => {
    this.setState({ isOpen: false });
  };
}
