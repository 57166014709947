import { ca } from '@cian/analytics';
import { featuresToActionMap, roomstoFlatsMapNew } from '../../actions/analytics/helpers';
import { getCPUrlFromNewbuildingGaLabel } from '../../utils/customPageUrl';
import { IJsonQuery } from '../../types/interfaces';

export enum PromoSnippetFavoriteActionNames {
  Add = 'add_from_promo_block',
  Remove = 'remove_from_promo_block',
}

interface IExtras {
  cplDynamicCalltracking: 1 | 0;
  cplCalltracking: 1 | 0;
  '3dtour_enabled'?: 1;
  additional_price?: number;
}

export const trackPromoSnippetShow = (newbuildingId: number, jsonQuery: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'show_sopr',
    label: 'promo_block',
    sc: jsonQuery,
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
      },
    ],
  });
};

export const trackPromoSnippetJkClick = (newbuildingId: number, jsonQuery: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'to_cardJK',
    label: 'promo_block',
    sc: jsonQuery,
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        extra: {
          is_pro_plus: true,
        },
      },
    ],
  });
};

export const trackPromoSnippetReviewsClick = (newbuildingId: number, jsonQuery: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'to_reviewsJK',
    label: 'promo_block',
    sc: jsonQuery,
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        extra: {
          is_pro_plus: true,
        },
      },
    ],
  });
};

export const trackPromoSnippetFeaturesClick = (newbuildingId: number, action: string, jsonQuery: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: featuresToActionMap[action] || 'to_cardJK',
    label: 'promo_block',
    sc: jsonQuery,
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        extra: {
          is_pro_plus: true,
        },
      },
    ],
  });
};

export const trackPromoSnippetBuilderClick = (newbuildingId: number, jsonQuery: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'to_card_builder',
    label: 'promo_block',
    sc: jsonQuery,
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        extra: {
          is_pro_plus: true,
        },
      },
    ],
  });
};

export const trackPromoSnippetDeveloperFlatsClick = ({
  newbuildingId,
  room,
  label = 'from_promo_block',
  jsonQuery,
}: {
  newbuildingId: number;
  room: string;
  label?: string;
  jsonQuery: IJsonQuery;
}) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing_JK',
    action: 'click_developersFlats',
    label,
    sc: jsonQuery,
    page: {
      extra: {
        roomscount: roomstoFlatsMapNew[room],
      },
    },
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
      },
    ],
  });
};

export const trackPromoSnippetPhoneNumberShow = (newbuildingId: number, label: string, jsonQuery: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Phones',
    action: 'Open_listingjk_promo_block',
    label: getCPUrlFromNewbuildingGaLabel(label),
    sc: jsonQuery,
    products: [
      {
        id: newbuildingId,
        offerType: 'JK',
        extra: {
          is_pro_plus: true,
        },
      },
    ],
  });
};

export const trackPromoSnippetContactsShow = (
  newbuildingId: number,
  label: string,
  extra: IExtras,
  jsonQuery: IJsonQuery,
) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'open_contacts',
    label: getCPUrlFromNewbuildingGaLabel(label),
    sc: jsonQuery,
    page: {
      extra,
    },
    products: [
      {
        extra,
        id: newbuildingId,
        offerType: 'JK',
        position: 1,
      },
    ],
  });
};
