import * as React from 'react';
import { connect } from 'react-redux';
import { noop } from '@cian/newbuilding-utils';
import { Button } from '@cian/ui-kit';
import { IconToggleHeartOff16, IconToggleHeartOn16 } from '@cian/ui-kit-design-tokens/icons';
import { parseCookies } from '@cian/utils';
import { setCookie } from '@cian/utils/lib/browser/cookie';

import { trackingActionAddFromListingJG, trackingActionRemoveFromListingJK } from '../../actions/analytics';
import { IUpdateFavoriteParams, IUpdateFavoriteResolve, updateFavorite } from '../../actions/favorites';
import { ClickOutside } from '../../components/outside';
import { MountPopup } from '../../components/PopupViewer';
import { IApplicationState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { MotivationPopup } from './MotivationPopup';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;
const TRUTHY_COOKIE_CONTENT = '1';
const DATA_MARK_NAME = 'FavoriteButton';

export const MOTIVATION_COOKIE_NAME = 'newbuilding-search-favorite_motivation';

interface IFavoriteButtonOwnProps {
  isFavorite: boolean;
  newbuildingId: number;
  gaLabelFull: string;
  isPromo?: boolean;
  analyticsActionAddName?: string;
  analyticsActionRemoveName?: string;
}

interface IFavoriteButtonStateProps {
  userIsAuthenticated: boolean;
}

interface IFavoriteButtonDispatchProps {
  setFavorite(params: IUpdateFavoriteParams): Promise<IUpdateFavoriteResolve>;
  trackingAddFromListingJG(gaLabelFull: string, action?: string): void;
  trackingRemoveFromListingJK(gaLabelFull: string, action?: string): void;
}

type TFavoriteButtonProps = IFavoriteButtonOwnProps & IFavoriteButtonDispatchProps & IFavoriteButtonStateProps;

export class FavoriteButtonComponent extends React.PureComponent<TFavoriteButtonProps> {
  public state = {
    status: this.props.isFavorite,
    showPopup: false,
  };

  public render() {
    const { showPopup } = this.state;
    const { isPromo } = this.props;

    return (
      <MountPopup
        placement="left"
        component={
          showPopup && (
            <ClickOutside onOutside={this.closeMotivationPopup}>
              <MotivationPopup onClose={this.closeMotivationPopup} />
            </ClickOutside>
          )
        }
      >
        {isPromo ? this.getPromoButton() : this.getDefaultButton()}
      </MountPopup>
    );
  }

  private getPromoButton = () => {
    const { status } = this.state;

    return (
      <Button
        beforeIcon={status ? <IconToggleHeartOn16 color="primary_100" /> : <IconToggleHeartOff16 color="primary_100" />}
        theme="fill_secondary"
        onClick={this.handlerClick}
        data-mark={DATA_MARK_NAME}
      >
        {this.getLabel()}
      </Button>
    );
  };

  private getDefaultButton = () => {
    const { status } = this.state;

    return (
      <Button
        beforeIcon={status ? <IconToggleHeartOn16 color="error_100" /> : <IconToggleHeartOff16 color="error_100" />}
        theme="fill_secondary"
        onClick={this.handlerClick}
        data-mark={DATA_MARK_NAME}
        size="XS"
      >
        {this.getLabel()}
      </Button>
    );
  };

  private getLabel = () => {
    const { status } = this.state;

    return status ? 'В избранном' : 'В избранное';
  };

  private handlerClick = () => {
    const { setFavorite, newbuildingId, gaLabelFull, analyticsActionAddName, analyticsActionRemoveName } = this.props;

    const { status } = this.state;
    const newStatus = !status;
    setFavorite({
      status: newStatus,
      newbuildingId,
    })
      .then(({ status }) => {
        this.setState({ status });
        this.showMotivationPopup();
        if (status) {
          this.props.trackingAddFromListingJG(gaLabelFull, analyticsActionAddName);
        } else {
          this.props.trackingRemoveFromListingJK(gaLabelFull, analyticsActionRemoveName);
        }
      })
      .catch(noop);
  };

  private closeMotivationPopup = () => {
    if (this.state.showPopup) {
      this.setState({ showPopup: false });
    }
  };

  private showMotivationPopup = () => {
    if (this.canSowMotivationPopup()) {
      this.setState({ showPopup: true });
      setCookie(MOTIVATION_COOKIE_NAME, TRUTHY_COOKIE_CONTENT, {
        expires: ONE_DAY_IN_SECONDS,
        secure: false,
      });
    }
  };

  private canSowMotivationPopup = () => {
    const { userIsAuthenticated } = this.props;
    const { __header_favorites_count__ } = window;

    const cookies = parseCookies(document.cookie);

    return !cookies[MOTIVATION_COOKIE_NAME] && !userIsAuthenticated && __header_favorites_count__ === 1;
  };
}

export function mapStateToProps(state: IApplicationState) {
  return {
    userIsAuthenticated: state.user.isAuthenticated,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): IFavoriteButtonDispatchProps {
  return {
    setFavorite: (params: IUpdateFavoriteParams) => dispatch(updateFavorite(params)),
    trackingAddFromListingJG(gaLabelFull: string, action: string) {
      dispatch(trackingActionAddFromListingJG(gaLabelFull, action));
    },
    trackingRemoveFromListingJK(gaLabelFull: string, action: string) {
      dispatch(trackingActionRemoveFromListingJK(gaLabelFull, action));
    },
  };
}

export const FavoriteButton = connect<IFavoriteButtonStateProps, IFavoriteButtonDispatchProps, IFavoriteButtonOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(FavoriteButtonComponent);
