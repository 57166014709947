import * as React from 'react';

export function getHighlighted(
  line: string,
  positions: number[],
  width: number,
  Lighter: React.StatelessComponent<React.PropsWithChildren>,
) {
  if (!width || !positions.length) {
    return [line];
  }

  const highlighted: (string | JSX.Element)[] = [];
  let spot = 0;

  const lastPositionIdx = positions.length - 1;
  const lineLength = line.length;

  positions.forEach((position: number, idx: number) => {
    if (position !== 0) {
      const before = line.slice(spot, position);
      highlighted.push(before);
    }

    const bold = line.substring(position, position + width);
    highlighted.push(<Lighter key={bold + idx}>{bold}</Lighter>);

    spot = position + width;
    if (idx === lastPositionIdx && spot !== lineLength) {
      highlighted.push(line.slice(spot));
    }
  });

  return highlighted;
}
