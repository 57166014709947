import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCrossLinks } from '../../selectors/seoCrossLinks';
import { SeoCrossLinksV2 } from '../../components/SeoCrossLinksV2';

export function SeoCrossLinksContainer() {
  const crossLinks = useSelector(selectCrossLinks);

  if (!crossLinks || !crossLinks.length) {
    return null;
  }

  return <SeoCrossLinksV2 seoBlocks={crossLinks} />;
}
