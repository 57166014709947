import * as React from 'react';
import * as styles from './BrokerBlock.css';
import { UIText2 } from '@cian/ui-kit';

interface IBrokerBlockProps {
  rewardTariffsLink: string;
}

export const BrokerBlock: React.FC<IBrokerBlockProps> = ({ rewardTariffsLink }) => {
  return (
    <div className={styles['wrapper']} data-testid="BrokerBlock">
      <UIText2 whiteSpace="nowrap" color="black_100">
        Есть квартиры с комиссией от застройщика от 0,7%
      </UIText2>
      <a
        className={styles['link']}
        href={rewardTariffsLink}
        data-testid="BrokerLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Сколько можно заработать
      </a>
    </div>
  );
};
