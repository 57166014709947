import { ca } from '@cian/analytics';
import { IJsonQuery } from '../../../types/jsonQuery';

export const trackBannerClick = (sc: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BlackFriday',
    action: 'click_sopr',
    label: 'listing_banner',
    sc,
  });
};
