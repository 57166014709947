import * as React from 'react';

import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { InputsMultiple } from '../../components/InputsMultiple';
import { kitchenAreaInputDescriptors } from '../../constants/descriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';
import { debounce } from '@cian/newbuilding-utils';
import { DEFAULT_INPUT_TIMEOUT } from '../../constants/timeouts';

export interface IKitchenAreaInputsProps extends IDescriptorProps<number> {
  getOffersCount(): void;
}

class KitchenAreaInputsComponent extends React.Component<IKitchenAreaInputsProps> {
  public getOffersCount = debounce(DEFAULT_INPUT_TIMEOUT, this.props.getOffersCount);

  public shouldComponentUpdate(nextProps: IKitchenAreaInputsProps) {
    const { gte: nextGte, lte: nextLte } = this.getValue(nextProps);
    const { gte, lte } = this.getValue(this.props);

    return nextGte !== gte || nextLte !== lte;
  }

  public getValue = ({ jsonQuery: { kitchen_area } }: IKitchenAreaInputsProps) => {
    const gte = kitchen_area && kitchen_area.value ? kitchen_area.value.gte : '';
    const lte = kitchen_area && kitchen_area.value ? kitchen_area.value.lte : '';

    return { gte, lte };
  };

  public render() {
    const { actionCreator, descriptors } = this.props;
    const { gte, lte } = this.getValue(this.props);
    const action = (type: 'gte' | 'lte') => (value: string) => {
      actionCreator(descriptors[0])(parseFloat(value), type);
      this.getOffersCount();
    };

    return (
      <InputsMultiple
        label="кухня"
        stack={[
          {
            placeholder: 'от',
            ['aria-label']: 'Минимальная площадь кухни',
            value: gte,
            onChangeValue: action('gte'),
          },
          {
            placeholder: 'до',
            ['aria-label']: 'Максимальная площадь кухни',
            value: lte,
            onChangeValue: action('lte'),
          },
        ]}
      />
    );
  }
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const KitchenAreaInput = connectToDescriptors(
  kitchenAreaInputDescriptors,
  mapDispatchToProps,
)(KitchenAreaInputsComponent);
