import { putTagWithLabel, TPutTagWithLabelKeys, updateTags } from '../filterTags';
import { IJQGeo } from '../../utils/descriptorActions';
import { IUndergroundDetails } from '../../types/suggestions';
import { IThunkActionCreator } from '../../types/thunk';

interface ISelectPolygonOption {
  coordinates: Array<object>;
  name: string;
}

export const addTag = (
  key: TPutTagWithLabelKeys,
  value?: number | IJQGeo | IJQGeo[] | ISelectPolygonOption,
  label?: string,
  undergroundDetails?: Array<IUndergroundDetails>,
): IThunkActionCreator => {
  return dispatch => {
    if (label) {
      dispatch(putTagWithLabel(key, value, label, undergroundDetails));
    } else {
      dispatch(updateTags());
    }
  };
};
