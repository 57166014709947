import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { BrokerBanner } from '../../../components/BrokerBanner';
import { useBrokerLinks } from '../../../hooks/broker';
import { getCurrentJsonQuery } from '../../../selectors/location';
import { trackBannerShown, trackBannerClick } from './tracking';
import { getAnalyticsUser } from '../../../selectors/analytics';

export function BrokerBannerContainer() {
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const { landingPageLink } = useBrokerLinks();
  const user = useSelector(getAnalyticsUser);

  const handleButtonClick = React.useCallback(() => {
    trackBannerClick(jsonQuery, user);
    window.open(landingPageLink, '_blank', 'noopener');
  }, [landingPageLink, jsonQuery, user]);

  const handleBannerShown = React.useCallback(() => trackBannerShown(jsonQuery, user), [jsonQuery, user]);

  return (
    <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
      <BrokerBanner onClick={handleButtonClick} />
    </ActionAfterViewObserver>
  );
}
