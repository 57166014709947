import { equals } from 'ramda';
import { IStateTag, TJsonQueryValue } from './definitionFactory';
import { IThunkActionCreator } from '../../types/thunk';
import { IUndergroundDetails } from '../../types/suggestions';
import { removeCountryName } from '../../utils/yamaps';
import { updateTags } from './tags';

export type TPutTagWithLabelKeys = 'newbuilding_id' | 'builders' | 'geo' | 'polygon';

export function putTagWithLabel(
  key: TPutTagWithLabelKeys,
  value: TJsonQueryValue,
  label: string,
  undergroundDetails?: Array<IUndergroundDetails>,
): IThunkActionCreator {
  return (dispatch, getState) => {
    const { filters } = getState();

    const { tags } = filters;

    if (
      !tags.some(({ keyName, value: tagValue }) => {
        return key === keyName && equals(value, tagValue);
      })
    ) {
      const newTags: IStateTag[] = [...tags];

      newTags.push({
        keyName: key,
        value,
        label: removeCountryName(label),
        undergroundDetails,
      });

      dispatch(updateTags(newTags));
    }
  };
}
