import { AnyAction } from 'redux';
import { defaultTerm, descriptorsFactory, IJsonQuery } from '../../utils/filters';

const modifyValue = ({ finish_date = defaultTerm }: IJsonQuery, action: AnyAction) => {
  return action ? action.value : finish_date.value;
};

export const finishDateDescriptor = descriptorsFactory({ name: 'FinishDate', clear: [] })(
  {
    form: [
      { key: 'finish_date', type: 'term', value: modifyValue },
      { key: 'is_finished', type: 'term', value: false },
    ],
    label: '',
    state: 'setFinishDate',
  },
  {
    form: [
      { key: 'finish_date', type: 'term', value: '' },
      { key: 'is_finished', type: 'term', value: modifyValue },
    ],
    label: '',
    state: 'isFinished',
    clear: ['status'],
  },
);
