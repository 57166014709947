import { ISuggestionGroup } from '../../types/suggestions';

export const removeCountryName = (value: string) => value.replace(/(^Россия, )|(, Россия$)/i, '');

export function getAddressSuggestion(item: YMaps.ISuggestResult): ISuggestionGroup {
  return {
    title: removeCountryName(item.displayName),
    ...item,
  };
}
