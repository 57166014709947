export function createPolygon(boundedBy: number[][]) {
  if (boundedBy && boundedBy.length === 2) {
    return [
      boundedBy[0],
      [boundedBy[0][0], boundedBy[1][1]],
      boundedBy[1],
      [boundedBy[1][0], boundedBy[0][1]],
      boundedBy[0],
    ];
  }

  return [...boundedBy, boundedBy[0]];
}
