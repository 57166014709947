import * as React from 'react';
import { useSelector } from 'react-redux';
import { getBlackFridayUrl } from '../../selectors/newbuildingBlackFriday';
import { BlackFridayBanner } from '../../components/BlackFridayBanner';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { getCurrentJsonQuery } from '../../selectors/location';
import { trackBannerClick, trackBannerShown } from './tracking';

export const BlackFridayBannerContainer: React.FC = () => {
  const jsonQuery = useSelector(getCurrentJsonQuery);
  const url = useSelector(getBlackFridayUrl);

  const handleBannerClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);
  const handleBannerShown = React.useCallback(() => trackBannerShown(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
      <BlackFridayBanner url={url} onBannerClick={handleBannerClick} />
    </ActionAfterViewObserver>
  );
};
