import { ILocationState } from '../../types/location';
import { ELocationActions, ISetPageAction } from '../../actions/location';

export const defaultRegion: ILocationState = {
  subdomain: 'www',
  path: '',
  page: 1,
  redirectUrl: null,
  jsonQuery: {
    _type: 'newobjectsale',
    engine_version: { type: 'term', value: 2 },
  },
};

export function location(state: ILocationState = defaultRegion, action: ISetPageAction) {
  switch (action.type) {
    case ELocationActions.setPage: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
