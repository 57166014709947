import * as React from 'react';
import { LinkButton } from '@cian/ui-kit';
import * as styles from './InternalBanner.css';

interface IInternalBannerProps {
  url: string;
}

export const InternalBanner: React.FC<IInternalBannerProps> = ({ url }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['content']}>
        <div className={styles['text']}>Какие ещё бывают ипотечные программы с господдержкой</div>
        <LinkButton href={url} size="M" theme="fill_primary" target="_blank" rel="noopener noreferrer">
          Смотреть программы
        </LinkButton>
      </div>
      <div className={styles['image']} />
    </div>
  );
};
