/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const district = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 13">
    <g fill="#C9C9C9" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M13.68 3c1.592.354 2.774 1.696 2.774 3.3 0 2.545-3.61 6.3-3.61 6.3s-.82-.852-1.677-2.02c1.324-1.935 2.661-4.376 2.661-6.344 0-.426-.051-.84-.149-1.236zm-7.693 7.58A22.223 22.223 0 0 1 4.31 12.6S.7 8.845.7 6.3C.7 4.697 1.882 3.355 3.474 3a5.19 5.19 0 0 0-.148 1.236c0 1.968 1.337 4.409 2.661 6.345z"
      />
      <path d="M8.595 0C6.055 0 4 2.003 4 4.48c0 3.36 4.595 8.32 4.595 8.32s4.595-4.96 4.595-8.32C13.19 2.003 11.135 0 8.595 0zm0 6.08c-.906 0-1.641-.717-1.641-1.6 0-.883.735-1.6 1.64-1.6.907 0 1.642.717 1.642 1.6 0 .883-.735 1.6-1.641 1.6z" />
    </g>
  </svg>
);
