import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';
import { Label } from '@cian/ui-kit';
import { IconFunctionalSuccess12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { LabelsBox } from '../../components/LabelsBox';

interface ILabelsContainerProps {
  offerId: number;
  promo?: boolean;
}

export const LabelsContainer: React.FC<ILabelsContainerProps> = ({ offerId, promo }) => {
  const { isOfferVisited } = useVisitedOffersContext();

  const labels = [];

  if (isOfferVisited(offerId)) {
    labels.push(
      <Label
        key="visited"
        icon={<IconFunctionalSuccess12 color="white_100" />}
        background="rgba(0, 0, 0, 0.40)"
        color="white"
      >
        Вы смотрели
      </Label>,
    );
  }

  if (!labels.length) {
    return null;
  }

  return <LabelsBox promo={promo}>{labels}</LabelsBox>;
};
