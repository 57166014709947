import { IQuickLinksTab } from '../../types/quickLinks';
import { TQuickLinksActions, EQuickLinksActions } from '../../actions/quickLinks';

export const defaultState: IQuickLinksTab[] | null = null;

export function quickLinks(state: IQuickLinksTab[] | null = defaultState, action: TQuickLinksActions) {
  switch (action.type) {
    case EQuickLinksActions.SetQuickLinks:
      return action.payload;
    default:
      return state;
  }
}
