import { AnyAction } from 'redux';
import { defaultRange, descriptorsFactory, IJsonQuery } from '../../utils/filters';

const modifyValue = ({ kitchen_area = defaultRange }: IJsonQuery, action: AnyAction) => {
  return action ? Object.assign({}, kitchen_area.value, { [action.subType]: action.value }) : kitchen_area.value;
};

export const kitchenAreaInputDescriptors = descriptorsFactory({ name: 'KitchenArea', clear: [] })({
  form: [{ key: 'kitchen_area', type: 'range', value: modifyValue }],
  label: '',
  state: 'kitchen_area',
});
