import { IApplicationState } from '../../types/redux';
import { selectAdditionalAdfoxPuids } from './selectAdditionalAdfoxPuids';

export function selectAdfoxPuids(state: IApplicationState) {
  const additionalAdfoxPuids = selectAdditionalAdfoxPuids(state);

  return {
    ...state.offersData.puids,
    ...additionalAdfoxPuids,
  };
}
