import * as React from 'react';
import { CustomTooltip } from '@cian/ui-kit';
import { SalesStartLabel } from '../../components/SalesStartLabel';

export interface ISalesStartLabelTooltip {
  description: string;
  tooltipContent: ISalesStartTooltip;
  hovered: boolean;
  onMouseEnter(e: React.MouseEvent<HTMLDivElement>): void;
  onMouseLeave(e: React.MouseEvent<HTMLDivElement>): void;
}

export interface ISalesStartTooltip {
  header: string;
  content: string;
}

export function SalesStartLabelTooltip(props: ISalesStartLabelTooltip) {
  const { description, tooltipContent, hovered, onMouseEnter, onMouseLeave } = props;

  const ref = React.useRef(null);

  return (
    <>
      <span ref={ref}>
        <SalesStartLabel
          description={description}
          hovered={hovered}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </span>
      <CustomTooltip
        {...tooltipContent}
        anchorRef={ref}
        open={hovered}
        theme={'white'}
        placement={'right-start'}
        maxWidth={290}
      />
    </>
  );
}
