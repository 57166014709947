import { getPromoSearchTypes } from '../../selectors/filters';
import { IApplicationState } from '../../types/redux';
import { descriptorsFactory, IFilterPureDescriptor } from '../../utils/filters';

export const createMortgagePromoSearchTypeDescriptors = (state: IApplicationState): IFilterPureDescriptor[] => {
  const createDescriptor = descriptorsFactory({ name: 'MortgagePromoSearchType', clear: [] });

  const promoSearchTypes = getPromoSearchTypes(state);

  if (!promoSearchTypes) {
    return [];
  }

  const locals = promoSearchTypes.mortgage.map(item => ({
    form: [
      {
        key: 'promo_search_types' as const,
        type: 'terms' as const,
        value: [item.value],
      },
    ],
    label: item.name,
    state: item.value,
  }));

  return createDescriptor(...locals);
};
