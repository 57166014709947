import { getConfig } from '@cian/config/browser';
import { IApplicationState } from '../../../shared/types/redux';
import { createReduxStore } from '../../../shared/utils/store';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry as getRegistry } from '@cian/mf-registry/browser';

export function createApplicationState() {
  const config = getConfig();
  const httpApi = getHttpApi();
  const logger = getLogger();
  const microfrontendsRegistry = getRegistry();

  const initialState = config.getStrict<IApplicationState>('initialState');

  const reduxContext = {
    config,
    httpApi,
    logger,
    microfrontendsRegistry,
  };

  return {
    config,
    httpApi,
    logger,
    store: createReduxStore(initialState, reduxContext),
    microfrontendsRegistry,
  };
}
