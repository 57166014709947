import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const hasPanoramasDefinition = definitionFactory({
  key: 'has_panoramas',
  mapper: jsonQueryValue => {
    return [
      {
        label: 'С панорамами',
        value: jsonQueryValue,
      },
    ];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'has_panoramas', options);
  },
});
