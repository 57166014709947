import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { getConfig } from '@cian/config/browser';
import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { IRegistry } from '@cian/mf-registry/browser';

import { TReduxStore } from '../../../shared/types/redux';
import { Application } from '../../../shared/containers/App';
import { ICustomContextParams } from '../../../shared/types/ICustomContextParams';

interface IDependencies {
  store: TReduxStore;
  httpApi: IHttpApi;
  logger: ILogger;
  microfrontendsRegistry: IRegistry;
}

export function renderApplication(dependencies: IDependencies) {
  const config = getConfig();
  const { store, httpApi, logger, microfrontendsRegistry } = dependencies;

  const projectName = config.getStrict<string>('projectName');
  const customContext = config.getStrict<ICustomContextParams>('applicationContext.custom');
  const rootElement = document.getElementById(projectName as string);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  ReactDOM.hydrateRoot(
    rootElement,
    <Application
      httpApi={httpApi}
      config={config}
      logger={logger}
      reduxStore={store}
      customContext={customContext}
      microfrontendsRegistry={microfrontendsRegistry}
    />,
  );
}
