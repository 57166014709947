import * as React from 'react';
import { INewbuilding } from '../../types/newbuilding';
import { AddressLine } from '../../containers/OffersContainer/addressLine';
import * as styles from './PromoSnippetAddress.css';

interface IPromoSnippetAddressProps {
  newbuilding: INewbuilding;
}

export function PromoSnippetAddress({ newbuilding }: IPromoSnippetAddressProps) {
  return <div className={styles['container']}>{AddressLine({ newbuilding, isPromo: true })}</div>;
}
