import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@cian/ui-kit';

import { openUpcomingSalePopup, setNewbuildingGaLabel, setNewbuildingId } from '../../actions/upcomingSale';
import { getProduct } from '../../selectors/analytics';
import { trackUpcomingSaleButtonClick } from './analytics';

export interface IUpcomingSaleButtonContainerProps {
  newbuildingId: number;
  gaLabel: string | null;
}

export const UpcomingSaleButtonContainer: React.FC<IUpcomingSaleButtonContainerProps> = ({
  newbuildingId,
  gaLabel,
}) => {
  const product = useSelector(getProduct(newbuildingId));
  const dispatch = useDispatch();

  const onClickHandler = React.useCallback(() => {
    dispatch(openUpcomingSalePopup(true));
    dispatch(setNewbuildingId(newbuildingId));
    dispatch(setNewbuildingGaLabel(gaLabel));
    trackUpcomingSaleButtonClick({ gaLabel, product });
  }, [dispatch, newbuildingId, gaLabel, product]);

  return (
    <Button onClick={onClickHandler} size="XS">
      Оставить заявку
    </Button>
  );
};
