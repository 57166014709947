import { Action } from 'redux';
import { IFilterPureDescriptor } from '../../types/interfaces';
import { TTypedThunkDispatch } from '../../types/thunk';

export interface IFiltersAction<D> extends Action<string> {
  type: string;
  subType?: 'gte' | 'lte';
  value?: D;
}

/**
 * Action Creator для redux, создает action из дескриптора
 */
export function createAction<T>(dispatch: TTypedThunkDispatch, descriptor: IFilterPureDescriptor) {
  if (descriptor.form.find(({ type }) => type === 'range')) {
    return createRangeAction<T>(dispatch, descriptor);
  }

  return createSimpleAction<T>(dispatch, descriptor);
}

/**
 * Простой action creator для toggle like фильтров
 * Обновляем тэги фильтров
 */
export function createSimpleAction<T>(dispatch: TTypedThunkDispatch, descriptor: IFilterPureDescriptor) {
  return (value?: T) => {
    dispatch({
      type: `${descriptor.name}/${descriptor.state}`,
      value,
    });
  };
}

/**
 * Action creator для range based фильтров (gte|lte only)
 * Обновляем тэги фильтров
 */
export function createRangeAction<T>(dispatch: TTypedThunkDispatch, descriptor: IFilterPureDescriptor) {
  return (value: T, type: 'gte' | 'lte') => {
    dispatch({
      subType: type,
      type: `${descriptor.name}/${descriptor.state}`,
      value,
    });
  };
}
