import { Button } from '@cian/ui-kit';
import * as React from 'react';

interface IBrokerButtonProps {
  loading: boolean;
  onClick: () => void;
  buttonText: string;
}

export const BrokerButton: React.FC<IBrokerButtonProps> = ({ loading, onClick, buttonText }) => {
  return (
    <Button loading={loading} size="XS" theme="fill_success_primary" onClick={onClick} data-testid="BrokerButton">
      {buttonText}
    </Button>
  );
};
