import * as React from 'react';

import { useSelector } from 'react-redux';
import { getNewbuildingReviewStat } from '../../selectors/newbuildingReviewStat';
import { ReviewLink } from '../../components/ReviewLink';
import { INewbuilding } from '../../types/newbuilding';
import { trackReviewLinkAnalyticClick } from './analytics/tracking';
import { getAnalyticsUser, getProduct } from '../../selectors/analytics';

export interface IReviewLinkContainerProps {
  newbuildingId: number;
  newbuildingUrl: string;
  newbuilding: INewbuilding;
  onClick?(): void;
}

export function ReviewLinkContainer({
  newbuildingId,
  onClick,
  newbuildingUrl,
  newbuilding,
}: IReviewLinkContainerProps) {
  const { totalRate, reviewCountText, reviewCount } = getNewbuildingReviewStat(newbuilding);

  const user = useSelector(getAnalyticsUser);
  const product = useSelector(getProduct(newbuildingId));
  const page = {
    pageType: 'ListingJK',
    siteType: 'desktop',
    extra: {
      type: 'review',
      rating: totalRate,
    },
  };

  const trackingReviewLinkClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      trackReviewLinkAnalyticClick({ user, page, product });
    }
  }, []);

  return (
    <div>
      {newbuilding.displayName && <meta itemProp="itemReviewed" content={newbuilding.displayName} />}
      <meta itemProp="worstRating" content="1" />
      <meta itemProp="bestRating" content="5" />
      <ReviewLink
        reviewCountText={reviewCountText}
        totalRate={totalRate}
        newbuildingUrl={newbuildingUrl}
        reviewLinkClick={trackingReviewLinkClick}
        reviewCount={reviewCount}
      />
    </div>
  );
}
