import { Action } from 'redux';
import { TDistrictsState } from '../../reducers/region/districts';

export enum EDistrictActions {
  init = 'init/districts',
}

export interface IDistrictActions extends Action {
  type: EDistrictActions.init;
  data: TDistrictsState;
}

//TODO Ошибка в названии
export function iniDistricts(districts: TDistrictsState): IDistrictActions {
  return {
    data: districts,
    type: EDistrictActions.init,
  };
}
