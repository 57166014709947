import * as React from 'react';
import { IconMapMetroMsk16, IconMapMetroSpb16 } from '@cian/ui-kit-design-tokens/icons';

interface IUndergroundIconProps {
  regionId: number;
}

export function UndergroundIcon(props: IUndergroundIconProps) {
  const { regionId } = props;

  switch (regionId) {
    case -2:
    case 2:
    case 4588:
      return <IconMapMetroSpb16 color={'current_color'} />;
    case -1:
    case 1:
    case 4593:
    default:
      return <IconMapMetroMsk16 color={'current_color'} />;
  }
}
