import * as React from 'react';
import { IUndergroundDetails } from '../../types/suggestions';
import * as styles from './Undergrounds.css';

interface IUndergroundsProps {
  undergrounds?: Array<IUndergroundDetails>;
  pointColor?: string;
}

export const Undergrounds = (props: IUndergroundsProps) => {
  const { undergrounds, pointColor } = props;

  if (undergrounds) {
    return (
      <div className={styles['undergrounds']}>
        {undergrounds.map((underground, idx) => {
          return (
            <i
              key={idx}
              className={styles['undergrounds__item']}
              style={{ backgroundColor: `#${underground.lineColor}` }}
            />
          );
        })}
      </div>
    );
  }
  if (pointColor) {
    return (
      <div className={styles['undergrounds']}>
        <i className={styles['undergrounds__item']} style={{ backgroundColor: pointColor }} />
      </div>
    );
  }

  return null;
};
