import { trackingActionSuggestionSelect } from '../analytics';
import { getOffersCount } from '../offersData';
import { TSuggestionSelectedItem } from '../../types/suggestions';
import { IThunkActionCreator } from '../../types/thunk';

import {
  selectNewbuildingSuggestion,
  selectBuilderSuggestion,
  selectGeocodeSuggestion,
  selectDistrictSuggestion,
  selectUndergroundSuggestion,
} from './';

export const onSuggestionSelect = (item: TSuggestionSelectedItem): IThunkActionCreator => {
  return dispatch => {
    if (item.dataType === 'geocode' && Array.isArray(item.data.details) && item.data.details.length) {
      return dispatch(selectGeocodeSuggestion(item));
    }

    if (item.dataType !== 'simple') {
      return;
    }

    const id = Number(item.data.value);

    if (item.type === 'newobject') {
      dispatch(selectNewbuildingSuggestion(id, item.data.displayName));
      dispatch(trackingActionSuggestionSelect('newobject', 'cian'));
      dispatch(getOffersCount());

      return;
    }

    if (item.type === 'builder') {
      dispatch(selectBuilderSuggestion(id, item.data.displayName));
      dispatch(trackingActionSuggestionSelect('builder', 'cian'));
      dispatch(getOffersCount());

      return;
    }

    if (item.type === 'underground') {
      const value = {
        id,
        type: 'underground',
      };

      dispatch(
        selectUndergroundSuggestion({
          value,
          displayName: item.data.displayName,
          undergroundDetails: item.data.undergroundLines,
        }),
      );
      dispatch(trackingActionSuggestionSelect('metro', 'cian'));
      dispatch(getOffersCount());

      return;
    }

    if (item.type === 'district') {
      const value = {
        id,
        type: 'district',
      };
      dispatch(selectDistrictSuggestion({ value, displayName: item.data.displayName }));
      dispatch(trackingActionSuggestionSelect('district', 'cian'));
      dispatch(getOffersCount());

      return;
    }
  };
};
