import * as React from 'react';
import { clsx } from 'clsx';
import { ArticleHeading4, UIText2 } from '@cian/ui-kit';
import { IconActionChevronRight12 } from '@cian/ui-kit-design-tokens/icons';

import { SALES_TYPE_ICONS } from '../../../../constants/salesTypeIcons';
import { ESalesType, ISalesLink } from '../../../../types/offersData';
import { getDescriptionColor, getTitleColor } from './utils';

import * as styles from './SalesLink.css';

interface ISalesLinkProps {
  link: ISalesLink;
  onClick(link: ISalesLink): void;
}

const SALES_TYPE_CLASSES = new Map<ESalesType, string>([
  [ESalesType.BlackFriday, styles['black-friday']],
  [ESalesType.ConsultantPage, styles['consultant']],
  [ESalesType.PromoIndexPage, styles['newbuilding-promo']],
  [ESalesType.SalesLeader, styles['sales-leader']],
  [ESalesType.SalesStart, styles['sales-start']],
  [ESalesType.UpcomingSale, styles['sales-soon']],
]);

export function SalesLink({ link, onClick }: ISalesLinkProps) {
  const { salesType } = link;

  const iconSource = SALES_TYPE_ICONS.get(salesType);
  const salesTypeClassName = SALES_TYPE_CLASSES.get(salesType);
  const titleColor = getTitleColor(salesType);
  const descriptionColor = getDescriptionColor(salesType);

  const handleClick = React.useCallback(() => {
    onClick(link);
  }, [onClick, link]);

  return (
    <a
      className={clsx(styles['link'], salesTypeClassName)}
      href={link.url}
      onClick={handleClick}
      data-testid="SalesLink"
    >
      <div className={styles['container']}>
        {iconSource && <img className={styles['icon']} src={iconSource} alt="" />}
        <div className={styles['content']}>
          <div className={styles['title']}>
            <ArticleHeading4 as="h4" color={titleColor}>
              {link.title}
            </ArticleHeading4>
          </div>
          <UIText2 color={descriptionColor}>{link.text}</UIText2>
        </div>
        <div className={styles['arrow']}>
          <IconActionChevronRight12 color="current_color" />
        </div>
      </div>
    </a>
  );
}
