import * as React from 'react';
import { Input, InputGroup } from '@cian/ui-kit';

import { Text } from '../Text';

import * as styles from './InputsMultiple.css';

interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  onChangeValue(value: string): void;
}

export interface IInputsMultipleProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  stack: IInputProps[];
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function InputsMultiple(props: IInputsMultipleProps) {
  const { stack, label } = props;

  return (
    <div className={styles['field']}>
      <div className={styles['label']}>
        <Text fontSize={14} lineHeight={18}>
          {label}
        </Text>
      </div>
      <div className={styles['stack']}>
        <InputGroup spliced>
          {stack.map((stackProps, index) => {
            const handleChange = (_e: React.ChangeEvent<HTMLInputElement>, value?: string) =>
              stackProps.onChangeValue(value || '');

            return (
              <Input
                key={index}
                type="number"
                disabled={props.disabled}
                placeholder={stackProps.placeholder}
                value={stackProps.value ? String(stackProps.value) : undefined}
                aria-label={stackProps['aria-label']}
                onChange={handleChange}
              />
            );
          })}
        </InputGroup>
      </div>
    </div>
  );
}
