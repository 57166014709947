import * as React from 'react';

import { Preloader } from '../Preloader';

import * as styles from './SearchPreloader.css';

export function SearchPreloader() {
  return (
    <div data-mark="SearchPreloader" className={styles['preloader_overlay']}>
      <div className={styles['preloader']}>
        <Preloader size={50} />
      </div>
    </div>
  );
}
