import { definitionFactory, TStateTagValue } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

const TypeDefinitionLabels: { [index: string]: string } = {
  free: 'Свободная продажа',
  alternative: 'Альтернативная продажа',
  fz_214: '214-ФЗ',
  dupt: 'Договор уступки права требования',
  djsk: 'Договор ЖСК',
  pdkp: 'Предварительный договор',
  invest: 'Договор инвестирования',
};

export const saleTypeDefinition = definitionFactory({
  key: 'sost_type',
  mapper: jsonQueryValue =>
    jsonQueryValue.map((value: TStateTagValue) => ({
      label: TypeDefinitionLabels[value as string],
      value,
    })),
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'sost_type', options);
  },
});
