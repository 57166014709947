import { TUndergroundsState } from '../../reducers/region/undergrounds';

export enum EUndergroundActions {
  init = 'init/undergrounds',
}

export interface IUndergroundAction {
  type: EUndergroundActions;
  data: TUndergroundsState;
}

export function initUndergrounds(undergrounds: TUndergroundsState) {
  return {
    data: undergrounds,
    type: EUndergroundActions.init,
  };
}
