import loadable from '@loadable/component';

export const UpcomingSalePopupContainerLoadable = loadable(() => {
  if (process.env.IS_BROWSER_BUILD) {
    return import('./UpcomingSalePopupContainer');
  }

  return Promise.resolve({
    default: () => null,
  });
});
