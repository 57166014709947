import { ISuggestionsSchema } from '../../../../repositories/geo-suggest/entities/entities/SuggestionsSchema';
import { ISuggestionGroup, IUndergroundDetails } from '../../../../types/suggestions';

export const SUGGESTION_TYPE_MAP: Record<string, string> = {
  company: 'builder',
  builder: 'builder',
  newbuilding: 'newobject',
  newbuildings: 'newobject',
  district: 'district',
  districts: 'district',
  underground: 'underground',
  undergrounds: 'underground',
};

export function extractSuggestion(suggestions: ISuggestionsSchema, type: keyof ISuggestionsSchema): ISuggestionGroup[] {
  const section = suggestions[type];
  const oneSection = (section && section.items) || [];
  const isNewbuildingsType = type === 'newbuildings';

  return oneSection.map(item => {
    const itemName = isNewbuildingsType ? `ЖК «${item.name}»` : item.name;

    return {
      title: itemName,
      displayName: itemName,
      type: SUGGESTION_TYPE_MAP[item.type],
      value: String(item.id),
      dataType: 'simple',
      url: item.link || undefined,
      fullAddress: item.address || undefined,
      undergroundLines: (item.lines as IUndergroundDetails[]) || undefined,
    };
  });
}
