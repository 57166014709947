import { Button, UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';
import coinTop from './assets/coin-top.png';
import starRight from './assets/star-right.png';
import starTop from './assets/star-top.png';
import arrow from './assets/arrow.png';
import checkMark from './assets/check-mark.png';
import coinBottom from './assets/coin-bottom.png';

import * as styles from './BrokerBanner.css';

interface IBrokerBannerProps {
  onClick(): void;
}

export function BrokerBanner({ onClick }: IBrokerBannerProps) {
  return (
    <div className={styles['container']} data-testid="BrokerBanner">
      <div className={styles['content']}>
        <UIHeading2 color="white_100">Зарабатывайте с Циан</UIHeading2>
        <div className={styles['text']}>
          <UIText1 color="white_100">
            Продайте квартиру от застройщика и получите вознаграждение по партнёрской программе
          </UIText1>
        </div>
        <div className={styles['button-wrapper']} data-testid="BrokerBannerButton">
          <Button theme="fill_white_primary" onClick={onClick}>
            Хочу заработать
          </Button>
        </div>
      </div>
      <div className={styles['image']} />
      <img className={styles['coin-top']} src={coinTop} />
      <img className={styles['star-right']} src={starRight} />
      <img className={styles['arrow']} src={arrow} />
      <img className={styles['check-mark']} src={checkMark} />
      <img className={styles['star-top']} src={starTop} />
      <img className={styles['coin-bottom']} src={coinBottom} />
    </div>
  );
}
