import { definitionFactory, TStateTagForFactory } from '../definitionFactory';
import { equalTagKey } from '../../../utils/tags';
import { onDeleteAction } from '../definitionsDeleteAction';
import { TGeoValue } from '../../../types/jsonQuery';

export const geoDefinition = definitionFactory({
  key: 'geo',
  mapper: (jsonQueryValue, tags, { jsonQuery }, extraParams) => {
    const geoAddressList = extraParams && extraParams.address_tags;
    const metroList = extraParams && extraParams.metro_tags;
    const districtList = extraParams && extraParams.district_tags;

    const newTags = jsonQueryValue.reduce((acc: TStateTagForFactory[], jqValue: TGeoValue) => {
      const jqValueId = 'id' in jqValue && Number(jqValue.id);
      let newTag: TStateTagForFactory | undefined;

      if (!jqValueId) {
        return acc;
      }

      if (jqValue.type === 'underground' && metroList && metroList.length) {
        const metro = metroList.find(m => m.id === jqValueId);
        newTag = metro
          ? {
              label: metro.text,
              value: jqValue,
              pointColor: metro.color,
            }
          : undefined;
      } else if (jqValue.type === 'district' && districtList && districtList.length) {
        const district = districtList.find(d => d.id === jqValueId);

        newTag = district
          ? {
              label: district.text,
              value: jqValue,
            }
          : undefined;
      } else if (geoAddressList && geoAddressList.length) {
        const geoAddress = geoAddressList.find(
          address =>
            !!address.addressNodes.find(
              detail => detail.id === jqValueId && detail.geoType.toLowerCase() === jqValue.type.toLowerCase(),
            ),
        );

        newTag = geoAddress
          ? {
              label: geoAddress.text,
              value: jqValue,
            }
          : undefined;
      }

      return newTag ? acc.concat(newTag) : acc;
    }, []);

    const prepareTags = [...tags.filter(equalTagKey('geo')), ...newTags];

    let values = jsonQueryValue.map(({ id }: { id: number }) => id);

    const regionValues = jsonQuery.region && jsonQuery.region.value;
    if (regionValues && regionValues.length) {
      values = values.filter(
        (id: number, index: number) => !(jsonQueryValue[index] === 'location' && regionValues.includes(id)),
      );
    }

    return prepareTags.filter(tag => values.includes(tag.value.id)) as TStateTagForFactory[];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'geo', options);
  },
});
