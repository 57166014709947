import { ESalesType } from '../types/offersData';

import BlackFridayIcon from '../assets/black-friday.svg';
import SalesStartIcon from '../assets/sales-start.svg';
import SalesLeaderIcon from '../assets/sales-leader.svg';
import SalesSoonIcon from '../assets/sales-soon.svg';
import DiscountMortgageIcon from '../assets/discount-mortgage.svg';
import ConsultantIcon from '../assets/consultant.svg';
import PromoIcon from '../assets/newbuilding-promo.svg';

export const SALES_TYPE_ICONS = new Map<ESalesType, string>([
  [ESalesType.SalesStart, SalesStartIcon],
  [ESalesType.SalesLeader, SalesLeaderIcon],
  [ESalesType.UpcomingSale, SalesSoonIcon],
  [ESalesType.BlackFriday, BlackFridayIcon],
  [ESalesType.DiscountMortgage, DiscountMortgageIcon],
  [ESalesType.ConsultantPage, ConsultantIcon],
  [ESalesType.PromoIndexPage, PromoIcon],
]);
