import * as React from 'react';
import { Chips } from '@cian/ui-kit';

import { saleTypeDescriptor } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

export interface ISaleTypeTypeProps extends IDescriptorProps<string> {
  getOffersCount(): void;
}

const OPTIONS = [{ label: '214-ФЗ', value: true }];

class SaleTypeComponent extends React.Component<ISaleTypeTypeProps> {
  public shouldComponentUpdate(nextProps: ISaleTypeTypeProps) {
    const { descriptors, jsonQuery } = this.props;
    const activeDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
    const nextActiveDescriptor = nextProps.descriptors.find(descriptor =>
      isDescriptorActive(descriptor)(nextProps.jsonQuery),
    );
    /* istanbul ignore next */
    const value = activeDescriptor ? activeDescriptor.state : undefined;
    /* istanbul ignore next */
    const nextValue = nextActiveDescriptor ? nextActiveDescriptor.state : undefined;

    return nextValue !== value;
  }

  public render() {
    const { jsonQuery, descriptors } = this.props;

    const activeDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
    const value = activeDescriptor?.state === 'fz_214' ? [true] : [];

    return <Chips options={OPTIONS} size="S" value={value} onChange={this.handleChange} />;
  }

  private handleChange = () => {
    const descriptor = this.props.descriptors.find(d => d.state === 'fz_214');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.props.actionCreator(descriptor!)();
    this.props.getOffersCount();
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const SaleType = connectToDescriptors(saleTypeDescriptor, mapDispatchToProps)(SaleTypeComponent);
