import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const penthouseTypeDefinition = definitionFactory({
  key: 'is_penthouse',
  mapper: jsonQueryValue => (jsonQueryValue === true ? [{ label: 'Пентхаус', value: jsonQueryValue }] : []),
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'is_penthouse', options);
  },
});
