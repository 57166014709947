import * as React from 'react';

import { IMarkedListStoreProps } from '../../containers/MarkedListContainer/MarkedListContainer';

import * as styles from './MarkedList.css';

export function MarkedList(props: IMarkedListStoreProps) {
  const { items, dataMark = 'MarkedList' } = props;

  if (!items || !items.length) {
    return null;
  }

  return (
    <div data-mark={dataMark} className={styles['marked_list_wrapper']}>
      <ul>
        {items.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
