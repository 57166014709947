import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';

export enum ERegionSearchCitiesActions {
  set = 'set/regionSearchCities',
}

export interface IRegionSearchCitiesAction {
  type: ERegionSearchCitiesActions;
  data: ISearchCatalogItemSchema[];
}

export function setRegionSearchCities(regionSearchCitiesData: ISearchCatalogItemSchema[]) {
  return {
    data: regionSearchCitiesData,
    type: ERegionSearchCitiesActions.set,
  };
}
