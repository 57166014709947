import { IThunkActionCreator } from '../../types/thunk';
import { getSeparateUrl, memoize } from '@cian/newbuilding-utils';
import { IOffersDataState } from '../../types/offersData';
import { triggerHeaderRegionChanged } from '../../utils/events/triggerHeaderRegionChanged';

const getSeparateUrlMemo = memoize(getSeparateUrl);

export function updateBrowserUrl(offersData: IOffersDataState): IThunkActionCreator {
  return (dispatch, getState) => {
    const {
      jsonQuery,
      location,
      region: { currentRegion },
      filters: { tags },
    } = getState();
    const { seo, fullUrl } = offersData;
    if (typeof window !== 'undefined' && fullUrl) {
      const { protocol: newProtocol, domain: newDomain, path: newPath } = getSeparateUrlMemo(fullUrl, location.path);
      const { protocol, domain, path } = getSeparateUrlMemo(window.location.href);
      const isLocalDev = domain.indexOf('localhost') !== -1;

      if (newProtocol !== protocol || (!isLocalDev && newDomain !== domain)) {
        let url = fullUrl;

        if (isLocalDev) {
          url = url.replace(newDomain, window.location.host).replace(newProtocol, protocol);
        }

        window.location.href = url;
      } else if (newPath !== path) {
        const title = (seo && seo.title) || document.title || '';
        document.title = title;
        triggerHeaderRegionChanged(currentRegion);
        window.history.pushState(
          {
            jsonQuery,
            currentRegion,
            path: newPath,
            page: location.page,
            title,
            tags,
          },
          title,
          newPath,
        );
      }
    }
  };
}
