import * as React from 'react';
import * as style from './style.css';
import { UIText2 } from '@cian/ui-kit';
import { IQuickLink } from '../../types/quickLinks';

interface IQuickLinkProps {
  link: IQuickLink;
}

export const QuickLink = ({ link }: IQuickLinkProps) => {
  return (
    <a className={style['link']} href={link.url} target="_blank" rel="noreferrer">
      <UIText2 color="primary_100" as="span" display="inline-block">
        {link.title}
      </UIText2>
      <span className={style['counter']}>
        <UIText2 color="current_color" as="span" display="inline-block">
          {link.counter}
        </UIText2>
      </span>
    </a>
  );
};
