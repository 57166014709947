import * as React from 'react';
import { ArticleHeading4, ArticleParagraph2 } from '@cian/ui-kit';

import * as styles from './NewbuildingBlackFridayTopBanner.css';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';

export const NewbuildingBlackFridayTopBanner: React.FC = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['image']} />
      <div className={styles['text']}>
        <ArticleHeading4>Скидки на новостройки только до 29 сентября</ArticleHeading4>
        <ArticleParagraph2>
          Мы собрали выгодные предложения от застройщиков. Выберите ЖК и{NO_BREAK_SPACE}позвоните по{NO_BREAK_SPACE}
          номеру в{NO_BREAK_SPACE}объявлении
        </ArticleParagraph2>
      </div>
    </div>
  );
};
