import * as React from 'react';
import { useSelector } from 'react-redux';
import { getQuickLinksTabs } from '../../selectors/quickLinks';
import { QuickLinksTabsComponent } from '../../components/QuickLinks';

export const QuickLinks = () => {
  const tabs = useSelector(getQuickLinksTabs);

  if (tabs.length === 0) {
    return null;
  }

  return <QuickLinksTabsComponent tabs={tabs} />;
};
