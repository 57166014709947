import { AnyAction } from 'redux';
import { defaultRange, descriptorsFactory, IJsonQuery } from '../../utils/filters';

const modifyValue = ({ price = defaultRange }: IJsonQuery, action: AnyAction) => {
  return action ? Object.assign({}, price.value, { [action.subType]: action.value }) : price.value;
};

export const priceInputDescriptors = descriptorsFactory({ name: 'Price', clear: [] })({
  form: [{ key: 'price', type: 'range', value: modifyValue }],
  label: '',
  state: 'price',
});
