import { ca } from '@cian/analytics';

import { getCPUrlFromNewbuildingGaLabel } from '../../utils/customPageUrl';
import { IThunkActionCreator } from '../../types/thunk';
import { createUserTrackingData, getTrackingPageParam, productFromNewbuildingMapper } from './helpers';
import { INewbuilding } from '../../types/newbuilding';

// При Расхлопе конкретного номера телефона в новую ручку СОПРа (site) отправлять событие
export function trackingActionShowPhone(newbuilding: INewbuilding): IThunkActionCreator {
  return async (dispatch, getState) => {
    const state = getState();
    const positionIndex = state.offersData.newbuildings.findIndex(({ id }) => id === newbuilding.id);
    ca('eventSite', {
      name: 'oldevent',
      category: 'Phones',
      action: 'Open_listing_jk',
      label: getCPUrlFromNewbuildingGaLabel(newbuilding.gaLabels.full),
      user: createUserTrackingData(state),
      page: getTrackingPageParam(state),
      products: [productFromNewbuildingMapper(newbuilding, positionIndex)],
      searchConditions: state.jsonQuery,
    });
  };
}
