import { IJsonQuery } from '../../types/interfaces';
import { ca } from '@cian/analytics';

export function trackBannerClick(jsonQuery: IJsonQuery): void {
  ca('eventSite', {
    action: 'click_sopr',
    category: 'ListingJK',
    label: 'nb_mortgage_banner',
    name: 'oldevent',
    sc: jsonQuery,
    useLastProducts: true,
  });
}

export function trackBannerShow(jsonQuery: IJsonQuery): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'ListingJK',
    label: 'nb_mortgage_banner',
    name: 'oldevent',
    sc: jsonQuery,
    useLastProducts: true,
  });
}
