export const W = 87;
export const S = 83;
export const H = 72;
export const J = 74;
export const K = 75;
export const L = 76;
export const A = 65;
export const D = 68;
export const ENTER = 13;
export const ESC = 27;
export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;

export const SPACE_CODE = 'Space';
