import * as React from 'react';
import { getBreadcrumbs } from '../../selectors/seo/getBreadcrumbs';
import { useSelector } from 'react-redux';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { SIZE_24 } from '../../constants/sizes';
import { Container } from '../../components/Container';

export const BreadcrumbsContainer = () => {
  const breadcrumbs = useSelector(getBreadcrumbs);

  return (
    <Container dataMark="QuickLinksComponent" padding={`0 0 ${SIZE_24}`} noPrint>
      <Breadcrumbs stack={breadcrumbs} />
    </Container>
  );
};
