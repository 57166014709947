import { combineReducers } from 'redux';
import { currentRegion } from './currentRegion';
import { districts, TDistrictsState } from './districts';
import { regions } from './regions';
import { regionsByID } from './regionsByID';
import { TUndergroundsState, undergrounds } from './undergrounds';
import { TLocationState, location } from './location';
import { IRegion, IRegionsByID, TRegions } from '../../types/regions';

export interface IRegionState {
  undergrounds: TUndergroundsState;
  districts: TDistrictsState;
  regions: TRegions;
  regionsByID: IRegionsByID;
  currentRegion: IRegion;
  location: TLocationState;
}

export const region = combineReducers<IRegionState>({
  currentRegion,
  districts,
  regions,
  regionsByID,
  undergrounds,
  location,
});
