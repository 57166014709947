import { IThunkActionCreator } from '../../types/thunk';
import { createSimpleAction } from '../../containers/сonnectToDescriptors';
import { polygonDescriptor } from '../../constants/descriptors';
import { addTag } from './addTag';

interface ISelectPolygonOption {
  coordinates: Array<object>;
  name: string;
}

export const setPolygon = (polygon: ISelectPolygonOption, displayName: string): IThunkActionCreator => {
  return dispatch => {
    createSimpleAction(dispatch, polygonDescriptor[0])(polygon);
    dispatch(addTag('polygon', polygon, displayName));
  };
};
