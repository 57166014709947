import { descriptorsFactory } from '../../utils/filters';

export const hasMortgageDesdcriptor = descriptorsFactory({ name: 'HasMortgage', clear: ['has_mortgage'] })(
  {
    form: [{ key: 'has_mortgage', value: true, type: 'term' }],
    label: 'Возможна ипотека',
    state: 'On',
  },
  {
    form: [],
    label: 'Возможна ипотека',
    state: 'Off',
  },
);
