import { IThunkActionCreator } from '../../types/thunk';
import { createSimpleAction } from '../../containers/сonnectToDescriptors';
import { geoDescriptor } from '../../constants/descriptors';
import { ISelectGeoSuggestionOptions } from './types';
import { addTag } from './';

export const selectGeoSuggestion = (options: ISelectGeoSuggestionOptions = { value: [] }): IThunkActionCreator => {
  return dispatch => {
    const { value, displayName, undergroundDetails } = options;
    createSimpleAction(dispatch, geoDescriptor[0])(value);
    dispatch(addTag('geo', value, displayName, undergroundDetails));
  };
};
