import * as React from 'react';
import { useSelector } from 'react-redux';
import { UpcomingSalePopupContainerLoadable } from './loadable';
import { getIsUpcomingSalePopupOpen, getUpcomingSaleNewbuildingId } from '../../selectors/upcomingSale';

export function UpcomingSalePopupContainerWrapper() {
  const isOpen = useSelector(getIsUpcomingSalePopupOpen);
  const newbuildingId = useSelector(getUpcomingSaleNewbuildingId);

  if (!isOpen || !newbuildingId) {
    return null;
  }

  return <UpcomingSalePopupContainerLoadable newbuildingId={newbuildingId} />;
}
