import * as React from 'react';

import { connect } from 'react-redux';
import { IApplicationState } from '../../types/redux';
import { createAction } from './createAction';
import { IDescriptorProps, IFilterPureDescriptor } from '../../types/interfaces';
import { TTypedThunkDispatch } from '../../types/thunk';

type TFilterPureDescriptorCreator = (state: IApplicationState) => IFilterPureDescriptor[];
type TDispatchProps<T> = Pick<IDescriptorProps<T>, 'actionCreator'>;
type TStateProps<T> = Pick<IDescriptorProps<T>, 'descriptors' | 'jsonQuery'>;
type TDescriptorProps<T> = TDispatchProps<T> & TStateProps<T>;
type TDescriptorComponent<T, P> =
  | React.StatelessComponent<P & TDescriptorProps<T>>
  | React.ComponentClass<P & TDescriptorProps<T>>;

export function connectToDescriptors<T, P>(
  descriptors: IFilterPureDescriptor[] | TFilterPureDescriptorCreator,
  mapDispatchToProps: (dispatch: TTypedThunkDispatch) => {} = () => ({}),
) {
  return (Component: TDescriptorComponent<T, P>) =>
    connect<TStateProps<T>, TDispatchProps<T>, {}, IApplicationState>(
      state => ({
        descriptors: typeof descriptors === 'function' ? descriptors(state) : descriptors || [],
        jsonQuery: state.jsonQuery,
      }),
      dispatch => ({
        ...mapDispatchToProps(dispatch),
        actionCreator: (descriptor: IFilterPureDescriptor) => createAction<T>(dispatch, descriptor),
      }),
      //@ts-expect-error Легаси
    )(Component);
}
