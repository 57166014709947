import { similarTrackingAction } from './eventEbcSimilarTrackingAction';
import { IJsonQuery } from '../../utils/filters';

// показ страницы/выдачи на которой есть список похожих ЖК
export const trackingActionShowSimilarJK = (jsonQuery: IJsonQuery) =>
  similarTrackingAction('show_similar_JK', jsonQuery);

// клик по похожему ЖК
export const trackingActionClickSimilarJK = (newbuildingId: number, gaLabelFull: string, jsonQuery: IJsonQuery) =>
  similarTrackingAction('click_similar_JK', jsonQuery, newbuildingId, gaLabelFull);

// показ других ЖК этого застройщика
// TODO будет использовано в https://cianru.atlassian.net/browse/CD-36570
export const trackingActionShowOtherJK = (jsonQuery: IJsonQuery) => similarTrackingAction('show_others_JK', jsonQuery);

// клик по другому ЖК этого застройщика
// TODO будет использовано в https://cianru.atlassian.net/browse/CD-36570
export const trackingActionClickOtherJK = (newbuildingId: number, gaLabelFull: string, jsonQuery: IJsonQuery) =>
  similarTrackingAction('click_others_JK', jsonQuery, newbuildingId, gaLabelFull);
