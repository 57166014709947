import { IThunkActionCreator } from '../../types/thunk';
import { IStateTag } from './definitionFactory';
import { createTags } from '../../utils/tags/createTags';
import { needDefaultRegion } from '../../utils/geo';
import { createSimpleAction } from '../../containers/сonnectToDescriptors';
import { regionDescriptor } from '../../constants/descriptors';
import { setCurrentRegion } from '../region/currentRegion';
import { clearJsonQueryAction } from '../filters';

export enum ETagsActions {
  clean = 'tags/clean',
  set = 'tags/set',
}

export interface IBaseFilterTagsAction {
  type: ETagsActions;
}

export interface IFilterTagsCleanTagsAction extends IBaseFilterTagsAction {
  type: ETagsActions.clean;
}

export interface IFilterTagsSetTagsAction extends IBaseFilterTagsAction {
  type: ETagsActions.set;
  tags: IStateTag[];
}

export type IAllFilterTagsActions = IFilterTagsCleanTagsAction | IFilterTagsSetTagsAction;

export function cleanTags(): IFilterTagsCleanTagsAction {
  return {
    type: ETagsActions.clean,
  };
}

export function setTags(tags: IStateTag[]): IFilterTagsSetTagsAction {
  return {
    type: ETagsActions.set,
    tags,
  };
}

export interface IUpdateTagsParams {
  newbuildingLabel?: string;
  builderLabel?: string;
}

export function updateTags(prepareTags?: IStateTag[]): IThunkActionCreator {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      jsonQuery,
      filters: { tags },
    } = appState;

    const newTags = createTags(jsonQuery, prepareTags || tags, appState);

    dispatch(setTags(newTags));
  };
}

export function putDefaultRegion(): IThunkActionCreator {
  return (dispatch, getState) => {
    const appState = getState();
    const { jsonQuery, region } = appState;
    if (needDefaultRegion(jsonQuery)) {
      const moscowAndMO = region.regionsByID['-1'];
      createSimpleAction(dispatch, regionDescriptor[0])(-1);
      dispatch(setCurrentRegion(moscowAndMO));
    }
  };
}

export function clearTagsAfterChangeRegion(previousRegionId: number): IThunkActionCreator {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      filters: { tags },
      region,
    } = appState;

    const tagsKeyForRemove = ['builders', 'newbuilding_id', 'geo'];

    if (region.currentRegion.id !== previousRegionId) {
      tags.forEach(tag => {
        if (tagsKeyForRemove.includes(tag.keyName)) {
          dispatch(clearJsonQueryAction(tag.keyName, tag.value));
          dispatch(updateTags());
          dispatch(putDefaultRegion());
        }
      });
    }
  };
}
