/* eslint-disable max-lines */

export const MARGIN_AUTO = '0 auto';
export const SIZE_0 = '0';
export const SIZE_WIDE = '100%';
export const SIZE_AUTO = 'auto';
export const SIZE_HALF = '50%';
export const SIZE_THIRD = `${100 / 3}%`;
export const SIZE_FOURTH = '25%';
export const SIZE_VH_80 = '80vh';
export const SIZE_1 = '1px';
export const SIZE_4 = '4px';
export const SIZE_8 = '8px';
export const SIZE_12 = '12px';
export const SIZE_16 = '16px';
export const SIZE_20 = '20px';
export const SIZE_24 = '24px';
export const SIZE_28 = '28px';
export const SIZE_32 = '32px';
export const SIZE_36 = '36px';
export const SIZE_40 = '40px';
export const SIZE_44 = '44px';
export const SIZE_48 = '48px';
export const SIZE_52 = '52px';
export const SIZE_56 = '56px';
export const SIZE_60 = '60px';
export const SIZE_64 = '64px';
export const SIZE_68 = '68px';
export const SIZE_72 = '72px';
export const SIZE_76 = '76px';
export const SIZE_80 = '80px';
export const SIZE_84 = '84px';
export const SIZE_88 = '88px';
export const SIZE_92 = '92px';
export const SIZE_96 = '96px';
export const SIZE_100 = '100px';
export const SIZE_104 = '104px';
export const SIZE_108 = '108px';
export const SIZE_112 = '112px';
export const SIZE_116 = '116px';
export const SIZE_120 = '120px';
export const SIZE_124 = '124px';
export const SIZE_128 = '128px';
export const SIZE_132 = '132px';
export const SIZE_136 = '136px';
export const SIZE_140 = '140px';
export const SIZE_144 = '144px';
export const SIZE_148 = '148px';
export const SIZE_152 = '152px';
export const SIZE_156 = '156px';
export const SIZE_160 = '160px';
export const SIZE_164 = '164px';
export const SIZE_168 = '168px';
export const SIZE_172 = '172px';
export const SIZE_176 = '176px';
export const SIZE_180 = '180px';
export const SIZE_184 = '184px';
export const SIZE_188 = '188px';
export const SIZE_192 = '192px';
export const SIZE_196 = '196px';
export const SIZE_200 = '200px';
export const SIZE_204 = '204px';
export const SIZE_208 = '208px';
export const SIZE_212 = '212px';
export const SIZE_216 = '216px';
export const SIZE_220 = '220px';
export const SIZE_224 = '224px';
export const SIZE_228 = '228px';
export const SIZE_232 = '232px';
export const SIZE_236 = '236px';
export const SIZE_240 = '240px';
export const SIZE_244 = '244px';
export const SIZE_248 = '248px';
export const SIZE_252 = '252px';
export const SIZE_256 = '256px';
export const SIZE_260 = '260px';
export const SIZE_264 = '264px';
export const SIZE_268 = '268px';
export const SIZE_272 = '272px';
export const SIZE_276 = '276px';
export const SIZE_280 = '280px';
export const SIZE_284 = '284px';
export const SIZE_288 = '288px';
export const SIZE_292 = '292px';
export const SIZE_296 = '296px';
export const SIZE_300 = '300px';
export const SIZE_304 = '304px';
export const SIZE_308 = '308px';
export const SIZE_312 = '312px';
export const SIZE_316 = '316px';
export const SIZE_320 = '320px';
export const SIZE_324 = '324px';
export const SIZE_328 = '328px';
export const SIZE_332 = '332px';
export const SIZE_336 = '336px';
export const SIZE_340 = '340px';
export const SIZE_344 = '344px';
export const SIZE_348 = '348px';
export const SIZE_352 = '352px';
export const SIZE_356 = '356px';
export const SIZE_360 = '360px';
export const SIZE_364 = '364px';
export const SIZE_368 = '368px';
export const SIZE_372 = '372px';
export const SIZE_376 = '376px';
export const SIZE_380 = '380px';
export const SIZE_384 = '384px';
export const SIZE_388 = '388px';
export const SIZE_392 = '392px';
export const SIZE_396 = '396px';
export const SIZE_400 = '400px';
export const SIZE_404 = '404px';
export const SIZE_408 = '408px';
export const SIZE_412 = '412px';
export const SIZE_416 = '416px';
export const SIZE_420 = '420px';
export const SIZE_424 = '424px';
export const SIZE_428 = '428px';
export const SIZE_432 = '432px';
export const SIZE_436 = '436px';
export const SIZE_440 = '440px';
export const SIZE_444 = '444px';
export const SIZE_448 = '448px';
export const SIZE_452 = '452px';
export const SIZE_456 = '456px';
export const SIZE_460 = '460px';
export const SIZE_464 = '464px';
export const SIZE_468 = '468px';
export const SIZE_472 = '472px';
export const SIZE_476 = '476px';
export const SIZE_480 = '480px';
export const SIZE_484 = '484px';
export const SIZE_488 = '488px';
export const SIZE_492 = '492px';
export const SIZE_496 = '496px';
export const SIZE_500 = '500px';
export const SIZE_504 = '504px';
export const SIZE_508 = '508px';
export const SIZE_512 = '512px';
export const SIZE_516 = '516px';
export const SIZE_520 = '520px';
export const SIZE_524 = '524px';
export const SIZE_528 = '528px';
export const SIZE_532 = '532px';
export const SIZE_536 = '536px';
export const SIZE_540 = '540px';
export const SIZE_544 = '544px';
export const SIZE_548 = '548px';
export const SIZE_552 = '552px';
export const SIZE_556 = '556px';
export const SIZE_560 = '560px';
export const SIZE_564 = '564px';
export const SIZE_568 = '568px';
export const SIZE_572 = '572px';
export const SIZE_576 = '576px';
export const SIZE_580 = '580px';
export const SIZE_584 = '584px';
export const SIZE_588 = '588px';
export const SIZE_592 = '592px';
export const SIZE_596 = '596px';
export const SIZE_600 = '600px';
export const SIZE_604 = '604px';
export const SIZE_608 = '608px';
export const SIZE_612 = '612px';
export const SIZE_616 = '616px';
export const SIZE_620 = '620px';
export const SIZE_624 = '624px';
export const SIZE_628 = '628px';
export const SIZE_632 = '632px';
export const SIZE_636 = '636px';
export const SIZE_640 = '640px';
export const SIZE_644 = '644px';
export const SIZE_648 = '648px';
export const SIZE_652 = '652px';
export const SIZE_656 = '656px';
export const SIZE_660 = '660px';
export const SIZE_664 = '664px';
export const SIZE_668 = '668px';
export const SIZE_672 = '672px';
export const SIZE_676 = '676px';
export const SIZE_680 = '680px';
export const SIZE_684 = '684px';
export const SIZE_688 = '688px';
export const SIZE_692 = '692px';
export const SIZE_696 = '696px';
export const SIZE_700 = '700px';
export const SIZE_704 = '704px';
export const SIZE_708 = '708px';
export const SIZE_712 = '712px';
export const SIZE_716 = '716px';
export const SIZE_720 = '720px';
export const SIZE_724 = '724px';
export const SIZE_728 = '728px';
export const SIZE_732 = '732px';
export const SIZE_736 = '736px';
export const SIZE_740 = '740px';
export const SIZE_744 = '744px';
export const SIZE_748 = '748px';
export const SIZE_752 = '752px';
export const SIZE_756 = '756px';
export const SIZE_760 = '760px';
export const SIZE_764 = '764px';
export const SIZE_768 = '768px';
export const SIZE_772 = '772px';
export const SIZE_776 = '776px';
export const SIZE_780 = '780px';
export const SIZE_784 = '784px';
export const SIZE_788 = '788px';
export const SIZE_792 = '792px';
export const SIZE_796 = '796px';
export const SIZE_800 = '800px';
export const SIZE_804 = '804px';
export const SIZE_808 = '808px';
export const SIZE_812 = '812px';
export const SIZE_816 = '816px';
export const SIZE_820 = '820px';
export const SIZE_824 = '824px';
export const SIZE_828 = '828px';
export const SIZE_832 = '832px';
export const SIZE_836 = '836px';
export const SIZE_840 = '840px';
export const SIZE_844 = '844px';
export const SIZE_848 = '848px';
export const SIZE_852 = '852px';
export const SIZE_856 = '856px';
export const SIZE_860 = '860px';
export const SIZE_864 = '864px';
export const SIZE_868 = '868px';
export const SIZE_872 = '872px';
export const SIZE_876 = '876px';
export const SIZE_880 = '880px';
export const SIZE_884 = '884px';
export const SIZE_888 = '888px';
export const SIZE_892 = '892px';
export const SIZE_896 = '896px';
export const SIZE_900 = '900px';
export const SIZE_904 = '904px';
export const SIZE_908 = '908px';
export const SIZE_912 = '912px';
export const SIZE_916 = '916px';
export const SIZE_920 = '920px';
export const SIZE_924 = '924px';
export const SIZE_928 = '928px';
export const SIZE_932 = '932px';
export const SIZE_936 = '936px';
export const SIZE_940 = '940px';
export const SIZE_944 = '944px';
export const SIZE_948 = '948px';
export const SIZE_952 = '952px';
export const SIZE_956 = '956px';
export const SIZE_960 = '960px';
export const SIZE_964 = '964px';
export const SIZE_968 = '968px';
export const SIZE_972 = '972px';
export const SIZE_976 = '976px';
export const SIZE_980 = '980px';
export const SIZE_984 = '984px';
export const SIZE_988 = '988px';
export const SIZE_992 = '992px';
export const SIZE_996 = '996px';
export const SIZE_1000 = '1000px';
export const SIZE_1004 = '1004px';
export const SIZE_1008 = '1008px';
export const SIZE_1012 = '1012px';
export const SIZE_1016 = '1016px';
export const SIZE_1020 = '1020px';
export const SIZE_1024 = '1024px';
export const SIZE_1028 = '1028px';
export const SIZE_1032 = '1032px';
export const SIZE_1036 = '1036px';
export const SIZE_1040 = '1040px';
export const SIZE_1044 = '1044px';
export const SIZE_1048 = '1048px';
export const SIZE_1052 = '1052px';
export const SIZE_1056 = '1056px';
export const SIZE_1060 = '1060px';
export const SIZE_1064 = '1064px';
export const SIZE_1068 = '1068px';
export const SIZE_1072 = '1072px';
export const SIZE_1076 = '1076px';
export const SIZE_1080 = '1080px';
export const SIZE_1084 = '1084px';
export const SIZE_1088 = '1088px';
export const SIZE_1092 = '1092px';
export const SIZE_1096 = '1096px';
export const SIZE_1100 = '1100px';
export const SIZE_1104 = '1104px';
export const SIZE_1108 = '1108px';
export const SIZE_1112 = '1112px';
export const SIZE_1116 = '1116px';
export const SIZE_1120 = '1120px';
export const SIZE_1124 = '1124px';
export const SIZE_1128 = '1128px';
export const SIZE_1132 = '1132px';
export const SIZE_1136 = '1136px';
export const SIZE_1140 = '1140px';
export const SIZE_1144 = '1144px';
export const SIZE_1148 = '1148px';
export const SIZE_1152 = '1152px';
export const SIZE_1156 = '1156px';
export const SIZE_1160 = '1160px';
export const SIZE_1164 = '1164px';
export const SIZE_1168 = '1168px';
export const SIZE_1172 = '1172px';
export const SIZE_1176 = '1176px';
export const SIZE_1180 = '1180px';
export const SIZE_1184 = '1184px';
export const SIZE_1188 = '1188px';
export const SIZE_1192 = '1192px';
export const SIZE_1196 = '1196px';
export const SIZE_1200 = '1200px';
export const SIZE_1204 = '1204px';
export const SIZE_1208 = '1208px';
export const SIZE_1212 = '1212px';
export const SIZE_1216 = '1216px';
export const SIZE_1220 = '1220px';
export const SIZE_1224 = '1224px';
export const SIZE_1228 = '1228px';
export const SIZE_1232 = '1232px';
