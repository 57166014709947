import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';

import { useApplicationContext } from '../../utils/applicationContext';
import { ConsultBanner } from '../../components/ConsultBanner';
import { getCurrentJsonQuery } from '../../selectors/location';

import { trackBannerClick, trackBannerShow } from './tracking';

export function NewbuildingConsultBannerContainer() {
  const { config } = useApplicationContext();

  const landingLink = config.getStrict<string>('newbuildingConsultant.landingLink');

  const jsonQuery = useSelector(getCurrentJsonQuery);

  const handleClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);
  const handleShow = React.useCallback(() => trackBannerShow(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleShow} percentVisible={0.5} triggerOnce callbackDelay={1000}>
      <ConsultBanner onClick={handleClick} url={landingLink} />
    </ActionAfterViewObserver>
  );
}
