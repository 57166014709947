import * as React from 'react';
import * as styles from './Select.css';

export type ISelectMultipleProps = React.HTMLProps<HTMLDivElement>;

export class SelectMultiple extends React.PureComponent<ISelectMultipleProps> {
  public render() {
    const { children } = this.props;

    return (
      <div data-mark="SelectMultiple" className={styles.group}>
        {children}
      </div>
    );
  }
}
