import { AnyAction } from 'redux';
import { descriptorsFactory } from '../../utils/filters';
import { IApplicationState } from '../../types/redux';

export const polygonDescriptor = descriptorsFactory({ name: 'Polygon', clear: ['polygon'] })(
  {
    form: [
      {
        key: 'polygon',
        type: 'terms',
        value: (state: IApplicationState, action: AnyAction) => action.value,
      },
    ],
    label: 'polygon',
    state: 'polygon_add',
  },
  {
    form: [],
    label: 'polygon',
    state: 'polygon_clear',
  },
);
