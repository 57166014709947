import { descriptorsFactory, IFilterPureDescriptor } from '../../utils/filters';

export const hasDiscountMortgageDescriptor: IFilterPureDescriptor[] = descriptorsFactory({
  name: 'hasDiscountMortgage',
  clear: ['is_discount_mortgage'],
})(
  {
    form: [
      {
        key: 'is_discount_mortgage',
        type: 'term',
        value: true,
      },
    ],
    label: 'Льготная ипотека',
    state: 'On',
  },
  {
    form: [],
    label: 'Льготная ипотека',
    state: 'Off',
  },
);
