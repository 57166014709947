import * as React from 'react';
import { Container } from '../../components/Container';
import { SIZE_4 } from '../../constants/sizes';
import { SPACE_SYMBOL } from '../../constants/symbolsUnicode';
import { Link } from '../../components/Link';
import { Text } from '../../components/Text';
import { INewbuilding } from '../../types/newbuilding';

interface IAddressLineProps {
  newbuilding: INewbuilding;
  isPromo?: boolean;
}

export function AddressLine(props: IAddressLineProps) {
  const { newbuilding, isPromo } = props;

  return (
    <Container margin={`${SIZE_4} 0`} dataMark="CellAddressBlock">
      {newbuilding.addressLine &&
        newbuilding.addressLine.map(({ title, fullUrl, qs }, index) => (
          <React.Fragment key={index}>
            {!!index && `,${SPACE_SYMBOL}`}
            <Link url={fullUrl || `/newobjects/list?${qs}`} target="blank">
              <Text color={isPromo ? 'gray_6' : 'black'} colorHovered={isPromo ? 'blue_2' : 'red'} whiteSpace="nowrap">
                {title}
              </Text>
            </Link>
          </React.Fragment>
        ))}
    </Container>
  );
}
