import * as React from 'react';
import { useSelector } from 'react-redux';

import { SalesDescription } from '../../components/SalesDescription';
import { getSalesDescription } from '../../selectors/getSalesDescription';
import { DiscountMortgageDescriptionContainer } from '../DiscountMortgageDescriptionContainer';
import { ESalesType } from '../../types/offersData';

export function SalesDescriptionContainer() {
  const description = useSelector(getSalesDescription);
  const descriptionText = React.useMemo(() => (description ? description.text.split('\n') : []), [description]);

  if (!description) {
    return null;
  }

  if (description.salesType === ESalesType.DiscountMortgage) {
    return <DiscountMortgageDescriptionContainer />;
  }

  return <SalesDescription text={descriptionText} type={description.salesType} />;
}
