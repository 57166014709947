import * as React from 'react';
import { clsx } from 'clsx';

import * as styles from './LabelsBox.css';

interface ILabelsBoxProps {
  promo?: boolean;
}

export const LabelsBox: React.FC<React.PropsWithChildren<ILabelsBoxProps>> = ({ children, promo }) => {
  return <div className={clsx(styles['labels'], /* istanbul ignore next */ promo && styles['promo'])}>{children}</div>;
};
