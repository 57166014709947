import * as React from 'react';

import { Container } from '../Container';
import { SIZE_AUTO } from '../../constants/sizes';

import * as styles from './PhoneInfoBuilderLogo.css';

interface IPhoneInfoBuilderLogoProps {
  logo: string;
  dataMark?: string;
}

export const PhoneInfoBuilderLogo = ({ logo, dataMark }: IPhoneInfoBuilderLogoProps) => (
  <Container
    margin={`2px 0 0 ${SIZE_AUTO}`}
    width="52px"
    height="52px"
    borderRadius={2}
    borderStyle="solid"
    borderTopWidth={1}
    borderRightWidth={1}
    borderLeftWidth={1}
    borderBottomWidth={1}
    borderColor="gray_0"
    style={{ backgroundImage: `url(${logo})` }}
    className={styles['logo']}
    dataMark={dataMark || 'PhoneInfoBuilderLogo'}
  />
);
