import { getSeparateUrl } from '@cian/newbuilding-utils';
import { resolveNewbuildingJsonQuery } from '../../api';
import { IThunkActionCreator } from '../../types/thunk';
import { IJsonQuery } from '../../utils/filters';
import { getOffersData } from '../offersData';
import { initJsonQuery } from '../filters';
import { QUERY_PARAM_NAME_PAGE, PATH_PREFIX_NON_FIRST_PAGE } from '../../constants/config';
import { IOffersDataState } from '../../types/offersData';
import { updateTags } from '../filterTags';

export enum ELocationActions {
  setPage = 'location/setPage',
}

export interface ISetLocationPageParams {
  page?: number;
  path?: string;
  canUseHiddenBase?: boolean;
  jsonQuery?: IJsonQuery;
}

export interface ISetPageAction {
  type: ELocationActions;
  payload: ISetLocationPageParams;
}

export function loadPageWithNewJsonQuery(path: string): IThunkActionCreator {
  return (dispatch, getState, { logger, httpApi }) => {
    const { location, user } = getState();
    resolveNewbuildingJsonQuery(location.subdomain, path, logger, httpApi)
      .then(response => {
        const { jsonQuery } = response as { jsonQuery: IJsonQuery };
        const canUseHiddenBase = user.isAuthenticated && user.permissions.canUseHiddenBase;

        dispatch(initJsonQuery(jsonQuery));
        dispatch(updateTags());
        dispatch(getOffersData({ shouldUpdateHistory: true, path, canUseHiddenBase }));
      })
      .catch(() => {
        window.location.href = path;
      });
  };
}

export function setPage(
  page: number,
  offersData: IOffersDataState,
  jsonQuery: IJsonQuery,
  canUseHiddenBase: boolean = false,
): IThunkActionCreator {
  return dispatch => {
    const { seo, queryString, fullUrl } = offersData;
    const { path: newPath } = getSeparateUrl(fullUrl);
    const query = (seo && seo.query) || queryString;
    const queryPath = `?${query}&${QUERY_PARAM_NAME_PAGE}=${page}`;
    const path = page === 1 ? newPath : `/${PATH_PREFIX_NON_FIRST_PAGE}${queryPath}`;
    dispatch(
      setLocationPage({
        path,
        page,
        canUseHiddenBase,
        jsonQuery,
      }),
    );
  };
}

export function setLocationPage(payload: ISetLocationPageParams): ISetPageAction {
  return {
    payload,
    type: ELocationActions.setPage,
  };
}
