import { descriptorsFactory } from '../../utils/filters';

export const isBlackFridayDescriptor = descriptorsFactory({
  name: 'isBlackFriday',
  clear: ['is_black_friday'],
})(
  {
    form: [
      {
        key: 'is_black_friday',
        type: 'term',
        value: true,
      },
    ],
    label: 'Скидки и акции',
    state: 'On',
  },
  {
    form: [],
    label: 'Скидки и акции',
    state: 'Off',
  },
);
