import { resolveRegionCities } from '../../api/regionCities';
import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import { IApplicationContext } from '../../types/applicationContext';
import { IApplicationState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { setRegionCities } from './setRegionCities';

export function getRegionCities(id: number) {
  return (dispatch: TTypedThunkDispatch, getState: () => IApplicationState, { httpApi }: IApplicationContext) => {
    const subdomain = getState().location.subdomain || 'www';

    return resolveRegionCities(httpApi, id, subdomain).then((regionCitiesData: ISearchCatalogItemSchema[]) => {
      dispatch(setRegionCities(regionCitiesData));
    });
  };
}
