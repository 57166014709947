import { IRegion } from '../../types/regions';
import { setRegion } from './setRegion';
import { setRegionFilter } from './setRegionFilter';
import { selectNewbuildingSuggestion } from './selectNewbuildingSuggestion';
import { setPolygon } from './setPolygon';
import { getOffersCount } from '../offersData';
import { trackingActionSuggestionSelect } from '../analytics';
import { selectGeoSuggestion } from './selectGeoSuggestion';
import { IThunkActionCreator } from '../../types/thunk';
import { ISuggestionGeoItem } from '../../types/suggestions';
import { locationFromCurrentRegion, getRegionInfo, getDisplayName } from './helpers';
import { IGeoCodedSearchDetails } from '../../types/geocode';

export type IRegionInfo = IGeoCodedSearchDetails | undefined;

export const selectGeocodeSuggestion = (item: ISuggestionGeoItem): IThunkActionCreator => {
  return (dispatch, getState) => {
    const state = getState();

    const previousRegionId = state.region.currentRegion.id;
    const regionTermValue = state.jsonQuery.region ? state.jsonQuery.region.value : [];

    const {
      data: { text: textName, details: locationsList = [], regionId, undergroundDetails },
    } = item;

    const lastLocationsListItem = locationsList[locationsList.length - 1];

    const displayName = getDisplayName(lastLocationsListItem, textName);
    const regionInfo: IRegionInfo = getRegionInfo(locationsList);
    const newRegionId = lastLocationsListItem ? lastLocationsListItem.id : regionId;
    const type = lastLocationsListItem ? lastLocationsListItem.geoType.toLowerCase() : 'location';
    const isCity = !!(
      lastLocationsListItem &&
      lastLocationsListItem.locationInfo &&
      lastLocationsListItem.locationInfo.parentId
    );
    const isLocationFromCurrentRegion = locationFromCurrentRegion(regionInfo, regionTermValue, lastLocationsListItem);

    if (regionInfo && regionInfo.locationInfo && !isLocationFromCurrentRegion) {
      const { text, ...regionData } = regionInfo.locationInfo;

      const region: IRegion = {
        ...regionData,
        id: regionId,
        name: text,
        displayName: text,
        fullName: text,
        isCity,
        isArea: false,
      } as IRegion;

      dispatch(setRegion(region, regionInfo.geoType, previousRegionId));
      dispatch(setRegionFilter(regionInfo.id));
      dispatch(selectNewbuildingSuggestion());
    }

    if (lastLocationsListItem && lastLocationsListItem.id === 0 && item.polygon) {
      const polygon = {
        name: displayName,
        coordinates: [...item.polygon.value],
      };
      dispatch(setPolygon(polygon, displayName));

      dispatch(getOffersCount());
      dispatch(trackingActionSuggestionSelect('polygon', 'yandex'));

      return;
    }

    if (
      regionInfo &&
      lastLocationsListItem &&
      !(regionInfo.id === newRegionId && regionInfo.geoType === lastLocationsListItem.geoType)
    ) {
      dispatch(trackingActionSuggestionSelect(regionInfo.geoType.toLowerCase(), 'yandex'));
      dispatch(
        selectGeoSuggestion(
          locationsList.length > 1 ? { value: { id: newRegionId, type }, displayName, undergroundDetails } : undefined,
        ),
      );
    }

    dispatch(getOffersCount());

    return;
  };
};
