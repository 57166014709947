import * as React from 'react';
import { ArticleHeading2, ModalWindow } from '@cian/ui-kit';
import { IPromoProp } from '../../types/promoPopup';
import * as styles from './BannerPopup.css';
import { InternalBanner } from './components/InternalBanner';
import { PromoFeatures } from './components/PromoFeatures';

interface IBannerPopupProps {
  description: string;
  onClose(): void;
  open: boolean;
  props: IPromoProp[];
  title: string;
  internalBannerUrl: string | null;
}

export const DiscountMortgageDescriptionPopup: React.FC<IBannerPopupProps> = props => {
  const { description, onClose, open, props: propsList, title, internalBannerUrl } = props;

  return (
    <ModalWindow open={open} onClose={onClose} width="100%" maxWidth={648}>
      <div className={styles['container']} data-testid="BannerPopup">
        <div className={styles['title']}>
          <ArticleHeading2 as="h2">{title}</ArticleHeading2>
        </div>
        {propsList.length > 0 && (
          <div className={styles['features-list']}>
            <PromoFeatures features={propsList} />
          </div>
        )}
        {description && <p className={styles['description']}>{description}</p>}
        {internalBannerUrl && (
          <div className={styles['internal-banner']}>
            <InternalBanner url={internalBannerUrl} />
          </div>
        )}
      </div>
    </ModalWindow>
  );
};
