import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../../types/interfaces';
import { IBrokerAnalyticsUser } from '../../../../types/broker';

export const trackBannerShown = (sc: IJsonQuery, user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'Show_sopr',
    label: 'BrokerDealBanner',
    sc,
    user,
  });
};
