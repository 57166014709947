import * as React from 'react';

import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { InputsMultiple } from '../../components/InputsMultiple';
import { totalAreaInputDescriptors } from '../../constants/descriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';
import { debounce } from '@cian/newbuilding-utils';
import { DEFAULT_INPUT_TIMEOUT } from '../../constants/timeouts';

export interface ITotalAreaInputProps extends IDescriptorProps<number> {
  getOffersCount(): void;
}

class TotalAreaInputsComponent extends React.Component<ITotalAreaInputProps> {
  public getOffersCount = debounce(DEFAULT_INPUT_TIMEOUT, this.props.getOffersCount);

  public shouldComponentUpdate(nextProps: ITotalAreaInputProps) {
    const { gte: nextGte, lte: nextLte } = this.getValue(nextProps);
    const { gte, lte } = this.getValue(this.props);

    return nextGte !== gte || nextLte !== lte;
  }

  public getValue = ({ jsonQuery: { total_area } }: ITotalAreaInputProps) => {
    const gte = total_area && total_area.value ? total_area.value.gte : '';
    const lte = total_area && total_area.value ? total_area.value.lte : '';

    return { gte, lte };
  };

  public render() {
    const { actionCreator, descriptors } = this.props;
    const { gte, lte } = this.getValue(this.props);
    const action = (type: 'gte' | 'lte') => (value: string) => {
      actionCreator(descriptors[0])(parseFloat(value), type);
      this.getOffersCount();
    };

    return (
      <InputsMultiple
        label="общая"
        stack={[
          {
            placeholder: 'от',
            ['aria-label']: 'Минимальная общая площадь квартиры',
            value: gte,
            onChangeValue: action('gte'),
          },
          {
            placeholder: 'до',
            ['aria-label']: 'Максимальная общая площадь квартиры',
            value: lte,
            onChangeValue: action('lte'),
          },
        ]}
      />
    );
  }
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const TotalAreaInput = connectToDescriptors(
  totalAreaInputDescriptors,
  mapDispatchToProps,
)(TotalAreaInputsComponent);
