import { Reducer } from 'redux';
import { EDistrictActions, IDistrictActions } from '../../actions/region/districts';
import { IDistrict } from '../../types/regions';

export type TDistrictsState = Array<IDistrict>;

export const districts: Reducer<TDistrictsState> = (
  state: TDistrictsState = [],
  action: IDistrictActions,
): TDistrictsState => {
  switch (action.type) {
    case EDistrictActions.init:
      return action.data;
    default:
      return state;
  }
};
