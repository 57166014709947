import { descriptorsFactory } from '../../utils/filters';

export const houseTypeDescriptor = descriptorsFactory({
  clear: [],
  name: 'HouseType',
})(
  {
    form: [{ key: 'house_type', type: 'terms', value: ['brick'] }],
    label: 'Кирпичный',
    state: 'brick',
  },
  {
    form: [{ key: 'house_type', type: 'terms', value: ['monolith'] }],
    label: 'Монолитный',
    state: 'monolith',
  },
  {
    form: [{ key: 'house_type', type: 'terms', value: ['brickMonolithic'] }],
    label: 'Кирпично-монолитный',
    state: 'brickMonolithic',
  },
  {
    form: [{ key: 'house_type', type: 'terms', value: ['panel'] }],
    label: 'Панельный',
    state: 'panel',
  },
);
