import * as React from 'react';

import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { InputsMultiple } from '../../components/InputsMultiple';
import { livingAreaInputDescriptors } from '../../constants/descriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';
import { debounce } from '@cian/newbuilding-utils';
import { DEFAULT_INPUT_TIMEOUT } from '../../constants/timeouts';

export interface ILivingAreaInputsProps extends IDescriptorProps<number> {
  getOffersCount(): void;
}

class LivingAreaInputsComponent extends React.Component<ILivingAreaInputsProps> {
  public getOffersCount = debounce(DEFAULT_INPUT_TIMEOUT, this.props.getOffersCount);

  public shouldComponentUpdate(nextProps: ILivingAreaInputsProps) {
    const { gte: nextGte, lte: nextLte } = this.getValue(nextProps);
    const { gte, lte } = this.getValue(this.props);

    return nextGte !== gte || nextLte !== lte;
  }

  public getValue = ({ jsonQuery: { living_area } }: ILivingAreaInputsProps) => {
    const gte = living_area && living_area.value ? living_area.value.gte : '';
    const lte = living_area && living_area.value ? living_area.value.lte : '';

    return { gte, lte };
  };

  public render() {
    const { actionCreator, descriptors } = this.props;
    const { gte, lte } = this.getValue(this.props);
    const action = (type: 'gte' | 'lte') => (value: string) => {
      actionCreator(descriptors[0])(parseFloat(value), type);
      this.getOffersCount();
    };

    return (
      <InputsMultiple
        label="жилая"
        stack={[
          {
            placeholder: 'от',
            ['aria-label']: 'Минимальная жилая площадь квартиры',
            value: gte,
            onChangeValue: action('gte'),
          },
          {
            placeholder: 'до',
            ['aria-label']: 'Максимальная жилая площадь квартиры',
            value: lte,
            onChangeValue: action('lte'),
          },
        ]}
      />
    );
  }
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const LivingAreaInput = connectToDescriptors(
  livingAreaInputDescriptors,
  mapDispatchToProps,
)(LivingAreaInputsComponent);
