import * as React from 'react';
import { Label } from '@cian/ui-kit';
import * as styles from './PromoSnippetLabels.css';
import { ICardLabel } from '../../types/newbuilding';
import { CardLabelList } from '../CardLabelList';

interface IPromoSnippetLabelsProps {
  isSalesLeader: boolean;
  labels: ICardLabel[] | null;
  hasProblem: boolean;
  url: string;
  id: number;
}

export function PromoSnippetLabels({ isSalesLeader, labels, hasProblem, url, id }: IPromoSnippetLabelsProps) {
  return (
    <>
      {labels && <CardLabelList labels={labels} hasProblem={hasProblem} url={url} id={id} />}
      {isSalesLeader && (
        <span className={styles.label} data-testid="salesLeader">
          <Label background="gray10_100" color="black_100">
            Лидер продаж
          </Label>
        </span>
      )}
    </>
  );
}
