import * as React from 'react';
import { debounce } from '@cian/newbuilding-utils';
import { NumberInput } from '@cian/ui-kit';

import { undergroundWalkTimeDescriptors } from '../../constants/descriptors';
import { connectToDescriptors, IDescriptorProps, IJQDescription } from '../сonnectToDescriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { clearJsonQueryAction } from '../../actions/filters';
import { getOffersCount } from '../../actions/offersData';
import { Container } from '../../components/Container';
import { Text } from '../../components/Text';
import { SIZE_80, SIZE_8 } from '../../constants/sizes';
import { DEFAULT_INPUT_TIMEOUT } from '../../constants/timeouts';

export interface IUndergroundWalkTimeInputProps extends IDescriptorProps<number> {
  getOffersCount(): void;
  clearJsonQuery(value: IJQDescription['value']): void;
}

class UndergroundWalkTimeInputComponent extends React.Component<IUndergroundWalkTimeInputProps> {
  public getOffersCount = debounce(DEFAULT_INPUT_TIMEOUT, this.props.getOffersCount);

  public shouldComponentUpdate({
    jsonQuery: { underground_walk_time: nextUndergroundWalkTime },
  }: IUndergroundWalkTimeInputProps) {
    const {
      jsonQuery: { underground_walk_time },
    } = this.props;
    /* istanbul ignore next */
    const value = underground_walk_time && underground_walk_time.value ? underground_walk_time.value.lte : '';
    /* istanbul ignore next */
    const nextValue = nextUndergroundWalkTime && nextUndergroundWalkTime.value ? nextUndergroundWalkTime.value.lte : '';

    return nextValue !== value;
  }

  public render() {
    const {
      actionCreator,
      descriptors,
      jsonQuery: { underground_walk_time },
    } = this.props;
    const lte = underground_walk_time && underground_walk_time.value ? underground_walk_time.value.lte : '';

    const handleChange = (type: 'lte') => (inputValue: number | undefined) => {
      const value = inputValue || '';

      if (value) {
        actionCreator(descriptors[0])(value, type);
      } else {
        this.props.clearJsonQuery(value);
      }

      this.getOffersCount();
    };

    return (
      <Container display="flex" alignItems="center">
        <Text>Не более: </Text>

        <Container padding={`0 ${SIZE_8} 0 ${SIZE_8}`} width={SIZE_80}>
          <NumberInput aria-label="Время пешком до метро" onChange={handleChange('lte')} value={lte as number} />
        </Container>
        <Text>мин.</Text>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
    clearJsonQuery: (value: IJQDescription['value']) => {
      dispatch(clearJsonQueryAction('underground_walk_time', value));
    },
  };
}

export const UndergroundWalkTimeInput = connectToDescriptors(
  undergroundWalkTimeDescriptors,
  mapDispatchToProps,
)(UndergroundWalkTimeInputComponent);
