import * as React from 'react';
import { RadioButtonGroup } from '@cian/ui-kit';
import { IQuickLinksTab } from '../../types/quickLinks';

interface IMoreButtonProps {
  tabs: IQuickLinksTab[];
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: number) => void;
}

export const Tabs = ({ tabs, value, onChange }: IMoreButtonProps) => {
  const options = React.useMemo(
    () =>
      tabs.map((tab, index) => ({
        label: tab.title,
        value: index,
      })),
    [tabs],
  );

  return (
    <div data-testid="QuickLinksTabs">
      <RadioButtonGroup spliced options={options} value={value} onChange={onChange} />
    </div>
  );
};
