import * as React from 'react';
import * as styles from './CarouselBlock.css';
import { clsx } from 'clsx';

export interface ICarouselBlockProps extends React.PropsWithChildren {
  logo?: React.ReactNode;
  features?: React.ReactNode;
  isPromo?: boolean;
  labels: React.ReactNode;
}

export const CarouselBlock: React.FC<ICarouselBlockProps> = ({ children, features, isPromo, logo, labels }) => {
  return (
    <div className={clsx(styles['container'], isPromo && styles['is-promo'])}>
      {children}
      {features && <div className={styles['features']}>{features}</div>}
      {logo && <div className={styles['logo']}>{logo}</div>}
      {labels}
    </div>
  );
};
