import { ca } from '@cian/analytics';
import { IJsonQuery } from '../../../../types/interfaces';
import { IBrokerAnalyticsUser } from '../../../../types/broker';

export const trackSellApartmentClick = (sc: IJsonQuery, user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BrokerDeal',
    action: 'Click',
    label: 'Button',
    sc,
    page: {
      pageType: 'ListingJK',
      siteType: 'desktop',
    },
    user,
    useLastProducts: true,
  });
};
