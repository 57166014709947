/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRegionsModel,
  IGetRegionsRequest,
  IMappers,
  IGetRegionsResponse,
  IGetRegionsResponseError,
  TGetRegionsResponse,
} from './types';

const defaultConfig: TGetRegionsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-regions/',
  hostType: 'api',
} as TGetRegionsModel;

function createGetRegionsModel(parameters: IGetRegionsRequest): TGetRegionsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRegionsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRegionsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRegions<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRegionsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetRegionsResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetRegionsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRegionsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRegionsResponseError);
    }
  }

  return { response, statusCode } as TGetRegionsResponse;
}

export { defaultConfig, createGetRegionsModel, fetchGetRegions };
