import { IThunkActionCreator } from '../../types/thunk';
import { createSimpleAction } from '../../containers/сonnectToDescriptors';
import { builderDescriptor } from '../../constants/descriptors';
import { addTag } from './addTag';

export const selectBuilderSuggestion = (value?: number, displayName?: string): IThunkActionCreator => {
  return dispatch => {
    createSimpleAction(dispatch, builderDescriptor[value === undefined ? 1 : 0])(value);
    dispatch(addTag('builders', value, displayName));
  };
};
