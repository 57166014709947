import { EUserActions } from '../../actions/user';
import { TUser } from '../../types/user';

export interface IFetchUserData {
  type: EUserActions.fetchUserDataSuccess;
  user: TUser;
}

export interface IFetchUserDataError {
  type: EUserActions.fetchuserDataError;
}

export type TUserState = TUser;

const initialState: TUserState = {
  isAuthenticated: false,
};

export function user(state: TUser = initialState, action: IFetchUserData | IFetchUserDataError) {
  switch (action.type) {
    case EUserActions.fetchUserDataSuccess:
      return {
        ...state,
        ...action.user,
      };
    case EUserActions.fetchuserDataError:
      return state;
    default:
      return state;
  }
}
