export enum EOffersCountActions {
  get = 'filters/count/get',
  set = 'filters/count/set',
  clean = 'filters/count/clear',
}

export interface IBaseOffersCountAction {
  type: EOffersCountActions;
}

export interface IFilterOffersCountGetAction extends IBaseOffersCountAction {
  type: EOffersCountActions.get;
}

export interface IFilterOffersCountSetAction extends IBaseOffersCountAction {
  type: EOffersCountActions.set;
  count: number | null;
}

export interface IFilterOffersCountCleanAction extends IBaseOffersCountAction {
  type: EOffersCountActions.clean;
}

export interface IStateOffersCount {
  count: number | null;
  isFetching: boolean;
}

export function cleanOffersCount(): IFilterOffersCountCleanAction {
  return {
    type: EOffersCountActions.clean,
  };
}

export function setOffersCount(count: number | null): IFilterOffersCountSetAction {
  return {
    type: EOffersCountActions.set,
    count,
  };
}

export function loadOffersCount(): IFilterOffersCountGetAction {
  return {
    type: EOffersCountActions.get,
  };
}
