import { descriptorsFactory } from '../../utils/filters';

export const priceTypeDescriptors = descriptorsFactory({ name: 'PriceType', clear: ['price_sm'] })(
  {
    form: [{ key: 'price_sm', type: 'term', value: true }],
    label: `за м²`,
    state: 'price_sm_part',
  },
  {
    form: [],
    label: `за все`,
    state: 'price_sm_all',
  },
);
