import * as React from 'react';
import { ISeller, IBuilder } from '../../types/newbuilding';
import { ShowPhoneButton } from '../ShowPhoneButton';

interface IShowPhoneButtonContainerProps {
  builders?: IBuilder[];
  sellers?: ISeller[] | null;
  onShowPhonesClick(): void;
  onPhoneClick?(): void;
  newbuildingId: number;
}

export function ShowPhoneButtonContainer(props: IShowPhoneButtonContainerProps) {
  let { builders, sellers, newbuildingId } = props;
  const { onShowPhonesClick, onPhoneClick } = props;
  sellers = sellers && sellers.filter(({ isBuilder }) => !isBuilder);
  builders = builders && builders.filter(({ phone }) => phone);

  if ((builders && builders.length) || (sellers && sellers.length)) {
    return (
      <ShowPhoneButton
        onShowPhonesClick={onShowPhonesClick}
        onPhoneClick={onPhoneClick}
        builders={builders}
        agents={sellers}
        newbuildingId={newbuildingId}
      />
    );
  }

  return null;
}
