import { TLocationState } from '../../reducers/region/location';

export enum ELocationActions {
  init = 'init/location',
}

export interface ILocationAction {
  type: ELocationActions;
  data: TLocationState;
}

export function initLocation(location: TLocationState): ILocationAction {
  return {
    data: location,
    type: ELocationActions.init,
  };
}
