import * as React from 'react';
import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { IJsonQuery } from '../../utils/filters';
import { getOffersData } from '../../actions/offersData';

import { TTypedThunkDispatch } from '../../types/thunk';
import { Container } from '../../components/Container';
import { SIZE_8, SIZE_24, SIZE_16, SIZE_4, SIZE_36 } from '../../constants/sizes';
import { SearchButton } from '../../components/SearchButton';
import { SelectMultiple } from '../../components/Select';
import { FromDeveloper, FinishDate, PriceInput, PriceType, GeoInput, Rooms } from '../Filters';
import { MoreFiltersModal } from '../Modals/MoreFiltersModal';
import { LocationModal } from '../Modals/LocationModal';
import { trackingActionShowObjectsClick } from '../../actions/analytics';

interface IFiltersStoreProps {
  isCountFetching?: boolean;
  count: number | null;
  jsonQuery?: IJsonQuery;
  isMobile: boolean;
  canUseHiddenBase: boolean;
}

interface IFiltersDispatchProps {
  onSubmit(canUseHiddenBase: boolean): void;
}

type TFiltersProps = IFiltersStoreProps & IFiltersDispatchProps;

class Filters extends React.PureComponent<TFiltersProps> {
  public render() {
    const { count, isCountFetching, isMobile = false } = this.props;
    const filtersRightMargin = isMobile ? SIZE_8 : SIZE_24;

    return (
      <Container dataMark="Filters">
        <form onSubmit={this.handleSubmit}>
          <Container display="flex">
            <Container margin={`0 ${filtersRightMargin} 0 0`}>
              <SelectMultiple>
                <Rooms />
                <FinishDate />
              </SelectMultiple>
            </Container>
            <Container margin={`0 ${filtersRightMargin} 0 0`}>
              <MoreFiltersModal />
            </Container>
            <Container width="266px" margin={`0 ${SIZE_8} 0 0`}>
              <PriceInput />
            </Container>
            <Container margin={`${SIZE_4} ${filtersRightMargin} 0 0`} width={SIZE_36}>
              <PriceType />
            </Container>
            <FromDeveloper />
          </Container>
          <Container margin={`${SIZE_16} 0 0 0`} display="flex">
            <Container margin={`0 ${filtersRightMargin} 0 0`}>
              <LocationModal />
            </Container>
            <Container width="400px" margin={`0 ${filtersRightMargin} 0 0`}>
              <GeoInput />
            </Container>
            <SearchButton onClick={this.handleSubmit} count={count} loading={isCountFetching} />
          </Container>
        </form>
      </Container>
    );
  }

  private handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.props.onSubmit(this.props.canUseHiddenBase);
  };
}

function mapStateToProps(state: IApplicationState): IFiltersStoreProps {
  return {
    count: state.filters.offersCount.count,
    isCountFetching: state.filters.offersCount.isFetching,
    jsonQuery: state.jsonQuery,
    isMobile: state.media.mobile,
    canUseHiddenBase: state.user.isAuthenticated && state.user.permissions.canUseHiddenBase,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    onSubmit: (canUseHiddenBase: boolean) => {
      dispatch(
        getOffersData({
          trackingAction: trackingActionShowObjectsClick,
          canUseHiddenBase,
        }),
      );
    },
  };
}

export const FiltersContainer = connect(mapStateToProps, mapDispatchToProps)(Filters);
