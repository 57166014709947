import { ca } from '@cian/analytics';
import { IAnalyticsUser, IProduct } from '../../../types/analytics';

export interface IPage {
  pageType: string;
  siteType: string;
  extra: {
    type: string;
    rating: number | null;
  };
}

export function trackReviewLinkAnalyticClick({
  user,
  page,
  product,
}: {
  user: IAnalyticsUser;
  page: IPage;
  product: IProduct;
}) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click_sopr',
    category: 'Listing',
    label: 'Newbuilding_Rating',
    user,
    page,
    products: [product],
  });
}
