export enum EUpcomingSale {
  OpenUpcomingSalePopup = 'upcomingSale/openUpcomingSalePopup',
  SetNewbuildingId = 'upcomingSale/setNewbuildingId',
  SetNewbuildingGaLabel = 'upcomingSale/setNewbuildingGaLabel',
}

export interface IOpenUpcomingSalePopupAction {
  type: EUpcomingSale.OpenUpcomingSalePopup;
  payload: boolean;
}

export function openUpcomingSalePopup(open: boolean) {
  return {
    type: EUpcomingSale.OpenUpcomingSalePopup,
    payload: open,
  };
}

export interface ISetNewbuildingIdAction {
  type: EUpcomingSale.SetNewbuildingId;
  payload: number | null;
}

export function setNewbuildingId(newbuildingId: number | null) {
  return {
    type: EUpcomingSale.SetNewbuildingId,
    payload: newbuildingId,
  };
}

export interface ISetNewbuildingGaLabelAction {
  type: EUpcomingSale.SetNewbuildingGaLabel;
  payload: string | null;
}

export function setNewbuildingGaLabel(gaLabel: string | null) {
  return {
    type: EUpcomingSale.SetNewbuildingGaLabel,
    payload: gaLabel,
  };
}

export type TUpcomingSaleActions =
  | IOpenUpcomingSalePopupAction
  | ISetNewbuildingIdAction
  | ISetNewbuildingGaLabelAction;
