import { IThunkActionCreator } from '../../types/thunk';
import { addNewbuildingToFavorites, removeNewbuildingFromFavorites } from '../../api/favorite';

export interface IUpdateFavoriteParams {
  status: boolean;
  newbuildingId: number;
}

export interface IUpdateFavoriteResolve {
  status: boolean;
}

export const FAVORITES_LIMIT_FOR_UNAUTH_USER = 5;

export function updateFavorite(params: IUpdateFavoriteParams): IThunkActionCreator<Promise<IUpdateFavoriteResolve>> {
  return async (dispatch, getState, { httpApi }) => {
    const { user } = getState();

    const { waitForLoginPopup, __header_updateFavorites__, __header_favorites_count__ = 0 } = window;

    if (__header_favorites_count__ < 0) {
      throw new Error('Значение должно быть положительно');
    }

    const { status, newbuildingId } = params;

    if (
      status &&
      !user.isAuthenticated &&
      __header_favorites_count__ >= FAVORITES_LIMIT_FOR_UNAUTH_USER &&
      waitForLoginPopup
    ) {
      waitForLoginPopup('favorites');

      throw new Error('Дождитесь закрытия попапа');
    }

    try {
      if (status) {
        await addNewbuildingToFavorites({ httpApi, newbuildingId });
      } else {
        await removeNewbuildingFromFavorites({ httpApi, newbuildingId });
      }
    } catch (err) {
      return { status: !status };
    }

    window.__header_favorites_count__ = __header_favorites_count__ + (status ? 1 : -1);

    if (__header_updateFavorites__) {
      __header_updateFavorites__(status);
    }

    return { status };
  };
}
