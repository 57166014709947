import { TTypedThunkDispatch } from '../../types/thunk';
import { IApplicationState } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';
import { fetchGetNewbuildingsForSerpService } from '../../services/fetchGetNewbuildingsForSerpService';
import { noop } from '@cian/newbuilding-utils';
import { IGetNewbuildingsInfoForTgbRequest } from '../../repositories/newbuilding-search/v1/get-newbuildings-info-for-tgb';
import { setNewbuildingsInfoForTgb } from './setNewbuildingsInfoForTgb';
import { INewbuildingInfoForTgbSchema } from '../../repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';
import { selectNewbuidingsInfoForTgb } from '../../selectors/infoForTgb/selectNewbuidingsInfoForTgb';

export function fetchNewbuildingsInfoForTgb(parameters: IGetNewbuildingsInfoForTgbRequest) {
  return (
    dispatch: TTypedThunkDispatch,
    getState: () => IApplicationState,
    applicationContext: IApplicationContext,
  ) => {
    return fetchGetNewbuildingsForSerpService(applicationContext, parameters).then(
      data => {
        const state = getState();

        const newbuildingInfoForTgb = selectNewbuidingsInfoForTgb(state);

        const payload = data.newbuildings.reduce<Record<number, INewbuildingInfoForTgbSchema>>(
          (accumulator, newbuilding, index) => {
            const newbuildingId = parameters.newbuildingIds[index];

            accumulator[newbuildingId] = newbuilding;

            return accumulator;
          },
          { ...newbuildingInfoForTgb },
        );

        dispatch(setNewbuildingsInfoForTgb(payload));
      },
      /**
       * @description Не требуется особых действий, так как в сервисе залогировали поведение,
       * ретраи не требуются и в сама ошибка в сторе нам на данном этапе не требуется
       */
      noop,
    );
  };
}
