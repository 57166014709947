import { MAX_ADDRESS_SUGGEST_ELEMENTS, MAX_GEO_SUGGEST_ELEMENTS } from '../../../constants/config';
import { ISuggestData, TBoundedBy } from '../types';
import { requestAddressSuggestions } from './requestAddressSuggestions';
import { requestGeoSuggestions } from './requestGeoSuggestions';

export interface IRequestSuggestionsParams {
  boundedBy?: TBoundedBy;
  locationId: number;
  term: string;
}

export const requestSuggestions = async ({
  boundedBy,
  locationId,
  term,
}: IRequestSuggestionsParams): Promise<ISuggestData> => {
  const [geoSuggestions, address] = await Promise.all([
    requestGeoSuggestions(term, locationId),
    requestAddressSuggestions(term, boundedBy),
  ]);

  return {
    address: address.slice(0, MAX_ADDRESS_SUGGEST_ELEMENTS),
    builders: geoSuggestions.builders.slice(0, MAX_GEO_SUGGEST_ELEMENTS),
    districts: geoSuggestions.districts.slice(0, MAX_GEO_SUGGEST_ELEMENTS),
    newbuildings: geoSuggestions.newbuildings.slice(0, MAX_GEO_SUGGEST_ELEMENTS),
    undergrounds: geoSuggestions.undergrounds.slice(0, MAX_GEO_SUGGEST_ELEMENTS),
  };
};
