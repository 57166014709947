import { IRegionsByAlphabet } from '../getRegionsByAlphabet';
import { IRegion } from '../../types/regions';

const getBalancedResult = (array: IRegion[][][]): IRegion[][][] => {
  const result = array;

  // TO DO Балансировать результаты

  return result;
};

export function getRegionsColumns(regionsByAlphabet: IRegionsByAlphabet, regionsLength: number): IRegion[][][] {
  const result: IRegion[][][] = [[], [], [], []];
  const TITLE_MOD = 3;
  const LINE_MOD = 2;
  const columnLength = 4;
  const maxColumnLength = Math.ceil(
    (regionsLength * LINE_MOD + TITLE_MOD * regionsByAlphabet.order.length) / columnLength,
  );
  const columnsLengthArray = [0, 0, 0, 0];
  let index = 0;

  regionsByAlphabet.order.forEach((symbol: string) => {
    const overLength = index + 1 >= columnLength;
    const mod = result[index].length * TITLE_MOD;
    const isNext = columnsLengthArray[index] * LINE_MOD + mod + regionsByAlphabet[symbol].length > maxColumnLength;

    if (isNext) {
      columnsLengthArray[index] = columnsLengthArray[index] + mod;
      index = overLength ? 3 : index + 1;
    }

    result[index].push(regionsByAlphabet[symbol] as IRegion[]);
    columnsLengthArray[index] += regionsByAlphabet[symbol].length;
  });

  return getBalancedResult(result);
}
