import { IConfigState } from '../../types/redux/config';
import { TReduxActions } from '../../types/redux';
import { EPromoSnippetActions } from '../../actions/promoSnippet';

export const defaultState: IConfigState = {
  isPromoSnippetEnabled: true,
  isPromoSnippetDisplayed: false,
  isDiscountMortgageEnabled: false,
  isDiscountMortgageBannerEnabled: false,
  isPromoSearchTypesEnabled: false,
  newbuildingBlackFriday: {
    isActive: false,
  },
};

export const configReducer = (state: IConfigState = defaultState, action: TReduxActions) => {
  switch (action.type) {
    case EPromoSnippetActions.setPromoSnippetVisibility:
      return {
        ...state,
        isPromoSnippetDisplayed: action.payload,
      };
    default:
      return state;
  }
};
