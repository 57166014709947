import { AnyAction } from 'redux';
import { defaultRange, descriptorsFactory, IJsonQuery } from '../../utils/filters';

const modifyValue = ({ total_area = defaultRange }: IJsonQuery, action: AnyAction) => {
  return action ? Object.assign({}, total_area.value, { [action.subType]: action.value }) : total_area.value;
};

export const totalAreaInputDescriptors = descriptorsFactory({ name: 'TotalArea', clear: [] })({
  form: [{ key: 'total_area', type: 'range', value: modifyValue }],
  label: '',
  state: 'total_area',
});
