import * as React from 'react';
import { Chips } from '@cian/ui-kit';

import { penthouseTypeDescriptor } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

export interface IPenthouseTypeProps extends IDescriptorProps<string> {
  getOffersCount(): void;
}

const OPTIONS = [{ label: 'Пентхаус', value: true }];

class PenthouseTypeComponent extends React.Component<IPenthouseTypeProps> {
  public shouldComponentUpdate(nextProps: IPenthouseTypeProps) {
    const { jsonQuery, descriptors } = this.props;
    const nextActiveDescriptor = nextProps.descriptors.find(descriptor =>
      isDescriptorActive(descriptor)(nextProps.jsonQuery),
    );
    const activeDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));

    /* istanbul ignore next */
    const nextValue = nextActiveDescriptor ? nextActiveDescriptor.state : undefined;
    /* istanbul ignore next */
    const value = activeDescriptor ? activeDescriptor.state : undefined;

    return nextValue !== value;
  }

  public render() {
    const { jsonQuery, descriptors } = this.props;

    const activeDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
    const value = activeDescriptor?.state === 'On' ? [true] : [];

    return <Chips options={OPTIONS} size="S" value={value} onChange={this.handleChange} />;
  }

  private handleChange = (newValue: boolean[]) => {
    const checked = Boolean(newValue.length);

    const descriptor = this.props.descriptors.find(d => {
      return (d.state === 'On') === checked;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.props.actionCreator(descriptor!)();
    this.props.getOffersCount();
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const PenthouseType = connectToDescriptors(penthouseTypeDescriptor, mapDispatchToProps)(PenthouseTypeComponent);
