import { IJsonQuery } from '../../types/jsonQuery';
import { TransportTypes } from '../../types/TransportTypes';

export function jsonRegionIdToTransportType(jsonQuery: IJsonQuery): TransportTypes | null {
  const regionId = jsonQuery.region?.value[0] ?? 1;

  switch (regionId) {
    case -2:
    case 2:
    case 4588:
      return 'spb';

    case -1:
    case 1:
    case 4593:
      return 'mow';
    default:
      return null;
  }
}
