import * as React from 'react';
import jump from 'jump.js';

import { Container } from '../../components/Container';
import { Link } from '../../components/Link';
import { Text } from '../../components/Text/';
import { SIZE_8 } from '../../constants/sizes';

export interface IPaginationOnClickParams {
  page: number;
  url: string;
}

interface IPaginationProps {
  currentPage: number;
  maxPage: number;
  dataMark?: string;
  urlCreator(page: number): string;
  onClick?(params: IPaginationOnClickParams): void;
}

const LEFT_PAGES = 2;
const VISIBLE_SHOULDER_PAGES = 5;

export class Pagination extends React.PureComponent<IPaginationProps> {
  public render() {
    const arrayOfPages = this.getArrayOfPages();

    return (
      <Container
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        dataMark={'Pagination' || this.props.dataMark}
      >
        {arrayOfPages.map((pageNumber: number) => (
          <Container
            key={pageNumber}
            padding={`1px 7px`}
            margin={`0 ${SIZE_8} 0 0`}
            borderStyle="solid"
            borderColor={this.isCurrentPage(pageNumber) ? 'blue' : 'transparent'}
            borderTopWidth={0}
            borderRightWidth={0}
            borderLeftWidth={0}
            borderBottomWidth={3}
          >
            {this.isCurrentPage(pageNumber) ? this.getText(pageNumber) : this.getLink(pageNumber)}
          </Container>
        ))}
      </Container>
    );
  }

  private isCurrentPage = (pageNumber: number) => pageNumber === this.props.currentPage;

  private isExternalPage = (pageNumber: number): boolean => {
    const { currentPage, maxPage } = this.props;
    const isFirst = pageNumber <= LEFT_PAGES;
    const isLast = pageNumber === maxPage;
    const isVisible = isFirst || isLast;
    const isExternal = Math.abs(currentPage - pageNumber) === VISIBLE_SHOULDER_PAGES;

    return !isVisible && isExternal;
  };

  private getArrayOfPages = (): number[] => {
    const { currentPage, maxPage } = this.props;

    let prev: number[] = [];
    if (currentPage > LEFT_PAGES) {
      const arrLength =
        currentPage > VISIBLE_SHOULDER_PAGES + LEFT_PAGES ? VISIBLE_SHOULDER_PAGES : currentPage - LEFT_PAGES;
      prev = [...Array(arrLength)].map((item, index) => currentPage - index - 1).reverse();
    }

    let current: number[] = [];
    if (currentPage >= LEFT_PAGES && currentPage <= maxPage) {
      current = [currentPage];
    }

    let next: number[] = [];
    if (currentPage < maxPage) {
      const arrLength = maxPage - currentPage > VISIBLE_SHOULDER_PAGES ? VISIBLE_SHOULDER_PAGES : maxPage - currentPage;
      next = [...Array(arrLength)].map((item, index) => currentPage + index + 1);
    }

    const first = [1];

    return first.concat(prev, current, next);
  };

  private getLink = (pageNumber: number) => {
    const { urlCreator } = this.props;

    const url = urlCreator(pageNumber);
    const onClickParams = {
      page: pageNumber,
      url,
    };

    return (
      <Link url={url} onClick={() => this.handleClick(onClickParams)} notChangeLocation>
        {this.getText(pageNumber)}
      </Link>
    );
  };

  private getText = (pageNumber: number) => (
    <Text
      color={this.isCurrentPage(pageNumber) ? 'black' : 'blue'}
      colorHovered={this.isCurrentPage(pageNumber) ? 'black' : 'red'}
      fontSize={16}
      lineHeight={24}
    >
      {this.isExternalPage(pageNumber) ? '…' : pageNumber}
    </Text>
  );

  private handleClick = (params: IPaginationOnClickParams) => {
    const { onClick } = this.props;

    if (onClick) {
      jump(-window.scrollY, { duration: 300 });
      onClick(params);
    }
  };
}
