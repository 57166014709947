import * as React from 'react';
import { Checkbox } from '@cian/ui-kit';

import { hasMortgageDesdcriptor } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { updateTags } from '../../actions/filterTags';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

interface IHasMortgageDispatchProps {
  updateTags(): {};
  getOffersCount(): void;
}

type IHasMortgageOwnProps = IDescriptorProps<string>;

type THasMortgage = IHasMortgageOwnProps & IHasMortgageDispatchProps;

function HasMortgageComponent({ actionCreator, jsonQuery, descriptors, updateTags, getOffersCount }: THasMortgage) {
  const activeDescriptor = getActiveDescriptor({ descriptors, jsonQuery });
  const isActive = activeDescriptor.state === 'On';
  const onClick = () => {
    actionCreator(isActive ? descriptors[1] : descriptors[0])();
    updateTags();
    getOffersCount();
  };

  return <Checkbox label={activeDescriptor.label} checked={isActive} onChange={onClick} />;
}

function getActiveDescriptor({ jsonQuery, descriptors }: Pick<IHasMortgageOwnProps, 'jsonQuery' | 'descriptors'>) {
  const selectedDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
  if (!selectedDescriptor) {
    return descriptors[1];
  }

  return selectedDescriptor;
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    updateTags: () => {
      dispatch(updateTags());
    },
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const HasMortgage = connectToDescriptors(hasMortgageDesdcriptor, mapDispatchToProps)(HasMortgageComponent);
