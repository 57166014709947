import { getEventBusIfDefined } from '@cian/event-bus';
import { IRegion } from '../../types/regions';
import {
  LO_ID,
  MOSCOW_AND_MOSCOW_AREA_ID,
  MOSCOW_AREA_ID,
  MOSCOW_ID,
  SPB_AND_LO_ID,
  SPB_ID,
} from '../../constants/geo';
import { HEADER_REGION_CHANGED } from '../../constants/events';

// Событие для смены ссылки на спецпроект в хедере.
// Передаем id региона, для Москвы и СПБ берем области
export function triggerHeaderRegionChanged(region: IRegion) {
  const eventBus = getEventBusIfDefined();
  if (!eventBus) {
    return;
  }

  let regionId: number;

  if (MOSCOW_AND_MOSCOW_AREA_ID === region.id) {
    regionId = MOSCOW_AREA_ID;
  } else if (SPB_AND_LO_ID === region.id) {
    regionId = LO_ID;
  } else if ([MOSCOW_ID, SPB_ID].includes(region.id)) {
    regionId = region.id;
  } else {
    regionId = region.parentId || region.id;
  }

  eventBus.emit(HEADER_REGION_CHANGED, { type: String(regionId) });
}
