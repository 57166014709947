export const DEFAULT_OFFERS_DATA = {
  breadcrumbs: [],
  newbuildings: [],
  similarNewbuildings: [],
  seo: null,
  fullUrl: '',
  users: [],
  builder: null,
  builderStats: null,
  newbuildingBuilderStats: [],
  total: 0,
  quickLinks: null,
  queryString: '',
  mapFullUrl: '',
  salesLeaderFullUrl: '',
  analytics: {
    mlModelVersion: null,
    mlSearchGuid: null,
  },
  newbuildingIdsForBuildersPromoSlider: [],
};
