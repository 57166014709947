import { descriptorsFactory } from '../../utils/filters';

export const isSalesLeaderDescriptor = descriptorsFactory({
  name: 'isSalesLeader',
  clear: ['is_sales_leader'],
})(
  {
    form: [
      {
        key: 'is_sales_leader',
        type: 'term',
        value: true,
      },
    ],
    label: 'Лидер продаж',
    state: 'On',
  },
  {
    form: [],
    label: 'Лидер продаж',
    state: 'Off',
  },
);
