import loadable from '@loadable/component';

export const NewbuildingChatContainerLoadable = loadable(() => {
  if (process.env.IS_BROWSER_BUILD) {
    return import('./NewbuildingChat');
  }

  return Promise.resolve({
    default: () => null,
  });
});
