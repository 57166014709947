import { ELocationActions, ILocationAction } from '../../actions/region/location';
import { IRegion } from '../../types/region';

export type TLocationState = Partial<IRegion>;

export const location = (state: TLocationState = {}, action: ILocationAction) => {
  switch (action.type) {
    case ELocationActions.init:
      return action.data;
    default:
      return state;
  }
};
