import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const isBlackFridayDefinition = definitionFactory({
  key: 'is_black_friday',
  mapper: jsonQueryValue => {
    return [
      {
        label: 'Скидки и акции: до 29 сентября',
        value: jsonQueryValue,
      },
    ];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'is_black_friday', options);
  },
});
