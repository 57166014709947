import * as React from 'react';
import { BuilderLabel } from '../../components/BuilderLabel';
import { Logo } from '../../components/Logo';
import { SIZE_80, SIZE_20, SIZE_8 } from '../../constants/sizes';
import { Container } from '../../components/Container';
import { Text } from '../../components/Text';

export function CianInfo() {
  return (
    <Container data-testid="CianInfo">
      <Container
        display="flex"
        borderRadius={4}
        padding={SIZE_20}
        borderColor="gray_5"
        borderStyle="solid"
        justifyContent="center"
        alignItems="center"
        borderTopWidth={1}
        borderRightWidth={1}
        borderBottomWidth={1}
        borderLeftWidth={1}
        width={SIZE_80}
        height={SIZE_80}
      >
        <Logo />
      </Container>
      <Container margin={`${SIZE_8} 0 ${SIZE_8} 0`}>
        <Text fontSize={16} lineHeight={22}>
          Циан
        </Text>
      </Container>
      <BuilderLabel text="Консультант" />
    </Container>
  );
}
