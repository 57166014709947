import * as React from 'react';
import { SIZE_12 } from '../../constants/sizes';
import { TextAndIcon } from '../TextAndIcon';
import { Container } from '../Container';
import { IconProductTopSeller16 } from '@cian/ui-kit-design-tokens/icons';

export function SalesLeader() {
  return (
    <Container padding={`0 ${SIZE_12} 0 0`} display="inline-block" dataMark="SalesLeader">
      <TextAndIcon
        icon={<IconProductTopSeller16 color={'warning_100'} />}
        color="orange"
        colorHovered="orange"
        fontWeight="bold"
        offset={6}
        fontSize={12}
        lineHeight={18}
      >
        Лидер продаж
      </TextAndIcon>
    </Container>
  );
}
