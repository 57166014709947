import { ca } from '@cian/analytics';

import { getTrackingParamsForSopr1 } from './helpers';
import { IThunkActionCreator } from '../../types/thunk';

/**
 * В новую ручку СОПРа (site)
 * при применении фильтров и обновлении контента,
 * при пагинации отправлять событие просмотр страницы
 */

export function trackingActionUpdateListing(): IThunkActionCreator {
  return async (dispatch, getState) => {
    ca('pageviewSite', getTrackingParamsForSopr1(getState()));
  };
}
