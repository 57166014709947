import { IJsonQuery, IJQDescription } from '../../utils/filters';

export enum EFiltersActions {
  init = 'jsonQuery/init',
  clear = 'jsonQuery/clear',
}

export interface IInitJsonQuerAction {
  type: EFiltersActions;
  jsonQuery: IJsonQuery;
}

export interface IClearJsonQueryAction {
  type: EFiltersActions.clear;
  key: keyof IJsonQuery;
  value: IJQDescription['value'];
}

export function initJsonQuery(jsonQuery: IJsonQuery): IInitJsonQuerAction {
  return {
    jsonQuery,
    type: EFiltersActions.init,
  };
}

export function clearJsonQueryAction(key: keyof IJsonQuery, value: IJQDescription['value']): IClearJsonQueryAction {
  return {
    type: EFiltersActions.clear,
    key,
    value,
  };
}
