import { IApplicationState } from '../../types/redux';
import { MIN_RATE_REVIEWS_FOR_DISPLAY } from '../../constants/promoSnippet';
import { getNewbuildingReviewStat } from '../../selectors/newbuildingReviewStat';

export function getIsVisiblePromoSnippetReviews(state: IApplicationState) {
  const newbuilding = state.offersData.newbuildingPromoSnippet?.newbuilding;

  if (!newbuilding) {
    return false;
  }

  const { totalRate } = getNewbuildingReviewStat(newbuilding);

  return MIN_RATE_REVIEWS_FOR_DISPLAY <= totalRate;
}
