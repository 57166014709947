import { IRange, ITerm, ITerms } from '../../types/interfaces';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore : Должен быть стринг, но так исторически сложилось
export const defaultTerm: ITerm<string> = { type: 'term', value: undefined };
export const defaultTerms: ITerms<[]> = { type: 'terms', value: [] };
export const defaultRange: IRange = { type: 'range', value: {} };

// Возможные типы oldTerm
// type TAllTerms<T> = IRange | ITerm<T> | ITerms<T> | boolean | string | undefined;
// тип реально any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeTerms<T>(oldTerm: any, value: T | T[], type: string) {
  switch (type) {
    case 'term': {
      const term = (oldTerm || defaultTerm) as ITerm<T>;

      return { ...term, value };
    }
    case 'terms': {
      const terms = (oldTerm || defaultTerms) as ITerms<T>;
      const newValue = terms.value.some(v => (value as T[]).includes(v))
        ? terms.value.filter(v => !(value as T[]).includes(v))
        : terms.value.concat(value);

      return { ...terms, value: newValue };
    }
    case 'plain':
      return value as T;
    case 'range': {
      const range = (oldTerm || defaultRange) as IRange;

      return { ...range, value: { ...range.value, ...(value as {}) } };
    }
    case 'geo':
      return { type: 'geo', ...oldTerm, value };

    default:
      throw new Error('term not implemented');
  }
}
