export enum ENBConsutantActions {
  OpenChat = 'newbuildingConsultant/openChat',
}

export interface IOpenChatAction {
  type: ENBConsutantActions;
  payload: boolean;
}

export function openChat(open: boolean): IOpenChatAction {
  return {
    type: ENBConsutantActions.OpenChat,
    payload: open,
  };
}

export type TNewbuildingConsultantActions = IOpenChatAction;
