import { definitionFactory } from '../definitionFactory';
import { equals } from 'ramda';
import { onDeleteAction } from '../definitionsDeleteAction';

export const constructionStatusDefinition = definitionFactory({
  key: 'status',
  mapper: jsonQueryValue => {
    if (equals(jsonQueryValue, ['underConstruction'])) {
      return [
        {
          label: 'Строится',
          value: jsonQueryValue,
        },
      ];
    }

    return [];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'status', options);
  },
});
