import * as React from 'react';
import * as styles from './PromoSnippetTitle.css';
import { UIHeading1 } from '@cian/ui-kit';

interface IPromoSnippetTitleProps {
  title: string;
  url: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

export function PromoSnippetTitle({ title, url, onClick }: IPromoSnippetTitleProps) {
  return (
    <a className={styles.container} target="_blank" href={url} rel="noreferrer" onClick={onClick} data-testid="title">
      <UIHeading1>{title}</UIHeading1>
    </a>
  );
}
