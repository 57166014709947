import { getEventBusIfDefined } from '@cian/event-bus';
import { HEADER_SET_NB_CONSULTANT_PHONE } from '../../constants/events';

// Показываем телефон консультанта для Москвы и региона
export function triggerHeaderShowNBConsultantPhone(phone: string) {
  const eventBus = getEventBusIfDefined();
  /* istanbul ignore if */
  if (!eventBus) {
    return;
  }

  eventBus.emit(HEADER_SET_NB_CONSULTANT_PHONE, { type: phone });
}
