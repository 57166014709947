import { IApplicationState } from '../types/redux';
import { triggerHeaderShowNBConsultantPhone } from './events/triggerHeaderShowNBConsultantPhone';
import { getRegionId } from '../selectors/getRegionId';
import { getRegionParentId } from '../selectors/getRegionParentId';

export function displayConsultantPhone(state: IApplicationState, enabledRegions: number[], phone?: string | null) {
  const regionId = getRegionId(state);
  const regionParentId = getRegionParentId(state);

  const availableInRegion =
    enabledRegions.includes(regionId) || (!!regionParentId && enabledRegions.includes(regionParentId));

  if (phone && availableInRegion) {
    triggerHeaderShowNBConsultantPhone(phone);
  }
}
