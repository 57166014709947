import { Button, ModalWindow, UIHeading2, UIText1 } from '@cian/ui-kit';
import { IconActionClose24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './BrokerFallbackModal.css';

interface IBrokerFallbackModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onRetry: () => void;
}

/**
 * Модалка отображается, если не удалось показать виджет брокерки.
 */
export const BrokerFallbackModal: React.FC<IBrokerFallbackModalProps> = ({ isLoading, isOpen, onClose, onRetry }) => {
  return (
    <ModalWindow
      content={
        <div className={styles['content']} data-testid="BrokerFallbackModalContent">
          <div className={styles['icon-wrapper']}>
            <IconActionClose24 color="decorative__theme__red" />
          </div>
          <div className={styles['heading']}>
            <UIHeading2>Что-то пошло не так...</UIHeading2>
          </div>
          <UIText1>Попробуйте ещё раз</UIText1>
        </div>
      }
      fixed={false}
      footer={
        <div className={styles['footer']}>
          <Button fullWidth={false} loading={isLoading} onClick={onRetry} size="XS">
            Попробовать ещё раз
          </Button>
        </div>
      }
      onClose={onClose}
      open={isOpen}
      width={648}
    />
  );
};
