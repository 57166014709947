export const FAKE_RUSSIA_REGION = {
  id: 0,
  displayName: 'Россия',
  fullName: 'Российская Федерация',
  hasDistricts: true,
  hasHighway: true,
  hasMetro: true,
  boundedBy: {
    upperCorner: {
      lat: 56.021224,
      lng: 37.967428,
    },
    lowerCorner: {
      lat: 55.142175,
      lng: 36.803101,
    },
  },
  name: 'Россия',
  fullNamePrepositional: 'России',
  baseHost: 'https://www.cian.ru',
  lng: 37.6213,
  lat: 55.7557,
  prepositionalPretext: 'в',
  isCity: false,
  isArea: true,
};

export const FAKE_MOSCOW_REGION = {
  boundedBy: {
    upperCorner: {
      lat: 56.021224,
      lng: 37.967428,
    },
    lowerCorner: {
      lat: 55.142175,
      lng: 36.803101,
    },
  },
  hasMetro: true,
  displayName: 'Москва',
  name: 'Москва',
  id: 1,
  hasHighway: false,
  hasDistricts: true,
  fullNamePrepositional: 'Москве',
  baseHost: 'https://www.cian.ru',
  lng: 37.6213,
  lat: 55.7557,
  fullName: 'Москва',
  prepositionalPretext: 'в',
  isCity: true,
  isArea: false,
};

export const FAKE_SPB_REGION = {
  boundedBy: {
    upperCorner: {
      lat: 56.021224,
      lng: 37.967428,
    },
    lowerCorner: {
      lat: 55.142175,
      lng: 36.803101,
    },
  },
  hasMetro: true,
  displayName: 'Санкт-Петербург',
  name: 'Санкт-Петербург',
  id: 2,
  hasHighway: false,
  hasDistricts: true,
  fullNamePrepositional: 'Санкт-Петербурге',
  baseHost: 'https://spb.cian.ru',
  lng: 37.6213,
  lat: 55.7557,
  fullName: 'Санкт-Петербург',
  prepositionalPretext: 'в',
  isCity: true,
  isArea: false,
};
