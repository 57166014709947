import { IJsonQuery, IJQDescription } from '../../types/interfaces';
import { defaultRenderer } from '../../containers/FilterTags/defaultRenderer';
import { IApplicationState } from '../../types/redux';
import { IUndergroundDetails } from '../../types/suggestions';
import { ITagsData } from '../../types/tags';
import { IThunkActionCreator } from '../../types/thunk';

export type TDefinitionKey = keyof Pick<
  IJsonQuery,
  | 'house_type'
  | 'has_mortgage'
  | 'from_developer'
  | 'newbuilding_class'
  | 'is_apartment'
  | 'is_penthouse'
  | 'kitchen_area'
  | 'living_area'
  | 'total_area'
  | 'newbuilding_id'
  | 'builders'
  | 'geo'
  | 'region'
  | 'underground_walk_time'
  | 'polygon'
  | 'has_decoration'
  | 'status'
  | 'yeargte'
  | 'sost_type'
  | 'mcad_distance'
  | 'is_sales_leader'
  | 'is_sales_start'
  | 'is_black_friday'
  | 'is_upcoming_sale'
  | 'has_flat_tour_booking'
  | 'is_discount_mortgage'
  | 'has_panoramas'
  | 'promo_search_types'
>;

export type TJsonQueryValue = IJQDescription['value'];

export type TStateTagValue = TJsonQueryValue;

export interface IStateTag {
  keyName: TDefinitionKey;
  label: string;
  value: TStateTagValue;
  undergroundDetails?: Array<IUndergroundDetails>;
  pointColor?: string;
}

export type TStateTagForFactory = Pick<IStateTag, 'label' | 'value' | 'undergroundDetails' | 'pointColor'>;

export interface IDeleteOptions {
  skipUpdateOffersCount?: boolean;
}

export interface IDefinitionFactoryParams {
  key: TDefinitionKey;
  mapper(
    jsonQueryValue: TJsonQueryValue,
    tags: IStateTag[],
    appState: IApplicationState,
    extraParams?: ITagsData,
  ): TStateTagForFactory[];
  onDeleteAction(value?: TStateTagValue, options?: IDeleteOptions): IThunkActionCreator;
  renderer?(props: IStateTag): JSX.Element;
}

export interface IDefinitionValue extends Required<Pick<IDefinitionFactoryParams, 'renderer' | 'onDeleteAction'>> {
  mapper(
    jsonQueryValue: TJsonQueryValue,
    tags: IStateTag[],
    appState: IApplicationState,
    extraParams?: ITagsData,
  ): IStateTag[];
}

export type TDefinition = [TDefinitionKey, IDefinitionValue];

export function definitionFactory(params: IDefinitionFactoryParams): TDefinition {
  const { key, mapper, renderer = defaultRenderer, onDeleteAction } = params;

  return [
    key,
    {
      mapper: (jsonQueryValue, tags, appState, extraParams) => {
        return mapper(jsonQueryValue, tags, appState, extraParams).map(item => ({ ...item, keyName: key }));
      },
      onDeleteAction,
      renderer,
    },
  ];
}
