import { makeSuggestionRequester } from '../../../api/geo';
import { ISuggestionGroup } from '../../../types/suggestions';
import { serializeAddress } from '../../../utils/yamaps';
import { TBoundedBy } from '../types';

export const requestAddressSuggestions = async (term: string, boundedBy?: TBoundedBy): Promise<ISuggestionGroup[]> => {
  const response = await makeSuggestionRequester({ boundedBy })(term);
  const suggestions = serializeAddress(response);

  return suggestions;
};
