import { IStateTag, TDefinitionKey } from '../../actions/filterTags/definitionFactory';
import { IJsonQuery } from '../filters';
import { definitions } from '../../actions/filterTags/definitions';
import { IApplicationState } from '../../types/redux';
import { ITagsData } from '../../types/tags';

export function createTags(
  jsonQuery: IJsonQuery,
  prepareTags: IStateTag[],
  appState: IApplicationState,
  extraParams?: ITagsData,
) {
  return Object.keys(jsonQuery).reduce((tags: IStateTag[], key: TDefinitionKey) => {
    const definition = definitions.get(key);
    const JQDescription = jsonQuery[key];

    return definition && JQDescription
      ? tags.concat(...definition.mapper(JQDescription.value, prepareTags, appState, extraParams))
      : tags;
  }, []);
}
