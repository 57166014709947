import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const manifestationTypeDefinition = definitionFactory({
  key: 'from_developer',
  mapper: jsonQueryValue =>
    jsonQueryValue === false
      ? [
          {
            label: 'От агентов и частных лиц',
            value: false,
          },
        ]
      : [],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'from_developer', options);
  },
});
