/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const newbuilding = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-1-4h20v20H-1z" />
      <path fill="#C9C9C9" d="M.515 9L9 .515 17.485 9l-2.828 2.828-5.87-5.87-5.657 5.658z" />
      <path fill="#C9C9C9" d="M12 1h3v6h-3z" />
    </g>
  </svg>
);
