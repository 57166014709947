import loadable from '@loadable/component';

export const NewbuildingPromoCarouselLoadableContainer = loadable(() => {
  if (process.env.IS_BROWSER_BUILD) {
    return import('./NewbuildingPromoCarousel');
  }

  return Promise.resolve({
    default: () => null,
  });
});
