import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const hasDecorationDefinition = definitionFactory({
  key: 'has_decoration',
  mapper: jsonQueryValue => [
    {
      label: jsonQueryValue === true ? 'С отделкой' : 'Без отделки',
      value: jsonQueryValue,
    },
  ],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'has_decoration', options);
  },
});
