/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const builder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <rect width="1.6" height="1.6" x="5.557" y="9" fill="#C9C9C9" rx=".8" />
      <rect width="1.6" height="1.6" x="9.557" y="9" fill="#C9C9C9" rx=".8" />
      <path
        stroke="#C9C9C9"
        strokeLinecap="round"
        strokeWidth="1.2"
        d="M2.857 9.5c0 1.336.75 5.9 5.5 5.9s5.5-4.564 5.5-5.9"
      />
      <rect width="3" height="1" x="6.857" y="12" fill="#C9C9C9" rx=".5" />
      <path
        fill="#C9C9C9"
        d="M11 2v3H9.857V1c0-.552-.446-1-1-1H8C7.447 0 7 .444 7 1v4H5.857V2c-2.818 1.038-3 3-3 4h-1c-.552 0-1 .444-1 1 0 .552.438 1 1.003 1h13c.55 0 .997-.444.997-1 0-.552-.444-1-1-1h-1c0-.997-.039-2.962-2.857-4z"
      />
    </g>
  </svg>
);
