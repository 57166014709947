import * as React from 'react';
import { NoSsr } from '@cian/react-utils';
import { UIHeading2 } from '@cian/ui-kit';
import { Base64 } from 'js-base64';
import { Container } from '../../components/Container';

interface ISimilarBlockTitleProps {
  hasNewbuildings: boolean;
}

/**
 * Заголовок: "Ничего не найдено, но есть похожие предложения"
 * Сделано через base64, чтобы не было в индексе
 * @see https://jira.cian.tech/browse/OM-930
 **/
const BASE_64_TITLE =
  '0J3QuNGH0LXQs9C+INC90LUg0L3QsNC50LTQtdC90L4sINC90L4g0LXRgdGC0Ywg0L/QvtGF0L7QttC40LUg0L/RgNC10LTQu9C+0LbQtdC90LjRjw==';

export const SimilarBlockTitle = ({ hasNewbuildings }: ISimilarBlockTitleProps) => {
  if (!hasNewbuildings) {
    return (
      <noindex>
        <NoSsr defer fallback={<Container height="28px" width="100%" />}>
          <UIHeading2>{Base64.decode(BASE_64_TITLE)}</UIHeading2>
        </NoSsr>
      </noindex>
    );
  }

  return <UIHeading2>Дополнительные предложения по вашему запросу</UIHeading2>;
};
