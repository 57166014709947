import * as React from 'react';
import { Checkbox } from '@cian/ui-kit';

/***** STYLES *****/
import * as styles from './styles/SelectionList.css';

/***** UTILS *****/
import { mergeStyles } from '@cian/utils';
import { noop } from '@cian/newbuilding-utils';

/***** COMPONENTS *****/
import { Icon } from '../Icon/';
import { Text } from '../Text/';

/***** TYPES *****/
export interface ISelectionItem {
  id: string;
  label: string;
  isSubtitle?: boolean;
}

export interface ISelectionListProps {
  simple?: boolean;
  multiline?: boolean;
  options: Array<ISelectionItem>;
  selectedItems: Array<ISelectionItem['id'] | undefined>;
  onClick?(options: { id: string; label: string }): void;
}

/***** SelectionList *****/
export class SelectionList extends React.PureComponent<ISelectionListProps> {
  public render() {
    const { multiline, simple, onClick, selectedItems, options } = this.props;
    const isItemActive = isActiveFactory(selectedItems);

    return (
      <ul data-mark="SelectionList" className={styles['container']} data-testid="SelectionList">
        {options.map(({ label, id, isSubtitle }) => {
          const active = isItemActive(id);

          return (
            <li
              {...mergeStyles(styles['item'], active && styles['active'], isSubtitle && styles['is-subtitle'])}
              key={id}
              onClick={handleOptionClick({ id, label, isSubtitle }, onClick)}
            >
              {simple ? (
                <Text fontWeight={isSubtitle ? 'bold' : 'normal'}>{label}</Text>
              ) : multiline ? (
                <Checkbox checked={active} onChange={noop} onClick={preventDefault} label={label} />
              ) : (
                <>
                  <div {...mergeStyles(styles['icon'], !active && styles['icon--hidden'])}>
                    {active && <Icon size={14} type="check" color="blue" />}
                  </div>
                  <Text fontWeight={isSubtitle ? 'bold' : 'normal'} whiteSpace="nowrap">
                    {label}
                  </Text>
                </>
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}

function handleOptionClick(options: ISelectionItem, onClick?: (options: ISelectionItem) => void) {
  return () => {
    if (options.isSubtitle) {
      return;
    }

    return onClick && onClick(options);
  };
}

function preventDefault(event: React.SyntheticEvent) {
  event.preventDefault();
}

export function isActiveFactory(items: ISelectionListProps['selectedItems']) {
  return (item: ISelectionItem['id']): boolean => items.includes(item);
}
