import * as React from 'react';
import { UIHeading5 } from '@cian/ui-kit';
import { clsx } from 'clsx';

import * as styles from './FieldTitle.css';

interface IFieldTitleProps extends React.PropsWithChildren {
  alignment?: 'center' | 'top';
}

export const FieldTitle: React.FC<IFieldTitleProps> = ({ alignment = 'center', children }) => {
  return (
    <div className={clsx(styles['field-title'], styles[alignment])}>
      <UIHeading5>{children}</UIHeading5>
    </div>
  );
};
