import * as React from 'react';
import { useSelector } from 'react-redux';

import { SalesLinks } from '../../components/SalesLinks';
import { getSalesLinks } from '../../selectors/getSalesLinks';
import { getHasBlackFridaySalesLink } from '../../selectors/newbuildingBlackFriday';
import { ISalesLink } from '../../types/offersData';
import { trackBlackFridaySalesLinkShow, trackSalesLinkClick } from './tracking';

export function SalesLinksContainer() {
  const salesLinks = useSelector(getSalesLinks);
  const hasBlackFridayLink = useSelector(getHasBlackFridaySalesLink);

  const handleLinkClick = React.useCallback((link: ISalesLink) => {
    trackSalesLinkClick(link.salesType);
  }, []);

  const handleLinksShow = React.useCallback(() => {
    if (hasBlackFridayLink) {
      trackBlackFridaySalesLinkShow();
    }
  }, [hasBlackFridayLink]);

  if (!salesLinks.length) {
    return null;
  }

  return <SalesLinks links={salesLinks} onLinkClick={handleLinkClick} onShow={handleLinksShow} />;
}
