import * as React from 'react';
import { Popover } from '@cian/ui-kit';

import { priceTypeDescriptors } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { ClickOutside } from '../../components/outside';
import { Clickable } from '../../components/Clickable';
import { Text } from '../../components/Text';
import { Container } from '../../components/Container';
import { SelectionList } from '../../components/SelectionList';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';

export interface IPriceTypeProps extends IDescriptorProps<string> {
  getOffersCount(): void;
}

export interface IPriceTypeState {
  isOpen: boolean;
}

class PriceTypeComponent extends React.Component<IPriceTypeProps, IPriceTypeState> {
  public state: Readonly<IPriceTypeState> = {
    isOpen: false,
  };

  public shouldComponentUpdate(nextProps: IPriceTypeProps, nextState: IPriceTypeState) {
    const { descriptors, jsonQuery } = this.props;
    const nextActiveDescriptor = nextProps.descriptors.find(descriptor =>
      isDescriptorActive(descriptor)(nextProps.jsonQuery),
    );
    const activeDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));

    const nextValue = nextActiveDescriptor ? nextActiveDescriptor.state : undefined;
    const value = activeDescriptor ? activeDescriptor.state : undefined;

    return nextValue !== value || nextState.isOpen !== this.state.isOpen;
  }

  public render() {
    const { jsonQuery, descriptors } = this.props;
    const { isOpen } = this.state;
    const activeDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
    const value = activeDescriptor ? activeDescriptor.state : undefined;
    const label = activeDescriptor ? activeDescriptor.label : undefined;

    return (
      <Popover
        open={Boolean(isOpen)}
        placement="bottom"
        content={
          <ClickOutside onOutside={this.toggleOpen}>
            <Container backgroundColor="white" borderRadius={4} borderColor="gray_0" borderStyle="solid" shadow>
              <SelectionList
                options={descriptors.map(({ label, state }) => ({ id: state, label }))}
                onClick={this.handleChange}
                selectedItems={[value]}
              />
            </Container>
          </ClickOutside>
        }
      >
        <div>
          <Clickable onClick={this.toggleOpen}>
            <Container
              borderTopWidth={0}
              borderRightWidth={0}
              borderLeftWidth={0}
              borderBottomWidth={1}
              borderStyle="dashed"
              borderColor="blue"
              borderColorHovered="red"
            >
              <Text color="blue" colorHovered="red" whiteSpace="nowrap">
                {label}
              </Text>
            </Container>
          </Clickable>
        </div>
      </Popover>
    );
  }

  private toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  private handleChange = (option: { label: string; id: string }) => {
    this.props.actionCreator(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.props.descriptors.find(d => {
        return d.state === option.id;
        // убрали правило из конвенции и из tslint-config(https://cianru.atlassian.net/browse/CD-33534),
        // но забыли про test-utils, по этому пока  пишем с исключениями
      })!,
    )();
    this.toggleOpen();
    this.props.getOffersCount();
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const PriceType = connectToDescriptors(priceTypeDescriptors, mapDispatchToProps)(PriceTypeComponent);
