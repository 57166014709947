import * as React from 'react';
import { findDOMNode } from 'react-dom';

import { IStyleConfig, mergeStyles } from '@cian/utils/lib/shared/style';

import { createTextSizeCalculator } from '../../utils/tooltipTextSizeCalculator';
import { throttle } from '@cian/newbuilding-utils';

import * as styles from './index.css';

export interface ITextSizeCalculator {
  calculate(text: string): number;
  detach(): void;
}

export interface ITooltipProps extends React.PropsWithChildren {
  tooltipContent: string;
  tooltipStyles?: IStyleConfig;
  thin?: boolean;
  arrow?: boolean;
  error?: boolean;
  notCovered?: boolean;
  multiline?: boolean;
}

export interface ITooltipState {
  childrenWidth: number;
  childrenHeight: number;
  tooltipContentWidth: number;
}

export class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
  public state = {
    childrenHeight: 0,
    childrenWidth: 0,
    tooltipContentWidth: 0,
  };

  private recalculatePositionOnResize: (() => void) | void;
  private textSizeCalculator: ITextSizeCalculator | void;

  public componentDidMount() {
    this.textSizeCalculator = createTextSizeCalculator(styles['text_size_calculator']);

    this.recalculatePositionOnResize = throttle(50, () => {
      this.calculatePosition(this.props);
    });

    this.calculatePosition(this.props);

    window.addEventListener('resize', this.recalculatePositionOnResize);
  }

  public componentDidUpdate(prevProps: ITooltipProps) {
    if (this.props.tooltipContent !== prevProps.tooltipContent) {
      this.calculatePosition(this.props);
    }
  }

  public componentWillUnmount() {
    if (this.textSizeCalculator && this.recalculatePositionOnResize) {
      this.textSizeCalculator.detach();
      window.removeEventListener('resize', this.recalculatePositionOnResize);
      this.textSizeCalculator = undefined;
      this.recalculatePositionOnResize = undefined;
    }
  }

  public render() {
    const { children, tooltipStyles, tooltipContent, error, notCovered } = this.props;

    return (
      <span {...mergeStyles(styles['wrapper'], tooltipStyles)} data-mark="Tooltip">
        {this.state.childrenWidth > 0 && !!tooltipContent && (
          <span
            {...mergeStyles(
              styles['container'],
              notCovered && styles['container--not-covered'],
              this.getContainerStyle(),
            )}
          >
            <span {...mergeStyles(styles['block'], error && styles['block--error'])}>
              <span {...mergeStyles(styles['content'])} data-testid="TooltipContent">
                {tooltipContent}
              </span>
            </span>
          </span>
        )}
        {children}
      </span>
    );
  }

  private getContainerStyle() {
    return [
      styles['bottom_center'],
      {
        top: this.state.childrenHeight + 6,
      },
    ];
  }

  private calculatePosition(props: ITooltipProps) {
    // eslint-disable-next-line react/no-find-dom-node
    const children = (findDOMNode(this) as HTMLDivElement).children;
    const childrenRect = children[children.length - 1].getBoundingClientRect();

    this.setState({
      childrenHeight: childrenRect.height,
      childrenWidth: childrenRect.width,
      tooltipContentWidth: this.textSizeCalculator
        ? this.textSizeCalculator.calculate(props.tooltipContent)
        : this.state.tooltipContentWidth,
    });
  }
}
