export enum ESalesStartOnboardingActions {
  SetOnboardingStatus = 'set/onboardingStatus',
  SetNewbuildingId = 'set/newbuildingId',
}

export interface ISetSalesStartOnboardingStatusAction {
  type: ESalesStartOnboardingActions.SetOnboardingStatus;
  payload: boolean;
}

export function setSalesStartOnboardingStatus(isEnabled: boolean): ISetSalesStartOnboardingStatusAction {
  return {
    type: ESalesStartOnboardingActions.SetOnboardingStatus,
    payload: isEnabled,
  };
}

export interface ISetSalesStartNewbuildingIdAction {
  type: ESalesStartOnboardingActions.SetNewbuildingId;
  payload: number | null;
}

export function setSalesStartNewbuildingId(newbuildingId: number | null): ISetSalesStartNewbuildingIdAction {
  return {
    type: ESalesStartOnboardingActions.SetNewbuildingId,
    payload: newbuildingId,
  };
}

export type TSalesStartOnboardingActions = ISetSalesStartOnboardingStatusAction | ISetSalesStartNewbuildingIdAction;
