import { Chips } from '@cian/ui-kit';
import * as React from 'react';

interface ICheckboxButtonsStackProps {
  text: string;
  value: string;
}

export interface ICheckboxButtonsMultipleProps {
  stack: ICheckboxButtonsStackProps[];
  value?: string;
  onChange?(value: string): void;
}

export const CheckboxButtonsMultiple = ({ stack, value, onChange }: ICheckboxButtonsMultipleProps) => {
  const options = React.useMemo(() => stack.map(({ text, value }) => ({ label: text, value })), [stack]);
  const currentValue = React.useMemo(() => (value ? [value] : []), [value]);

  return (
    <Chips options={options} size="S" value={currentValue} onChipClick={onChange} data-mark="CheckboxButtonsMultiple" />
  );
};
