import { TRegions } from '../../types/regions';

export enum ERegionActions {
  init = 'init/regions',
}

export interface IRegionAction {
  type: ERegionActions;
  data: TRegions;
}

export function initRegions(regions: TRegions) {
  return {
    data: regions,
    type: ERegionActions.init,
  };
}
