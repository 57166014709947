import { ca } from '@cian/analytics';

import { TUser } from '../../types/user';
import { IProduct } from '../../types/analytics';

interface ITrackPageViewCollectParams {
  user: TUser;
  products: IProduct[];
}

export const trackPageViewCollect = ({ user, products }: ITrackPageViewCollectParams) => {
  return ca('event', {
    category: 'seen_label',
    action: 'show_sopr',
    name: 'oldevent',
    page: {
      pageType: 'ListingJK',
      siteType: 'desktop',
    },
    user: user.isAuthenticated ? { userId: user.id } : {},
    products,
  });
};
