import { definitionFactory } from '../definitionFactory';
import { rangeDistanceStr } from '../../../utils/text';
import { onDeleteAction } from '../definitionsDeleteAction';

export const mcadDefinition = definitionFactory({
  key: 'mcad_distance',
  mapper: ({ gte, lte }) =>
    gte || lte
      ? [
          {
            label: rangeDistanceStr('Расстояние до МКАД', gte, lte),
            value: { gte, lte },
          },
        ]
      : [],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'mcad_distance', options);
  },
});
