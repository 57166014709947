import { ISalesStartOnboarding } from '../../types/salesStartOnborading';
import { ESalesStartOnboardingActions, TSalesStartOnboardingActions } from '../../actions/salesStartOnboarding';

export const defaultState: ISalesStartOnboarding = {
  newbuildingId: null,
  onboardingEnabled: false,
};

export function salesStartOnboarding(
  state: ISalesStartOnboarding = defaultState,
  action: TSalesStartOnboardingActions,
) {
  switch (action.type) {
    case ESalesStartOnboardingActions.SetNewbuildingId:
      return {
        ...state,
        newbuildingId: action.payload,
      };
    case ESalesStartOnboardingActions.SetOnboardingStatus:
      return {
        ...state,
        onboardingEnabled: action.payload,
      };
    default:
      return state;
  }
}
