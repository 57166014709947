import { definitionFactory, TStateTagValue } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

const NewbuildingClassLabels: { [index: string]: string } = {
  economy: 'Эконом',
  comfort: 'Комфорт',
  business: 'Бизнес',
  premium: 'Премиум',
};

export const newbuildingClassDefinition = definitionFactory({
  key: 'newbuilding_class',
  mapper: jsonQueryValue =>
    jsonQueryValue.map((value: TStateTagValue) => ({
      label: NewbuildingClassLabels[value as string],
      value,
    })),
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'newbuilding_class', options);
  },
});
