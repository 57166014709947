import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsSalesStartOnboardingEnabled,
  getNewbuildingIdForSalesStartOnboarding,
} from '../../selectors/salesStartOnboarding';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';
import { setSalesStartOnboardingStatus } from '../../actions/salesStartOnboarding';
import { SalesStartLabelOnboarding } from './SalesStartLabelOnboarding';
import { SalesStartLabelTooltip } from './SalesStartLabelTooltip';
import {
  getSalesStartOnboardingCookie,
  createSalesStartSuccessOnboardingCookie,
} from '../../utils/salesStartOnboarding';

interface ISalesStartLabelContainer {
  newbuildingId: number;
  description: string;
}

export function SalesStartLabelContainer(props: ISalesStartLabelContainer) {
  const [hovered, setHovered] = React.useState(false);
  const dispatch = useDispatch();

  const { newbuildingId, description } = props;

  const onboardingEnabled = useSelector(getIsSalesStartOnboardingEnabled);
  const onboardingNewbuildingId = useSelector(getNewbuildingIdForSalesStartOnboarding);
  const onboardingAvailable = onboardingEnabled && newbuildingId === onboardingNewbuildingId;

  let timesSalesStartOnboardingShown = 0;

  React.useEffect(() => {
    timesSalesStartOnboardingShown = getSalesStartOnboardingCookie();
  });

  const changeHovered = React.useCallback(() => setHovered(!hovered), [hovered]);
  const hideOnboarding = React.useCallback(() => {
    dispatch(setSalesStartOnboardingStatus(false));
    createSalesStartSuccessOnboardingCookie(timesSalesStartOnboardingShown + 1);
  }, []);

  const tooltipContent = {
    header: 'Старт продаж',
    content: `Есть выгодные предложения и${NO_BREAK_SPACE}скидки`,
  };

  if (onboardingAvailable) {
    return (
      <SalesStartLabelOnboarding
        description={description}
        tooltipContent={tooltipContent}
        openTooltip={onboardingAvailable}
        onClose={hideOnboarding}
      />
    );
  }

  return (
    <SalesStartLabelTooltip
      description={description}
      tooltipContent={tooltipContent}
      hovered={hovered}
      onMouseEnter={changeHovered}
      onMouseLeave={changeHovered}
    />
  );
}
