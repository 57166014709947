import * as geo from './';
import { IRegion } from '../../types/regions';
import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';

export function isMoscow(region: IRegion) {
  return region.id === geo.MOSCOW_ID;
}

export function isMoscowArea(region: IRegion) {
  return region.id === geo.MOSCOW_AREA_ID;
}

export function isSPB(region: IRegion) {
  return region.id === geo.SPB_ID;
}

export function isLo(region: IRegion) {
  return region.id === geo.LO_ID;
}

export function isMoscowAndMoscowArea(region: IRegion | ISearchCatalogItemSchema) {
  return region.id === geo.MOSCOW_AND_MOSCOW_AREA_ID;
}

export function isSPBAndLo(region: IRegion | ISearchCatalogItemSchema) {
  return region.id === geo.SPB_AND_LO_ID;
}

export function isMoscowOrMoscowArea(regionOrId?: IRegion | ISearchCatalogItemSchema | number): boolean {
  if (typeof regionOrId === 'undefined') {
    return false;
  }

  const id = typeof regionOrId === 'number' ? regionOrId : regionOrId.id;

  return [geo.MOSCOW_ID, geo.MOSCOW_AREA_ID, geo.MOSCOW_AND_MOSCOW_AREA_ID].includes(id);
}

export function isSBPOrLo(regionOrId?: IRegion | ISearchCatalogItemSchema | number): boolean {
  if (typeof regionOrId === 'undefined') {
    return false;
  }

  const id = typeof regionOrId === 'number' ? regionOrId : regionOrId.id;

  return [geo.SPB_ID, geo.LO_ID, geo.SPB_AND_LO_ID].includes(id);
}

export function isSPBOrMoscow(region: IRegion | ISearchCatalogItemSchema) {
  return isMoscowAndMoscowArea(region) || isSPBAndLo(region) || isMoscowOrMoscowArea(region) || isSBPOrLo(region);
}

// конвертируем долбанный [1, 4593] в [-1]
export function JSONQueryIdsToQsId(ids: number[]) {
  if (geo.MOSCOW_AND_MO_IDS.every(id => ids.includes(id))) {
    return [-1];
  } else if (geo.SPB_AND_LO_IDS.every(id => ids.includes(id))) {
    return [-2];
  }

  return ids;
}
// конвертриуем -1 или -2 в [1, 4593] и че там у СПБ, да будут не ладны эти регионы!
export function QsIdToJSONQueryIds(id: number) {
  switch (id) {
    case -1:
      return [geo.MOSCOW_ID, geo.MOSCOW_AREA_ID];
    case -2:
      return [geo.SPB_ID, geo.LO_ID];
    default:
      return id;
  }
}
