import { plural } from '@cian/utils/lib/shared/plural';

const OFFER_TEXT_VARIANTS: [string, string, string] = ['предложение', 'предложения', 'предложений'];

export const getSearchButtonText = (count?: number | null): string => {
  if (typeof count === 'number') {
    return `Показать ` + `${count} ${plural(count, OFFER_TEXT_VARIANTS)}`;
  }

  return 'Показать предложения';
};
