import { descriptorsFactory } from '../../utils/filters';

export const fromDeveloperDesdcriptor = descriptorsFactory({ name: 'FromDeveloper', clear: ['from_developer'] })(
  {
    form: [{ key: 'from_developer', value: true, type: 'term' }],
    label: 'От застройщика',
    state: 'On',
  },
  {
    form: [],
    label: 'От застройщика',
    state: 'Off',
  },
);
