import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const yearGteDefinition = definitionFactory({
  key: 'yeargte',
  mapper: jsonQueryValue => [
    {
      label: 'На старте продаж',
      value: jsonQueryValue,
    },
  ],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'yeargte', options);
  },
});
