import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const isSalesStartDefinition = definitionFactory({
  key: 'is_sales_start',
  mapper: jsonQueryValue => {
    return [
      {
        label: 'Старт продаж',
        value: jsonQueryValue,
      },
    ];
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'is_sales_start', options);
  },
});
