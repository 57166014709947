import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from '@cian/ui-kit';

import { IApplicationState } from '../../../types/redux';

import { CityModal } from './CityModal/CityModal';
import { Container } from '../../../components/Container';
import { TTypedThunkDispatch } from '../../../types/thunk';
import { trackingActionPopupGeoOpen } from '../../../actions/analytics';
import { getOffersCount } from '../../../actions/offersData';

interface ILocationModalStoreProps {
  regionName: string;
}

interface ILocationModalDispatchProps {
  trackingPopupGeoOpen(): void;
  getOffersCount(): void;
}

interface ILocationModalState {
  isOpen: boolean;
}

type TLocationModalProps = ILocationModalStoreProps & ILocationModalDispatchProps;

class LocationModalComponent extends React.PureComponent<TLocationModalProps, ILocationModalState> {
  public state: Readonly<ILocationModalState> = {
    isOpen: false,
  };

  public render() {
    const { regionName } = this.props;
    const { isOpen } = this.state;

    return (
      <Container>
        <Button onClick={this.handleOpenModal} size="XS" tabIndex={-1} theme="stroke_secondary" type="button">
          {regionName}
        </Button>
        <CityModal onClose={this.handleCloseModal} onSubmitModal={this.handleSubmit} open={isOpen} />
      </Container>
    );
  }

  private handleOpenModal = () => {
    this.props.trackingPopupGeoOpen();
    this.setState({ isOpen: true });
  };

  private handleCloseModal = () => {
    this.props.getOffersCount();
    this.setState({ isOpen: false });
  };

  private handleSubmit = () => {
    this.setState({ isOpen: false });
  };
}

function mapStateToProps(state: IApplicationState): ILocationModalStoreProps {
  return {
    regionName: state.region.currentRegion.displayName,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): ILocationModalDispatchProps {
  return {
    trackingPopupGeoOpen() {
      dispatch(trackingActionPopupGeoOpen());
    },
    getOffersCount() {
      dispatch(getOffersCount());
    },
  };
}

export const LocationModal = connect(mapStateToProps, mapDispatchToProps)(LocationModalComponent);
