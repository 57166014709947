import * as React from 'react';

import { INewbuilding } from '../../types/newbuilding';
import { NewbuildingFeature } from './NewbuildingFeature';

import * as styles from './NewbuildingFeatures.css';

interface INewbuildingFeaturesProps {
  newbuilding: INewbuilding;
  onClick?(type: string): void;
}

export class NewbuildingFeatures extends React.PureComponent<INewbuildingFeaturesProps> {
  public render() {
    const { newbuilding, onClick } = this.props;
    const { newbuildingFeaturesV2, url } = newbuilding;

    if (!newbuildingFeaturesV2) {
      return null;
    }

    return (
      <div className={styles['container']}>
        {newbuildingFeaturesV2.map((feature, idx) => {
          return (
            <NewbuildingFeature
              key={idx}
              type={feature.type}
              title={feature.text}
              url={feature.link || url}
              onClick={onClick}
            />
          );
        })}
      </div>
    );
  }
}
