import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from '@cian/ui-kit';

import { IApplicationState } from '../../types/redux';
import { Container } from '../../components/Container';
import { IStateTag } from '../../actions/filterTags/definitionFactory';
import { definitions } from '../../actions/filterTags/definitions';
import { SIZE_8, SIZE_16 } from '../../constants/sizes';
import { rendererFactory } from './defaultRenderer';
import { TTypedThunkDispatch } from '../../types/thunk';
import { updateTags } from '../../actions/filterTags';
import { getOffersCount } from '../../actions/offersData';

interface IFilterTagsStateProps {
  tags: IStateTag[];
}

interface IFiltertagsDispatchProps {
  clearAllTags(tags: IStateTag[]): void;
}

type FilterTagsProps = IFilterTagsStateProps & IFiltertagsDispatchProps;

interface ITagContainerProps {
  children: React.ReactNode;
}

function TagContainer(props: ITagContainerProps) {
  return (
    <Container display="inline-block" margin={`0 ${SIZE_8} ${SIZE_8} 0`}>
      {props.children}
    </Container>
  );
}

class FilterTagsComponent extends React.Component<FilterTagsProps> {
  public render() {
    const { tags, clearAllTags } = this.props;

    if (!tags.length) {
      return null;
    }

    return (
      <Container display="flex" flexWrap="wrap" dataMark="FilterTags" padding={`${SIZE_16} 0 0`}>
        {tags.map((tag, index) => {
          const definition = definitions.get(tag.keyName);

          if (!definition) {
            return null;
          }

          const Component = rendererFactory(definition);

          return (
            <TagContainer key={index}>
              <Component {...tag} />
            </TagContainer>
          );
        })}
        {tags.length >= 2 && (
          <TagContainer>
            <Button onClick={() => clearAllTags(tags)} size="XS" theme="fill_secondary" type="button">
              Очистить
            </Button>
          </TagContainer>
        )}
      </Container>
    );
  }
}

export function mapStateToProps({ filters }: IApplicationState): IFilterTagsStateProps {
  const { tags } = filters;

  return {
    tags,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    clearAllTags(tags: IStateTag[]) {
      tags.forEach(tag => {
        const definition = definitions.get(tag.keyName);
        if (definition) {
          const onDeleteAction = definition.onDeleteAction(tag.value, { skipUpdateOffersCount: true });
          if (onDeleteAction) {
            dispatch(onDeleteAction);
          }
        }
      });
      dispatch(getOffersCount());
      dispatch(updateTags());
    },
  };
}

export const FilterTags = connect(mapStateToProps, mapDispatchToProps)(FilterTagsComponent);
