import * as React from 'react';
import { connect } from 'react-redux';
import { debounce } from '@cian/newbuilding-utils';
import { ModalWindow } from '@cian/ui-kit';

import { trackingActionPopupGeoSave } from '../../../../actions/analytics';
import { clearTagsAfterChangeRegion, updateTags } from '../../../../actions/filterTags';
import { getOffersCount, getOffersData } from '../../../../actions/offersData';
import { setCurrentRegion } from '../../../../actions/region/currentRegion';
import { clearRegionCities, getRegionCities } from '../../../../actions/regionCities';
import { getRegionSearchCities } from '../../../../actions/regionSearchCities';
import { Container } from '../../../../components/Container';
import { Divider } from '../../../../components/Divider';
import { geoDescriptor, regionDescriptor } from '../../../../constants/descriptors';
import { SIZE_1040, SIZE_16, SIZE_20, SIZE_24, SIZE_VH_80, SIZE_WIDE } from '../../../../constants/sizes';
import { DEFAULT_INPUT_TIMEOUT } from '../../../../constants/timeouts';
import { ISearchCatalogItemSchema } from '../../../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import { getCitiesByAlphabet } from '../../../../selectors/getCitiesByAlphabet';
import { getRegionsByAlphabet } from '../../../../selectors/getRegionsByAlphabet';
import { getRegionsColumns } from '../../../../selectors/getRegionsColumns';
import { selectCanUseHiddenBase } from '../../../../selectors/user';
import { IApplicationState, TRegionSearchCitiesState } from '../../../../types/redux';
import { IRegion, IRegionsByID } from '../../../../types/regions';
import { TTypedThunkDispatch } from '../../../../types/thunk';
import { isMoscowAndMoscowArea, isSPBAndLo, LO_ID, MOSCOW_AREA_ID } from '../../../../utils/geo';
import { createSimpleAction } from '../../../сonnectToDescriptors';
import { CityModalContent } from './CityModalContent';
import { CityModalFooter } from './CityModalFooter';
import { CityModalInput } from './CityModalInput';
import { selectRegionCities, selectRegionSearchCities } from '../../../../selectors/temp';

export type TCityModalStep = 1 | 2;

interface ICityModalOwnProps {
  onClose(): void;
  onSubmitModal(): void;
  open: boolean;
}

interface ICityModalStoreProps {
  count: number | null;
  isCountFetching?: boolean;
  searchList: TRegionSearchCitiesState;
  regionsByID: IRegionsByID;
  regionColumns: IRegion[][][];
  citiesColumns: IRegion[][][];
  mainRegions?: (IRegion | ISearchCatalogItemSchema)[];
  mainCities?: (IRegion | ISearchCatalogItemSchema)[];
  currentRegion: IRegion | ISearchCatalogItemSchema;
  canUseHiddenBase: boolean;
}

interface ICityModalDispatchProps {
  onSubmit(canUseHiddenBase: boolean): void;
  onInput(text: string): void;
  onRegionChange(regionId: number): void;
  clearCities(): void;
  setRegion(region: IRegion | ISearchCatalogItemSchema, previousRegion: IRegion | ISearchCatalogItemSchema): void;
  setRegionFilter(id: number): void;
  getOffersCount(region?: IRegion | ISearchCatalogItemSchema): void;
}

interface ICityModalState {
  currentRegion: IRegion | ISearchCatalogItemSchema;
  step: TCityModalStep;
}

type TCityModalProps = ICityModalOwnProps & ICityModalStoreProps & ICityModalDispatchProps;

class CityModalComponent extends React.PureComponent<TCityModalProps, ICityModalState> {
  public constructor(props: TCityModalProps) {
    super(props);

    this.state = {
      step: 1,
      currentRegion: props.currentRegion,
    };
  }

  private sTimeout: NodeJS.Timeout | number;

  public componentWillUnmount() {
    clearTimeout(this.sTimeout);
  }

  public render() {
    /* istanbul ignore next */
    const {
      count,
      isCountFetching,
      searchList = [],
      mainRegions = [],
      mainCities = [],
      regionsByID = {},
      regionColumns = [[], [], [], []],
      citiesColumns = [[], [], [], []],
      onClose,
      open,
    } = this.props;
    const { currentRegion, step } = this.state;

    return (
      <ModalWindow onClose={onClose} open={open} width="calc(100% - 40px)" maxWidth={SIZE_1040}>
        <Container
          data-testid="CityModal"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height={SIZE_WIDE}
          maxHeight={SIZE_VH_80}
        >
          <Container padding={`0 ${SIZE_20} ${SIZE_16}`} boxShadow="0 5px 10px 0 rgba(0, 0, 0, 0.1)" shadow>
            <CityModalInput onClick={this.handleListClick} onChange={this.handleInputChange} searchList={searchList} />
          </Container>
          <Container padding={`${SIZE_24} ${SIZE_20}`} height={SIZE_WIDE} overflow="auto">
            <CityModalContent
              step={step}
              currentRegion={currentRegion}
              regionsByID={regionsByID}
              mainRegions={step === 1 ? mainRegions : mainCities}
              regionColumns={
                step === 2 && (citiesColumns[0].length || mainRegions.length) ? citiesColumns : regionColumns
              }
              onClick={this.handleRegionClick}
            />
          </Container>
          <Divider />
          <CityModalFooter
            count={count}
            isCountFetching={isCountFetching}
            step={step}
            onBackClick={this.handleBack}
            onSubmit={this.handleSubmit}
          />
        </Container>
      </ModalWindow>
    );
  }

  private setDeliverState = (newState: ICityModalState) => {
    this.sTimeout = setTimeout(() => this.setState(newState), 0);
  };

  private handleInputChange = debounce(DEFAULT_INPUT_TIMEOUT, (inputValue: string) => {
    this.props.onInput(inputValue);
  });

  private handleSubmit = () => {
    const {
      setRegion,
      onSubmit,
      onSubmitModal,
      setRegionFilter,
      canUseHiddenBase,
      currentRegion: previousRegion,
    } = this.props;
    const { currentRegion } = this.state;

    if (currentRegion !== this.props.currentRegion) {
      setRegionFilter(currentRegion.id);
      setRegion(currentRegion, previousRegion);
      onSubmit(canUseHiddenBase);
    }

    onSubmitModal();
  };

  private handleBack = () => {
    this.setDeliverState({ step: 1, currentRegion: this.props.currentRegion });
    this.props.clearCities();
    this.props.getOffersCount();
  };

  private handleRegionClick = (region: IRegion): void => {
    let capitalID;

    if (isMoscowAndMoscowArea(region)) {
      capitalID = MOSCOW_AREA_ID;
    } else if (isSPBAndLo(region)) {
      capitalID = LO_ID;
    }

    this.changeRegion(region, 2, capitalID);
  };

  private handleListClick = (options: { id: string; label: string }) => {
    const region = this.props.searchList[parseInt(options.id, 10)];
    this.changeRegion(region, 2, region.parentId, true);
  };

  private changeRegion = (
    region: IRegion | ISearchCatalogItemSchema,
    targetStep: TCityModalStep,
    parentId?: number | null,
    fromSearch?: boolean,
  ) => {
    const { onRegionChange } = this.props;
    const { currentRegion } = this.state;

    if (this.state.step === 1 || fromSearch) {
      onRegionChange(parentId || region.id);
    }

    this.setDeliverState({
      currentRegion: region,
      step: targetStep,
    });

    if (region.id !== currentRegion.id) {
      this.props.getOffersCount(region);
    }
  };
}

function mapStateToProps(state: IApplicationState): ICityModalStoreProps {
  const {
    region: { currentRegion, regionsByID },
  } = state;
  const regionCities = selectRegionCities(state);
  const regions = getRegionsByAlphabet(state);
  const regionsLength = state.region.regions.length - regions._.length;
  const regionColumns = getRegionsColumns(regions, regionsLength);
  const cities = getCitiesByAlphabet(state);
  const citiesLength = regionCities.length - cities._.length;
  const citiesColumns = getRegionsColumns(cities, citiesLength);
  const count = state.filters.offersCount.count;
  const isCountFetching = state.filters.offersCount.isFetching;

  return {
    count,
    isCountFetching,
    regionsByID,
    currentRegion,
    mainRegions: regions._ as IRegion[],
    mainCities: cities._ as IRegion[],
    regionColumns,
    citiesColumns,
    searchList: selectRegionSearchCities(state),
    canUseHiddenBase: selectCanUseHiddenBase(state),
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): ICityModalDispatchProps {
  return {
    onSubmit: (canUseHiddenBase: boolean) => {
      dispatch(
        getOffersData({
          trackingAction: trackingActionPopupGeoSave,
          canUseHiddenBase,
        }),
      );
    },
    onInput: (text: string) => dispatch(getRegionSearchCities(text)),
    onRegionChange: (regionId: number) => {
      dispatch(getRegionCities(regionId)).catch(() => {});
    },
    clearCities: () => dispatch(clearRegionCities()),
    setRegion: (region: IRegion, previousRegion: IRegion) => {
      dispatch(setCurrentRegion(region));
      dispatch(clearTagsAfterChangeRegion(previousRegion.id));
      dispatch(updateTags());
      createSimpleAction(dispatch, geoDescriptor[1])();
    },
    setRegionFilter: createSimpleAction(dispatch, regionDescriptor[0]),
    getOffersCount: (region?: IRegion) => {
      dispatch(getOffersCount(region));
    },
  };
}

export const CityModal = connect(mapStateToProps, mapDispatchToProps)(CityModalComponent);
