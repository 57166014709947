import { ca } from '@cian/analytics';
import { IProduct } from '../../types/analytics';

export interface ITrackUpcomingSaleButtonClickParams {
  gaLabel: string | null;
  product: IProduct;
}

export function trackUpcomingSaleButtonClick({ gaLabel, product }: ITrackUpcomingSaleButtonClickParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'Click_sales_soon_application',
    label: gaLabel,
    products: [product],
  });
}
