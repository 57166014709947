import { IRegion } from '../../types/regions';

export const getBBoxFromRegion = (region: IRegion) => {
  const { boundedBy } = region;
  if (!boundedBy || !boundedBy.upperCorner || !boundedBy.lowerCorner) {
    return undefined;
  }

  return [
    [boundedBy.upperCorner.lng, boundedBy.upperCorner.lat],
    [boundedBy.lowerCorner.lng, boundedBy.lowerCorner.lat],
  ] as [[number, number], [number, number]];
};
