import { IJsonQuery } from '../../utils/filters';
import {
  fetchGetNewbuildingsCountForSerp,
  IGetNewbuildingsCountForSerpResponse,
  TGetNewbuildingsCountForSerpResponse,
} from '../../repositories/newbuilding-search/v1/get-newbuildings-count-for-serp';
import { getHttpApi } from '@cian/http-api/browser';

export interface IOffersJsonQueryRequestSchema {
  subdomain: string;
  jsonQuery: IJsonQuery;
}

export async function getOffersDataCount(
  jsonQuery: IJsonQuery,
  subdomain: string,
): Promise<TGetNewbuildingsCountForSerpResponse> {
  const httpApi = getHttpApi();
  const parameters: IOffersJsonQueryRequestSchema = {
    jsonQuery,
    subdomain,
  };

  return fetchGetNewbuildingsCountForSerp({ httpApi, parameters });
}

export async function resolveOffersDataCount(
  jsonQuery: IJsonQuery,
  subdomain: string,
): Promise<IGetNewbuildingsCountForSerpResponse | { count: undefined }> {
  const getSearchOffersCountDesktopResponse = await getOffersDataCount(jsonQuery, subdomain);
  if (getSearchOffersCountDesktopResponse.statusCode === 200) {
    return getSearchOffersCountDesktopResponse.response;
  } else {
    return { count: undefined };
  }
}
