export enum EMediaActions {
  set = 'set/media',
}

export interface IMediaAction {
  type: EMediaActions;
}

export function setMedia() {
  return {
    type: EMediaActions.set,
  };
}
