import * as React from 'react';
import * as keyCodes from '../../constants/keyCodes';

export interface IOutsideClickEvent extends MouseEvent {
  target: HTMLInputElement | null;
}

export interface IClickOutsideProps extends React.PropsWithChildren {
  onOutside(e?: IOutsideClickEvent): void;
}

export class ClickOutside extends React.Component<IClickOutsideProps> {
  private node: HTMLElement;
  private clickTimer: number;

  public componentDidMount() {
    this.clickTimer = window.setTimeout(() => {
      document.addEventListener('click', this.handleOutsideClick, false);
      window.addEventListener('resize', this.handleResize, false);
      document.addEventListener('keydown', this.handleKeyDown, false);
    }, 0);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    window.removeEventListener('resize', this.handleResize, false);
    document.removeEventListener('keydown', this.handleKeyDown, false);
    window.clearTimeout(this.clickTimer);
  }

  private handleOutsideClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (e.target && this.node.contains(e.target as Node)) {
      return;
    }

    this.props.onOutside(e as IOutsideClickEvent);
  };

  private handleResize = () => {
    this.props.onOutside();
  };

  private handleKeyDown = (event: KeyboardEvent) => {
    const intKey = event.keyCode;

    if (intKey === keyCodes.ESC) {
      this.props.onOutside();
    }
  };

  public render() {
    return (
      <span
        ref={node => {
          return node && (this.node = node);
        }}
      >
        {this.props.children}
      </span>
    );
  }
}
