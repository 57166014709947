export function getIndicesOf(doner: string, searchStr: string, onlyFirstInstance?: boolean, caseSensitive?: boolean) {
  let slug = searchStr;
  const slugLen = slug.length;

  if (slugLen === 0) {
    return [];
  }

  const indices = [];
  let startIndex = 0;

  let sentence = doner;

  if (!caseSensitive) {
    slug = slug.toLowerCase();
    sentence = sentence.toLowerCase();
  }

  let index = sentence.indexOf(slug, startIndex);

  while (index > -1) {
    indices.push(index);

    if (onlyFirstInstance) {
      break;
    }

    startIndex = index + slugLen;
    index = sentence.indexOf(slug, startIndex);
  }

  return indices;
}
