import { definitionFactory } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const hasMortgageDefinition = definitionFactory({
  key: 'has_mortgage',
  mapper: jsonQueryValue => [
    {
      label: 'Возможна ипотека',
      value: jsonQueryValue,
    },
  ],
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'has_mortgage', options);
  },
});
