import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { Preloader } from '../Preloader';
import { Text } from '../Text';

import * as styles from './Input.css';

type TChangeEvent = React.ChangeEvent<HTMLInputElement>;

export interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  inputType?: 'input' | 'textarea';
  minHeight?: string;
  label?: string;
  sync?: boolean;
  isInvalid?: boolean;
  dataMark?: string;
  onChange?(e: TChangeEvent): void;
  onKeyArrowUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyArrowDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyEnter?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyEscape?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyTab?(e: React.KeyboardEvent<HTMLInputElement>): void;
}

const handleChange = (onChange?: (e: TChangeEvent) => void) => (event: TChangeEvent) => {
  if (onChange) {
    event.persist();
    onChange(event);
  }
};

export function Input({
  sync,
  inputType = 'input',
  minHeight,
  label,
  isInvalid,
  onChange,
  onKeyDown,
  onKeyArrowDown,
  onKeyArrowUp,
  onKeyEnter,
  onKeyEscape,
  onKeyTab,
  onFocus,
  dataMark = 'Input',
  ...rest
}: IInputProps) {
  const { disabled } = rest;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    const nativeEvent = event.nativeEvent as KeyboardEvent;
    const code = nativeEvent.code || nativeEvent.key;

    switch (code) {
      case 'ArrowDown':
      case 'Down':
        if (onKeyArrowDown) {
          onKeyArrowDown(event);
        }
        break;
      case 'ArrowUp':
      case 'Up':
        if (onKeyArrowUp) {
          onKeyArrowUp(event);
        }
        break;
      case 'Tab':
        if (onKeyTab) {
          onKeyTab(event);
        }
        break;
      case 'NumpadEnter':
      case 'Enter':
        if (onKeyEnter) {
          onKeyEnter(event);
        }
        break;
      case 'Escape':
        if (onKeyEscape) {
          onKeyEscape(event);
        }
        break;
      default:
        break;
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component = inputType as any;
  const inlineStyles = inputType === 'textarea' ? { minHeight } : {};

  return (
    <div
      {...mergeStyles([
        styles['field'],
        isInvalid && styles['invalid'],
        disabled && styles['disabled'],
        sync && styles['sync'],
      ])}
      data-mark={dataMark}
    >
      {label && (
        <div className={styles['label']}>
          <Text fontSize={12} lineHeight={18}>
            {label}
          </Text>
        </div>
      )}
      <Component
        className={styles['input']}
        style={inlineStyles}
        onChange={handleChange(onChange)}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        {...rest}
      />
      {sync && (
        <div className={styles['loader']}>
          <Preloader size={14} />
        </div>
      )}
    </div>
  );
}
