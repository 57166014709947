/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IRegionMetaResponseSchema } from '../../entities/schemas/RegionMetaResponseSchema';

export type TRegion_metaModel = IModel<IRegion_metaRequest, TRegion_metaResponse>;

export interface IRegion_metaRequest {
  /** ИД локации, например 1 или 2 **/
  locationId?: number | null;
}

export type TRegion_metaResponse = IRegion_metaResponse200 | IRegion_metaResponse500 | IRegion_metaResponse400;

export interface IRegion_metaResponse200 extends IModelResponse<IRegion_metaResponse> {
  statusCode: 200;
}

export interface IRegion_metaResponse500 extends IModelResponse<IRegion_metaResponseInternalServerError> {
  statusCode: 500;
}

export interface IRegion_metaResponse400 extends IModelResponse<IRegion_metaResponseError> {
  statusCode: 400;
}

export interface IRegion_metaResponse {
  data: IRegionMetaResponseSchema;
  status: EStatus;
}

export interface IRegion_metaResponseInternalServerError {
  errors: any[];
  message: string;
}

export interface IRegion_metaResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IRegion_metaResponse | IRegion_metaResponseInternalServerError | IRegion_metaResponseError;

export enum EStatus {
  /** undefined **/
  Ok = 'ok',
}

export interface IMappers<TResponse200, TResponse500, TResponse400> {
  200(response: IRegion_metaResponse): TResponse200;
  500(response: IRegion_metaResponseInternalServerError): TResponse500;
  400(response: IRegion_metaResponseError): TResponse400;
}
