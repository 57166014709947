import { EInfoForTgbActions } from './actionTypes';
import { INewbuildingInfoForTgbSchema } from '../../repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';

export type FetchInfoForTgbSuccess = {
  type: EInfoForTgbActions.fetchNewbuildingsInfoForTgbSuccess;
  payload: Record<number, INewbuildingInfoForTgbSchema>;
};

export function setNewbuildingsInfoForTgb(
  payload: Record<number, INewbuildingInfoForTgbSchema>,
): FetchInfoForTgbSuccess {
  return {
    payload,
    type: EInfoForTgbActions.fetchNewbuildingsInfoForTgbSuccess,
  };
}
