import { IThunkActionCreator } from '../../types/thunk';
import { ca } from '@cian/analytics';
import { getCPUrlFromNewbuildingGaLabel } from '../../utils/customPageUrl';
import { createUserTrackingDataEbc, getTrackingPageBaseParam, productEbcFromNewbuildingMapper } from './helpers';
import { IJsonQuery } from '../../utils/filters';

export function similarTrackingAction(
  action: string,
  jsonQuery: IJsonQuery,
  newbuildingId?: number,
  gaLabelFull?: string,
): IThunkActionCreator {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      offersData: { similarNewbuildings },
    } = state;

    ca('eventEbc', {
      name: 'oldevent',
      category: 'similar_objects',
      action,
      label: gaLabelFull ? getCPUrlFromNewbuildingGaLabel(gaLabelFull) : '',
      products: newbuildingId
        ? similarNewbuildings.filter(({ id }) => id === newbuildingId).map(productEbcFromNewbuildingMapper)
        : similarNewbuildings.map(productEbcFromNewbuildingMapper),
      page: getTrackingPageBaseParam(state),
      user: createUserTrackingDataEbc(state),
      searchConditions: jsonQuery,
    });
  };
}
