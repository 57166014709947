import { apartmentTypeDescriptors, OFF_VALUE, ON_VALUE } from './apartmentTypeDescriptors';
import { getPromoSearchTypes } from '../../selectors/filters';
import { IApplicationState } from '../../types/redux';
import { descriptorsFactory, IFilterPureDescriptor } from '../../utils/filters';
import { APARTMENTS_VALUE, DISCOUNTS_BY_PROPERTY_VALUE } from '../promoSearchTypes';

const getWhenNot = (value: string) => {
  if (value === DISCOUNTS_BY_PROPERTY_VALUE) {
    return apartmentTypeDescriptors.filter(item => item.state === ON_VALUE).flatMap(item => item.form);
  }

  if (value === APARTMENTS_VALUE) {
    return apartmentTypeDescriptors.filter(item => item.state === OFF_VALUE).flatMap(item => item.form);
  }

  return undefined;
};

export const createDiscountPromoSearchTypeDescriptors = (state: IApplicationState): IFilterPureDescriptor[] => {
  const createDescriptor = descriptorsFactory({ name: 'DiscountPromoSearchType', clear: [] });

  const promoSearchTypes = getPromoSearchTypes(state);

  if (!promoSearchTypes) {
    return [];
  }

  const locals = promoSearchTypes.discount.map(item => ({
    form: [
      {
        key: 'promo_search_types' as const,
        type: 'terms' as const,
        value: [item.value],
      },
    ],
    label: item.name,
    state: item.value,
    whenNot: getWhenNot(item.value),
  }));

  return createDescriptor(...locals);
};
