import { IApplicationState } from '../../types/redux';
import { getNewbuildingPromoSnippetDct, getWorkTime } from '../../utils/newbuildingPromoSnippet';
import { ISeller } from '@cian/frontend-newbuilding-call-component/es/types/seller';

export const getNewbuildingPromoSnippetSellers = (state: IApplicationState) =>
  state.offersData?.newbuildingPromoSnippet?.newbuilding?.sellers
    ? state.offersData.newbuildingPromoSnippet.newbuilding.sellers.reduce((sellers: ISeller[], seller, index) => {
        if (!seller.isBuilder && state.offersData.newbuildingPromoSnippet?.extensionFields?.sellers) {
          sellers.push({
            avatarUrl: seller.avatarUrl,
            name: seller.name,
            isLeadFactory: !!seller.isLeadFactory,
            ...getNewbuildingPromoSnippetDct(index, state.offersData.newbuildingPromoSnippet.extensionFields.sellers),
            workTime: getWorkTime(seller.workTimeInfo),
          });
        }

        return sellers;
      }, [])
    : [];
