import { IUpcomingSale } from '../../types/upcomingSale';
import { EUpcomingSale, TUpcomingSaleActions } from '../../actions/upcomingSale';

export const defaultState: IUpcomingSale = {
  showPopup: false,
  newbuildingId: null,
  gaLabel: null,
};

export function upcomingSale(state: IUpcomingSale = defaultState, action: TUpcomingSaleActions) {
  switch (action.type) {
    case EUpcomingSale.OpenUpcomingSalePopup:
      return {
        ...state,
        showPopup: action.payload,
      };
    case EUpcomingSale.SetNewbuildingId: {
      return {
        ...state,
        newbuildingId: action.payload,
      };
    }
    case EUpcomingSale.SetNewbuildingGaLabel: {
      return {
        ...state,
        gaLabel: action.payload,
      };
    }
    default:
      return state;
  }
}
