import { definitionFactory, TStateTagValue } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';

export const HouseTypeLabels: { [index: string]: string } = {
  brick: 'Кирпичный',
  monolith: 'Монолитный',
  brickMonolithic: 'Кирпично-монолитный',
  panel: 'Панельный',
};

export const houseTypeDefinition = definitionFactory({
  key: 'house_type',
  mapper: jsonQueryValue =>
    jsonQueryValue.map((value: TStateTagValue) => ({
      label: HouseTypeLabels[value as string],
      value,
    })),
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'house_type', options);
  },
});
