import { present } from '@cian/newbuilding-utils';
import { loadYmapsApi } from '../../utils/yamaps';

export function repeatTask<T>(times: number, task: () => Promise<T>): Promise<T> {
  if (times <= 1) {
    return task();
  }

  return task().catch(() => repeatTask(times - 1, task));
}

function isScriptError(item: {}): item is YMaps.IScriptError {
  return item && 'message' in item && (item as { message?: string }).message === 'scriptError';
}

export function suggest(request: string, options?: YMaps.ISuggestOptions): Promise<YMaps.ISuggestResult[]> {
  return repeatTask(3, () =>
    loadYmapsApi({ require: ['suggest'] })
      .then(ymaps => ymaps.suggest(request, options))
      .then(items => {
        if (isScriptError(items)) {
          throw new Error(items.message);
        }

        return items || [];
      }),
  );
}

export function makeSuggestionRequester(options?: YMaps.ISuggestOptions) {
  return (request: string) => {
    if (present(request)) {
      return suggest(`Россия, ${request}`, options);
    }

    return Promise.resolve([]);
  };
}
