import { IRegion } from '../../types/regions';
import { setCookie } from '@cian/utils/lib/browser/cookie';
import { ISearchCatalogItemSchema } from '../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';

export function setRegionCookies(region: IRegion | ISearchCatalogItemSchema) {
  if (region && typeof window !== 'undefined' && region.id !== 0) {
    setCookie('session_region_id', String(region.id), { secure: false });
    setCookie('session_main_town_region_id', String(region.mainTownId || region.id), { secure: false });
  }
}
