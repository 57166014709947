import * as React from 'react';
import { Carousel } from '../Carousel';
import { IImageInfo } from '../../types/newbuilding';
import { ISlideProps } from '../Carousel/Carousel';
import { Image } from '../Image';
import { CarouselBlock } from '../CarouselBlock';

interface IPromoSnippetCarouselProps {
  features: React.ReactNode;
  title: string;
  logo: React.ReactNode;
  images: Pick<IImageInfo, 'thumbnailUrl' | 'fullUrl' | 'isDefault'>[];
  labels: React.ReactNode;
}

export function PromoSnippetCarousel({ features, images, title, logo, labels }: IPromoSnippetCarouselProps) {
  return (
    <CarouselBlock isPromo={true} features={features} logo={logo} labels={labels}>
      <Carousel
        isPromo={true}
        slides={images.map(
          (img: IImageInfo, index: number) =>
            // eslint-disable-next-line react/display-name
            ({ visible }: ISlideProps) =>
              visible && (
                <Image
                  alt={`${index + 1}-я Фотография ${title}`}
                  src={img.thumbnailUrl || img.fullUrl}
                  objectFit="cover"
                  lazy={index !== 0}
                />
              ),
        )}
        defaultSlideIndex={images.findIndex((img: IImageInfo) => img.isDefault)}
      />
    </CarouselBlock>
  );
}
