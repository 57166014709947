import * as React from 'react';

/***** STYLES *****/
import * as styles from './TextAndIcon.css';

/***** UTILS *****/
import { IStyleArgument, mergeStyles } from '@cian/utils';

/***** COMPONENTS *****/
import { ITextProps, Text } from '../Text';

/***** TYPES *****/
import { TAlignItems } from '../../types/cssVariables';

export interface ITextAndIconProps extends ITextProps {
  alignItems?: TAlignItems;
  iconOpacity?: number;
  icon?: React.ReactNode;
  offset?: number;
  reverse?: boolean;
  textStyle?: IStyleArgument;
}

export interface ITextAndIconState {
  hovered?: boolean;
}

/***** COMPONENT *****/
export class TextAndIcon extends React.Component<ITextAndIconProps, ITextAndIconState> {
  public state: Readonly<ITextAndIconState> = {
    hovered: false,
  };

  public render() {
    const {
      /* With default variables */
      color,
      icon,
      display = 'inline',
      fontSize = 14,
      fontWeight = 'normal',
      lineHeight = 20,
      offset = 8,
      tagName = 'span',
      whiteSpace = 'normal',
      wordWrap = 'normal',

      /* Without default variables */
      alignItems,
      borderBox,
      children,
      colorHovered,
      displayDesktopMax,
      displayMobile,
      displayPhone,
      displayTablet,
      ellipsis,
      fontSizeDesktop,
      fontSizeMobile,
      lineHeightMobile,
      lineHeightDesktop,
      overflow,
      textAlign,
      textDecoration,
      type,
      verticalAlign,
      visibility,
      width,
      id,
    } = this.props;

    const inlineStyles = {
      alignItems,
    };

    const { hovered } = this.state;

    return (
      <div
        id={id}
        {...mergeStyles(
          styles.container,
          styles[`container-display--${display}`],
          displayDesktopMax && styles[`container-display_desktop_max--${displayDesktopMax}`],
          displayMobile && styles[`container-display_mobile--${displayMobile}`],
          displayPhone && styles[`container-display_phone--${displayPhone}`],
          displayTablet && styles[`container-display_tablet--${displayTablet}`],
          width && styles[`container-width--${width}`],
        )}
        onMouseEnter={this.setHovered}
        onMouseLeave={this.setHovered}
        data-mark="TextAndIcon"
      >
        <div {...mergeStyles(styles.element, styles['element-reverse'], inlineStyles)}>
          <Text
            ellipsis={ellipsis}
            overflow={overflow}
            textAlign={textAlign}
            textDecoration={textDecoration}
            type={type}
            verticalAlign={verticalAlign}
            visibility={visibility}
            borderBox={borderBox}
            color={hovered ? colorHovered : color}
            fontSize={fontSize}
            fontSizeMobile={fontSizeMobile}
            fontSizeDesktop={fontSizeDesktop}
            fontWeight={fontWeight}
            lineHeight={lineHeight}
            lineHeightMobile={lineHeightMobile}
            lineHeightDesktop={lineHeightDesktop}
            tagName={tagName}
            whiteSpace={whiteSpace}
            wordWrap={wordWrap}
          >
            {children}
          </Text>
          <div {...mergeStyles({ width: `${offset}px` }, styles.offset)} />
          <div className={styles['icon']}>{icon}</div>
        </div>
      </div>
    );
  }

  private setHovered = () => {
    this.setState({
      hovered: !this.state.hovered,
    });
  };
}
