import { descriptorsFactory } from '../../utils/filters';

export const penthouseTypeDescriptor = descriptorsFactory({ name: 'IsPenthouse', clear: ['is_penthouse'] })(
  {
    form: [{ key: 'is_penthouse', type: 'term', value: true }],
    label: 'Пентхаус',
    state: 'On',
  },
  {
    form: [],
    label: 'Пентхаус',
    state: 'Off',
  },
);
