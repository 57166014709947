import * as React from 'react';
import { Checkbox } from '@cian/ui-kit';

import { fromDeveloperDesdcriptor } from '../../constants/descriptors';
import { isDescriptorActive } from '../../utils/filters';
import { connectToDescriptors, IDescriptorProps } from '../сonnectToDescriptors';
import { updateTags } from '../../actions/filterTags';
import { TTypedThunkDispatch } from '../../types/thunk';
import { getOffersCount } from '../../actions/offersData';
import { Container } from '../../components/Container';

interface IFromDeveloperDispatchProps {
  updateTags(): {};
  getOffersCount(): void;
}

type IFromDeveloperOwnProps = IDescriptorProps<string>;

type TFromDeveloperProps = IFromDeveloperOwnProps & IFromDeveloperDispatchProps;

class FromDeveloperComponent extends React.Component<TFromDeveloperProps> {
  public shouldComponentUpdate(nextProps: TFromDeveloperProps) {
    const { descriptors, jsonQuery } = this.props;
    const nextValue = getActiveDescriptor({ descriptors: nextProps.descriptors, jsonQuery: nextProps.jsonQuery }).state;
    const value = getActiveDescriptor({ descriptors, jsonQuery }).state;

    return nextValue !== value;
  }

  public render() {
    const { actionCreator, jsonQuery, descriptors, updateTags, getOffersCount } = this.props;
    const activeDescriptor = getActiveDescriptor({ descriptors, jsonQuery });
    const isActive = activeDescriptor.state === 'On';
    const onClick = () => {
      actionCreator(isActive ? descriptors[1] : descriptors[0])();

      updateTags();
      getOffersCount();
    };

    return (
      <Container display="flex" alignItems="center">
        <Checkbox checked={isActive} onChange={onClick} label={activeDescriptor.label} />
      </Container>
    );
  }
}

function getActiveDescriptor({ jsonQuery, descriptors }: Pick<IFromDeveloperOwnProps, 'jsonQuery' | 'descriptors'>) {
  const selectedDescriptor = descriptors.find(descriptor => isDescriptorActive(descriptor)(jsonQuery));
  if (!selectedDescriptor) {
    return descriptors[1];
  }

  return selectedDescriptor;
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    updateTags: () => {
      dispatch(updateTags());
    },
    getOffersCount: () => {
      dispatch(getOffersCount());
    },
  };
}

export const FromDeveloper = connectToDescriptors(fromDeveloperDesdcriptor, mapDispatchToProps)(FromDeveloperComponent);
