import * as React from 'react';
import {
  TAdFoxBannerProps as TAdFoxBannerComponentProps,
  IAdFoxBannerOnLoadData,
  TAdFoxBannerSizeProps,
  IAdFoxBannerOnErrorError,
  connect as adFoxConnect,
  EAdFoxBannerOnErrorType,
} from '@cian/adfox-component';
import { IWithPostViewAnalyticsPayload, withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { useSelector } from 'react-redux';

import { AdfoxOwnTemplatesContainer } from '../AdfoxOwnTemplatesContainer';
import { selectAdfoxPuids } from '../../selectors/adfox';
import { EAdFoxBannerType, getBannerByType } from '../../constants/adfox';
import { AdfoxLayout } from '../../components/AdfoxLayout';

type TAdfoxContainerProps = {
  postViewAnalyticsData?: IWithPostViewAnalyticsPayload;
  type: EAdFoxBannerType;
  size: TAdFoxBannerSizeProps;
} & Omit<TAdFoxBannerComponentProps, 'params'>;

const AdfoxComponent = adFoxConnect(AdfoxOwnTemplatesContainer);
let isAdBlockActive = false;

export function AdfoxContainer({ postViewAnalyticsData, onLoad, onStub, onError, type, size }: TAdfoxContainerProps) {
  const [hidden, setHidden] = React.useState(false);
  const puids = useSelector(selectAdfoxPuids);

  const handleLoad = React.useCallback(
    (data: IAdFoxBannerOnLoadData) => {
      if (onLoad) {
        onLoad(data);
      }
    },
    [onLoad],
  );

  const handleStub = React.useCallback(() => {
    if (onStub) {
      onStub();
    }

    setHidden(true);
  }, [onStub, setHidden]);

  const handleError = React.useCallback(
    (error: IAdFoxBannerOnErrorError) => {
      if (onError) {
        onError(error);
      }

      if (error.type === EAdFoxBannerOnErrorType.LoadScriptError) {
        isAdBlockActive = true;
      }
      setHidden(true);
    },
    [onError, setHidden],
  );

  const banner = React.useMemo(() => {
    const bannerProps = {
      lazyLoad: {
        fetchMargin: 100,
        mobileScaling: 1,
      },
      ...size,
      params: {
        ...getBannerByType(type),
        ...(puids as { [key: string]: string }),
      },
      onLoad: handleLoad,
      onStub: handleStub,
      onError: handleError,
    };

    if (postViewAnalyticsData) {
      const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdfoxComponent, bannerProps, postViewAnalyticsData);

      return (
        <AdfoxLayout>
          <AdfoxWithPostView />
        </AdfoxLayout>
      );
    }

    return (
      <AdfoxLayout>
        <AdfoxComponent {...bannerProps} />
      </AdfoxLayout>
    );
  }, [size, handleLoad, handleStub, handleError, type]);

  if (hidden || isAdBlockActive) {
    return null;
  }

  return banner;
}
