import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from '@cian/ui-kit';

import { Tooltip } from '../../components/Tooltip';
import { Container } from '../../components/Container';
import { Text } from '../../components/Text';
import { Input } from '../../components/Input';
import { SIZE_16, SIZE_12, SIZE_8, SIZE_200, SIZE_240, SIZE_360, SIZE_4, SIZE_28 } from '../../constants/sizes';
import { TTypedThunkDispatch } from '../../types/thunk';
import { IApplicationState } from '../../types/redux';
import { subscribe } from '../../actions/subscribe';
import { TSubscriptionsResponse } from '../../api/subscription/subscriptions';
import { hasEmail } from '../../utils/verifications';
import { trackingActionSend } from '../../actions/analytics';
import { ClickOutside } from '../../components/outside/ClickOutside';

interface ISubscribeStoreProps {
  email: string;
}

interface ISubscribeDispatchProps {
  subscribe(email: string): Promise<TSubscriptionsResponse>;
  trackingSubscribeSend(): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISubscribeOwnProps {}

type TSubscribeProps = ISubscribeOwnProps & ISubscribeStoreProps & ISubscribeDispatchProps;

interface ISubscribeState {
  status: null | 'ok' | 'fail';
  email: string;
}

export class SubscribeComponent extends React.Component<TSubscribeProps, ISubscribeState> {
  public state: ISubscribeState = {
    status: null,
    email: this.props.email,
  };

  public componentDidMount() {
    document.addEventListener('scroll', this.handleCloseTooltip, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleCloseTooltip, false);
  }

  public render() {
    const { status, email } = this.state;

    if (status === 'ok') {
      return (
        <Container
          margin={`${SIZE_12} 0 ${SIZE_16}`}
          padding={`${SIZE_12}`}
          backgroundColor="blue_light_2"
          displayMobile="block"
          display="flex"
          justifyContent="space-between"
        >
          <Text>
            На <Text fontWeight="bold">{email}</Text> отправлено письмо для активации подписки
          </Text>
        </Container>
      );
    }
    const errorText = email && email.length ? 'Не удалось оформить подписку. Попробуйте снова.' : 'Введите email';

    return (
      <Container
        margin={`${SIZE_12} 0 ${SIZE_16}`}
        padding={`${SIZE_4} ${SIZE_12} ${SIZE_12}`}
        backgroundColor="blue_light_2"
        displayMobile="block"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        noPrint
      >
        <Container
          minWidth={SIZE_200}
          padding={`${SIZE_4} ${SIZE_16} 0 0`}
          flexDirection="column"
          justifyContent="center"
        >
          <Text>Узнайте первым о продаже квартир в этом ЖК</Text>
        </Container>
        <Container padding={`${SIZE_8} 0 0 0`} minWidth={SIZE_360} display="inline-flex">
          <Container padding={`0 ${SIZE_12} 0 0`} width={SIZE_240}>
            <ClickOutside onOutside={this.handleCloseTooltip}>
              <Tooltip arrow error notCovered tooltipContent={status === 'fail' ? errorText : ''}>
                <Input
                  placeholder="Ваша эл. почта"
                  value={email}
                  aria-label="Электронная почта"
                  onChange={event =>
                    this.setState({
                      email: event.target.value,
                      status: null,
                    })
                  }
                />
              </Tooltip>
            </ClickOutside>
          </Container>
          <Container height={SIZE_28}>
            <Button size="XS" onClick={this.handleSubscribe}>
              Подписаться
            </Button>
          </Container>
        </Container>
      </Container>
    );
  }

  private handleCloseTooltip = () =>
    this.setState({
      status: null,
    });

  private handleSubscribe = () => {
    const { subscribe, trackingSubscribeSend } = this.props;
    const { email } = this.state;

    subscribe(email)
      .then(() => {
        this.setState({ status: 'ok' });
        trackingSubscribeSend();
      })
      .catch(() => this.setState({ status: 'fail' }));
  };
}

function mapStateToProps({ user }: IApplicationState): ISubscribeStoreProps {
  return {
    email: (hasEmail(user) && user.email) || '',
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): ISubscribeDispatchProps {
  return {
    subscribe: (email: string) => dispatch(subscribe(email)),
    trackingSubscribeSend() {
      dispatch(trackingActionSend());
    },
  };
}

export const Subscribe = connect(mapStateToProps, mapDispatchToProps)(SubscribeComponent);
