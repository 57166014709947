import { ERegionActions, IRegionAction } from '../../actions/region/regions';
import { IRegion, IRegionsByID } from '../../types/regions';

export const regionsByID = (state: IRegionsByID = {}, action: IRegionAction) => {
  switch (action.type) {
    case ERegionActions.init: {
      const regions: IRegionsByID = {};

      action.data.forEach((region: IRegion) => {
        regions[region.id] = region;
      });

      return regions;
    }
    default:
      return state;
  }
};
