import { getHttpApi } from '@cian/http-api/browser';
import {
  fetchSuggest,
  ISuggestRequest,
  ISuggestResponse200,
  ISuggestResponse400,
} from '../../../repositories/geo-suggest/v2/suggest';

export async function getSearchAutocomplete(parameters: ISuggestRequest) {
  // eslint-disable-next-line no-useless-catch
  try {
    const httpApi = getHttpApi();

    const { response, statusCode } = await fetchSuggest<ISuggestResponse200, ISuggestResponse400>({
      httpApi,
      parameters,
    });

    if ('errors' in response || statusCode !== 200) {
      throw response;
    }

    return response;
  } catch (error) {
    throw error;
  }
}
