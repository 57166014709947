import { ILogger } from '@cian/logger';

import { iniDistricts } from '../../actions/region/districts';
import { initLocation } from '../../actions/region/location';
import { initRegions } from '../../actions/region/regions';
import { initUndergrounds } from '../../actions/region';
import { getRegionMeta, resolveGetRegions } from '../../api/region';
import { TReduxStore } from '../../types/redux';
import { IHttpApi } from '@cian/http-api';
import { IRegionMetaResponseSchema } from '../../repositories/monolith-python/entities/schemas/RegionMetaResponseSchema';
import { IUnderground } from '../../types/regions';
import { IRegion } from '../../types/region';

export interface IInitInfo {
  regionId: number;
  store: TReduxStore;
  logger: ILogger;
  subdomain: string;
  httpApi: IHttpApi;
}

/* istanbul ignore next */
export function initNewbuildingSearch({ regionId, store, logger, subdomain, httpApi }: IInitInfo) {
  try {
    getRegionMeta(regionId, logger, subdomain, httpApi).then(
      ({ districts, underground, location }: IRegionMetaResponseSchema) => {
        store.dispatch(iniDistricts(districts));
        store.dispatch(initUndergrounds(underground as IUnderground[]));
        store.dispatch(initLocation(location as IRegion));
      },
    );
    resolveGetRegions({ httpApi, subdomain, logger }).then(regions => store.dispatch(initRegions(regions)));
  } catch (err) {
    logger.error(err, {
      message: err.message,
      stack: err.stack,
      comment: 'Error in initNewbuildingSearch',
    });
  }
}
