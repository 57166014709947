import { AnyAction } from 'redux';
import { defaultRange, descriptorsFactory, IJsonQuery } from '../../utils/filters';

const modifyValue = ({ underground_walk_time = defaultRange }: IJsonQuery, action: AnyAction) => {
  return action
    ? Object.assign({}, underground_walk_time.value, { [action.subType]: action.value })
    : underground_walk_time.value;
};

export const undergroundWalkTimeDescriptors = descriptorsFactory({
  name: 'UndergroundWalkTime',
  clear: ['underground_walk_time'],
})({
  form: [{ key: 'underground_walk_time', type: 'range', value: modifyValue }],
  label: '',
  state: 'underground_walk_time',
});
