import * as React from 'react';
import * as styles from './Layout.css';

import { mergeStyles } from '@cian/utils';

/***** TYPES *****/
type TDisplayType = 'none' | 'block';

export interface ILayoutProps extends React.HTMLProps<HTMLDivElement> {
  /* Native Props */
  children: React.ReactNode;
  display?: TDisplayType; // default display for 992…1231

  /* Custom Props */
  displayDesktopMax?: TDisplayType; // display for 1232…1376
  displayMobile?: TDisplayType; // display for 320…991
  displayPhone?: TDisplayType; // display for 320…639
  displayTablet?: TDisplayType; // display for 640…991
}

/***** COMPONENT *****/
export function Layout(props: ILayoutProps) {
  const {
    /* With default variables */
    display = 'block',
    /* Without default variables */
    children,
    displayDesktopMax,
    displayMobile,
    displayPhone,
    displayTablet,
    id,
  } = props;

  return (
    <div
      id={id}
      {...mergeStyles(
        styles.container,
        styles[`container-display--${display}`],
        displayDesktopMax && styles[`container-display_desktop_max--${displayDesktopMax}`],
        displayMobile && styles[`container-display_mobile--${displayMobile}`],
        displayPhone && styles[`container-display_phone--${displayPhone}`],
        displayTablet && styles[`container-display_tablet--${displayTablet}`],
      )}
    >
      {children}
    </div>
  );
}
