import * as React from 'react';
import { UIHeading3 } from '@cian/ui-kit';

import { Divider } from '../../Divider';

import * as styles from './FieldRowGroupTitle.css';

interface IFieldRowGroupTitleProps {
  title: string;
}

export const FieldRowGroupTitle: React.FC<IFieldRowGroupTitleProps> = ({ title }) => (
  <section className={styles['container']}>
    <div className={styles['heading-wrapper']}>
      <UIHeading3>{title}</UIHeading3>
    </div>
    <Divider />
  </section>
);
