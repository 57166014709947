import { memoize } from '@cian/newbuilding-utils';
import { getPromoSearchTypes } from '../../../selectors/filters';
import { definitionFactory, TStateTagValue } from '../definitionFactory';
import { onDeleteAction } from '../definitionsDeleteAction';
import { getValueToTagLabelMap } from '../../../utils/promoSearchTypes';

const getValueToTagLabelMapMemoized = memoize(getValueToTagLabelMap);

export const promoSearchTypeDefinition = definitionFactory({
  key: 'promo_search_types',
  mapper: (jsonQueryValue, _tags, appState) => {
    const promoSearchTypes = getPromoSearchTypes(appState);

    if (!promoSearchTypes) {
      return [];
    }

    return jsonQueryValue.map((value: TStateTagValue) => {
      const valueToLabelMap = getValueToTagLabelMapMemoized(promoSearchTypes);

      return {
        label: valueToLabelMap.get(value),
        value,
      };
    });
  },
  onDeleteAction: (value, options = {}) => {
    return onDeleteAction(value, 'promo_search_types', options);
  },
});
