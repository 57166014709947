import * as React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentJsonQuery } from '../../selectors/location';
import { NewbuildingBlackFridayTopBanner } from '../../components/NewbuildingBlackFridayTopBanner';
import { Container } from '../../components/Container';
import { SIZE_24 } from '../../constants/sizes';

export const TopBannerContainer: React.FC = () => {
  const currentJsonQuery = useSelector(getCurrentJsonQuery);

  if (currentJsonQuery.is_black_friday) {
    return (
      <Container padding={`${SIZE_24} 0 ${SIZE_24} 0`}>
        <NewbuildingBlackFridayTopBanner />
      </Container>
    );
  }

  return null;
};
