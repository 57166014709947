export enum EPreloaderActions {
  changeVisibility = 'change_visibility/preloader',
}

export interface IPreloaderAction {
  type: EPreloaderActions;
  isVisible: boolean;
}

export function changePreloaderVisibility(isVisible: boolean) {
  return {
    isVisible,
    type: EPreloaderActions.changeVisibility,
  };
}
