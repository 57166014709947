import * as React from 'react';
import { UIHeading1 } from '@cian/ui-kit';
import { getHeading } from '../../selectors/seo/getHeading';
import { useSelector } from 'react-redux';

export const Heading = () => {
  const heading = useSelector(getHeading);

  return <UIHeading1 as="h1">{heading}</UIHeading1>;
};
