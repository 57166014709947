import { TTypedThunkDispatch } from '../../types/thunk';
import { IApplicationState } from '../../types/redux';
import { subscribeForUpdates } from '../../api/subscription';
import { IApplicationContext } from '../../types/applicationContext';

export function subscribe(email: string) {
  return (
    dispatch: TTypedThunkDispatch,
    getState: () => IApplicationState,
    { httpApi, logger }: IApplicationContext,
  ) => {
    const { offersData, location } = getState();

    return subscribeForUpdates({
      email,
      queryString: offersData.queryString,
      subdomain: location.subdomain,
      httpApi,
      logger,
    });
  };
}
