import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../types/interfaces';
import { IBrokerAnalyticsUser } from '../../../types/broker';

export const trackFallbackModalRetryClick = (sc: IJsonQuery, user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BrokerDeal',
    action: 'Click',
    label: 'BrokerDealPopUpRetry',
    sc,
    page: {
      pageType: 'ListingJK',
      siteType: 'desktop',
      extra: {
        is_link: false,
      },
    },
    user,
    useLastProducts: true,
  });
};
