import * as React from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/Container';
import { SIZE_24, SIZE_56 } from '../../constants/sizes';
import { IApplicationState } from '../../types/redux';
import { INewbuilding } from '../../types/newbuilding';

import { GKCard } from './GKCard';
import { TTypedThunkDispatch } from '../../types/thunk';
import { trackingActionClickSimilarJK } from '../../actions/analytics';
import { IJsonQuery } from '../../utils/filters';
import { compose } from 'ramda';
import { IVisitedOffersContext, withVisitedOffersContext } from '@cian/frontend-visited-offers-widget';
import { SimilarBlockTitle } from './SimilarBlockTitle';

interface ISimilarNewbuildingsComponentStoreProps {
  similarNewbuildings: INewbuilding[];
  jsonQuery: IJsonQuery;
}
interface ISimilarNewbuildingsComponentOwnProps {
  hasNewbuildings: boolean;
}

interface ISimilarNewbuildingsComponentDispatchProps {
  trackingClickSimilarJK(newbuildingId: number, gaLabelFull: string, jsonQuery: IJsonQuery): void;
}

type TSimilarNewbuildingsComponentProps = ISimilarNewbuildingsComponentOwnProps &
  ISimilarNewbuildingsComponentStoreProps &
  ISimilarNewbuildingsComponentDispatchProps &
  IVisitedOffersContext;

class SimilarNewbuildingsComponent extends React.PureComponent<TSimilarNewbuildingsComponentProps> {
  public render() {
    const { similarNewbuildings, hasNewbuildings, jsonQuery, trackingClickSimilarJK, setOfferVisited } = this.props;

    if (!similarNewbuildings || !similarNewbuildings.length) {
      return null;
    }

    return (
      <>
        <Container dataMark="SimilarNewbuildings" margin={`${hasNewbuildings ? SIZE_56 : SIZE_24} 0 ${SIZE_24}`}>
          <SimilarBlockTitle hasNewbuildings={hasNewbuildings} />
        </Container>
        <Container
          backgroundColor="white"
          borderStyle="solid"
          borderColor="gray_1"
          borderTopWidth={1}
          borderLeftWidth={1}
          borderRightWidth={1}
          borderBottomWidth={0}
        >
          {similarNewbuildings.map(newbuilding => (
            <GKCard
              key={newbuilding.id}
              newbuilding={newbuilding}
              onCardClick={() => {
                trackingClickSimilarJK(newbuilding.id, newbuilding.gaLabels.full, jsonQuery);
                setOfferVisited(newbuilding.id);
              }}
            />
          ))}
        </Container>
      </>
    );
  }
}

function mapStateToProps(state: IApplicationState): ISimilarNewbuildingsComponentStoreProps {
  return {
    similarNewbuildings: state.offersData.similarNewbuildings,
    jsonQuery: state.jsonQuery,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    trackingClickSimilarJK(newbuildingId: number, gaLabelFull: string, jsonQuery: IJsonQuery) {
      dispatch(trackingActionClickSimilarJK(newbuildingId, gaLabelFull, jsonQuery));
    },
  };
}

export function mapContextToProps({ setOfferVisited }: IVisitedOffersContext) {
  return {
    setOfferVisited,
  };
}

export const SimilarNewbuildings = compose(
  withVisitedOffersContext(mapContextToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(SimilarNewbuildingsComponent) as React.ComponentType<ISimilarNewbuildingsComponentOwnProps>;
